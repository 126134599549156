import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

import {nameStr} from  "gih_web_common";

import {upload} from "../../../utils/firebase";
import {FILES} from  "../../../utils/analyticsConstants";


export function modelUpdate(campaignId, opts, user) {
    return {
        _id: null,
        type: opts.value,
        postedBy: nameStr(user),
        heading: opts.defaultHeading,
        videoName: null,
        imageName: null,
        text: "",
        threshold: null,
        campaignId: campaignId,
    }
}

export const typeOptions = [
    {
        value: "progress",
        label: "A general progress update that everyone can see",
        title: "Campaign progress update",
        tableName: "Campaign updates",
        icon: TaskAltIcon,
        colour: "text-green-500",
        defaultHeading: "",
    }, {
        value: "thanks",
        label: "A donor thank you message",
        tableName: "Messages of thanks",
        title: "Thank you message",
        icon: ThumbUpIcon,
        colour: "text-yellow-500",
        defaultHeading: "Thank you for your donation!",
    }
]

export async function validate(values, initial) {

    console.log("Validating:", values)

    if (values.type === "thanks" && !initial.videoName && !values.videoFile) {
        throw "You must upload a video message when thanking donors."
    }

    if (!initial.imageName && !values.imageFile && !initial.videoName && !values.videoFile) {
        throw "Please upload a picture or video to bring your update to life!"
    }

    const update = {
        type: values.type,
        postedBy: values.postedBy,
        heading: values.heading,
        text: values.message,
        imageName: initial.imageName,
        videoName: initial.videoName,
        threshold: (values.type === "thanks") ? parseInt(values.threshold) : null,
    }

    await upload(values.imageFile, 'campaign_update', 'primary', FILES.campaignUpdateImage, fullPath => { update.imageName = fullPath })
    await upload(values.videoFile, 'campaign_update', 'primary', FILES.campaignUpdateVideo, fullPath => { update.videoName = fullPath })

    console.log("Campaign update:", update)

    return update
}

export function getAudience(update) {
    switch (update.type) {
        case "progress":
            return "Everyone";
        case "thanks":
            return `Anyone donating £${update.threshold} or more`;
        default:
            return "???"
    }
}
