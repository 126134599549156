import {useSelector} from "react-redux";
import {useEffect, useState} from "react";

import {useMutation} from "@apollo/react-hooks";

import {FormWrapper, FormInput, FormPrimaryButton, AlertModal, GlassCard} from "gih_web_common";

import {SEND_INVITE} from "../../utils/graphql/user";
import {logScreenViewEvent, logActionSuccess, logActionGraphQLFailure} from "../../utils/analytics";
import {ACTIONS, SCREEN_NAME, SCREEN_CLASS} from "../../utils/analyticsConstants";


export default function SendInvitePage() {

    const charity = useSelector(state => state.charity);
    const [sendInviteMutation] = useMutation(SEND_INVITE);

    const [failureMessage, setFailureMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const sendInvite = (values) => {
        return new Promise((resolve, reject) => {
            const invite = {
                toEmail: values["toEmail"].trim().toLowerCase(),
                charityId: charity.id
            }
            sendInviteMutation({
                variables: {
                    invite: invite
                }
            }).then(() => {
                logActionSuccess(ACTIONS.inviteSend, `Invite e-mail queued for ${invite.toEmail}`);
                setSuccessMessage(`Successfully sent invite e-mail to ${invite.toEmail}`);
                resolve("Success");
            }, e => {
                logActionGraphQLFailure(ACTIONS.inviteSend, e);
                if (e.message.includes("Bad Request (400)")) {
                    setFailureMessage(`"${invite.toEmail}" does not appear to be a valid e-mail address`);
                } else {
                    setFailureMessage(`Failed to send invite - please try again later`);
                }
                reject(e);
            });
        })
    }

    useEffect(() => {
        logScreenViewEvent(SCREEN_NAME.sendInvite, SCREEN_CLASS.user)
    }, [])

    return (
        <div className="flex justify-center items-center overflow-y-auto" style={{ height: 'calc(100vh - 64px)' }}>
            <GlassCard>
                <div className="text-2xl p-2">Invite a colleague to access the<br/>Giving Is Human portal</div>
                <FormWrapper onSubmit={sendInvite} reset>
                    <div className="space-y-3">
                        <FormInput
                            id="toEmail"
                            name="toEmail"
                            type="text"
                            placeholder="e-mail"
                            label="Send invite to e-mail"
                            required
                        />
                        <FormPrimaryButton type="submit">
                            Send invite
                        </FormPrimaryButton>
                    </div>
                </FormWrapper>

                { successMessage &&
                    <AlertModal type="success" message={successMessage} onDismiss={() => setSuccessMessage(null)} />
                }

                { failureMessage &&
                    <AlertModal type="warning" message={failureMessage} onDismiss={() => setFailureMessage(null)} />
                }
            </GlassCard>
        </div>
    )
}
