export const ACTIONS = {
    activityCreate: 'ActivityCreate',
    activityCancel: 'ActivityCancel',
    activityUpdate: 'ActivityUpdate',
    participationStatusSet: 'ParticipationStatusSet',
    campaignCreate: 'CampaignCreate',
    campaignCancel: 'CampaignCancel',
    campaignUpdate: 'CampaignUpdate',
    campaignUpdateCreate: 'CampaignUpdateCreate',
    campaignUpdateUpdate: 'CampaignUpdateUpdate',
    campaignUpdateDelete: 'CampaignUpdateDelete',
    charityCreate: 'CharityCreate',
    charityUpdate: 'CharityUpdate',
    superAdminStatsQuery: 'SuperAdminStatsQuery',
    inviteSend: 'InviteSend',
    inviteRevoke: 'InviteRevoke',
    userFirebaseCreate: 'UserFirebaseCreate',
    userCreate: 'UserCreate',
    userActivate: 'UserActivate',
    userDeactivate: 'UserDeactivate',
    userUpdate: 'UserUpdate',
    userHintAck: 'UserHintAck',
    userSendPasswordResetEmail: 'UserSendPasswordResetEmail',
    userSendSelfSignupEmail: 'UserSendSelfSignupEmail',
    userPhoneVerificationCodeSend: 'UserPhoneVerificationCodeSend',
    userPhoneVerificationCodeCheck: 'UserPhoneVerificationCodeCheck',
    userAuthenticate: 'UserAuthenticate',
    userInactivityLogout: 'UserInactivityLogout',
    userLogin: 'UserLogin',
    userLogout: 'UserLogout',
    configGet: 'ConfigGet',
    giftAidReportGet: 'GiftAidReportGet',
};

export const FORMS = {
    activity: 'Activity',
    campaign: 'Campaign',
    charity: 'Charity',
}

export const FILES = {
    activityImage: 'ActivityImage',
    activityVideo: 'ActivityVideo',
    campaignImage: 'CampaignImage',
    campaignVideo: 'CampaignVideo',
    campaignUpdateImage: 'CampaignUpdateImage',
    campaignUpdateVideo: 'CampaignUpdateVideo',
    charityLogoImage: 'CharityLogoImage',
    charityProfileImage: 'CharityProfileImage',
}

export const SCREEN_CLASS = {
    volunteers: "Volunteers",
    charity: "Charity",
    activity: "Activity",
    campaign: "Campaign",
    user: "User",
};

export const SCREEN_NAME = {
    // Volunteers.
    manageVolunteers: "ManageVolunteers",
    manageVolunteersByActivity: "ManageVolunteersByActivity",

    // Charity.
    charityProfile: "CharityProfile",
    charityUsers: "CharityUsers",
    charityVolunteerStats: "CharityVolunteerStats",
    charityCampaignStats: "CharityCampaignStats",
    charityEdit: "CharityEdit",
    charitySelect: "CharitySelect",

    // Activity.
    activityCreate: "ActivityCreate",
    activityDetail: "ActivityDetail",
    activityEdit: "ActivityEdit",
    activityList: "ActivityList",
    activityCalendar: "ActivityCalendar",

    // Campaign.
    campaignCreate: "CampaignCreate",
    campaignDetail: "CampaignDetail",
    campaignEdit: "CampaignEdit",
    campaignList: "CampaignList",

    // User.
    accountSettings: "AccountSettings",
    sendInvite: "SendInvite",
};
