import {useState} from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

import {useQuery} from "@apollo/react-hooks";

import TextsmsIcon from '@mui/icons-material/Textsms';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import {buttonStyles} from "gih_web_common";

import {GET_ACTIVITY_CREATORS_BY_CHARITYID} from "../../utils/graphql/activity";

import {Hint} from "../common/hint";

import {dayOptions} from "./form/repetition";


function notificationsOff(user) {
    return !user.enableConcierge && (user.smsNotificationPhone === null || user.smsNotificationDays === 0)
}


export function NotificationsHint() {

    const charity = useSelector(state => state.charity);
    const user = useSelector(state => state.user);

    const [isFirst, setIsFirst] = useState(false);

    useQuery(GET_ACTIVITY_CREATORS_BY_CHARITYID, {
        variables: { charityId: charity.id},
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            const myRecentlyCreated = data.portalFindActivitiesByCharityId.filter(activity => activity.createdById === user._id)
            setIsFirst(myRecentlyCreated.length === 1)
        }
    });

    return (
        <Hint name="notification" forceVisible={user => notificationsOff(user)}>
            { isFirst &&
            <p className="font-bold text-base">Congratulations on creating your first volunteering opportunity!</p>
            }

            { !user.enableConcierge && user.smsNotificationPhone === null &&
            <>
                <p>You're <strong>not</strong> currently set to receive <strong>notifications</strong> when new Volunteers come
                forward or message your organisation.</p>
                <p>We'd like to send you a once-per-day catchup e-mail whenever a new volunteer expresses interest or if you
                have any unread messages from prospective volunteers.</p>
                <Link to="/user/settings" className={buttonStyles.redLgNarrow}>
                    <MailOutlineIcon/><span className="ml-1">Setup notification by email</span>
                </Link>
            </>
            }

            { user.enableConcierge &&
            <p>We'll send a once-per-day catchup e-mail to you and any other admins connected to your
            organisation's profile whenever a new volunteer expresses interest or if you
            have any unread messages from prospective volunteers.</p>
            }

            { user.smsNotificationPhone === null &&
            <>
                <p>Timely responses to new volunteers help keep them engaged! Be the first to know
                when you have new messages by <strong>turning on SMS notifications</strong> - you pick the days of
                the week and we'll give you a reminder whenever something new comes in (working hours
                only and no more than once per hour).</p>
                <Link to="/user/settings" className={buttonStyles.altLgNarrow}>
                    <TextsmsIcon/><span className="ml-1">Setup notification by text</span>
                </Link>
            </>
            }

            { user.smsNotificationPhone !== null && user.smsNotificationDays === 0 &&
            <>
                <p>You have setup SMS notifications for new volunteer interest and messages, but they're not currently
                enabled for any day of the week.</p>
                <Link to="/user/settings" className={buttonStyles.redLgNarrow}>
                    <TextsmsIcon/><span className="ml-1">Update text notifications</span>
                </Link>
            </>
            }

            { user.smsNotificationPhone !== null && user.smsNotificationDays !== 0 &&
            <>
                <p>You're set to receive SMS notifications to {user.smsNotificationPhone} during working hours
                on {dayOptions.filter((day, index) => (user.smsNotificationDays & (1 << index))).map(day => day.label).join(', ')} whenever
                a new volunteer expresses interest or if you have any unread messages from prospective volunteers.
                </p>
                <Link to="/user/settings" className={buttonStyles.blueLgNarrow}>
                    <TextsmsIcon/><span className="ml-1">Change notification preferences</span>
                </Link>
            </>
            }
        </Hint>
    )
}
