import {DateTime} from "luxon";
import {Link} from "react-router-dom";

import {buttonStyles, TableContainer2, Table, TableHead, TableBody, TableRow, TableCell, RefreshCell, TagsCell, dateToString, intervalToString, nameStr, cellStyles, FormattedUserName} from "gih_web_common";

import {statusToText, statusToBackground} from "../../../utils/activity";
import {genderToString} from "../../../utils/address";

import {CharityCell} from "../../common/elements";

import {UserAvatar} from "../../chats/common/avatar";


export function AppUserTable({loading, error, users, refetch, withActions, emptyText}) {

    const now = DateTime.now();

    return (
        <TableContainer2
            loading={loading}
            error={error}
            rows={users}
            emptyText={emptyText}
            refresh={refetch}
            sx={{maxHeight: 740}}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <RefreshCell sx={cellStyles.dateTime} action={refetch} text="Created" />
                        { withActions &&
                        <TableCell sx={cellStyles.actions}>Actions</TableCell>
                        }
                        <TableCell sx={cellStyles.dateTime}>Last signin</TableCell>
                        <TableCell sx={cellStyles.dateTime}>Last seen</TableCell>
                        <TableCell sx={cellStyles.person}>Name</TableCell>
                        <TableCell sx={cellStyles.freeMedium}>Interest</TableCell>
                        <TableCell sx={cellStyles.freeNarrow}>Postcode</TableCell>
                        <TableCell sx={cellStyles.freeNarrow}>DoB</TableCell>
                        <TableCell sx={cellStyles.freeNarrow}>Gender</TableCell>
                        <TableCell sx={cellStyles.freeNarrow}>Phone</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { users?.map(u => (
                        <TableRow key={u.user._id}>
                            <TableCell align="left">{dateToString(u.user.createdAt)}</TableCell>
                            { withActions &&
                            <TableCell align="center"><Link to={`/overview/app-users/${u.user._id}`} className={buttonStyles.altLgNarrow}>Details</Link></TableCell>
                            }
                            <TableCell align="left">{dateToString(u.lastSignIn)}</TableCell>
                            <TableCell align="left">{dateToString(u.lastSeen)}</TableCell>
                            <TableCell align="left">
                                <div className="flex items-center gap-2">
                                    <UserAvatar chat={{ profilePicture: u.user.profilePicture, user_names: [ nameStr(u.user) ]}} />
                                    <FormattedUserName user={u.user} />
                                </div>
                            </TableCell>
                            <TagsCell
                                tags={u.participations}
                                textFn={p => `${p.activityId.name} (${p.charityId.name}) (${intervalToString(p.createdAt, now)}) (${statusToText(p.status)})`}
                                bgFn={p => statusToBackground(p.status, false)}
                            />
                            <TableCell align="left">{u.user.postcode}</TableCell>
                            <TableCell align="left">{u.user.age}</TableCell>
                            <TableCell align="left">{genderToString(u.user.gender)}</TableCell>
                            <TableCell align="left">{u.user.phone}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer2>
    );
}

export function PortalUserTable({loading, error, users, refetch, withActions, emptyText}) {

    return (
        <TableContainer2
            loading={loading}
            error={error}
            rows={users}
            emptyText={emptyText}
            refresh={refetch}
        >
            <Table sx={{minWidth: 1000}}>
                <TableHead>
                    <TableRow>
                        <RefreshCell sx={cellStyles.dateTime} action={refetch} text="Created" />
                        { withActions &&
                        <TableCell sx={cellStyles.actions}>Actions</TableCell>
                        }
                        <TableCell sx={cellStyles.charity}>Charity</TableCell>
                        <TableCell sx={cellStyles.dateTime}>Last signin</TableCell>
                        <TableCell sx={cellStyles.dateTime}>Last seen</TableCell>
                        <TableCell sx={cellStyles.email}>E-mail</TableCell>
                        <TableCell sx={cellStyles.person}>Name</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { users?.map(user => (
                        <TableRow key={user._id}>
                            <TableCell align="left">{dateToString(user.createdAt)}</TableCell>
                            { withActions &&
                            <TableCell align="center"><Link to={`/overview/portal-users/${user._id}`} className={buttonStyles.altLgNarrow}>Details</Link></TableCell>
                            }
                            <CharityCell charity={user.charityId} />
                            <TableCell align="left">{dateToString(user.lastSignIn)}</TableCell>
                            <TableCell align="left">{dateToString(user.lastSeen)}</TableCell>
                            <TableCell align="left">{user.email}</TableCell>
                            <TableCell align="left"><FormattedUserName user={user} /></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer2>
    );
}
