import {TableCell} from "@mui/material";

import {Lozenge} from "gih_web_common";

import {OUTCOMES} from "../../../utils/analytics";


export const colours = {
    general: 'bg-stone-500',
    giving: 'bg-primary-dark',
    volunteering: 'bg-alt-dark',
    fallback: 'bg-yellow-500',
    success: 'bg-green-500',
    failure: 'bg-red-500',
    warning: 'bg-orange-500',
    superAdmin: 'bg-blue-500',
};

export const appNoteworthyEventAttrs = new Map([
    ['MarketResearch', { category: 'install', colour: colours.general }],
    ['SearchLocation', { category: 'browse', colour: colours.general }],
    ['NewSignUp', { category: 'signup', colour: colours.general }],
    ['NewSignIn', { category: 'signup', colour: colours.general }],
    ['PersonalDetails', { category: 'signup', colour: colours.general }],

    ['ExpressedInterest', { category: 'active', colour: colours.volunteering }],
    ['ReExpressedInterest', { category: 'active', colour: colours.volunteering }],
    ['WithdrewInterest', { category: 'active', colour: colours.volunteering }],
    ['CheckInSuccess', { category: 'active', colour: colours.volunteering }],
    ['CheckInFailure', { category: 'active', colour: colours.volunteering }],
    ['ShareActivity', { category: 'interest', colour: colours.volunteering }],
    ['AddToCalendar', { category: 'interest', colour: colours.volunteering }],
    ['CalendarAccessDenied', { category: 'interest', colour: colours.volunteering }],

    ['ShareCampaign', { category: 'interest', colour: colours.giving }],
    ['StartDonation', { category: 'active', colour: colours.giving }],
    ['StartPayment', { category: 'active', colour: colours.giving }],
]);

const screenAttrs = new Map([
    ['LandingPage', { category: 'install', colour: colours.general }],
    ['MarketResearch', { category: 'install', colour: colours.general }],
    ['SetLocation', { category: 'install', colour: colours.general }],
    ['Interstitial', { category: 'install', colour: colours.general }],
    ['ActivitySearch', { category: 'browse', colour: colours.volunteering }],
    ['ActivityMap', { category: 'browse', colour: colours.volunteering }],
    ['CampaignSearch', { category: 'browse', colour: colours.giving }],
    ['CampaignMap', { category: 'browse', colour: colours.giving }],
    ['ChangeLocation', { category: 'browse', colour: colours.general }],
    ['ActivityDetail', { category: 'interest', colour: colours.volunteering }],
    ['CampaignDetail', { category: 'interest', colour: colours.giving }],
    ['CampaignUpdates', { category: 'interest', colour: colours.giving }],
    ['CharityDetail', { category: 'interest', colour: colours.general }],
    ['PhoneNumber', { category: 'signup', colour: colours.general }],
    ['OTPVerification', { category: 'signup', colour: colours.general }],
    ['SignupDetails', { category: 'signup', colour: colours.general }],
    ['PrivacyPolicy', { category: 'signup', colour: colours.general }],
    ['TsAndCs', { category: 'signup', colour: colours.general }],
    ['Chat', { category: 'active', colour: colours.volunteering }],
    ['ConfirmCheckin', { category: 'active', colour: colours.volunteering }],
    ['CheckinFailure', { category: 'active', colour: colours.volunteering }],
    ['QRScanner', { category: 'other', colour: colours.volunteering }],
    ['Achievements', { category: 'other', colour: colours.volunteering }],
    ['AchievementDetail', { category: 'other', colour: colours.volunteering }],
    ['ChatList', { category: 'other', colour: colours.volunteering }],
    ['MyActivities', { category: 'other', colour: colours.volunteering }],
    ['MyCampaigns', { category: 'other', colour: colours.giving }],
    ['MyDonations', { category: 'other', colour: colours.giving }],
    ['MyDonationsDetail', { category: 'other', colour: colours.giving }],
    ['MyDonationsStatement', { category: 'other', colour: colours.giving }],
    ['MyProfile', { category: 'other', colour: colours.general }],
    ['About', { category: 'other', colour: colours.general }],
    ['PersonalInformation', { category: 'other', colour: colours.general }],
    ['DonationAmount', { category: 'active', colour: colours.giving }],
    ['DonationMessage', { category: 'active', colour: colours.giving }],
    ['DonationGiftAid', { category: 'active', colour: colours.giving }],
    ['DonationGiftAidConfirmation', { category: 'active', colour: colours.giving }],
    ['DonationSummary', { category: 'active', colour: colours.giving }],
]);

const portalScreenAttrs = new Map([
    // Volunteers.
    ['ManageVolunteers', { category: 'volunteering', colour: colours.volunteering }],
    ['ManageVolunteersByActivity', { category: 'volunteering', colour: colours.volunteering }],

    // Charity.
    ['CharityProfile', { category: 'organisation', colour: colours.general }],
    ['CharityUsers', { category: 'admin', colour: colours.general }],
    ['CharityVolunteerStats', { category: 'organisation', colour: colours.volunteering }],
    ['CharityCampaignStats', { category: 'organisation', colour: colours.giving }],
    ['CharityEdit', { category: 'organisation', colour: colours.general }],

    // Activity.
    ['ActivityCreate', { category: 'volunteering', colour: colours.volunteering }],
    ['ActivityDetail', { category: 'volunteering', colour: colours.volunteering }],
    ['ActivityEdit', { category: 'volunteering', colour: colours.volunteering }],
    ['ActivityList', { category: 'volunteering', colour: colours.volunteering }],
    ['ActivityCalendar', { category: 'volunteering', colour: colours.volunteering }],

    // Campaign.
    ['CampaignCreate', { category: 'giving', colour: colours.giving }],
    ['CampaignDetail', { category: 'giving', colour: colours.giving }],
    ['CampaignEdit', { category: 'giving', colour: colours.giving }],
    ['CampaignList', { category: 'giving', colour: colours.giving }],

    // User.
    ['AccountSettings', { category: 'admin', colour: colours.general }],
    ['SendInvite', { category: 'admin', colour: colours.general }],
    
    // Super-admin.
    ['CharitySelect', { category: 'superAdmin', colour: colours.superAdmin }],
]);

export function getColour(mapping, event) {
    if (event.outcome !== null) {
        switch (event.outcome) {
            case OUTCOMES.success:
                return colours.success;

            case OUTCOMES.failedGraphQL:
            case OUTCOMES.failed:
                return colours.failure;

            case OUTCOMES.userError:
                return colours.warning;

            default:
                return colours.fallback;
        }
    } else {
        return mapping.get(event.what)?.colour ?? colours.fallback;
    }
}

export function getText(event) {
    let s = event.what;
    if (event.outcome !== null)
        s += ` (${event.outcome})`;
    return (event.text !== null) ? [ s, event.text ] : [ s ]; 
}

export function Screens({screens, filter, portal}) {
    return (
        <TableCell align="left">
        { screens.map(screen => {
            const attrs = (portal ? portalScreenAttrs : screenAttrs).get(screen.name);
            if (!attrs) {
                return (!filter || filter === "other") ? <Lozenge key={screen.name} colour={colours.fallback} text={`!!! ${screen.name}: ${screen.count}`} /> : null;
            } if (!filter || attrs.category === filter) {
                return <Lozenge key={screen.name} colour={attrs.colour} text={`${screen.name}: ${screen.count}`} />;
            } else {
                return null;
            }
        })}
        </TableCell>
    )
}
