import {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';

import {useMutation, useQuery} from "@apollo/react-hooks";

import {buttonStyles, cellStyles, GlassCard, OnLoadViewer, Modal, RefreshButton, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, AlertModal, dateToString, dateToStringRelative, nameStr, FormattedUserName} from "gih_web_common";

import {ALL_CHARITY_USERS_LIST, GET_INVITES_BY_CHARITY, SET_ACCOUNT_DISABLE, DELETE_INVITE} from "../../utils/graphql/charity";
import {logScreenViewEvent, logActionSuccess, logActionGraphQLFailure} from "../../utils/analytics";
import {ACTIONS, SCREEN_NAME, SCREEN_CLASS} from "../../utils/analyticsConstants";

import {dayOptions} from "../activities/form/repetition";
import {EmailTrace} from "../common/email";


function emailNotifications(user) {
    let text = user.enableConcierge ? "Enabled" : "-"
    if (user.lastEmailNotification !== null) {
        text += ` (last emailed ${dateToString(user.lastEmailNotification)})`
    }
    return text
}

function textNotifications(user) {
    let text

    if (user.smsNotificationPhone === null) {
        text = '-'
    } else if (user.smsNotificationDays !== 0 && user.smsNotificationDays !== null) {
        text = `Notify ${user.smsNotificationPhone} on ${dayOptions.filter((day, index) => (user.smsNotificationDays & (1 << index))).map(day => day.label).join(', ')}`
    } else {
        text = `Notifications to ${user.smsNotificationPhone} paused`
    }

    if (user.lastSmsNotification !== null) {
        text += ` (last text ${dateToString(user.lastSmsNotification)})`
    }

    return text
}

function InvitesTable() {

    const charity = useSelector(state => state.charity);

    const [invites, setInvites] = useState(null);
    const [inviteToDelete, setInviteToDelete] = useState(null);
    const [inviteDeleteOutcome, setInviteDeleteOutcome] = useState(null);

    const {loading, error, refetch} = useQuery(GET_INVITES_BY_CHARITY, {
        variables: {
            charityId: charity.id
        },
        fetchPolicy: "cache-and-network",
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setInvites(data.invitesByCharityId),
    })

    const [deleteInvite] = useMutation(DELETE_INVITE);

    function requestDeleteInvite(invite) {
        deleteInvite({
            variables: {
                charityId: charity.id,
                toEmail: invite.sentInviteEmail
            }
        }).then(() => {
            logActionSuccess(ACTIONS.inviteRevoke, `for ${invite.sentInviteEmail}`);
            setInviteToDelete(null);
            setInviteDeleteOutcome({
                type: "success",
                message: `All invites to "${invite.sentInviteEmail}" have been revoked.`
            });
            refetch();
        }, e => {
            logActionGraphQLFailure(ACTIONS.inviteRevoke, e);
            setInviteToDelete(null);
            setInviteDeleteOutcome({
                type: "warning",
                message: `Sorry, the invite for "${invite.sentInviteEmail}" could not be revoked - please try again later.`
            });
            throw e;
        });
    }

    return (
        <GlassCard width="w-full max-w-full">
            <div className="flex items-center pb-2">
                <RefreshButton action={refetch} />
                <div className="text-2xl font-bold">
                    Invites sent on behalf of your charity administrators
                </div>
            </div>
            <OnLoadViewer loading={loading && !invites}>
                { error &&
                    <div className="bg-red-600 text-white w-fit p-2 rounded my-1">{`Failed to fetch list of invites: ${error}`}</div>
                }
                { invites &&
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={cellStyles.email}>Invite sent to</TableCell>
                                <TableCell sx={cellStyles.dateTime}>Date last invited</TableCell>
                                <TableCell sx={cellStyles.relativeTime}>Expiry</TableCell>
                                <TableCell sx={cellStyles.freeNarrow}>Status</TableCell>
                                <TableCell sx={cellStyles.actions}>Revoke</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { invites.map((invite) => (
                                <TableRow key={invite.sentInviteEmail}>
                                    <TableCell>{invite.sentInviteEmail}</TableCell>
                                    <TableCell>{dateToString(invite.createdAt)}</TableCell>
                                    <TableCell>{dateToStringRelative(invite.expiresAt)}</TableCell>
                                    <TableCell>
                                        { invite.trace &&
                                        <EmailTrace trace={invite.trace} />
                                        }
                                    </TableCell>
                                    <TableCell>
                                        <button onClick={() => { setInviteToDelete(invite) }} className={buttonStyles.altLg} disabled={new Date(invite.expiresAt) < new Date()}>
                                            <CancelScheduleSendIcon/> Revoke Invite
                                        </button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                }
            </OnLoadViewer>

            { inviteToDelete &&
            <Modal
                title={`Are you sure you want to revoke all invites sent to "${inviteToDelete.sentInviteEmail}"?`}
                onDismiss={() => setInviteToDelete(null)}
                footer={
                    <button className={buttonStyles.redLg} type="button" onClick={() => requestDeleteInvite(inviteToDelete)}>
                        Revoke invite
                    </button>
                }
            >
                <div className="text-sm py-3">
                    Please note: this action cannot be undone.
                </div>
            </Modal>
            }

            { inviteDeleteOutcome !== null &&
            <AlertModal
                type={inviteDeleteOutcome.type}
                message={inviteDeleteOutcome.message}
                onDismiss={ () => {
                    setInviteDeleteOutcome(null)
                }}
            />
            }
        </GlassCard>
    )
}

function AdminsTable() {

    const charity = useSelector(state => state.charity);
    const firebaseId = useSelector(state => state.user?.firebaseId);
    const fullAccess = useSelector(state => state.user?.fullAccess);

    const [charityUsers, setCharityUsers] = useState(null);
    const [userToModify, setUserToModify] = useState(null);
    const [outcome, setOutcome] = useState(null);

    const {loading, error,  refetch} = useQuery(ALL_CHARITY_USERS_LIST, {
        variables: {
            charityId: charity.id
        },
        fetchPolicy: "cache-and-network",
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setCharityUsers(data.findUsersByCharityId),
    })

    const [setAccountDisable] = useMutation(SET_ACCOUNT_DISABLE);

    const requestSetAccountDisable = (user, disable) => {
        const action = disable ? ACTIONS.userDeactivate : ACTIONS.userActivate;
        setAccountDisable({
            variables: {
                firebaseId: user.firebaseId,
                disable: disable
            }
        }).then(() => {
            logActionSuccess(action, `user ${user.firebaseId}`);
            setUserToModify(null);
            setOutcome({
                type: "success",
                message: `The account for "${nameStr(user)}" has been ${disable ? "deactivated" : "activated"}.`
            });
            // TODO - could use the response here to update local data rather than refetch.
            refetch();
        }, e => {
            logActionGraphQLFailure(action, e);
            setUserToModify(null);
            setOutcome({
                type: "warning",
                message: `Sorry, the account for "${nameStr(user)}" could not be ${action} - please try again later.`
            });
            throw e;
        })
    }

    return (
        <GlassCard width="w-full max-w-full">
            <div className="text-2xl font-bold pb-2">
                Administrators associated with your charity
            </div>

            <OnLoadViewer loading={loading && !charityUsers}>
                { error &&
                    <div className="bg-red-600 text-white w-fit p-2 rounded my-1">{`Failed to fetch list of admins: ${error}`}</div>
                }
                { charityUsers &&
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={cellStyles.person}>Name</TableCell>
                                <TableCell sx={cellStyles.email}>Email</TableCell>
                                <TableCell sx={cellStyles.dateTime}>Created At</TableCell>
                                <TableCell sx={cellStyles.dateTime}>Last login</TableCell>
                                <TableCell sx={cellStyles.freeNarrow}>Email notifications</TableCell>
                                <TableCell sx={cellStyles.freeNarrow}>Text notifications</TableCell>
                                <TableCell sx={cellStyles.actions}>Enable/Disable</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { charityUsers.map((user) => (
                                <TableRow key={user._id}>
                                    <TableCell><FormattedUserName user={user} /></TableCell>
                                    <TableCell><span className={user.disabled ? "line-through" : ""}>{user.email}</span></TableCell>
                                    <TableCell>{dateToString(user.createdAt)}</TableCell>
                                    <TableCell>
                                        { fullAccess ? (
                                        <div className="flex flex-wrap gap-2 items-center">
                                            <span>{dateToString(user.lastSeen)}</span>
                                            <span><Link to={`/overview/portal-users/${user._id}`} className={buttonStyles.altLgNarrow}>Details</Link></span>
                                        </div>
                                        ) : (
                                        <span>{dateToString(user.lastSeen)}</span> 
                                        )}
                                    </TableCell>
                                    <TableCell>{emailNotifications(user)}</TableCell>
                                    <TableCell>{textNotifications(user)}</TableCell>
                                    <TableCell>
                                        <button onClick={() => { setUserToModify(user) }} className={buttonStyles.altLg} disabled={user.firebaseId === firebaseId}>
                                            { user.disabled ? <span><PersonIcon/> Enable Account</span> : <span><PersonOffIcon/> Disable Account</span> }
                                        </button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                }
            </OnLoadViewer>

            { userToModify &&
            <Modal
                title={`Are you sure you want to ${userToModify.disabled ? "enable" : "disable"} the account for "${nameStr(userToModify)}"?`}
                onDismiss={() => setUserToModify(null)}
                footer={
                    <button
                        className={userToModify.disabled ? buttonStyles.emeraldLg : buttonStyles.redLg}
                        type="button"
                        onClick={() => requestSetAccountDisable(userToModify, !userToModify.disabled)}
                    >
                        {userToModify.disabled ? "Enable" : "Disable"}
                    </button>
                }
            />
            }

            { outcome !== null &&
            <AlertModal
                type={outcome.type}
                message={outcome.message}
                onDismiss={ () => {
                    setOutcome(null)
                }}
            />
            }
        </GlassCard>
    )
}

export default function CharityUsersList() {

    useEffect(() => {
        logScreenViewEvent(SCREEN_NAME.charityUsers, SCREEN_CLASS.charity)
    }, [])

    return (
        <div className="p-1 lg:p-4 flex flex-col overflow-y-auto" style={{ height: 'calc(100vh - 64px)' }}>    
            <div className="max-w-full mx-auto space-y-4">
                <AdminsTable/>
                <InvitesTable/>
            </div>
        </div>
    )
}
