import {useState} from "react";
import {useQuery} from "@apollo/react-hooks";
import {parseISO} from 'date-fns';

import {
    GET_USERS_CREATED_BY_PERIOD,
    GET_NOTIFICATIONS_BY_TYPE_BY_PERIOD,
} from "../../../utils/graphql/admin-user";

import {
    BarGraph,
    arrayByTimeToGraphData,
    colourScheme,
    notificationTypes,
    expandSeries,
    periodToDateFmt,
} from "../../../utils/stats";


export function UsersCreatedByPeriod({start, end, period}) {

    const [graphData, setGraphData] = useState(null);

    const {error} = useQuery(GET_USERS_CREATED_BY_PERIOD, {
        variables: {
            type: "volunteer",
            start: start,
            end: end,
            period: period
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData(arrayByTimeToGraphData(data.usersCreatedByPeriod, period, [
            {
                label: "Number of volunteers",
                getter: item => item.count,
                colour: colourScheme.users
            }
        ]))
    })

    return (
        <BarGraph title={`New App users signed up per ${period}`} data={graphData} />
    )
}


export function NotiifcationsByTypeByPeriod({start, end, period, outcome}) {

    const [graphData, setGraphData] = useState(null);

    const {error} = useQuery(GET_NOTIFICATIONS_BY_TYPE_BY_PERIOD, {
        variables: {
            start: start,
            end: end,
            period: period,
            outcome: outcome,
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            const result = data.notificationsByTypeByPeriod
            setGraphData({
                labels: result.map(item => parseISO(item.when).toLocaleDateString("en-GB", periodToDateFmt(period))),
                datasets: expandSeries(notificationTypes, result, values => values.count, (item, value) => item.type === value)
            })
        }
    })

    return (
        <BarGraph title={`Notifications with outcome "${outcome}" by type by ${period}`} data={graphData} stacked={true} />
    )
}
