import {useState} from "react";
import {useQuery} from "@apollo/react-hooks";

import {graphQLCache} from "../../../index";

import {
    GET_ENGAGEMENT_BY_CHARITY,
    GET_ENGAGEMENT_BY_ACTIVITY,
    GET_ENGAGEMENT_BY_DISTANCE,
    GET_ACTIVITY_SHARES_BY_SHARE_TARGET_BY_PERIOD,
} from "../../../utils/graphql/admin-volunteering";

import {
    GET_ENGAGEMENT_BY_CAMPAIGN
} from "../../../utils/graphql/admin-giving";

import {
    GET_ENGAGEMENT_BY_SCREEN_BY_DAY,
    GET_COUNTS_BY_EVENT_BY_DAY,
    GET_NEW_INSTALLS_BY_PLATFORM_BY_PERIOD,
    GET_NEW_INSTALLS_BY_MARKETING_LEAD_BY_PERIOD,
    GET_NEW_SIGNUPS_BY_PLATFORM_BY_PERIOD,
    GET_NEW_SIGNUPS_BY_NOTIFICATION_PERMISSION_BY_PERIOD,
    GET_ACTIVE_USERS_BY_PLATFORM_BY_PERIOD,
    GET_ACTIVE_USERS_BY_SCREEN_BY_PERIOD,
    GET_ACTIVE_USERS_BY_APP_VERSION_BY_PERIOD,
    GET_ACTIVE_USERS_BY_OS_VERSION_BY_PERIOD,
    GET_ACTIVE_USERS_GENERATING_EVENT_BY_OS_VERSION_BY_PERIOD,
    GET_USER_RETENTION_BY_INSTALL_COHORT,
    GET_ACTIVE_PORTAL_USERS_BY_PLATFORM_BY_PERIOD,
} from "../../../utils/graphql/admin-user";

import {dateToStringNoTime} from "gih_web_common";

import {BarGraph, seriesByTimeToGraphData, seriesByNameToGraphData, hash, osToPalette} from "../../../utils/stats";


export function invalidate(advanced) {
    console.log(`Evicting event stats query results with advanced=${advanced}`);

    const queries = advanced ? [
        "newInstallsByLead",
        "activeUsersByAppVersion",
        "activeUsersByOSVersion",
        "engagementByScreenByDay",
        "engagementByDistance",
        "activePortalUsersByPlatform",
        "activeUsersByScreen",
        "activitySharesByShareTarget",
        "activeUsersGeneratingEventByOSVersion",
        "eventCountsByDay",
    ] : [
        "newInstallsByPlatform",
        "newInstallsByLead",
        "newSignupsByPlatform",
        "activeUsersByPlatform",
        "userRetentionByInstallCohort",
        "newSignupsByNotificationPermission",
        "engagementByCharity",
        "engagementByActivity",
        "engagementByCampaign",
    ];

    queries.forEach(query => {
        graphQLCache.evict({
            id: "ROOT_QUERY", 
            fieldName: query,
        })
    });

    graphQLCache.gc();
}


export function NewInstallsByPlatformByPeriod({start, end, period}) {

    const [graphData, setGraphData] = useState(null);

    const {data, refetch} = useQuery(GET_NEW_INSTALLS_BY_PLATFORM_BY_PERIOD, {
        variables: {
            start: start,
            end: end,
            period: period
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData(seriesByTimeToGraphData(data.newInstallsByPlatform))
    })

    return (
        <BarGraph title={`New installs by platform by ${period}`} data={graphData} stacked={true} tall={true} />
    )
}


export function NewInstallsByMarketingLeadByPeriod({start, end, period, simplify}) {

    const [graphData, setGraphData] = useState(null);

    const {data, refetch} = useQuery(GET_NEW_INSTALLS_BY_MARKETING_LEAD_BY_PERIOD, {
        variables: {
            start: start,
            end: end,
            period: period,
            simplify: simplify
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData(seriesByTimeToGraphData(data.newInstallsByLead))
    })

    return (
        <BarGraph title={`New installs by marketing lead by ${period}`} data={graphData} stacked={true} tall={true} />
    )
}


export function NewSignupsByPlatformByPeriod({start, end, period}) {

    const [graphData, setGraphData] = useState(null);

    const {data, refetch} = useQuery(GET_NEW_SIGNUPS_BY_PLATFORM_BY_PERIOD, {
        variables: {
            start: start,
            end: end,
            period: period
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData(seriesByTimeToGraphData(data.newSignupsByPlatform))
    })

    return (
        <BarGraph title={`New signups by platform by ${period}`} data={graphData} stacked={true} tall={true} />
    )
}


export function NewSignupsByNotificationPermissionByPeriod({start, end, period}) {

    const [graphData, setGraphData] = useState(null);

    const {data, refetch} = useQuery(GET_NEW_SIGNUPS_BY_NOTIFICATION_PERMISSION_BY_PERIOD, {
        variables: {
            start: start,
            end: end,
            period: period
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData(seriesByTimeToGraphData(data.newSignupsByNotificationPermission))
    })

    return (
        <BarGraph title={`New signups by notification permission by ${period}`} data={graphData} stacked={true} tall={true} />
    )
}


export function ActiveUsersByPlatformByPeriod({start, end, period, signedUp}) {

    const [graphData, setGraphData] = useState(null);

    const {data, refetch} = useQuery(GET_ACTIVE_USERS_BY_PLATFORM_BY_PERIOD, {
        variables: {
            start: start,
            end: end,
            period: period,
            signedUp: signedUp
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData(seriesByTimeToGraphData(data.activeUsersByPlatform))
    })

    return (
        <BarGraph title={`Active${signedUp ? " signed up" : ""} users by platform by ${period}`} data={graphData} stacked={true} tall={true} />
    )
}


export function ActivePortalUsersByPlatformByPeriod({start, end, period}) {

    const [graphData, setGraphData] = useState(null);

    const {data, refetch} = useQuery(GET_ACTIVE_PORTAL_USERS_BY_PLATFORM_BY_PERIOD, {
        variables: {
            start: start,
            end: end,
            period: period,
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData(seriesByTimeToGraphData(data.activePortalUsersByPlatform))
    })

    return (
        <BarGraph title={`Active portal users by platform by ${period}`} data={graphData} stacked={true} tall={true} />
    )
}


export function ActiveUsersByAppVersionByPeriod({start, end, period}) {

    const [graphData, setGraphData] = useState(null);

    const {data, refetch} = useQuery(GET_ACTIVE_USERS_BY_APP_VERSION_BY_PERIOD, {
        variables: {
            start: start,
            end: end,
            period: period
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData(seriesByTimeToGraphData(data.activeUsersByAppVersion, (series) => {
            const parts = series.label.split(' ')
            const palette = osToPalette(parts[0])
            return palette[hash(parts[1]) % palette.length]
        }))
    })

    return (
        <BarGraph title={`Active users by App version by ${period}`} data={graphData} stacked={true} tall={true} />
    )
}


export function ActiveUsersByOSVersionByPeriod({start, end, period}) {

    const [graphData, setGraphData] = useState(null);

    const {data, refetch} = useQuery(GET_ACTIVE_USERS_BY_OS_VERSION_BY_PERIOD, {
        variables: {
            start: start,
            end: end,
            period: period
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData(seriesByTimeToGraphData(data.activeUsersByOSVersion, (series) => {
            const parts = series.label.split(' ')
            const palette = osToPalette(parts[0])
            return palette[hash(parts[1]) % palette.length]
        }))
    })

    return (
        <BarGraph title={`Active users by mobile OS version by ${period}`} data={graphData} stacked={true} tall={true} />
    )
}


export function ActiveUsersByScreenByPeriod({start, end, period}) {

    const [graphData, setGraphData] = useState(null);

    const {data, refetch} = useQuery(GET_ACTIVE_USERS_BY_SCREEN_BY_PERIOD, {
        variables: {
            start: start,
            end: end,
            period: period
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData(seriesByTimeToGraphData(data.activeUsersByScreen))
    })

    return (
        <BarGraph title={`Unique users by screen viewed by ${period}`} data={graphData} tall={true} />
    )
}


export function ActiveUsersGeneratingEventByOSVersionByPeriod({start, end, period, screen}) {

    const [graphData, setGraphData] = useState(null);

    const {data, refetch} = useQuery(GET_ACTIVE_USERS_GENERATING_EVENT_BY_OS_VERSION_BY_PERIOD, {
        variables: {
            start: start,
            end: end,
            period: period,
            screen: screen
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData(seriesByTimeToGraphData(data.activeUsersGeneratingEventByOSVersion, (series) => {
            const parts = series.label.split(' ')
            const palette = osToPalette(parts[1])
            return palette[hash(parts[2]) % palette.length]
        }))
    })

    return (
        <BarGraph title={`Unique users viewing screen ${screen} by OS version by ${period}`} data={graphData} stacked={true} tall={true} />
    )
}


export function UserRetentionByInstallCohort({start, end, period}) {

    const [graphData, setGraphData] = useState(null);

    const {data, refetch} = useQuery(GET_USER_RETENTION_BY_INSTALL_COHORT, {
        variables: {
            start: start,
            end: end,
            period: period
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData(seriesByTimeToGraphData(data.userRetentionByInstallCohort))
    })

    return (
        <BarGraph title={`User retention by ${period} of installation`} data={graphData} tall={true} />
    )
}


export function EngagementByScreen({start, end}) {

    const [graphData, setGraphData] = useState(null);

    const {data, refetch} = useQuery(GET_ENGAGEMENT_BY_SCREEN_BY_DAY, {
        variables: {
            start: start,
            end: end,
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData(seriesByTimeToGraphData(data.engagementByScreenByDay))
    })

    return (
        <BarGraph title="Daily engagement as minutes by screen" data={graphData} stacked={true} tall={true} />
    )
}


export function EventsByDay({start, end, os}) {

    const [graphData, setGraphData] = useState(null);

    const {data, refetch} = useQuery(GET_COUNTS_BY_EVENT_BY_DAY, {
        variables: {
            start: start,
            end: end,
            os: os
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData(seriesByTimeToGraphData(data.eventCountsByDay))
    })

    return (
        <BarGraph title={`Event counts by day on ${os}`} data={graphData} stacked={true} tall={true} />
    )
}


export function EngagementByCharity({start, end}) {

    const [graphData, setGraphData] = useState(null);

    const {data, refetch} = useQuery(GET_ENGAGEMENT_BY_CHARITY, {
        variables: {
            start: start,
            end: end
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData(seriesByNameToGraphData(data.engagementByCharity))
    })

    return (
        <BarGraph title={`User engagement by charity from ${dateToStringNoTime(start)} - ${dateToStringNoTime(end)}`} data={graphData} tall={true} />
    )
}


export function EngagementByActivity({start, end}) {

    const [graphData, setGraphData] = useState(null);

    const {data, refetch} = useQuery(GET_ENGAGEMENT_BY_ACTIVITY, {
        variables: {
            start: start,
            end: end
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData(seriesByNameToGraphData(data.engagementByActivity))
    })

    return (
        <BarGraph title={`User engagement by activity from ${dateToStringNoTime(start)} - ${dateToStringNoTime(end)}`} data={graphData} tall={true} />
    )
}


export function EngagementByCampaign({start, end}) {

    const [graphData, setGraphData] = useState(null);

    const {data, refetch} = useQuery(GET_ENGAGEMENT_BY_CAMPAIGN, {
        variables: {
            start: start,
            end: end
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData(seriesByNameToGraphData(data.engagementByCampaign))
    })

    return (
        <BarGraph title={`User engagement by campaign from ${dateToStringNoTime(start)} - ${dateToStringNoTime(end)}`} data={graphData} tall={true} />
    )
}


export function EngagementByDistance({start, end}) {

    const [graphData, setGraphData] = useState(null);

    const {data, refetch} = useQuery(GET_ENGAGEMENT_BY_DISTANCE, {
        variables: {
            start: start,
            end: end
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData(seriesByNameToGraphData(data.engagementByDistance))
    })

    return (
        <BarGraph title={`User engagement by distance from activity for ${dateToStringNoTime(start)} - ${dateToStringNoTime(end)}`} data={graphData} tall={true} />
    )
}


export function ActivitySharesByShareTarget({start, end, period}) {

    const [graphData, setGraphData] = useState(null);

    const {data, refetch} = useQuery(GET_ACTIVITY_SHARES_BY_SHARE_TARGET_BY_PERIOD, {
        variables: {
            start: start,
            end: end,
            period: period
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData(seriesByTimeToGraphData(data.activitySharesByShareTarget))
    })

    return (
        <BarGraph title={`Activity shares by share target by ${period}`} data={graphData} stacked={true} tall={true} />
    )
}

