import {DateTime} from "luxon";

import {RefreshButton} from "gih_web_common";

import {NewInstallsByPlatformByPeriod,
        NewInstallsByMarketingLeadByPeriod,
        NewSignupsByPlatformByPeriod,
        ActiveUsersByPlatformByPeriod,
        invalidate} from "./graphs"


export function LongTermEventStats() {

    const now = DateTime.now()
    const start = now.minus({months:3}).startOf('month').toJSDate()
    const end = now.endOf('day').toJSDate()

    return (
        <div className="mx-auto max-w-[1650px] space-y-2">
            <RefreshButton action={() => invalidate(false)} label={true} />

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <NewInstallsByPlatformByPeriod period="month" start={start} end={end} />
                <NewInstallsByMarketingLeadByPeriod period="month" start={start} end={end} simplify={true} />

                <NewSignupsByPlatformByPeriod period="month" start={start} end={end} />
                <div></div>

                <ActiveUsersByPlatformByPeriod period="month" start={start} end={end} signedUp={false} />
                <ActiveUsersByPlatformByPeriod period="month" start={start} end={end} signedUp={true} />
            </div>
        </div>
    )
}
