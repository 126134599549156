
export default function minutesToString(min) {
    var num = min;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    if (rminutes === 0 ) {
        return `${rhours}h`
    } else {
        return `${rhours}h ${rminutes}m`
    }
}
