import {buttonStyles} from 'gih_web_common';


export function navButtonStyle(selected, giving) {
    if (giving) {
        return selected ? buttonStyles.navSelectedPortal : buttonStyles.navUnselectedPortal;
    } else {
        return selected ? buttonStyles.altNavSelectedPortal : buttonStyles.altNavUnselectedPortal;
    }
}

export function navIconStyle(selected, giving) {
    if (giving) {
        if (selected) {
            return "text-primary-default";
        } else {
            return "hover:text-primary-default text-gray-400";
        }
    } else {
        if (selected) {
            return "text-alt-default";
        } else {
            return "hover:text-alt-default text-gray-400";
        }
    }
}

export function tabButtonStyle(selected, giving) {
    if (giving) {
        return selected ? buttonStyles.tabSelected : buttonStyles.tabUnsleected;
    } else {
        return selected ? buttonStyles.altTabSelected : buttonStyles.altTabUnsleected;
    }
}

export function truncate(s, maxLength) {
    if (s.length > maxLength) {
        return s.slice(0, maxLength-3) + '...'
    } else {
        return s
    }
}

function isRunningStandalone() {
    return window.matchMedia('(display-mode: standalone)').matches;
}
