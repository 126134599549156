import {DateTime} from "luxon";
import {useState} from "react";
import {useParams} from "react-router-dom";

import {DateRangeCard, GlassCard, FormWrapper, FormInput} from "gih_web_common";

import {graphQLCache} from "../../../index";

import {PortalUser, PortalUserEventsTable} from "./user";


function invalidate(userId) {
    console.log(`Evicting event query stats with userId=${userId}`);

    graphQLCache.evict({
        id: "ROOT_QUERY", 
        fieldName: userId ? "eventsForPortalUser" : "interestingEventsForPortalUsers",
    });
    
    graphQLCache.gc();
}

export function PortalUserSearch() {

    const {id: pathUserId} = useParams();

    const now = DateTime.now();
    const [startDate, setStartDate] = useState(now.minus({days:7}).startOf('day').toJSDate());
    const [endDate, setEndDate] = useState(now.endOf('day').toJSDate());
    const [userId, setUserId] = useState(pathUserId);

    const id = pathUserId ?? userId;

    return (
        <div className="space-y-4 max-w-[2048px] mx-auto">
            <GlassCard width="max-w-full space-y-2">
                <div className="text-xl sm:text-2xl font-bold">User</div>

                { !pathUserId &&
                <FormWrapper
                    onSubmit={ values => {
                        setUserId(values.identifier);
                        return Promise.resolve();
                    }}
                >
                    <div className="w-[260px]">
                        { !pathUserId &&
                        <FormInput
                            id="identifier"
                            name="identifier"
                            type="text"
                            placeholder="identifier"
                            label="User identifier"
                            className="rounded-md w-full"
                            autoComplete="off"
                        />
                        }
                    </div>
                </FormWrapper>
                }

                { (!id || id.length === 24) ?
                <PortalUser userId={id} />
                :
                 <div className="px-4 py-2 my-4 bg-orange-500 text-white w-fit">Leave box blank OR enter a valid 24 character user ID and press return</div>
                }
            </GlassCard>

            { (!id || id.length === 24) &&
            <>
                <DateRangeCard
                    label={id ? "Events" : "Interesting events for all portal users"}
                    onRefresh={() => invalidate(id)}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    unit="time"
                />

                <GlassCard width="max-w-full space-y-2">
                    <PortalUserEventsTable start={startDate} end={endDate} userId={id} />
                </GlassCard>
            </>
            }
        </div>
    )
}
