import {FiMinusCircle, FiPlusCircle} from "react-icons/fi";
import {use100vh} from 'react-div-100vh'

import {fmtChatTimestamp} from "gih_web_common";

import minutesToString from "../../../utils/duration";

import {truncate} from "../../common/style";
import {BadgeCount} from "../../common/elements";

import {UserAvatar} from "../common/avatar";


function asActiveChat(chat) {
    return {
        ...chat,
        canSetStatus: false,
        buttons: []
    }
}

function WorkBadge({minutes}) {

    return (
        <div className="bg-blue-500 text-center text-sm text-white font-medium px-2 py-1 rounded-xl min-w-[3em]">
            {minutesToString(minutes)}
        </div>
    )
}

function Chats({user, activeChat, setActiveChat}) {

    return (
        <div className="block">
        { (user.chats && user.chats.length > 0) ?
            user.chats.map((chat, index) => {
                let isActiveChat = (activeChat && activeChat.id === chat.id);
                return (
                    <div className={`flex justify-between items-center py-3 px-3 ${isActiveChat ? "bg-alt-lighter" : "hover:bg-alt-dark"} border-t border-gray-200`}
                             onClick={() => { setActiveChat(asActiveChat(chat)) }}>
                        <div className="flex items-center">
                            <UserAvatar chat={chat}/>
                            <div className="ml-4">
                                <div className="flex py-1 items-center">
                                    <span className={`mr-2 text-sm ${isActiveChat ? "text-alt-darker font-bold" : "text-black"}`}>{chat.activity.name}</span>
                                    <WorkBadge minutes={chat.minutesWorked} />
                                </div>
                                <div className={`text-xs ${isActiveChat ? "text-black" : "text-gray-500"}`}>{truncate(chat.lastMessage.message, 192)}</div>
                            </div>
                        </div>
                        <div className="flex items-center space-x-1 min-w-fit">
                            { chat.unread_charity > 0 &&
                                <div className="flex justify-end mb-1">
                                    <BadgeCount count={chat.unread_charity} />
                                </div>
                            }
                            <p className="text-gray-500 text-xs text-right min-w-[4em]">{fmtChatTimestamp(chat.timestamp.toDate())}</p>
                        </div>
                    </div>
                )
            })
            :
            <div className="px-2 py-2">No chats</div>
        }
        </div>
    )
}

export function ChatList(props) {

    const height = use100vh();

    return (
        <div className="overflow-y-auto overscroll-contain border-t border-gray-400" style={{ height: `${height - 64 - 56 - 64}px`}}>
            { props.rankedUsers.length < 1 &&
                <div className="text-xl font-semibold p-4">
                    No volunteers have checked in to any activities within the last 12 months
                </div>
            }
            { props.rankedUsers.map((user) => {

                let active = (user.firebaseId === props.activeUser)

                return (
                    <div className="border-b border-gray-400">
                        <div className="flex space-x-2 text-base items-center justify-between px-3 py-3">
                            <div className="flex space-x-2 items-center">
                                { !active &&
                                <FiPlusCircle onClick={() => { props.setActiveUser(user.firebaseId) }} className="w-8 h-8 max-w-min hover:text-alt-darker"/>
                                }
                                { active &&
                                <FiMinusCircle onClick={() => { props.setActiveUser(null) }} className="w-8 h-8 max-w-min text-alt-dark hover:text-black"/>
                                }
                                <span className="font-bold">{user.name}</span>
                                { user.totalUnread > 0 &&
                                <BadgeCount count={user.totalUnread} />
                                }
                            </div>
                            <WorkBadge minutes={user.minutesWorked} />
                        </div>

                        { active &&
                            <Chats user={user} activeChat={props.activeChat} setActiveChat={props.setActiveChat} />
                        }
                    </div>
                )
            })}
        </div>
    )
}
