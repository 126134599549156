import {useState} from "react";

import {useQuery} from "@apollo/react-hooks";

import {GlassCard, OnLoadViewer, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, RefreshCell, dateToString, cellStyles} from "gih_web_common";

import {GET_GIFT_AID_CLAIM_SUBMISSIONS} from "../../../utils/graphql/admin-giving";


export function GiftAidSubmissions() {

    const [submissions, setSubmissions] = useState(null)

    const {refetch} = useQuery(GET_GIFT_AID_CLAIM_SUBMISSIONS, {
        onCompleted: data => setSubmissions(data.giftAidClaimSubmissions),
    })

    return (
        <GlassCard>
            <div className="text-2xl font-bold pb-2">
                GiftAid submissions
            </div>
            <OnLoadViewer loading={submissions === null}>
                { submissions &&
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <RefreshCell sx={cellStyles.dateTime} action={refetch} text="Created" />
                                <TableCell sx={cellStyles.dateTime}>Period end</TableCell>
                                <TableCell sx={cellStyles.freeMedium}>#Claim</TableCell>
                                <TableCell sx={cellStyles.dateTime}>Submitted</TableCell>
                                <TableCell sx={cellStyles.dateTime}>Response</TableCell>
                                <TableCell sx={cellStyles.dateTime}>Deleted</TableCell>
                                <TableCell sx={cellStyles.freeWide}>Response</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { submissions.map(submission => (
                                <TableRow key={submission._id}>
                                    <TableCell align="left">{dateToString(submission.createdAt)}</TableCell>
                                    <TableCell align="left">{dateToString(submission.periodEnd)}</TableCell>
                                    <TableCell align="left">{submission.claimNo}</TableCell>
                                    <TableCell align="left">{dateToString(submission.submissionGatewayTimestamp)}</TableCell>
                                    <TableCell align="left">{dateToString(submission.responseGatewayTimestamp)}</TableCell>
                                    <TableCell align="left">{dateToString(submission.deleteGatewayTimestamp)}</TableCell>
                                    <TableCell align="left" className="whitespace-pre" sx={{fontSize: "0.6em"}}>{submission.successResponse}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                }
            </OnLoadViewer>
        </GlassCard>
    );
}
