import {Modal, buttonStyles} from 'gih_web_common';


export function ConfirmLegalStatus({confirm}) {

    return (
        <Modal
            title="Please confirm the legal status of your organisation"
            onDismiss={() => confirm.cancel(false)}
            footer={
                <>
                    <button className={buttonStyles.blueLgNarrow} type="button" onClick={() => confirm.cancel(true)}>
                        I need to modify our details
                    </button>
                    <button className={buttonStyles.emeraldLgNarrow} type="button" onClick={() => confirm.agreed()}>
                        I confirm that the above is correct
                    </button>
                </>
            }
        >
            <div className="text-base py-2">
            {(details => {
                if (details.registration && details.companyNumber) {
                    return <span>"We are registered charity number <strong>{details.registration}</strong> incorporated with Companies House as company number <strong>{details.companyNumber}</strong>."</span>;
                } else if (details.registration) {
                    return <span>"We are registered charity number <strong>{details.registration}</strong>. We are an <strong>unincorporated organisation</strong> and are therefore not registered with Companies House."</span>;                            
                } else if (details.companyNumber) {
                    return <span>"We are a CIC registered with Companies House as company number <strong>{details.companyNumber}</strong>."</span>;
                } else {
                    return <span>"We are a non-profit organisation that is <strong>neither</strong> a registered charity <strong>nor</strong> registered with Companies House."</span>
                }
            })(confirm.details)}
            </div>
            <div className="text-sm italic">
                You cannot change this information once you have confirmed it. Should the legal status of your organisation change
                at any time in the future, please contact Giving Is Human using the live chat to request a change.
            </div>
        </Modal>
    );
}
