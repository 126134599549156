import {gql} from "@apollo/client";

export const GET_EMAILS_BY_SENT_DATE = gql`
    query PortalGetEmailsBySentDate($start: DateTime!, $end: DateTime!) {
        portalGetEmailsBySentDate(start: $start, end: $end) {
            trace {
                _id
                createdAt
                category
                to
                status
                lastStatusChange
                openCount
                lastOpen
                clickCount
                lastClick
            }
            charityId {
                _id
                name
                logoPicture
                provisional
            }
        }
    }
`

const portalUserMinimalDetail = `
    _id
    email
    firstName
    lastName
    createdAt
    lastSignIn
    lastSeen
    charityId {
        _id
        name
        logoPicture
        provisional
    }
`

export const GET_PORTAL_USERS_BY_CREATION_DATE = gql`
    query PortalUsersByCreationDate($start: DateTime!, $end: DateTime!) {
        portalUsersByCreationDate(start: $start, end: $end) {
            ${portalUserMinimalDetail}
        }
    }
`

export const GET_PORTAL_USER_BY_ID = gql`
    query PortalUserById($userId: ID!) {
        portalUserById(userId: $userId) {
            ${portalUserMinimalDetail}
        }
    }
`

const appUserAndParticipations = `
    lastSignIn
    lastSeen
    user {
        _id
        firstName
        lastName
        firebaseId
        profilePicture
        createdAt
        interests
        age
        gender {
            female
            male
            nonBinary
            transgender
            intersex
            preferNotToSay
        }
        phone
        postcode
        deletionRequestedAt
    }
    participations {
        activityId {
            _id
            name
        }
        status
        charityId {
            _id
            name
            logoPicture
        }
        createdAt
        occurrencesAvailable
    }
`

export const GET_APP_USERS_BY_CREATION_DATE = gql`
    query UsersByCreationDate($start: DateTime!, $end: DateTime!) {
        usersByCreationDate(start: $start, end: $end) {
            ${appUserAndParticipations}
        }
    }
`

export const GET_APP_USER_BY_ID = gql`
    query PortalGetAppUserById($userId: ID!) {
        portalGetAppUserById(userId: $userId) {
            ${appUserAndParticipations}
        }
    }
`

export const GET_APP_USERS_PENDING_DELETION = gql`
    query UsersPendingDeletion {
        usersPendingDeletion {
            ${appUserAndParticipations}
        }
    }
`

export const GET_APP_USER_LOCATIONS = gql`
    query PortalGetAppUserLocations {
        portalGetAppUserLocations {
            _id
            notifiable
            lat
            lng
        }
    }
`

export const GET_EVENTS_FOR_USER = gql`
    query EventsForUser($start: DateTime!, $end: DateTime!, $userId: ID!) {
        eventsForUser(start: $start, end: $end, userId: $userId) {
            when
            env
            what
            outcome
            text
            screens {
                name
                count
            }
        }
    }
`

export const GET_EVENTS_FOR_PORTAL_USER = gql`
    query EventsForPortalUser($start: DateTime!, $end: DateTime!, $userId: ID!) {
        eventsForPortalUser(start: $start, end: $end, userId: $userId) {
            when
            userId
            env
            what
            outcome
            text
            screens {
                name
                count
            }
        }
    }
`

export const GET_INTERESTING_EVENTS_FOR_PORTAL_USERS = gql`
    query InterestingEventsForPortalUsers($start: DateTime!, $end: DateTime!) {
        interestingEventsForPortalUsers(start: $start, end: $end) {
            when
            userId
            env
            what
            outcome
            text
            screens {
                name
                count
            }
        }
    }
`

export const GET_SESSION_SUMMARIES = gql`
    query SessionSummaries($start: DateTime!, $end: DateTime!, $productionApps: Boolean!) {
        sessionSummaries(start: $start, end: $end, productionApps: $productionApps) {
            start
            end
            text
            userIds
            screens {
                name
                count
            }
            locations {
                place
                latitude
                longitude
            }
            other {
                name
                count
            }
        }
    }
`

export const GET_LOCATION_SEARCH_EVENTS = gql`
    query LocationSearches($start: DateTime!, $end: DateTime!) {
        locationSearches(start: $start, end: $end) {
            when
            where {
                place
                latitude
                longitude
            }
        }
    }
`

export const GET_MARKET_RESEARCH_EVENTS = gql`
    query MarketResearch($start: DateTime!, $end: DateTime!, $productionApps: Boolean!) {
        marketResearch(start: $start, end: $end, productionApps: $productionApps) {
            start
            end
            text
            userIds
            screens {
                name
                count
            }
            locations {
                place
                latitude
                longitude
            }
            other {
                name
                count
            }
        }
    }
`

export const GET_NOTIFICATIONS_BY_TYPE_BY_PERIOD = gql`
    query NotificationsByTypeByPeriod($start: DateTime!, $end: DateTime!, $period: String!, $outcome: String!) {
        notificationsByTypeByPeriod(start: $start, end: $end, period: $period, outcome: $outcome) {
            when
            counts {
                type
                count
            }
        }
    }
`

export const GET_USERS_CREATED_BY_PERIOD = gql`
    query UsersCreatedByPeriod($type: String!, $start: DateTime!, $end: DateTime!, $period: String!) {
        usersCreatedByPeriod(type: $type, start: $start, end: $end, period: $period) {
            when
            count
        }
    }
`

export const GET_NEW_INSTALLS_BY_PLATFORM_BY_PERIOD = gql`
    query NewInstallsByPlatform($start: DateTime!, $end: DateTime!, $period: String!) {
        newInstallsByPlatform(start: $start, end: $end, period: $period) {
            times
            series {
                label
                data
            }
        }
    }
`

export const GET_NEW_INSTALLS_BY_MARKETING_LEAD_BY_PERIOD = gql`
    query NewInstallsByLead($start: DateTime!, $end: DateTime!, $period: String!, $simplify: Boolean!) {
        newInstallsByLead(start: $start, end: $end, period: $period, simplify: $simplify) {
            times
            series {
                label
                data
            }
        }
    }
`

export const GET_NEW_SIGNUPS_BY_PLATFORM_BY_PERIOD = gql`
    query NewSignupsByPlatform($start: DateTime!, $end: DateTime!, $period: String!) {
        newSignupsByPlatform(start: $start, end: $end, period: $period) {
            times
            series {
                label
                data
            }
        }
    }
`

export const GET_NEW_SIGNUPS_BY_NOTIFICATION_PERMISSION_BY_PERIOD = gql`
    query NewSignupsByNotificationPermission($start: DateTime!, $end: DateTime!, $period: String!) {
        newSignupsByNotificationPermission(start: $start, end: $end, period: $period) {
            times
            series {
                label
                data
            }
        }
    }
`

export const GET_ACTIVE_USERS_BY_PLATFORM_BY_PERIOD = gql`
    query ActiveUsersByPlatform($start: DateTime!, $end: DateTime!, $period: String!, $signedUp: Boolean!) {
        activeUsersByPlatform(start: $start, end: $end, period: $period, signedUp: $signedUp) {
            times
            series {
                label
                data
            }
        }
    }
`

export const GET_ACTIVE_PORTAL_USERS_BY_PLATFORM_BY_PERIOD = gql`
    query ActivePortalUsersByPlatform($start: DateTime!, $end: DateTime!, $period: String!) {
        activePortalUsersByPlatform(start: $start, end: $end, period: $period) {
            times
            series {
                label
                data
            }
        }
    }
`

export const GET_ACTIVE_USERS_BY_APP_VERSION_BY_PERIOD = gql`
    query ActiveUsersByAppVersion($start: DateTime!, $end: DateTime!, $period: String!) {
        activeUsersByAppVersion(start: $start, end: $end, period: $period) {
            times
            series {
                label
                data
            }
        }
    }
`

export const GET_ACTIVE_USERS_BY_OS_VERSION_BY_PERIOD = gql`
    query ActiveUsersByOSVersion($start: DateTime!, $end: DateTime!, $period: String!) {
        activeUsersByOSVersion(start: $start, end: $end, period: $period) {
            times
            series {
                label
                data
            }
        }
    }
`

export const GET_ACTIVE_USERS_GENERATING_EVENT_BY_OS_VERSION_BY_PERIOD = gql`
    query ActiveUsersGeneratingEventByOSVersion($start: DateTime!, $end: DateTime!, $period: String!, $screen: String!) {
        activeUsersGeneratingEventByOSVersion(start: $start, end: $end, period: $period, screen: $screen) {
            times
            series {
                label
                data
            }
        }
    }
`

export const GET_ACTIVE_USERS_BY_SCREEN_BY_PERIOD = gql`
    query ActiveUsersByScreen($start: DateTime!, $end: DateTime!, $period: String!) {
        activeUsersByScreen(start: $start, end: $end, period: $period) {
            times
            series {
                label
                data
            }
        }
    }
`

export const GET_USER_RETENTION_BY_INSTALL_COHORT = gql`
    query UserRetentionByInstallCohort($start: DateTime!, $end: DateTime!, $period: String!) {
        userRetentionByInstallCohort(start: $start, end: $end, period: $period) {
            times
            series {
                label
                data
            }
        }
    }
`

export const GET_COUNTS_BY_EVENT_BY_DAY = gql`
    query EventCountsByDay($start: DateTime!, $end: DateTime!, $os: String!) {
        eventCountsByDay(start: $start, end: $end, os: $os) {
            times
            series {
                label
                data
            }
        }
    }
`

export const GET_ENGAGEMENT_BY_SCREEN_BY_DAY = gql`
    query EngagementByScreenByDay($start: DateTime!, $end: DateTime!) {
        engagementByScreenByDay(start: $start, end: $end) {
            times
            series {
                label
                data
            }
        }
    }
`
