import {useState} from "react";
import {DateTime} from "luxon";

import {useQuery} from "@apollo/react-hooks";

import {OnLoadViewer, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, RefreshCell, TagsCell, dateToString, cellStyles, Lozenge} from "gih_web_common";

import {GET_MARKET_RESEARCH_EVENTS, GET_LOCATION_SEARCH_EVENTS, GET_SESSION_SUMMARIES} from "../../../utils/graphql/admin-user";

import {appNoteworthyEventAttrs, colours, Screens} from "../common/events";


function EventCounts({events}) {
    return (
        <TableCell align="left">
        { events.map(event => {
            const attrs = appNoteworthyEventAttrs.get(event.name) ?? { colour: colours.fallback, };
            return <Lozenge key={event.name} colour={attrs.colour} text={`${event.name}: ${event.count}`} />
        })}
        </TableCell>
    )
}

function Locations({locations}) {
    return (
        <TableCell align="left">
        { locations.map((location, index) => (
            <Lozenge key={index} colour={colours.general} text={`${location.place} (${location.latitude.toFixed(4)}, ${location.longitude.toFixed(4)})`} />
        ))}
        </TableCell>
    )
}

function SessionTable({sessions, refresh}) {
    return (
        <TableContainer sx={{maxHeight: 740}}>
            <Table>
                <TableHead>
                    <TableRow>
                        <RefreshCell sx={cellStyles.fixedWide} action={refresh} text="Time" />
                        <TableCell sx={cellStyles.fixedMedium}>Users</TableCell>
                        <TableCell sx={cellStyles.freeWide}>Description</TableCell>
                        <TableCell sx={cellStyles.fixedMedium}>Install</TableCell>
                        <TableCell sx={cellStyles.fixedMedium}>Browse</TableCell>
                        <TableCell sx={cellStyles.fixedMedium}>Interest</TableCell>
                        <TableCell sx={cellStyles.fixedMedium}>Signup</TableCell>
                        <TableCell sx={cellStyles.fixedMedium}>Active</TableCell>
                        <TableCell sx={cellStyles.fixedMedium}>Other</TableCell>
                        <TableCell sx={cellStyles.fixedWide}>Locations</TableCell>
                        <TableCell sx={cellStyles.fixedMedium}>Other events</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { sessions.map(session => (
                        <TableRow key={[session.start,session.end]}>
                            <TableCell align="left">
                                <Lozenge colour="bg-blue-500" text={dateToString(session.start)} />
                                <Lozenge colour="bg-gray-600" text={dateToString(session.end)} />
                            </TableCell>
                            <TagsCell tags={session.userIds} />
                            <TableCell align="left">{session.text}</TableCell>
                            <Screens screens={session.screens} filter="install" portal={false} />
                            <Screens screens={session.screens} filter="browse" portal={false} />
                            <Screens screens={session.screens} filter="interest" portal={false} />
                            <Screens screens={session.screens} filter="signup" portal={false} />
                            <Screens screens={session.screens} filter="active" portal={false} />
                            <Screens screens={session.screens} filter="other" portal={false} />
                            <Locations locations={session.locations} />
                            <EventCounts events={session.other} />
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}


export function RecentInstalls({productionApps}) {

    const [sessions, setSessions] = useState(null)
    const now = DateTime.now()

    const {refetch} = useQuery(GET_MARKET_RESEARCH_EVENTS, {
        variables: {
            start: now.minus({days:3}).startOf('day').toJSDate(),
            end: now.endOf('day').toJSDate(),
            productionApps: productionApps,
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setSessions(data.marketResearch),
    })

    return (
        <>
            <OnLoadViewer loading={sessions === null}>
                { sessions &&
                <SessionTable sessions={sessions} refresh={() => refetch()} />
                }
            </OnLoadViewer>
        </>
    )
}

export function RecentSessions({productionApps}) {

    const [sessions, setSessions] = useState(null)
    const now = DateTime.now()

    const {refetch} = useQuery(GET_SESSION_SUMMARIES, {
        variables: {
            start: now.minus({days:3}).startOf('day').toJSDate(),
            end: now.endOf('day').toJSDate(),
            productionApps: productionApps,
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setSessions(data.sessionSummaries),
    })

    return (
        <OnLoadViewer loading={sessions === null}>
            { sessions &&
            <SessionTable sessions={sessions} refresh={() => refetch()} />
            }
        </OnLoadViewer>
    )
}

export function RecentLocationSearches() {

    const now = DateTime.now()

    const {data, refetch} = useQuery(GET_LOCATION_SEARCH_EVENTS, {
        variables: {
            start: now.minus({days:3}).startOf('day').toJSDate(),
            end: now.endOf('day').toJSDate(),
        },
        fetchPolicy: "cache-first",
    })

    const events = data?.locationSearches

    return (
        <OnLoadViewer loading={events === null || events === undefined}>
            { events &&
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={cellStyles.dateTime}>Created</TableCell>
                            <TableCell sx={cellStyles.freeWide}>Text</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { events.map(event => (
                            <TableRow key={event.when}>
                                <TableCell align="left">{dateToString(event.when)}</TableCell>
                                <TableCell align="left">{`${event.where.place} (${event.where.latitude}, ${event.where.longitude})`}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            }
        </OnLoadViewer>
    )
}
