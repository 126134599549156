import ExcelJS from 'exceljs';

export function stripProtocol(url) {
    return url.startsWith('http://') ? url.slice(7) : url.startsWith('https://') ? url.slice(8) : url;
}

export function asHyperlink(url) {
    return url ? {
        text: stripProtocol(url),
        hyperlink: `https://${stripProtocol(url)}`
    } : '-';
}

export function makeWorkbook() {

    const wb = new ExcelJS.Workbook();

    wb.properties.date1904 = true;
    wb.calcProperties.fullCalcOnLoad = true;

    wb.views = [{
        x: 0,
        y: 0,
        width: 30000,
        height: 15000,
        firstSheet: 0,
        activeTab: 1,
        visibility: 'visible',
    }];

    return wb;
}

export function makeSheet(wb, sheetName, columns, rows, dataAsRow) {

    const ws = wb.addWorksheet(sheetName, {
        pageSetup: {
            paperSize: 9,
            orientation:'landscape',
            fitToPage: true,
            fitToWidth: 1,
            fitToHeight: 20,
            margins: {
                left: 0.75,
                right: 0.75,
                top: 0.75,
                bottom: 0.75,
                header: 0.3,
                footer: 0.3,
            },
        },
        views: [{
            showGridLines: false,
        }],
    });

    ws.getRow(1).values = columns.map(column => column.heading.label);

    ws.columns = columns.map(column => ({
        width: column.width,
    }));

    columns.forEach((column, index) => {

        const col = String.fromCharCode('A'.charCodeAt(0) + index);
        const cell = ws.getCell(`${col}1`);

        cell.font = {
            name: 'Arial Black',
            family: 2,
            size: 14,
            bold: true
        };

        cell.alignment = {
            wrapText: true,
            vertical: 'middle',
            horizontal: column.heading.horizontal,
        };
 
        cell.border = {
            bottom: { style: 'medium' },
        };
    });

    rows.forEach((data, index) => {

        const rowIndex = index + 3;
        const row = ws.getRow(rowIndex);
        
        row.values = dataAsRow(data, index);
        row.height = 32;

        columns.forEach((column, index) => {

            const col = String.fromCharCode('A'.charCodeAt(0) + index);
            const cell = ws.getCell(`${col}${rowIndex}`);

            if (column.data?.numFmt) {
                cell.numFmt = column.data.numFmt;
            }

            cell.alignment = {
                ...column.data?.alignment,
                vertical: 'middle',
            };

            cell.border = {
                bottom: {
                    style: 'thin',
                    color: { argb: 'FFC0C0C0' },
                },
            };
        });
    });

    return wb;
}

export async function downloadXLSX(wb, fileName) {
    const element = document.createElement("a");
    const file = new Blob([await wb.xlsx.writeBuffer()], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
}
