import {useState} from "react";
import {DateTime} from "luxon";

import {useQuery} from "@apollo/react-hooks";

import {OnLoadViewer} from "gih_web_common";

import {
    GET_APP_USERS_BY_CREATION_DATE,
    GET_APP_USERS_PENDING_DELETION,
    GET_PORTAL_USERS_BY_CREATION_DATE,
} from "../../../utils/graphql/admin-user";

import {AppUserTable, PortalUserTable} from "../common/user-table";


export function AppUsersRecentlyCreated({days}) {

    const now = DateTime.now();

    const {refetch, loading, data, error} = useQuery(GET_APP_USERS_BY_CREATION_DATE, {
        variables: {
            start: now.minus({days:days}).startOf('day').toJSDate(),
            end: now.endOf('day').toJSDate(),
        },
    });
    const users = data?.usersByCreationDate;

    return (
        <AppUserTable
            loading={loading}
            error={error}
            users={users}
            refetch={() => refetch()}
            withActions={true}
            emptyText={`No users created in the last ${days} days`}
        />
    );
}

export function AppUsersRequestedAccountDeletion() {

    const {refetch, loading, data, error} = useQuery(GET_APP_USERS_PENDING_DELETION);
    const users = data?.usersPendingDeletion;

    return (
        <AppUserTable
            loading={loading}
            error={error}
            users={users}
            refetch={() => refetch()}
            withActions={true}
            emptyText={`No users requested deletion`}
        />
    )
}

export function PortalUsersRecentlyCreated({days}) {

    const now = DateTime.now();

    const {refetch, loading, data, error} = useQuery(GET_PORTAL_USERS_BY_CREATION_DATE, {
        variables: {
            start: now.minus({days:days}).startOf('day').toJSDate(),
            end: now.endOf('day').toJSDate(),
        },
    });
    const users = data?.portalUsersByCreationDate;

    return (
        <PortalUserTable
            loading={loading}
            error={error}
            users={users}
            refetch={() => refetch()}
            withActions={true}
            emptyText={`No users created in the last ${days} days`}
        />
    );
}
