import {useSelector} from "react-redux";
import {useState} from "react";

import NearMeIcon from '@mui/icons-material/NearMe';
import InfoIcon from '@mui/icons-material/Info';

import {Image, phonePreviewStyle, phonePreviewScreenStyle, CampaignProgressBar, dateToStringNoTime} from "gih_web_common";

import campaignPreviewBackground from "../../../images/createCampaignMock.png";


export function CampaignPreview({campaign, charity, imageLocalURL}) {

    const imageURLPrefix = useSelector(state => state.cfg?.imageURLPrefix);
    const [showDetail, setShowDetail] = useState(false);

    return (
        <div className="relative mx-auto text-white" style={phonePreviewStyle()}>
            <img src={campaignPreviewBackground} alt="" className="object-contain"/>
            { showDetail ?
            <div className="absolute overflow-y-auto space-y-3" style={phonePreviewScreenStyle()} onClick={() => setShowDetail(false)}>
                <Image rmtPrefix={imageURLPrefix} rmt={campaign.imageURL} local={imageLocalURL} what="phonePreview" />
                <div className="rounded-lg bg-gray-700 p-2 mx-1 text-lg font-semibold text-center">
                    {campaign.name}
                </div>
                <div className="px-2">
                    <CampaignProgressBar total={campaign.total} target={campaign.target} supporters={campaign.uniqueDonors} />
                </div>
                <div className="rounded-lg bg-gray-700 p-2 mx-1 min-h-[96px] text-sm whitespace-pre-wrap">
                    {campaign.fullDescription}
                </div>
                <div className="rounded-lg bg-gray-700 p-2 mx-1 flex flex-row columns-2 items-center">
                    <span className="flex flex-row space-x-2 items-center text-sm text-primary-default">
                        <InfoIcon/>
                        <span>Find out more about {charity.name}</span>
                    </span>
                    <div className="mx-auto">
                        <Image rmtPrefix={imageURLPrefix} rmt={charity.logoPicture} local={null} what="logoSmall" />
                    </div>
                </div>
                <div className="rounded-lg bg-gray-700 p-2 mx-1 flex flex-row columns-2 items-center">
                    <span className="flex flex-row space-x-2 items-center text-sm text-primary-default">
                        <NearMeIcon/>
                        <span>(where your campaign has an impact)</span>
                    </span>
                    <div className="mx-auto text-sm">
                        (distance from donor)
                    </div>
                </div>
            </div>
            :
            <div className="absolute space-y-3" style={phonePreviewScreenStyle(true)}>
                <div className="mx-auto w-fit">
                    <Image rmtPrefix={imageURLPrefix} rmt={campaign.imageURL} local={imageLocalURL} what="phonePreview" />
                </div>
                <div className="absolute px-2 py-1 space-y-3 bottom-0 bg-black bg-opacity-50 w-full">
                    <div className="font-bold text-lg">
                        {(campaign.name.length > 0) ? campaign.name : "(campaign name)"}
                    </div>
                    <div className="flex flex-row grid grid-cols-2 text-base items-center">
                        <div>with {charity.name}</div>
                        <div className="mx-auto">
                            <Image rmtPrefix={imageURLPrefix} rmt={charity.logoPicture} local={null} what="logoSmall" />
                        </div>
                    </div>
                    <CampaignProgressBar total={campaign.total} target={campaign.target} supporters={campaign.uniqueDonors} />
                    { campaign.endDate &&
                    <div className="text-xs">
                        Campaign ends on {dateToStringNoTime(campaign.endDate)}
                    </div>
                    }
                    <div className="flex px-1 py-2 text-sm space-x-1 items-center">
                        <NearMeIcon/>
                        <span>(distance from the donor)</span>
                    </div>
                    <button className="rounded-lg bg-primary-dark hover:bg-primary-darker w-fit px-3 py-1 font-bold text-sm" onClick={() => setShowDetail(true)}>
                        Find out more
                    </button>
                </div>
            </div>
            }
        </div>
    )
}
