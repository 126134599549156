import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {useHistory} from "react-router-dom";

import { useQuery, useMutation } from "@apollo/react-hooks";

import {GlassCard, OnLoadViewer} from "gih_web_common";

import {GET_CHARITY_DETAIL_BY_ID, UPDATE_CHARITY_DETAILS} from "../../utils/graphql/charity";
import {logScreenViewEvent, logActionSuccess, logActionGraphQLFailure} from "../../utils/analytics";
import {ACTIONS, SCREEN_NAME, SCREEN_CLASS} from "../../utils/analyticsConstants";
import {evictAllDependentOnCharity} from "../../utils/graphql/cache";
import {charityTemplate} from "../../utils/charity";

import {TitleAndBackButton} from "../common/elements";
import {SelfSignUpProgress} from "../common/steps";

import {CharityDetailsForm} from "./detailsForm";


function getFormValues(existing) {
    if (existing.provisional) {
        return {
            ...charityTemplate(),
            name: existing.name,
            email: existing.email
        }
    } else {
        return existing
    }
}

function isSigningUp(selfSignupForGiving, charity) {
    return (charity.provisional
         || (!selfSignupForGiving && charity.awaiting1stActivity)
         || (selfSignupForGiving && charity.awaiting1stCampaign)
           );
}

export default function EditCharityDetailsPage() {

    const history = useHistory();
    const dispatch = useDispatch();

    const selfSignupForGiving = useSelector(state => state.cfg?.selfSignupForGiving);
    const currentCharity = useSelector(state => state.charity);

    const [updateCharity] = useMutation(UPDATE_CHARITY_DETAILS, { update: evictAllDependentOnCharity });

    const {loading: charityQueryInProgress, data: charityQueryResult} = useQuery(GET_CHARITY_DETAIL_BY_ID, {
        variables: { charityId: currentCharity.id },
        fetchPolicy: "network-only"
    });

    function pathOnSuccess() {
        if (!selfSignupForGiving && currentCharity.awaiting1stActivity)
            return "/activity/create";
        else if (selfSignupForGiving && currentCharity.awaiting1stCampaign)
            return "/campaign/create";
        else
            return "/charity/profile";
    }

    function onValidated(charity) {
        return updateCharity({
            variables: {
                charityId: currentCharity.id,
                details: charity
            }
        }).then(r => {
            logActionSuccess(ACTIONS.charityUpdate, `Updated charity ${currentCharity.id}`);
            dispatch({type: "SET_CHARITY", payload: r.data.updateCharityDetails});
            history.push(pathOnSuccess());
        }, e => {
            logActionGraphQLFailure(ACTIONS.charityUpdate, e);
            throw e;
        });
    }

    useEffect(() => {
        logScreenViewEvent(SCREEN_NAME.charityEdit, SCREEN_CLASS.charity)
    }, [])

    return (
        <div className="p-1 lg:p-4 flex flex-col overflow-y-auto" style={{ height: 'calc(100vh - 64px)' }}>
            <div className="mx-auto space-y-2 max-w-full">
                { isSigningUp(selfSignupForGiving, currentCharity) &&
                <GlassCard>
                    <SelfSignUpProgress activeStep={2} />
                </GlassCard>
                }
                <TitleAndBackButton title={currentCharity.provisional ? "Tell us about your organisation" : "Edit charity profile"} back={!currentCharity.provisional} />
                <OnLoadViewer loading={charityQueryInProgress}>
                    { charityQueryResult &&
                    <CharityDetailsForm
                        initial={getFormValues(charityQueryResult.portalFindCharityById)}
                        editing={!currentCharity.provisional}
                        onValidated={onValidated}
                    />
                    }
                </OnLoadViewer>
            </div>
        </div>
    )
}
