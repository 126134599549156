import {useState} from "react";
import Textarea from 'react-expanding-textarea'

import {useMutation} from "@apollo/react-hooks";

import {buttonStyles, inputStyles, Modal, AlertModal} from "gih_web_common";

import {SEND_MESSAGE_TO_PARTICIPANTS} from "../../../utils/graphql/activity";

import {tabButtonStyle} from "../../common/style";


export function GroupMessageModal(props) {

    const [selectedGroup, setSelectedGroup] = useState(null);
    const [message, setMessage] = useState("");

    const [sendMessageToParticipants] = useMutation(SEND_MESSAGE_TO_PARTICIPANTS)

    function sendMessage() {

        sendMessageToParticipants({
            variables: {
                activityId: props.activity._id,
                message: message,
                group: selectedGroup,
            }
        }).then((result) => {
            props.onCompletion({
                recipients: result.data.sendMessageToParticipants,
                group: selectedGroup
            })
        })
    }

    return (
        <Modal
            onDismiss={props.onDismiss}
            title={
                <div className="flex flex-wrap gap-2">
                    <span>Send a group message for</span>
                    <span className="italic">"{props.activity.name}"</span>
                </div>
            }
            footer={
                <button className={buttonStyles.altLg} type="button" disabled={selectedGroup === null || message.length < 1} onClick={sendMessage}>
                    Send
                </button>
            }
        >
            <div className="flex flex-wrap items-center justify-start gap-2">
                <div className="text-sm sm:text-base">
                    Select a group to receive this message
                </div>
                <div  className="flex flex-wrap gap-2">
                    { getGroups(props.activityGroup, props.activity).map((what) => {
                        const selected = selectedGroup === what.key
                        return (
                            <button
                                className={`${tabButtonStyle(selected, false)} uppercase`}
                                type="button"
                                onClick={() => { setSelectedGroup(what.key) }}
                            >
                                {what.text}
                            </button>
                        )
                    })}
                </div>
            </div>
            <Textarea
                className={`w-full ${inputStyles.text}`}
                id="group_message_text"
                name="group_message_text"
                style={{resize:"none"}}
                rows={3}
                placeholder="Type your message here..."
                value={message}
                onChange={(event) => setMessage(event.target.value)}
                autoComplete="off"
            />
        </Modal>
    )
}

export function GroupMessageResultModal({result, onDismiss}) {

    return (
        <AlertModal
            type={(result.recipients > 0) ? "success" : "warning"}
            message={getSendToGroupResultText(result)}
            onDismiss={onDismiss}
        />
    )
}

function getGroups(activityGroup, activity) {

    if (activity.repetition !== null) {
        return [
            {
                text: "All interested",
                key: "allInterested"
            },
            {
                text: "Everyone accepted",
                key: "onlyAccepted"
            },
            {
                text: "Attended at least once",
                key: "onlyAttended"
            },
        ]

    } else if (activityGroup === "upcoming") {
        return [
            {
                text: "All interested",
                key: "allInterested"
            },
            {
                text: "Everyone accepted",
                key: "onlyAccepted"
            },
        ]

    } else {
        return [
            {
                text: "All interested",
                key: "allInterested"
            },
            {
                text: "Everyone who attended",
                key: "onlyAttended"
            },
            {
                text: "Everyone who didn't turn up",
                key: "onlyAcceptedNotAttended"
            },
        ]
    }
}

function getSendToGroupResultText(result) {
    if (result.recipients > 1) {
        return `Your message was sent to ${result.recipients} volunteers.`
    } else if (result.recipients > 0) {
        return `Your message was sent to one volunteer.`
    } else if (result.group === "onlyAccepted") {
        return "Sorry, we couldn't find any volunteers accepted for the activity."
    } else if (result.group === "onlyAcceptedNotAttended") {
        return "Sorry, we couldn't find any volunteers who failed to turn up for the activity."
    } else if (result.group === "onlyAttended") {
        return "Sorry, we couldn't find any volunteers who checked in to the activity."
    } else {
        return "Sorry, we couldn't find any volunteers."
    }
}
