import {useMediaQuery} from 'react-responsive'
import {useSelector} from "react-redux";

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';


function getSteps(giving) {
    if (giving) {
        return [
            {
                label: 'Tell us your e-mail and the name of your organisation',
                props: {}
            }, {
                label: 'Verify your e-mail',
                props: {}
            }, {
                label: 'Enter your organisation details',
                props: {},
            }, {
                label: 'Create your first fundraising campaign',
                props: {},
            }
        ];
    } else {
        return [
            {
                label: 'Tell us your e-mail and the name of your organisation',
                props: {}
            }, {
                label: 'Verify your e-mail',
                props: {}
            }, {
                label: 'Enter your organisation details',
                props: {},
            }, {
                label: 'Create your first volunteering opportunity',
                props: {},
            }
        ];
    }
}

function getStepStyle(giving) {
    return {
        "& .MuiStepIcon-root": {
            fontSize: "2rem",
        },
        "&": {
            maxWidth: "16em",
        },
        "& .Mui-active": {
            "&.MuiStepIcon-root": {
                color: "var(--primaryDark)",
                fontSize: "3rem",
            },
            "&.MuiStepLabel-alternativeLabel": {
                color: "var(--primaryLight)",
                fontSize: "1rem",
            },
            "&.MuiStepConnector-line": {
                borderColor: "var(--primary)"
            }
        },
        "& .Mui-completed": {
            "&.MuiStepIcon-root": {
                color: "#00C040",
                fontSize: "2rem",
            },
            "&.MuiStepLabel-alternativeLabel": {
                color: "#00C040",
            },
            "& .MuiStepConnector-line": {
                borderColor: "#00C040"
            }
        }
    };
}

function getStepperProps(isNarrow) {
    if (isNarrow) {
        return {
            orientation: "vertical",
        }
    } else {
        return {
            alternativeLabel: true
        }
    }
}

export function SelfSignUpProgress({activeStep}) {

    const selfSignupForGiving = useSelector(state => state.cfg?.selfSignupForGiving);
    const isNarrow = useMediaQuery({ query: '(max-width: 640px)' })

    return (
        <Stepper activeStep={activeStep} {...getStepperProps(isNarrow)} className="w-fit mx-auto">
        { getSteps(selfSignupForGiving).map((step, index) => {
            return (
                <Step key={step.label} completed={activeStep > index} sx={getStepStyle(selfSignupForGiving)}>
                    <StepLabel>{step.label}</StepLabel>
                </Step>
            );
        })}
        </Stepper>
    )
}
