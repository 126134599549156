import {filterOptions} from "../../pages/charity/select";


export function evictAllDependentQueries(cache, check) {
    filterOptions.forEach(item => {
        if (check(item) === true) {
            if (cache.evict({
                id: "ROOT_QUERY", 
                fieldName: "portalCharityTable",
                args: { filter: item.value, filterText: "" }
            })) {
                console.log(`Evicted charity table for filter=${item.value}`);
            }
        }
    })
    cache.gc();
}

export function evictAllDependentOnActivity(cache, {data}) {
    let what
    if (data.cancelActivity !== undefined) {
        what = {
            _id: data.cancelActivity._id,
            charityId: data.cancelActivity.charityId
        }
    } else if (data.addActivity !== undefined) {
        what = {
            _id: data.addActivity._id,
            charityId: data.addActivity.charityId._id
        }
    }  else if (data.updateActivity !== undefined) {
        what = {
            _id: data.updateActivity._id,
            charityId: data.updateActivity.charityId._id
        }
    }
    console.log(`Evicting query results dependent on activity ${what._id} in charity ${what.charityId}`)
    evictAllDependentQueries(cache, item => item.dependencies.activities)
    evictAllDependentOnParticipationStatus(cache, what.charityId)
    cache.evict({
        id: "ROOT_QUERY", 
        fieldName: "portalFindActivitiesByCharityId",
        args: { charityId: what.charityId }
    })
    cache.evict({
        id: "ROOT_QUERY", 
        fieldName: "portalFindActivityById",
        args: { activityId: what._id }
    })
    cache.gc();
}

export function evistAllDependentOnCampaignFlags(cache, {data}) {
    cache.evict({
        id: "ROOT_QUERY", 
        fieldName: "campaignDashboard",
    })
    console.log(`Evicting query results dependent on campaign flags`);
    cache.gc();
}

export function evictAllDependentOnCampaign(cache, {data}) {
    let what
    if (data.cancelCampaign !== undefined) {
        what = {
            _id: data.cancelCampaign._id,
            charityId: data.cancelCampaign.charityId
        }
    } else if (data.addCampaign !== undefined) {
        what = {
            _id: data.addCampaign._id,
            charityId: data.addCampaign.charityId._id
        }
    } else if (data.updateCampaign !== undefined) {
        what = {
            _id: data.updateCampaign._id,
            charityId: data.updateCampaign.charityId._id
        }
    }
    console.log(`Evicting query results dependent on campaign ${what._id} in charity ${what.charityId}`)
    evictAllDependentQueries(cache, item => item.dependencies.campaigns)
    cache.evict({
        id: "ROOT_QUERY", 
        fieldName: "portalFindCampaignsByCharityId",
        args: { charityId: what.charityId }
    })
    cache.evict({
        id: "ROOT_QUERY", 
        fieldName: "portalFindCampaignById",
        args: { campaignId: what._id }
    })
    cache.gc();
}

export function evictAllDependentOnCharity(cache, {data}) {
    let charityId
    if (data.addNewCharity !== undefined) {
        charityId = data.addNewCharity._id
    } else if (data.updateCharityDetails !== undefined) {
        charityId = data.updateCharityDetails._id
    }
    console.log(`Evicting query results dependent on charity ${charityId}`)
    evictAllDependentQueries(cache, item => item.dependencies.charities)
    cache.evict({
        id: "ROOT_QUERY", 
        fieldName: "portalFindCharityById",
        args: { charityId: charityId }
    })
    cache.gc();
}

export function evictAllDependentOnComments(cache, mutationResult) {
    evictAllDependentQueries(cache, item => item.dependencies.comments)
    cache.evict({
        id: "ROOT_QUERY", 
        fieldName: "portalCharityFollowUps",
    })
    cache.gc();
}

export function evictAllDependentOnParticipationStatus(cache, charityId) {
    cache.evict({
        id: "ROOT_QUERY", 
        fieldName: "countPendingParticipationsForCharityId",
        args: { charityId: charityId }
    })
    cache.gc();
}
