import {gql} from "@apollo/client";


export const GET_ACTIVITIES_BY_CREATION_DATE = gql`
    query ActivitiesByCreationDate($start: DateTime!, $end: DateTime!) {
        activitiesByCreationDate(start: $start, end: $end) {
            _id
            createdAt
            createdBy
            name
            minutes
            imageURL
            videoURL
            scheduling
            eventDate
            repetition
            repetitionActive
            cancelled
            hidden
            full
            autoReply
            autoReplyText
            workLocation
            ageRestricted
            manageAvailability
            requiredVolunteers
            charityId {
                _id
                name
                logoPicture
            }
        }
    }
`

export const GET_PARTICIPATIONS_PENDING = gql`
    query ParticipationsPending($start: DateTime!, $end: DateTime!) {
        participationsPending(start: $start, end: $end) {
            _id
            userId {
                _id
                firstName
                lastName
                profilePicture
                createdAt
            }
            activityId {
                _id
                name
            }
            charityId {
                _id
                name
                logoPicture
            }
            createdAt
        }
    }
`

export const GET_ENGAGEMENT_BY_CHARITY = gql`
    query EngagementByCharity($start: DateTime!, $end: DateTime!) {
        engagementByCharity(start: $start, end: $end) {
            names
            series {
                label
                data
            }
        }
    }
`

export const GET_ENGAGEMENT_BY_ACTIVITY = gql`
    query EngagementByActivity($start: DateTime!, $end: DateTime!) {
        engagementByActivity(start: $start, end: $end) {
            names
            series {
                label
                data
            }
        }
    }
`

export const GET_ACTIVITY_SHARES_BY_SHARE_TARGET_BY_PERIOD = gql`
    query ActivitySharesByShareTarget($start: DateTime!, $end: DateTime!, $period: String!) {
        activitySharesByShareTarget(start: $start, end: $end, period: $period) {
            times
            series {
                label
                data
            }
        }
    }
`

export const GET_ENGAGEMENT_BY_DISTANCE = gql`
    query EngagementByDistance($start: DateTime!, $end: DateTime!) {
        engagementByDistance(start: $start, end: $end) {
            names
            series {
                label
                data
            }
        }
    }
`

export const GET_ACTIVITIES_CREATED_BY_PERIOD = gql`
    query ActivitiesCreatedByPeriod($start: DateTime!, $end: DateTime!, $period: String!) {
        activitiesCreatedByPeriod(start: $start, end: $end, period: $period) {
            when
            count
        }
    }
`

export const GET_PARTICIPATIONS_CREATED_BY_PERIOD = gql`
    query ParticipationsCreatedByPeriod($start: DateTime!, $end: DateTime!, $period: String!) {
        participationsCreatedByPeriod(start: $start, end: $end, period: $period) {
            when
            counts {
                status
                count
            }
        }
    }
`

export const GET_PARTICIPATIONS_CREATED_BY_CHARITY_BY_PERIOD = gql`
    query ParticipationsCreatedByCharityByPeriod($start: DateTime!, $end: DateTime!, $period: String!) {
        participationsCreatedByCharityByPeriod(start: $start, end: $end, period: $period) {
            when
            counts {
                entityId
                name
                count
            }
        }
    }
`

export const GET_PARTICIPATIONS_BY_CHARITY_BY_STATUS = gql`
    query ParticipationsByCharityByStatus {
        participationsByCharityByStatus {
            name
            counts {
                status
                count
            }
        }
    }
`

export const GET_WORK_BY_PERIOD = gql`
    query WorkByPeriod($start: DateTime!, $end: DateTime!, $period: String!) {
        workByPeriod(start: $start, end: $end, period: $period) {
            when
            checkins
            minutesWorked
        }
    }
`

export const GET_WORK_BY_CHARITY_BY_PERIOD = gql`
    query WorkByCharityByPeriod($start: DateTime!, $end: DateTime!, $period: String!) {
        workByCharityByPeriod(start: $start, end: $end, period: $period) {
            when
            counts {
                entityId
                name
                checkins
                minutesWorked
            }
        }
    }
`

export const GET_WORK_BY_USER_BY_PERIOD = gql`
    query WorkByUserByPeriod($start: DateTime!, $end: DateTime!, $period: String!) {
        workByUserByPeriod(start: $start, end: $end, period: $period) {
            when
            counts {
                entityId
                name
                checkins
                minutesWorked
            }
        }
    }
`

export const GET_CERTIFICATES_CREATED_BY_PERIOD = gql`
    query CertificatesByTypeByPeriod($start: DateTime!, $end: DateTime!, $period: String!) {
        certificatesByTypeByPeriod(start: $start, end: $end, period: $period) {
            when
            counts {
                hours
                count
            }
        }
    }
`

export const GET_OUTCOMES_BY_PERIOD = gql`
    query OutcomesByPeriod($start: DateTime!, $end: DateTime!, $period: String!) {
        outcomesByPeriod(start: $start, end: $end, period: $period) {
            when
            checkedIn
            noShow
            declined
            pending
            withdrewPending
            withdrewAccepted
        }
    }
`
