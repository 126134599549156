import {useState} from "react";
import Textarea from 'react-expanding-textarea'

import {Menu, Transition} from "@headlessui/react";

import {useQuery, useMutation} from "@apollo/react-hooks";

import {buttonStyles, inputStyles, Modal, dateToString} from "gih_web_common";

import minutesToString from "../../../utils/duration";
import {GET_PAST_OCCURRENCES_FOR_ACTIVITY, MANUAL_CHECKIN} from "../../../utils/graphql/activity";


export function ManualCheckinModal(props) {

    const [comments, setComments] = useState("");
    const [occurrence, setOccurrence] = useState(null);

    const {data: pastOccurrenceData} = useQuery(GET_PAST_OCCURRENCES_FOR_ACTIVITY, {
        variables: {
            activityId: props.activity._id,
            firebaseId: props.user.firebaseId
        },
        fetchPolicy: "no-cache"
    })

    const [manualCheckin] = useMutation(MANUAL_CHECKIN)

    const requestCheckin = () => {

        return manualCheckin({
            variables: {
                firebaseId: props.user.firebaseId,
                work: {
                    activityId: props.activity._id,
                    minutesWorked: props.activity.minutes,
                    comments: (comments.length > 0) ? comments : null,
                    eventDate: occurrence.eventDate,
                },
            }
        }).then((result) => {
            console.log("Checkin successful")
            props.onSuccess()
        })
    }

    return (
        <Modal
            onDismiss={props.onDismiss}
            title={
                <div className="flex flex-wrap gap-2">
                    <span>Manually check</span>
                    <span className="italic">"{props.user.name}"</span>
                    <span>into</span>
                    <span className="italic">"{props.activity.name}"</span>
                </div>
            }
            footer={
                <button className={buttonStyles.altLg} type="button" disabled={occurrence === null} onClick={requestCheckin}>
                    Checkin
                </button>
            }
        >
            { pastOccurrenceData &&
            <div className="flex items-center justify-start gap-2">
                <span className="text-base">Select the date the volunteer attended</span>
                <OccurrenceMenu
                    occurrences={pastOccurrenceData.activityPastOccurrences}
                    selection={occurrence}
                    setSelection={setOccurrence}
                />
            </div>
            }
            <Textarea
                className={`w-full ${inputStyles.text}`}
                id="checkin_comments"
                name="checkin_comments"
                style={{resize:"none"}}
                rows={3}
                placeholder="Add any comments here..."
                value={comments}
                onChange={(event) => setComments(event.target.value)}
                autoComplete="off"
            />
        </Modal>
    )
}


function OccurrenceMenu(props) {

    function occurrence2String(o) {
        return `${dateToString(o.eventDate)} (${minutesToString(o.minutes)})`
    }

    return (
        <Menu as="div" className="relative w-[60%] z-[60]">
            <Menu.Button className="flex justify-start w-full rounded-md bg-gray-100 text-black border border-gray-300 shadow-sm px-4 py-2 font-medium text-sm">
                {(props.selection !== null) ? occurrence2String(props.selection) : "Select"}
                <svg className="-mr-1 ml-2 h-5 w-5"
                     xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 20 20"
                     fill="currentColor"
                     aria-hidden="true">
                    <path fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"/>
                </svg>
            </Menu.Button>
            <Transition
                enter="transition ease-out duration-300"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-300"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute max-h-72 overflow-y-auto w-full border shadow-lg">
                { props.occurrences.map((occurrence, index) => (
                    <Menu.Item disabled={occurrence.checkedIn}>
                        { ({active}) => (
                            <div className={`w-full items-start px-3 py-2 ${active ? "bg-alt-dark text-white":"bg-gray-100"} font-medium text-sm ${occurrence.checkedIn ? "text-gray-400" : ""}`} onClick={() => { props.setSelection(occurrence) }}>
                                {occurrence2String(occurrence)}
                            </div>
                        )}
                    </Menu.Item>
                ))}
                </Menu.Items>
            </Transition>
        </Menu>
    )
}
