import { DateTime } from "luxon"

import {useEffect, useState} from 'react'
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';

import IosShareIcon from '@mui/icons-material/IosShare';

import {useQuery} from "@apollo/react-hooks";

import {buttonStyles, GlassCard, GlassPreviewCard, GroupBox, OnLoadViewer, addressToString, placesToString, CampaignProgressBar, dateToStringNoTime} from "gih_web_common";

import {logScreenViewEvent} from "../../utils/analytics";
import {SCREEN_NAME, SCREEN_CLASS} from "../../utils/analyticsConstants";
import {GET_CAMPAIGN_LIST_BY_CHARITYID} from "../../utils/graphql/campaign";

import {truncate} from "../common/style";
import {StripeBanner} from "../common/stripeBanner";

import {Badge} from "./badge";


export function groupCampaigns(campaignList) {

    const groups = {
        "ongoing": [],
        "completed": []
    }

    if (campaignList !== null) {

        const watershed = DateTime.now()

        campaignList.forEach((campaign) => {
            const group = (!campaign.cancelled && DateTime.fromISO(campaign.endDate).endOf('day') > watershed) ? "ongoing" : "completed"
            groups[group].push(campaign)
        })

        Object.keys(groups).forEach((group) => {
            const ascending = (group === "ongoing") ? 1 : -1
            groups[group].sort((a, b) => {
                const da = new Date(a.createdAt)
                const db = new Date(b.createdAt)
                if (da < db) return -ascending
                if (da > db) return ascending
                return 0
            })
        })

        console.log('Sorted groups', groups)
    }

    return groups
}

export function CampaignCards({group}) {

    const charity = useSelector(state => state.charity);
    const imageURLPrefix = useSelector(state => state.cfg?.imageURLPrefix);

    const [campaigns, setCampaigns] = useState(groupCampaigns(null));
    const [linkCopied, setLinkCopied] = useState(null);

    const {loading: campaignListLoading} = useQuery(GET_CAMPAIGN_LIST_BY_CHARITYID, {
        variables: { charityId: charity.id},
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => setCampaigns(groupCampaigns(data.portalFindCampaignsByCharityId)),
    });

    useEffect(() => {
        logScreenViewEvent(SCREEN_NAME.campaignList, SCREEN_CLASS.campaign)
    }, [])

    return (
        <div className="w-full py-4 overflow-y-auto" style={{ height: 'calc(100vh - 64px - 64px)' }}>
            <StripeBanner/>
            <OnLoadViewer loading={campaignListLoading}>
                <div className="p-1 lg:p-4 mx-auto w-fit grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 max-w-screen-2xl">
                    { campaigns[group].map(campaign => (
                        <GlassPreviewCard
                            key={campaign._id}
                            rmtPrefix={imageURLPrefix}
                            rmt={campaign.imageURL}
                            height={720}
                            overlay={<Badge campaign={campaign} />}
                        >
                            <GroupBox className="h-22 text-lg font-bold">
                                {campaign.name}
                            </GroupBox>
    
                            <GroupBox className="h-30 text-sm">
                                <CampaignProgressBar total={campaign.total} target={campaign.target} supporters={campaign.uniqueDonors} />
                                <div>Ends {dateToStringNoTime(campaign.endDate)}</div>
                            </GroupBox>
    
                            <GroupBox className="h-24 text-sm">
                                {campaign.address ? addressToString(campaign.address) : truncate(placesToString(campaign.places), 128)}
                            </GroupBox>
    
                            <div className="flex flex-row justify-center space-x-4 pt-2">
                                <Link to={`/campaign/${campaign._id}`} className={buttonStyles.primaryLg}>
                                    View details
                                </Link>
                                { campaign.dynamicLink &&
                                <CopyToClipboard text={campaign.dynamicLink} onCopy={() => setLinkCopied(campaign.dynamicLink)}>
                                    <button className={buttonStyles.blueLg}>
                                        <IosShareIcon /> {(linkCopied === campaign.dynamicLink) ? "Link copied!" : "Get link & share"}
                                    </button>
                                </CopyToClipboard>
                                }
                            </div>
                        </GlassPreviewCard>
                    ))}

                    { campaigns[group].length < 1 &&
                    <GlassCard>
                        <div className={"text-2xl font-semibold p-2 text-fgCard-default"}>No {(group === "ongoing") ? "ongoing" : "completed"} campaigns</div>
                    </GlassCard>
                    }
                </div>
            </OnLoadViewer>
        </div>
    );
}
