import {gql} from "@apollo/client";

import {charityBaseInfo, charityDetailInfo} from './basic'


export const GET_ALL_CHARITY_PROFILES = gql`
    query CharityTable($filter: String!, $filterText: String!) {
        portalCharityTable(filter: $filter, filterText: $filterText) {
            ${charityBaseInfo}
            createdAt
            profilePicture
            address {
                line1
                line2
                streetName
                townCity
                postcode
            }
        }
    }
`

export const CREATE_NEW_CHARITY = gql`
    mutation AddNewCharity($details: CharityInput!) {
        addNewCharity(details: $details) {
            ${charityDetailInfo}
        }
    }
`

export const GET_CHARITY_FOLLOWUPS = gql`
    query CharityFollowups {
        portalCharityFollowUps {
            charityId {
                ${charityBaseInfo}
            }
            due
            text
            status
        }
    }
`

export const GET_CHARITY_COMMENTS = gql`
    query CharityComments($charityId: ID!) {
        portalCharityComments(id: $charityId) {
            _id
            text
            status
            createdAt
            updatedAt
            createdBy
            followUp
        }
    }
`

export const ADD_CHARITY_COMMENT = gql`
    mutation AddComment($charityId: ID!, $comment: CommentInput!) {
        addCharityComment(charityId: $charityId, comment: $comment)
    }
`

export const UPDATE_CHARITY_COMMENT = gql`
    mutation UpdateComment($commentId: ID!, $comment: CommentInput!) {
        updateCharityComment(commentId: $commentId, comment: $comment)
    }
`

export const DELETE_CHARITY_COMMENT = gql`
    mutation DeleteComment($commentId: ID!) {
        deleteCharityComment(commentId: $commentId)
    }
`

export const GET_LIVE_COUNTS = gql`
    query LiveCounts {
        liveCounts {
            charities {
                active
                chasing
                waiting
                dormant
                dead
                provisional
            }
            activities {
                remote
                geographic
                withVideo
                charities
            }
            campaigns {
                remote
                geographic
                withVideo
                charities
            }
            participationsByStatus {
                status
                count
            }
        }
    }
`
