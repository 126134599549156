import {useState} from "react";
import {useQuery} from "@apollo/react-hooks";

import {dateToStringNoTime} from "gih_web_common";

import {graphQLCache} from "../../../index";

import {seriesToColour, colourScheme, BarGraph} from "../../../utils/stats";

import { GET_WEB_REQUEST_SUMMARY_COUNTS } from "../../../utils/graphql/admin-web-stats";

import {truncate} from "../../common/style";


export function invalidateWeb() {
    console.log(`Evicting database web stats query results`);

    const queries = [
        "webRequestSummaryCounts",
    ];

    queries.forEach(query => {
        graphQLCache.evict({
            id: "ROOT_QUERY", 
            fieldName: query,
        })
    });

    graphQLCache.gc();
}

function toString(origin, withCampign=true) {
    let s = origin.referrer ?? "<none>";
    if (origin.attribution)
        s += ` (${origin.attribution})`;
    if (origin.what)
        s += ` (${origin.what})`;
    if (origin.targetCampaign && withCampign)
        s += ` (${truncate(origin.targetCampaign.name, 24)})`;
    return s;
}

function empty() {
    return {
        labels: [],
        datasets: []
    };
}

export function RequestsByOrigin({start, end, operation}) {

    const [graphData, setGraphData] = useState(null);

    useQuery(GET_WEB_REQUEST_SUMMARY_COUNTS, {
        variables: {
            start: start,
            end: end,
            operation: operation,
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData((data.webRequestSummaryCounts.length > 0) ? toGraphData(data.webRequestSummaryCounts) : empty()),
    });

    return (
        <BarGraph title={`Origins of "${operation}" ${dateToStringNoTime(start, 'short')} - ${dateToStringNoTime(end, 'short')}`} data={graphData} stacked={true} />
    );
}

function toGraphData(r) {

    const originStrs = Array.from(r.reduce((all, site) => {
        site.origins.forEach(origin => all.add(toString(origin)));
        return all;
    }, new Set()).keys());

    return {
        labels: r.map(item => item.host),
        datasets: originStrs.map(originStr => {
            return {
                label: originStr,
                backgroundColor: seriesToColour({ label: originStr }),
                data: r.map(site => site.origins.find(origin => toString(origin) === originStr)?.count ?? 0)
            }
        }),
    };
}

export function RequestsToSiteByOrigin({start, end, host, operation}) {

    const [graphData, setGraphData] = useState(null);

    useQuery(GET_WEB_REQUEST_SUMMARY_COUNTS, {
        variables: {
            start: start,
            end: end,
            operation: operation,
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData((data.webRequestSummaryCounts.length > 0) ? toSingleSiteGraphData(data.webRequestSummaryCounts, host) : empty()),
    });

    return (
        <BarGraph title={`"${operation}" on site "${host}" ${dateToStringNoTime(start, 'short')} - ${dateToStringNoTime(end, 'short')}`} data={graphData} />
    );
}

function toSingleSiteGraphData(r, host) {

    const origins = r.find(site => site.host === host).origins;

    return {
        labels: origins.map(origin => toString(origin)),
        datasets: [{
            label: 'Count',
            backgroundColor: colourScheme.users,
            data: origins.map(origin => origin.count)
        }],
    };
}

export function RequestsToSiteByTargetCampaign({start, end, host, operation}) {

    const [graphData, setGraphData] = useState(null);

    useQuery(GET_WEB_REQUEST_SUMMARY_COUNTS, {
        variables: {
            start: start,
            end: end,
            operation: operation,
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData((data.webRequestSummaryCounts.length > 0) ? toRequestsByCampaign(data.webRequestSummaryCounts, host) : empty()),
    });

    return (
        <BarGraph title={`Origins of "${operation}" on site "${host}" by campaign ${dateToStringNoTime(start, 'short')} - ${dateToStringNoTime(end, 'short')}`} data={graphData} stacked={true} />
    );
}

function toRequestsByCampaign(r, host) {

    const origins = r.find(site => site.host === host).origins;

    const originStrs = Array.from(origins.reduce((all, origin) => {
        all.add(toString(origin, false));
        return all;
    }, new Set()).keys());

    const campaigns = Array.from(origins.reduce((all, origin) => {
        if (!origin.targetCampaign) {
            console.error('Target campaign is null')
        } else {
            let campaign = all.get(origin.targetCampaign._id);
            if (!campaign) {
                campaign = {
                    detail: origin.targetCampaign,
                    byOrigin: new Map(),
                };
                all.set(origin.targetCampaign._id, campaign);
            }
            campaign.byOrigin.set(toString(origin, false), origin.count);
        }
        return all;
    }, new Map()).values());

    return {
        labels: campaigns.map(campaign => truncate(campaign.detail.name, 32)),
        datasets: originStrs.map(originStr => {
            return {
                label: originStr,
                backgroundColor: seriesToColour({ label: originStr }),
                data: campaigns.map(campaign => campaign.byOrigin.get(originStr) ?? 0),
            }
        }),
    };
}
