import {Fragment, useState} from "react";
import {Link, useHistory, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useMediaQuery} from 'react-responsive'
import {isMobile} from 'react-device-detect';
import {Transition, Menu} from "@headlessui/react";

import {signOut} from "firebase/auth";

import classNames from "classnames";

import {TfiStatsUp, TfiWorld} from "react-icons/tfi";
import {FiMessageCircle, FiCalendar, FiPlusSquare, FiSettings} from "react-icons/fi";
import { ImUsers } from "react-icons/im";

import {Image} from "gih_web_common";
import Logo from 'gih_web_common/assets/gih-logo-1818x268.png';
import SquareLogo from 'gih_web_common/assets/gih-logo-192x192.png';

import {PendingParticipationBadge, TotalUnreadBadge} from "../pages/common/overallBadgeCounts"
import {navButtonStyle, navIconStyle} from "../pages/common/style"

import {auth} from "../utils/firebase";
import {logActionSuccess} from "../utils/analytics";
import {ACTIONS} from "../utils/analyticsConstants";


function getLinks(user, charity, fullAccess, givingMode, pathname) {

    const links = []

    if (charity !== null && !charity.provisional && (!fullAccess || charity.id !== user.charityId._id)) {
        if (givingMode === true) {
            if (!charity.awaiting1stCampaign) {
                links.push({
                    href: "/campaigns/ongoing",
                    name: "Manage Campaigns",
                    icon: FiCalendar,
                    current: pathname.includes("/campaigns/"),
                    badge: null,
                    giving: true,
                });
            }

            links.push({
                href: "/campaign/create",
                name: "Create Campaign",
                icon: FiPlusSquare,
                current: pathname === "/campaign/create",
                badge: null,
                giving: true,
            })

            if (!charity.awaiting1stCampaign) {
                links.push({
                    href: "/charity/campaignStats",
                    name: "Statistics",
                    icon: TfiStatsUp,
                    current: pathname === "/charity/campaignStats",
                    badge: null,
                    giving: true,
                });
            }

        } else if (givingMode === false) {
            if (!charity.awaiting1stActivity) {
                links.push({
                    href: "/chats/byActivity/upcoming",
                    name: "Manage Volunteers",
                    icon: FiMessageCircle,
                    current: pathname.includes("/chats/"),
                    badge: ManageVolunteersBadge,
                    giving: false,
                }, {
                    href: "/activities/upcoming",
                    name: "Manage Activities",
                    icon: FiCalendar,
                    current: pathname.includes("/activities/"),
                    badge: null,
                    giving: false,
                })
            }

            links.push({
                href: "/activity/create",
                name: "Create Activity",
                icon: FiPlusSquare,
                current: pathname === "/activity/create",
                badge: null,
                giving: false,
            })

            if (!charity.awaiting1stActivity) {
                links.push({
                    href: "/charity/volunteerStats",
                    name: "Statistics",
                    icon: TfiStatsUp,
                    current: pathname === "/charity/volunteerStats",
                    badge: null,
                    giving: false,
                })
            }
        }
    }

    if (fullAccess) {
        links.push({
            href: "/overview/crm",
            name: "Overview",
            icon: TfiWorld,
            current: pathname.includes("/overview/"),
            badge: null,
            giving: false,
        }, {
            href: "/charity/select",
            name: "Select Charity",
            icon: ImUsers,
            current: pathname === "/charity/select",
            badge: null,
            giving: false,
        })
    }

    return links
}

function showCharityLogo(path) {
    return path !== "/charity/edit"
        && path !== "/charity/select"
        && !path.toString().includes("/overview/")
}

function ManageVolunteersBadge() {
    return (
        <div className="flex gap-1">
            <PendingParticipationBadge/>
            <TotalUnreadBadge/>
        </div>
    )
}

export default function Navbar() {

    const iconifyNavbar = useMediaQuery({ query: '(max-width: 640px)' });
    const location = useLocation();

    const charity = useSelector(state => state.charity);
    const user = useSelector(state => state.user);
    const imageURLPrefix = useSelector(state => state.cfg?.imageURLPrefix);
    const givingMode = useSelector(state => state.givingMode);

    const [showOptions, setShowOptions] = useState(false);

    const handleClick = () => {
        setShowOptions(!showOptions);
    };

    return (
        <nav className={`bg-bg-navbar flex items-center justify-between main-navigation-bar w-full z-10 ${isMobile ? "fixed bottom-0" : ""}`}>
            { iconifyNavbar &&
            <div className="flex items-center justify-between w-full px-4">
                <div className="flex px-2 items-center h-16">
                    <img className="h-12 w-auto object-contain" src={SquareLogo} alt="Giving is Human"/>
                </div>

                { getLinks(user, charity, user?.fullAccess, givingMode, location.pathname).map((item) => {
                    const Icon = item.icon
                    const Badge = item.badge
                    return (
                        <div className="flex relative" key={item.name}>
                            <Link
                                to={item.href}
                                aria-current={item.current ? 'page' : undefined}
                            >
                                <Icon size={24} className={navIconStyle(item.current, givingMode)} />
                                { Badge !== null &&
                                    <div className="absolute top-[-16px] right-[-16px]">
                                        <Badge/>
                                    </div>
                                }
                            </Link>
                        </div>
                    )}
                )}

                <Menu as="div" className="items-center relative h-[24px]">
                    <Menu.Button onClick={handleClick}>
                        <div className={navIconStyle(false, givingMode)}>
                            <FiSettings size={24}/>
                        </div>
                    </Menu.Button>
                    <MenuItems/>
                </Menu>
            </div>
            }

            { !iconifyNavbar &&
            <>
                <div className="flex xl:hidden px-2 items-center h-16">
                    <img className="h-12 w-auto object-contain" src={SquareLogo} alt="Giving is Human"/>
                </div>

                <div className="hidden xl:flex px-2 items-center h-16">
                    <img className="h-12 w-auto object-contain" src={Logo} alt="Giving is Human"/>
                </div>

                <div className="flex flex-row items-center space-x-6">
                    { (!charity || !showCharityLogo(location.pathname)) ?
                        null
                    : charity.provisional ?
                        <div className="flex items-center max-w-[20em] bg-orange-500 text-xs text-white h-[52px] p-2 rounded-lg">{`"${charity.name}" - provisional`}</div>
                    :
                        <Image rmtPrefix={imageURLPrefix} rmt={charity.logoPicture} what="logoSmall" />
                    }
                    { getLinks(user, charity, user?.fullAccess, givingMode, location.pathname).map(item => {
                        const Badge = item.badge;
                        return (
                            <div className="flex relative" key={item.name}>
                                <Link
                                    to={item.href}
                                    className={navButtonStyle(item.current, item.giving)}
                                    aria-current={item.current ? 'page' : undefined}
                                >
                                    {item.name}
                                </Link>
                                { Badge &&
                                <div className="absolute top-[-12px] right-[-16px]">
                                    <Badge/>
                                </div>
                                }
                            </div>
                        );
                    })}
                </div>

                <Menu as="div" className="relative mx-4">
                    <Menu.Button className={navButtonStyle(true, givingMode)} onClick={handleClick}>
                        <div className="flex mx-auto w-fit px-2">
                            <span>Settings</span>
                            <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd"
                                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                      clipRule="evenodd"/>
                            </svg>
                        </div>
                    </Menu.Button>
                    <MenuItems/>
                </Menu>
            </>
            }
        </nav>
    )
}

function menuItemStyle(active, giving) {
    if (giving) {
        if (active) {
            return 'bg-primary-darker text-white'
        } else {
            return 'text-gray-700'
        }
    } else {
        if (active) {
            return 'bg-alt-darker text-white'
        } else {
            return 'text-gray-700'
        }
    }
}

function MenuItems() {

    const history = useHistory();
    const dispatch = useDispatch();

    const charity = useSelector(state => state.charity);
    const fullAccess = useSelector(state => state.user?.fullAccess);
    const givingMode = useSelector(state => state.givingMode);

    function getMenuItems() {

        const menuItems = [
            {
                href: "/user/settings",
                name: "Account settings",
                provisional: false,
                givingMode: null
            }, {
                href: "/charity/profile",
                name: "Charity profile",
                provisional: true,
                givingMode: null
            }, {
                href: "/user/invite",
                name: "Invite a colleague",
                provisional: false,
                givingMode: null
            }, {
                href: "/charity/users",
                name: "Manage users",
                provisional: fullAccess,
                givingMode: null
            }, {
                href: "/privacyPolicy",
                name: "Privacy policy",
                provisional: true,
                givingMode: null
            }, {
                href: "/termsAndConditions",
                name: "Terms & Conditions",
                provisional: true,
                givingMode: null
            }, {
                name: "Manage giving",
                style: "bg-primary-dark hover:bg-primary-darker font-medium text-xs",
                provisional: true,
                givingMode: false,
                onClick: () => {
                    dispatch({type: "SET_GIVING", payload: true});
                    history.push("/campaigns/ongoing");
                }
            }, /* VOLUNTEERING {
                name: "Manage volunteering",
                style: "bg-alt-dark hover:bg-alt-darker font-medium text-xs",
                provisional: false,
                givingMode: true,
                onClick: () => {
                    dispatch({type: "SET_GIVING", payload: false});
                    history.push("/activities/upcoming");
                }
            },*/ {
                name: "Sign out",
                style: "bg-red-500 hover:bg-red-700 font-medium text-base",
                provisional: true,
                givingMode: null,
                onClick: () => {
                    logActionSuccess(ACTIONS.userLogout, "Settings menu");
                    signOut(auth);
                }
            }
        ]

        return menuItems.filter(item =>
               charity
            && (item.provisional || !charity.provisional)
            && (item.givingMode === null || (charity.features.enableGiving && item.givingMode === givingMode)))
    }

    return (
        <Transition
            as={Fragment}
            enter="transition ease-out duration-300"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-300"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
        >
            <Menu.Items className={`absolute right-3 ${isMobile ? "bottom-0" : ""} w-48 rounded-md shadow-lg py-1 bg-bg-paper z-[60]`}>
                { getMenuItems().map(item => (
                    <Menu.Item key={item.name}>
                        { ({active}) => {
                            if (item.onClick) {
                                return <div className="flex items-center justify-end w-full h-full p-2">
                                    <div onClick={item.onClick} className={`flex ml-5 items-center ${item.style} px-3 py-2 rounded-full text-white`}>
                                        {item.name}
                                    </div>
                                </div>
                            } else {
                                return <Link
                                    key={item.name}
                                    to={item.href}
                                    className={classNames(menuItemStyle(active, givingMode), 'block px-4 py-2 text-sm text-fg-default')}
                                >
                                    {item.name}
                                </Link>
                            }
                        }}
                    </Menu.Item>
                ))}
            </Menu.Items>
        </Transition>
    )
}
