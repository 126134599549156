import {gql} from "@apollo/client";

const activitySummary = `
    _id
    createdAt
    updatedAt
    createdBy
    name
    minutes
    imageURL
    videoURL
    scheduling
    eventDate
    repetition
    repetitionActive
    cancelled
    hidden
    full
    autoReply
    workLocation
    ageRestricted
    manageAvailability
    requiredVolunteers
    address {
        line1
        line2
        streetName
        townCity
        postcode
    }
    places {
        name
        qualifier
        geohash
    }
    dynamicLink
`

const activityDetail = `
    ${activitySummary}
    briefDescription
    fullDescription
    cancelledReason
    autoReplyText
    keywords
    charityId {
        _id
    }
`

export const ADD_NEW_ACTIVITY = gql`
    mutation AddActivity($charityId: ID!, $activity: ActivityInput!) {
        addActivity(charityId: $charityId, activity: $activity) {
            ${activityDetail}
        }
    }
`

export const GET_ACTIVITY_LIST_BY_CHARITYID = gql`
    query FindActivitiesByCharityId($charityId: ID!) {
        portalFindActivitiesByCharityId(charityId: $charityId) {
            ${activitySummary}
        }
    }
`

export const GET_ACTIVITY_CREATORS_BY_CHARITYID = gql`
    query FindActivitiesByCharityId($charityId: ID!) {
        portalFindActivitiesByCharityId(charityId: $charityId) {
            _id
            createdAt
            createdBy
            createdById
       }
    }
`

export const GET_ACTIVITY_OCCURRENCES_BY_CHARITYID = gql`
    query ActivityOccurrencesByCharityId($charityId: ID!, $start: DateTime!, $end: DateTime!) {
        activityOccurrencesByCharityId(charityId: $charityId, start: $start, end: $end) {
            occurrences {
                id
                title
                start
                end
                backgroundColor
                borderColor
                textColor
                activityId
                available
            }
            users {
                _id
                firstName
                lastName
                profilePicture
            }
            colours {
                colour
                text
            }
        }
    }
`

export const UPDATE_USER_PARTICIPATION_STATUS = gql`
    mutation UpdateParticipation($status: String!, $activityId: ID!, $firebaseId: String!, $charityId: ID!) {
        updateParticipation(status: $status, activityId: $activityId, firebaseId: $firebaseId, charityId: $charityId) {
            status
            activityId {
                _id
            }
            firebaseId
            charityId {
                _id
            }
        }
    }
`

export const GET_ACTIVITY_BY_ID = gql`
    query FindActivityById($activityId: ID!) {
        portalFindActivityById(id: $activityId) {
            ${activityDetail}            
        }
    }
`

export const GET_TOTAL_USER_PARTICIPATION = gql`
    query FindTotalUP($activityId: ID!) {
        findTotalUP(activityId: $activityId) {
            activityId {
                _id
                name
            }
            _id
        }
    }
`

export const UPDATE_ACTIVITY = gql`
    mutation UpdateActivity($activityId: ID!, $activity: ActivityInput!) {
        updateActivity(id: $activityId, activity: $activity) {
            ${activityDetail}
        }
    }
`

export const CANCEL_ACTIVITY = gql`
    mutation CancelActivity($activityId: ID!, $tryDelete: Boolean!, $reason: String) {
        cancelActivity(id: $activityId, tryDelete: $tryDelete, reason: $reason) {
            _id
            deleted
            charityId
        }
    }
`

export const GET_ALL_PARTICIPATION_STATUS_BY_ACTIVITY = gql`
    query GetAllParticipationStatusByActvityId($activityId: ID!) {
        getAllParticipationStatusByActvityId(activityId: $activityId) {
            status
        }
    }
`

export const GET_WORK_FOR_ACTIVITY_BY_OCCURRENCE = gql `
    query WorkForActivityByOccurrence($activityId: ID!) {
        workForActivityByOccurrence(activityId: $activityId) {
            _id
            minutes
        }
    }
`

export const GET_WORK_FOR_ACTIVITY_BY_USER = gql`
    query WorkForActivityByUser($activityId: ID!) {
        workForActivityByUser(activityId: $activityId) {
            userId {
                _id
                firstName
                lastName
                firebaseId
            }
            checkins
            lastAttended
            minutesWorked
        }
    }
`

export const GET_PAST_OCCURRENCES_FOR_ACTIVITY = gql`
    query ActivityPastOccurrences($activityId: ID!, $firebaseId: String!) {
        activityPastOccurrences(activityId: $activityId, firebaseId: $firebaseId) {
            eventDate
            minutes
            checkedIn
        }
    }
`

export const SEND_MESSAGE_TO_PARTICIPANTS = gql`
    mutation SendMessageToParticipants($activityId: ID!, $message: String!, $group: String!) {
        sendMessageToParticipants(activityId: $activityId, message: $message, group: $group)
    }
`

export const MANUAL_CHECKIN = gql`
    mutation PortalManualCheckin($firebaseId: String!, $work: PortalWorkRecordInput!) {
        portalManualCheckin(firebaseId: $firebaseId, work: $work) {
            _id
        }
    }    
`
