import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import {createStore} from "redux";
import {BrowserRouter, Route, Switch} from "react-router-dom";

import {ApolloClient, ApolloProvider, createHttpLink, InMemoryCache} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";

import './index.css';
import App from './App';

import {reduxReducers} from "./utils/redux-reducers";
import {auth} from "./utils/firebase";

const store = createStore(reduxReducers);

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_SERVER
});

const authLink = setContext(async (_, { headers }) => {

    const r = {
        headers: {
            ...headers,
            'x-gih-page': document.location.href,
            'x-gih-referrer': document.referrer,
        }
    };

    const idToken = await auth.currentUser?.getIdTokenResult();

    if (idToken)
        r.headers.authorization = `Bearer ${idToken.token}`;

    return r;
});

export const graphQLCache = new InMemoryCache();

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: graphQLCache
});

ReactDOM.render(
  <React.StrictMode>
      <ApolloProvider client={client}>
        <Provider store={store}>
            <BrowserRouter>
                <Switch>
                    <Route path="/" component={App} />
                </Switch>
            </BrowserRouter>
        </Provider>
      </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
