import BrowserNotSupported from "@mui/icons-material/BrowserNotSupported";

import {ErrorWidget} from "gih_web_common";


export function BrowserNotSupportedBanner() {
    return (
        <div className="flex w-full min-h-screen justify-center items-center">
            <div className="flex bg-gray-500 h-[72px] m-6 p-4 rounded-lg justify-center items-center">
                <span className="p-2 rounded-lg bg-red-400">
                    <BrowserNotSupported className="text-white" aria-hidden="true" />
                </span>
                <span className="ml-3 font-medium text-white">
                    We don't currently support Safari - please use either Chrome or Firefox to access the Giving Is Human Charity portal.
                </span>
            </div>
        </div>
    )
}

export function PageNotFoundBanner() {
    return (
        <div className="flex w-full min-h-screen justify-center items-center">
            <ErrorWidget what="badPath" />
        </div>
    )
}
