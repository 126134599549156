import {useHistory} from "react-router-dom";
import {useMediaQuery} from 'react-responsive'

import {tabButtonStyle} from "../../common/style";


export function TabSelector(props) {

    const history = useHistory()
    const isNarrow = useMediaQuery({ query: '(max-width: 640px)' })

    function pastActivitiesButtonClicked() {
        history.push("/chats/byActivity/past")
    }

    function upcomingActivitiesButtonClicked() {
        history.push("/chats/byActivity/upcoming")
    }

    function topVolunteersButtonClicked() {
        history.push("/chats/byVolunteer")
    }

    return (
        <div className="flex flex-row space-x-3 px-3 py-3 h-[64px]">
            <button onClick={upcomingActivitiesButtonClicked} className={tabButtonStyle(props.tab === "upcoming", false)}>
                {isNarrow ? "Upcoming" : "Upcoming activities"}
            </button>
            <button onClick={pastActivitiesButtonClicked} className={tabButtonStyle(props.tab === "past", false)}>
                {isNarrow ? "Past" : "Past activities"}
            </button>
            <button onClick={topVolunteersButtonClicked} className={tabButtonStyle(props.tab === "top", false)}>
                {isNarrow ? "Top" : "Top volunteers"}
            </button>
        </div>
    )
}
