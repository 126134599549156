import {useEffect, useState} from 'react'
import {useSelector} from "react-redux";
import {use100vh} from 'react-div-100vh'

import styled from '@emotion/styled';
import {useLazyQuery} from "@apollo/react-hooks";
import Popover from '@mui/material/Popover';

import FullCalendar from '@fullcalendar/react'
import gbLocale from '@fullcalendar/core/locales/en-gb'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list';

import {GlassCard, nameStr, countToString} from 'gih_web_common';

import {logScreenViewEvent} from "../../utils/analytics";
import {SCREEN_NAME, SCREEN_CLASS} from "../../utils/analyticsConstants";

import { GET_ACTIVITY_OCCURRENCES_BY_CHARITYID } from "../../utils/graphql/activity";

import {UserAvatar} from "../chats/common/avatar";
import {ColourKey} from "../common/elements";

import "../common/fullCalendar.css";

const StyleWrapper = styled.div`
@media (max-width: 640px) {
    .fc-button {
        font-size: 0.75em;
    }
    .fc-toolbar-title {
        font-size: 1em;
    }
}
`


export function ActivityCalendar() {

    const height = use100vh();

    const [data, setData] = useState(null);
    const [popover, setPopOver] = useState(null);

    const charity = useSelector(state => state.charity);

    const [fetchOccurrences, {loading: occurrencesLoading}] = useLazyQuery(GET_ACTIVITY_OCCURRENCES_BY_CHARITYID)

    function datesSet(info) {
        console.log(`Fetching from ${info.start} to ${info.end}`)
        fetchOccurrences({
            variables: {
                charityId: charity.id,
                start: info.start,
                end: info.end
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: "cache-and-network",
            onCompleted: (data) => {
                const d = data.activityOccurrencesByCharityId
                setData({
                    occurrences: d.occurrences,
                    colours: d.colours,
                    usersById: new Map(d.users.map(obj => [obj._id, obj]))
                })
            }
        })
    }

    function renderEventContent(content) {
        if (content.view.type === "dayGridMonth") {
            return (
                <div className="fc-event fc-event-start fc-event-end fc-event-future fc-daygrid-event fc-daygrid-dot-event rounded-lg overflow-hidden text-ellipsis"
                        style={{ color: content.event.textColor, "background-color": content.event.backgroundColor }}>
                    <div className="fc-daygrid-event-dot" style={{ "border-color": content.event.borderColor}}></div>
                    <div className="fc-event-time">{content.timeText}</div>
                    <div className="fc-event-title pr-1">{content.event.title}</div>
                </div>
            )
        } else {
            return (
                <div className="fc-event-main"
                        style={{ color: content.event.textColor, "background-color": content.event.backgroundColor }}>
                    <div className="fc-event-main-frame">
                        <div className="fc-event-time pl-1">{content.timeText}</div>
                        <div className="fc-event-title-container pl-1">
                            <div className="fc-event-title fc-sticky">{content.event.title}</div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    function onEventClick(info) {
        console.log(`User clicked event '${info.event.title}'`)
    }

    function onEventEnter(info) {
        const props = info.event.extendedProps
        if (props.available !== null) {
            const available = []
            props.available.forEach(userId => {
                const user = data.usersById.get(userId)
                if (user !== undefined) {
                    available.push(user)
                }
            })
            setPopOver({
                available: available,
                anchor: info.el
            })
        }        
    }

    function onEventLeave(info) {
        setPopOver(null)
    }

    function onClosePopover() {
        console.log('Close!')
    }

    useEffect(() => {
        logScreenViewEvent(SCREEN_NAME.activityCalendar, SCREEN_CLASS.activity)
    }, [])

    return (
        <div className="relative w-full h-full py-2 overflow-y-auto" style={{ maxHeight: `${height - 64 - 64}px`}}>
            <div className="mx-auto h-fit w-fit">
                <GlassCard width="relative w-full max-w-screen-2xl">
                    <StyleWrapper>
                        <FullCalendar
                            height={"auto"}
                            locale={gbLocale}
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                            headerToolbar={{
                                left: 'prev,next today',
                                center: 'title',
                                right: 'dayGridMonth,timeGridWeek,timeGridDay listWeek'
                            }}
                            eventTimeFormat={{hour:'2-digit', minute:'2-digit', hour12:false}}
                            initialView='dayGridMonth'
                            events={data?.occurrences ?? []}
                            eventClick={onEventClick}
                            eventMouseEnter={onEventEnter}
                            eventMouseLeave={onEventLeave}
                            datesSet={datesSet}
                            aspectRatio={1.9}
                            eventContent={renderEventContent}
                            scrollTime="08:00"
                            allDaySlot={false}
                            businessHours={{
                              // days of week. an array of zero-based day of week integers (0=Sunday)
                              daysOfWeek: [ 1, 2, 3, 4, 5 ], // Monday - Thursday

                              startTime: '08:00', // a start time (10am in this example)
                              endTime: '19:00', // an end time (6pm in this example)
                            }}
                        />
                    </StyleWrapper>
                    <Popover
                        id="mouse-over-popover"
                        sx={{
                            pointerEvents: 'none',
                        }}
                        open={Boolean(popover)}
                        anchorEl={popover?.anchor}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        onClose={onClosePopover}
                        disableRestoreFocus
                    >
                        { popover &&
                            <div className="p-2 text-xs font-bold">{countToString('volunteer', popover.available.length)} available</div>
                        }
                        { popover?.available.map(user => (
                            <div className="flex items-center p-2">
                                <UserAvatar chat={{ profilePicture: user.profilePicture, user_names: [ nameStr(user) ]}} />
                                <span className="ml-2">{nameStr(user)}</span>
                            </div>
                        ))}
                    </Popover>
                    { data !== null &&
                    <div className="w-full pt-2">
                        <ColourKey items={data.colours} suffix="hover over an activity to see who's available" />
                    </div>
                    }

                    { occurrencesLoading &&
                    <>
                        <div className="flex absolute top-0 left-0 w-full h-full items-center justify-center z-50">
                            <div className="w-auto my-6 mx-auto">
                                <div className="text-black font-bold">Loading...</div>
                            </div>
                        </div>
                        <div className="absolute top-0 left-0 w-full h-full z-40 bg-black opacity-25 rounded-lg"></div>
                    </>
                    }
                </GlassCard>
            </div>
        </div>
    )
}
