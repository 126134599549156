import {gql} from "@apollo/client";

export const SET_CAMPAIGN_FLAG = gql `
    mutation SetCampaignFlag($campaignId: ID!, $flag: String!, $value: Boolean!) {
        setCampaignFlag(campaignId: $campaignId, flag: $flag, value: $value)
    }
`

export const GET_CAMPAIGN_DASHBOARD = gql`
    query CampaignDashboard {
        campaignDashboard {
            _id
            name
            videoURL
            hidden
            createdAt
            target
            endDate

            charityId {
                _id
                name
                logoPicture
                giftAidEligible
                stripeAccountStatus
                rating
            }

            createdById
            createdBy
            uniqueDonors
            total

            thankYou
            sharedToSocial
            fundraisers
        }
    }
`;

export const GET_DONATIONS_SUMMARY = gql`
    query DonationSummary($start: DateTime!, $end: DateTime!) {
        donationSummary(start: $start, end: $end) {
            count
            total
            giftAidTotal
            giftAidCount
            tipTotal
            appCount
            campaignCount
            registeredUserCount
            tipAmounts {
                label
                count
            }
            tipPercentages {
                label
                count
            }
            charityRatings {
                label
                count
            }
        }
    }
`;

export const GET_CAMPAIGNS_BY_CREATION_DATE = gql`
    query CampaignsByCreationDate($start: DateTime!, $end: DateTime!, $fundraisers: Boolean!) {
        campaignsByCreationDate(start: $start, end: $end, fundraisers: $fundraisers) {
            _id
            name
            createdAt
            createdBy
            endDate
            target
            charityId {
                _id
                name
                logoPicture
            }
        }
    }
`

export const GET_CAMPAIGNS_CREATED_BY_PERIOD = gql`
    query CampaignsCreatedByPeriod($start: DateTime!, $end: DateTime!, $period: String!) {
        campaignsCreatedByPeriod(start: $start, end: $end, period: $period) {
            when
            count
        }
    }
`

const donationTotals = `
    count
    total
    fundraiserTotal
    giftAidTotal
    tipTotal
`;

export const GET_DONATIONS_BY_CHARITY = gql`
    query DonationsByCharity($start: DateTime!, $end: DateTime!) {
        donationsByCharity(start: $start, end: $end) {
            _id
            name
            ${donationTotals}
        }
    }
`

export const GET_DONATIONS_BY_POSTCODE = gql`
    query DonationsByPostcode($start: DateTime!, $end: DateTime!) {
        donationsByPostcode(start: $start, end: $end) {
            name
            ${donationTotals}
        }
    }
`

export const GET_DONATIONS_BY_PERIOD = gql`
    query DonationsByPeriod($start: DateTime!, $end: DateTime!, $period: String!) {
        donationsByPeriod(start: $start, end: $end, period: $period) {
            when
            ${donationTotals}
            percentWithGiftAid
            percentProfit
        }
    }
`

export const GET_CAMPAIGNS_RECEIVING_DONATIONS_BY_PERIOD = gql`
    query CampaignsReceivingDonationsByPeriod($start: DateTime!, $end: DateTime!, $period: String!) {
        campaignsReceivingDonationsByPeriod(start: $start, end: $end, period: $period) {
            when
            count
        }
    }
`

export const GET_DONATIONS_BY_CREATION_DATE = gql`
    query DonationsByCreationDate($start: DateTime!, $end: DateTime!, $showIncomplete: Boolean!) {
        donationsByCreationDate(start: $start, end: $end, showIncomplete: $showIncomplete) {
            _id
            createdAt
            firstName
            lastName
            postcode
            status
            source
            donation
            tip
            giftAid
            campaignId {
                _id
                name
                charityId {
                    _id
                    name
                    logoPicture
                }
            }
            fundraiserId {
                _id
                name
                createdBy
            }
        }
    }
`

export const GET_DONATIONS_AMOUNT_DISTRIBUTION = gql`
    query DonationsAmountDistribution($start: DateTime!, $end: DateTime!) {
        donationsAmountDistribution(start: $start, end: $end) {
            min
            max
            count
        }
    }
`

export const GET_ENGAGEMENT_BY_CAMPAIGN = gql`
    query EngagementByCampaign($start: DateTime!, $end: DateTime!) {
        engagementByCampaign(start: $start, end: $end) {
            names
            series {
                label
                data
            }
        }
    }
`

export const GET_GIFT_AID_CLAIM_SUBMISSIONS = gql`
    query GiftAidClaimSubmissions {
        giftAidClaimSubmissions {
            _id
            createdAt
            claimNo
            periodEnd
            submissionGatewayTimestamp
            responseGatewayTimestamp
            deleteGatewayTimestamp
            irEnvelope
            successResponse
        }
    }
`
