import {DateTime} from "luxon";

import {RefreshButton} from "gih_web_common";

import {
    NewInstallsByMarketingLeadByPeriod,
    EngagementByScreen,
    EngagementByDistance,
    ActiveUsersByAppVersionByPeriod,
    ActiveUsersByScreenByPeriod,
    ActiveUsersByOSVersionByPeriod,
    ActiveUsersGeneratingEventByOSVersionByPeriod,
    ActivePortalUsersByPlatformByPeriod,
    EventsByDay,
    ActivitySharesByShareTarget,
    invalidate
} from "./graphs"


export function AdvancedEventStats() {

    const now = DateTime.now()
    const earlyStart = now.minus({days:28}).startOf('day').toJSDate()
    const start = now.minus({days:14}).startOf('day').toJSDate()
    const end = now.endOf('day').toJSDate()

    return (
        <div className="mx-auto max-w-[1650px] space-y-2">
            <RefreshButton action={() => invalidate(true)} label={true} />

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <NewInstallsByMarketingLeadByPeriod period="day" start={start} end={end} simplify={false} />
                <NewInstallsByMarketingLeadByPeriod period="week" start={earlyStart} end={end}simplify={false} />

                <ActiveUsersByAppVersionByPeriod period="day" start={start} end={end} />
                <ActiveUsersByAppVersionByPeriod period="week" start={earlyStart} end={end} />

                <ActiveUsersByOSVersionByPeriod period="day" start={start} end={end} />
                <ActiveUsersByOSVersionByPeriod period="week" start={earlyStart} end={end} />

                <EngagementByScreen start={start} end={end} />
                <EngagementByDistance start={start} end={end} />

                <ActivePortalUsersByPlatformByPeriod period="day" start={start} end={end} />
                <ActivePortalUsersByPlatformByPeriod period="week" start={start} end={end} />

                <ActiveUsersByScreenByPeriod period="day" start={start} end={end} />
                <ActiveUsersByScreenByPeriod period="week" start={earlyStart} end={end} />

                <ActivitySharesByShareTarget period="day" start={start} end={end} />
                <ActivitySharesByShareTarget period="week" start={earlyStart} end={end} />

                { false &&
                <>
                    <ActiveUsersGeneratingEventByOSVersionByPeriod period="day" screen={'PhoneNumber'} start={start} end={end} />
                    <ActiveUsersGeneratingEventByOSVersionByPeriod period="week" screen={'PhoneNumber'} start={earlyStart} end={end} />

                    <ActiveUsersGeneratingEventByOSVersionByPeriod period="day" screen={'OTPVerification'} start={start} end={end} />
                    <ActiveUsersGeneratingEventByOSVersionByPeriod period="week" screen={'OTPVerification'} start={earlyStart} end={end} />
                </>
                }

                <EventsByDay start={start} end={end} os='iOS' />
                <EventsByDay start={start} end={end} os='Android' />

                <EventsByDay start={start} end={end} os='Macintosh' />
                <EventsByDay start={start} end={end} os='Windows' />
            </div>
        </div>
    )
}
