import {useMutation} from "@apollo/react-hooks";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";

import {CREATE_NEW_CHARITY} from "../../utils/graphql/admin-charity";
import {charityTemplate} from "../../utils/charity";
import {evictAllDependentOnCharity} from "../../utils/graphql/cache";
import {logActionSuccess, logActionGraphQLFailure} from "../../utils/analytics";
import {ACTIONS} from "../../utils/analyticsConstants";

import {TitleAndBackButton} from "../common/elements";

import {CharityDetailsForm} from "./detailsForm";


export default function CreateNewCharity() {

    const dispatch = useDispatch();
    const history = useHistory();

    const [createNewCharity] = useMutation(CREATE_NEW_CHARITY, { update: evictAllDependentOnCharity })

    const onValidated = (charity) => {
        return createNewCharity({
            variables: {
                details: charity
            }
        }).then(r => {
            logActionSuccess(ACTIONS.charityCreate, `Created charity ${r.data.addNewCharity._id}`);
            dispatch({type: "SET_CHARITY", payload: r.data.addNewCharity});
            history.push('/charity/profile');
        }, e => {
            logActionGraphQLFailure(ACTIONS.charityCreate, e);
            throw e;
        });
    }

    return (
        <div className="p-1 lg:p-4 flex flex-col overflow-y-auto" style={{ height: 'calc(100vh - 64px)' }}>
            <div className="mx-auto space-y-2 max-w-full">
                <TitleAndBackButton title="Create a new charity profile" />
                <CharityDetailsForm
                    initial={charityTemplate()}
                    editing={false}
                    onValidated={onValidated}
                />
            </div>
        </div>
    )
}
