import {useState} from "react";
import {useSelector} from "react-redux";

import Switch from '@mui/material/Switch';
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";

import {useLazyQuery} from "@apollo/react-hooks";

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";

import {GlassCard, Video, FormWrapper, FormInput, FormTextarea, FormStickyPrimaryButton, ProceedAnywayModal, reinterpretAsUTC, reinterpretAsLocal, inputStyles, TagInput} from "gih_web_common";
import "gih_web_common/dist/general/date-picker/datepicker.css";

import {string2DateOrNull} from "../../../utils/date";
import {CHECK_KEYWORD} from "../../../utils/graphql/basic";
import {logFormValidationFailure, logFileSuccess, logFileFailure} from "../../../utils/analytics";
import {FORMS, FILES} from "../../../utils/analyticsConstants";

import {LocationSearch, noPlace, getPlace} from "../../common/locationSearch"
import {LocationsList} from "../../common/locationList"
import {TitleAndBackButton, VideoFileSelector, PhotoFileSelector} from "../../common/elements";
import {asSiteOption, SiteSelector} from "../../common/siteSelector";

import {eventTypeOptions, frequencyOptions, dayOptions, monthlyWhichOptions, endConditionOptions} from "./repetition";
import {validate, getWarning} from "./helpers";
import {ActivityPreview} from "./preview";


function getFormValues(initial) {
    return {
        name:               initial.activity.name,
        fullDescription:    initial.activity.fullDescription,
        minutes:            initial.derived.minutes,
        hours:              initial.derived.hours,
        line1:              initial.activity.address?.line1 ?? "",
        line2:              initial.activity.address?.line2 ?? "",
        streetName:         initial.activity.address?.streetName,
        townCity:           initial.activity.address?.townCity,
        postcode:           initial.activity.address?.postcode,
        interval:           initial.derived.interval,
        autoReplyText:      initial.activity.autoReplyText ?? "",
        requiredVolunteers: initial.activity.requiredVolunteers ?? "",
    }
}

function filterTime(time) {
    const now = new Date();
    const selected = new Date(time);
    return selected.getTime() > now.getTime();
}

function workLocationDescription(workLocation) {
    switch (workLocation) {
        case "onsite":
        case "hybrid":
            return `Where does this take place? You can choose the main address from your organisation profile,
                    use an address from the address book attached to your charity profile or enter an address here.
                    If the activity takes place across a wide area or in various locations, then consider changing
                    the "type of work" to "in the community" so you can list the areas where the activity takes place.`;

        case "community":
            return `Where does this take place? For activities in the community you can create a list of places, boroughs,
                    towns, cities or regions where the activity is available.`;

         case "remote":
            return `Your volunteers will be working from home, so use the main address of your organisation or you may
                    wish to enter a more general location based on the most likely catchment area for your volunteers.
                    This helps give some geographic context to the activity.`;

         case "residential":
            return `Where does this take place? You can choose the main address from your organisation profile,
                    use an address from the address book attached to your charity profile or enter an address here.
                    Alternatively, you may wish to enter a more general location based on the most likely catchment
                    area for your volunteers.`;
    }
}

function workLocationPrefix(workLocation) {
    switch (workLocation) {
        case "onsite":
            return "taking place at";

        case "community":
            return null;

        case "hybrid":
        case "remote":
            return "based at";

        case "residential":
            return "taking place at / in the area of";
    }
}

export const ActivityDetailsForm = (props) => {

    const charity = useSelector(state => state.charity);
    const imageURLPrefix = useSelector(state => state.cfg?.imageURLPrefix);
    const activityCfg = useSelector(state => state.cfg?.activity);

    const [warning, setWarning] = useState(null);
    const [chosenSite, setChosenSite] = useState(asSiteOption("-", props.initial.activity));
    const [eventDate, setEventDate] = useState(string2DateOrNull(props.initial.activity.eventDate));
    const [startDate, setStartDate] = useState(reinterpretAsLocal(props.initial.derived.startDate));
    const [until, setUntil] = useState(props.initial.derived.until);
    const [scheduling, setScheduling] = useState(props.initial.activity.scheduling);
    const [frequency, setFrequency] = useState(props.initial.derived.frequency);
    const [days, setDays] = useState(props.initial.derived.days);
    const [monthlyWhich, setMonthlyWhich] = useState(props.initial.derived.monthlyWhich)
    const [monthlyWhat, setMonthlyWhat] = useState(props.initial.derived.monthlyWhat)
    const [endCondition, setEndCondition] = useState(props.initial.derived.endCondition);
    const [formValues, setFormValues] = useState(getFormValues(props.initial));
    const [selectedLocation, setSelectedLocation] = useState((props.initial.activity.places.length > 0) ? getPlace(props.initial.activity.places[0]) : noPlace());
    const [autoReply, setAutoReply] = useState(props.initial.activity.autoReply);
    const [useCharityAutoReply, setUseCharityAutoReply] = useState(props.initial.activity.autoReplyText === null);
    const [hiddenFlag, setHiddenFlag] = useState(props.initial.activity.hidden);
    const [fullFlag, setFullFlag] = useState(props.initial.activity.full);
    const [workLocation, setWorkLocation] = useState(props.initial.activity.workLocation);
    const [ageRestricted, setAgeRestricted] = useState(props.initial.activity.ageRestricted);
    const [manageAvailability, setManageAvailability] = useState(props.initial.activity.manageAvailability);
    const [places, setPlaces] = useState(props.initial.activity.places.map(place => getPlace(place)));

    const [imageFile, setImageFile] = useState(null);
    const [imageLocalURL, setImageLocalURL] = useState(null);

    const [videoFile, setVideoFile] = useState(null);
    const [videoLocalURL, setVideoLocalURL] = useState(null);

    const [keywords, setKeywords] = useState(props.editing === true ? props.initial.activity.keywords : props.initial.charity.keywords);
    const [checkKeyword] = useLazyQuery(CHECK_KEYWORD);

    function toggleDay(e) {
        const newDays = days.filter((item) => item !== e.target.value);
        if (newDays.length === days.length) {
            newDays.push(e.target.value)
        }
        setDays(newDays);
    }

    async function getValues() {

        return {
            ...formValues,
            eventDate: eventDate,
            startDate: reinterpretAsUTC(startDate),
            chosenSite: chosenSite,
            scheduling: scheduling,
            frequency: frequency,
            days: days,
            monthlyWhich: (monthlyWhich != null && monthlyWhich !== "--") ? parseInt(monthlyWhich) : null,
            monthlyWhat: (monthlyWhat != null && monthlyWhat !== "--") ? parseInt(monthlyWhat) : null,
            endCondition: endCondition,
            until: until,
            imageFile: imageFile,
            videoFile: videoFile,
            autoReply: autoReply,
            autoReplyText: useCharityAutoReply ? null : formValues.autoReplyText,
            keywords: keywords,
            hidden: hiddenFlag,
            full: fullFlag,
            workLocation: workLocation,
            ageRestricted: ageRestricted,
            manageAvailability: manageAvailability,
            requiredVolunteers: (manageAvailability && formValues.requiredVolunteers.length > 0) ? parseInt(formValues.requiredVolunteers) : null,
            places: (workLocation === "community") ? places : [ selectedLocation ],
        }
    }

    function onSubmit() {
        return new Promise((resolve, reject) => {
            getValues()
                .then(values => {
                    return validate(values, props.initial.activity, props.initial.charity);
                })
                .then(activity => {
                    const warningMessage = getWarning(activity);
                    if (warningMessage !== null) {
                        setWarning({
                            message: `${warningMessage} - are you sure that this is correct?`,
                            onDismiss: (proceed) => {
                                if (proceed) {
                                    resolve(props.onValidated(activity));
                                } else {
                                    reject(`${warningMessage} - please check`);
                                }
                            }
                        });
                    } else {
                        resolve(props.onValidated(activity));
                    }
                }, e => {
                    logFormValidationFailure(FORMS.activity, e);
                    reject(e);
                });
        });
    }

    function onSelectVideo(file) {
        try {
            setVideoFile(file);
            setVideoLocalURL(URL.createObjectURL(file));
            logFileSuccess(FILES.activityVideo, file);
        } catch (e) {
            logFileFailure(FILES.activityVideo, e);
        }
    }

    function onSelectImage(file) {
        try {
            setImageFile(file);
            setImageLocalURL(URL.createObjectURL(file));
            logFileSuccess(FILES.activityImage, file);
        } catch (e) {
            logFileFailure(FILES.activityImage, e);
        }
    }

    function getTitle() {
        if (charity.awaiting1stActivity) {
            return "Tell us about your first volunteering opportunity!";
        } else if (props.editing) {
            return "Edit activity";
        } else {
            return "Create a new activity";
        }
    }

    return (
        <div className="flex flex-wrap w-fit grid grid-cols-1 xl:grid-cols-[minmax(560px,700px)_800px]">
            <div className="flex flex-col space-y-1">
                <TitleAndBackButton title={getTitle()} />
                <div className="bg-stone-100 px-1 py-2 rounded-lg">
                    <div className="text-xs leading-tight italic pb-2">
                        What type of work is this? Onsite activities take place at a specific address, whilst activities
                        in the community might involve home visits, driving or delivering in the area around the location you enter
                        below. Hybrid activities have an element of home working, whilst remote and residential activities may be
                        shown to people much further away, because they require no or infrequent travel.
                    </div>
                    <div className="bg-stone-200 p-2 rounded-lg">
                        <FormControl>
                            <label id="workLocationRadioButtonGroupLabel" className="text-xs sm:text-sm">Type of work</label>
                            <RadioGroup
                                row
                                aria-labelledby="workLocationRadioButtonGroupLabel"
                                name="workLocationRadioButtonGroup"
                                value={workLocation}
                                onChange={(e) => setWorkLocation(e.target.value)}
                            >
                                <FormControlLabel value="onsite" control={<Radio />} label="onsite" />
                                <FormControlLabel value="community" control={<Radio />} label="in the community" />
                                <FormControlLabel value="hybrid" control={<Radio />} label="hybrid" />
                                <FormControlLabel value="remote" control={<Radio />} label="remote" />
                                <FormControlLabel value="residential" control={<Radio />} label="residential/overnight" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                <FormWrapper
                    onSubmit={onSubmit}
                    initialValues={getFormValues(props.initial)}
                    className="space-y-2"
                    onValueChange={(values) => { setFormValues(values) }}
                    onValidationFailure={logFormValidationFailure}
                >
                    <div className="bg-slate-100 px-1 py-2 rounded-lg">
                        <div className="text-xs leading-tight italic pb-2">
                            Tell prospective volunteers about your activity - what they'll be doing, who they'll meet,
                            the skills they'll need, the level of commitment you'll expect and the benefits of volunteering
                            with you. In "duration", enter the rough time they'll spend with you <strong>each time</strong> they
                            come along - this is the time that'll be credited towards their next certificate of achievement each
                            time they checkin.
                        </div>
                        <div className="bg-slate-200 p-2 rounded-lg">
                            <div className="flex flex-wrap w-full grid grid-cols-[auto_8em_6em] gap-2">
                                <FormInput
                                    id="name"
                                    name="name"
                                    length={formValues.name.length}
                                    maxLength={activityCfg.nameMaxLength}
                                    type="text"
                                    placeholder="name"
                                    label="Activity name"
                                    required
                                    rootClassName="w-full"
                                />
                                <FormInput
                                    id="hours"
                                    name="hours"
                                    type="number"
                                    placeholder="hours"
                                    label="Duration hours"
                                    required
                                    rootClassName="w-full"
                                    subText="max hours: 48"
                                />
                                <FormInput
                                    id="minutes"
                                    name="minutes"
                                    type="number"
                                    label="minutes"
                                    placeholder="minutes"
                                    required
                                    rootClassName="w-full"
                                    subText="max mins: 59"
                                />
                            </div>
                            <FormTextarea
                                rows={5}
                                id="fullDescription"
                                name="fullDescription"
                                length={formValues.fullDescription.length}
                                maxLength={activityCfg.descriptionMaxLength}
                                type="text"
                                placeholder="description"
                                label="Description"
                                required
                                rootClassName="w-full"
                            />
                            <TagInput
                                id="keywords"
                                label="Keywords"
                                placeholder="Enter a keyword and press return"
                                value={keywords}
                                setValue={setKeywords}
                                findMatches={async hint => {
                                    const r = await checkKeyword({ variables: { word: hint }});
                                    return r.data.checkKeyword;
                                }}
                                settings={{
                                    maxTags: activityCfg.keywordsMax,
                                }}
                            />
                            <label className="flex items-center text-xs sm:text-base">
                                <Switch
                                    onChange={e => setAgeRestricted(prevState => !prevState)}
                                    color={"primary"}
                                    defaultChecked={ageRestricted}
                                />
                                <span className="ml-2">Restrict this activity to volunteers aged 18+</span>
                            </label>
                        </div>
                    </div>

                    <div className="bg-blue-100 px-1 py-2 rounded-lg">
                        { charity.features.additionalSchedulingOpts &&
                        <div className="text-xs leading-tight italic pb-2">
                            When does this happen? You can advertise one off events or activities that take place at a specific time
                            daily, weekly (on certain days) or monthly. Alternatively you can choose one of a set of common
                            scenarios such as "during school time", "outside of school time" or "during the school holidays".
                            If the volunteer is working remotely, then "flexible - any time" might fit best. Overall this should give
                            your volunteers a good idea of when they'd need to be available, but it doesn't have to be exact.
                        </div>
                        }
                        { !charity.features.additionalSchedulingOpts &&
                        <div className="text-xs leading-tight italic pb-2">
                            When does this happen? You can advertise one off events or activities that take place at a specific time
                            daily, weekly (on certain days) or monthly. Overall this should give your volunteers a good idea of when
                            they'd need to be available, but it doesn't have to be exact.
                        </div>
                        }
                        <div className="bg-blue-200 p-2 rounded-lg space-y-2">
                            <div className="flex flex-wrap sm:flex-nowrap gap-2 items-center">
                                <select value={scheduling} onChange={event => setScheduling(event.target.value)} className={inputStyles.select}>
                                    { eventTypeOptions.filter(opt => !opt.additional || charity.features.additionalSchedulingOpts).map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                                { scheduling === "oneTimeEvent" &&
                                <div className="w-full">
                                    <DatePicker
                                      id="eventDate"
                                      showTimeSelect
                                      timeFormat="HH:mm"
                                      timeIntervals={15}
                                      timeCaption="Time"
                                      dateFormat="d MMMM, yyyy HH:mm"
                                      calendarStartDay={1}
                                      minDate={new Date()}
                                      startDate={eventDate}
                                      filterTime={filterTime}
                                      selected={eventDate}
                                      onChange={date => setEventDate(date)}
                                      placeholderText="Select a date and time"
                                      autoComplete="off"
                                    />
                                </div>
                                }
                                { (scheduling === "repeatingEvent" || scheduling === "schoolHolidays") &&
                                <>
                                    <span className="whitespace-nowrap text-sm sm:text-base">starting on</span>
                                    <div className="w-full">
                                        <DatePicker
                                          id="startDate"
                                          showTimeSelect
                                          timeFormat="HH:mm"
                                          timeIntervals={15}
                                          timeCaption="Time"
                                          dateFormat="d MMMM, yyyy HH:mm"
                                          calendarStartDay={1}
                                          minDate={new Date()}
                                          startDate={startDate}
                                          filterTime={filterTime}
                                          selected={startDate}
                                          onChange={date => setStartDate(date)}
                                          placeholderText="Select a date and time"
                                          autoComplete="off"
                                        />
                                    </div>
                                </>
                                }
                                { (scheduling === "schoolTime" || scheduling === "outsideSchoolHours" || scheduling === "anyTime" || scheduling === "outsideSchoolHours" || scheduling === "byAgreement") &&
                                <>
                                    <span className="whitespace-nowrap text-sm sm:text-base">starting on</span>
                                    <div className="w-full">
                                        <DatePicker
                                          id="startDate"
                                          dateFormat="d MMMM, yyyy"
                                          calendarStartDay={1}
                                          minDate={new Date()}
                                          startDate={startDate}
                                          selected={startDate}
                                          onChange={date => setStartDate(date)}
                                          placeholderText="Select a date"
                                          autoComplete="off"
                                        />
                                    </div>
                                </>
                                }
                            </div>

                            { scheduling !== "oneTimeEvent" &&
                            <>
                                <div className="flex flex-wrap items-end space-x-3">
                                    <div>
                                        <select value={frequency} onChange={event => setFrequency(event.target.value)} className={inputStyles.select}>
                                            {frequencyOptions.filter(option => option.qualifier(scheduling)).map(option => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    { scheduling === "repeatingEvent" && (frequency === "weekly" || frequency === "monthly") &&
                                    <div className="flex flex-row items-end space-x-2 text-sm sm:text-base">
                                        <div className="pb-2">every</div>
                                        <div className="w-[6em]">
                                            <FormInput
                                                id="interval"
                                                name="interval"
                                                type="number"
                                                placeholder="interval"
                                                label="Interval"
                                                min="1"
                                                max="4"
                                                required
                                                rootClassName="w-full"
                                            />
                                        </div>
                                        <div className="pb-2">{frequency === "weekly" ? "weeks" : "months"}</div>
                                    </div>
                                    }
                                </div>

                                { frequency === "weekly" &&
                                <div className="flex grid grid-cols-7 w-[20em]">
                                    { dayOptions.map((item, index) => {
                                        return(
                                            <div key={item.id} className="flex flex-col">
                                                <input
                                                    id={item.id}
                                                    name="days"
                                                    type="checkbox"
                                                    className={inputStyles.checkbox}
                                                    checked={days.includes(item.value.toString())}
                                                    value={item.value}
                                                    onChange={toggleDay}
                                                />
                                                <label className="pt-1 text-sm sm:text-base">{item.label}</label>
                                            </div>
                                        )
                                    })}
                                </div>
                                }

                                { frequency === "monthly" &&
                                <div className="flex flex-wrap items-center gap-2 text-sm sm:text-base">
                                    <span className="whitespace-nowrap">on the</span>
                                    <select value={monthlyWhich} onChange={event => setMonthlyWhich(event.target.value)} className={inputStyles.select}>
                                        <option key="--" value="--">--</option>
                                        { monthlyWhichOptions.map(option => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                    <select value={monthlyWhat} onChange={event => setMonthlyWhat(event.target.value)} className={inputStyles.select}>
                                        <option key="--" value="--">--</option>
                                        { dayOptions.map((option, index) => (
                                            <option key={option.value} value={index}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                    <span className="whitespace-nowrap">of the month</span>
                                </div>
                                }

                                <div className="flex items-center space-x-3">
                                    <div>
                                        <select value={endCondition} onChange={(e) => {setEndCondition(e.target.value)}} className={inputStyles.select}>
                                            {endConditionOptions.map(option => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    { endCondition === "endOn" &&
                                    <div className="w-full">
                                        <DatePicker
                                            id="repeatUntilDate"
                                            dateFormat="d MMMM, yyyy"
                                            calendarStartDay={1}
                                            minDate={new Date()}
                                            startDate={until}
                                            selected={until}
                                            onChange={date => setUntil(date)}
                                            placeholderText="Select a date"
                                            autoComplete="off"
                                        />
                                    </div>
                                    }
                                </div>

                                { (charity.features.allowManageAvailability || manageAvailability) &&
                                <label className="flex items-center text-xs sm:text-base">
                                    <Switch
                                        onChange={e => setManageAvailability(prevState => !prevState)}
                                        color={"primary"}
                                        defaultChecked={manageAvailability}
                                    />
                                    <span className="ml-2">Require volunteers to indicate the specific sesssions they can attend</span>
                                </label>
                                }
                                {  manageAvailability &&
                                <div className="w-[60%]">
                                    <FormInput
                                        id="requiredVolunteers"
                                        name="requiredVolunteers"
                                        type="number"
                                        label="Required number of volunteers for each session (above this number the session shows as full and further volunteers put in reserve)"
                                        placeholder="number of volunteers"
                                        rootClassName="w-full"
                                    />
                                    <div className="text-xs">Leave blank if no limit</div>
                                </div>
                                }
                            </>
                            }
                        </div>
                    </div>

                    <div className="bg-green-100 px-1 py-2 rounded-lg">
                        <div className="text-xs leading-tight italic pb-2">
                            {workLocationDescription(workLocation)}
                        </div>
                        <div className="bg-green-200 p-2 rounded-lg space-y-2">
                            { workLocation !== "community" &&
                            <>
                                <label className="flex flex-wrap items-center gap-1">
                                    <span className="mr-2 whitespace-nowrap text-xs sm:text-base">{workLocationPrefix(workLocation)}</span>
                                    <SiteSelector charity={props.initial.charity} chosenSite={chosenSite} setChosenSite={setChosenSite} />
                                </label>
                                { chosenSite === null &&
                                <div className="ml-3">
                                    Address
                                    <div className="flex w-full space-x-3 items-end">
                                        <FormInput
                                            id="line1"
                                            name="line1"
                                            type="text"
                                            placeholder="Optional 1"
                                            label="Room/building/floor/flat name/number (optional)"
                                            rootClassName="w-full"
                                        />
                                        <FormInput
                                            id="line2"
                                            name="line2"
                                            type="text"
                                            placeholder="Optional 2"
                                            label="Room/building/floor/flat name/number (optional)"
                                            rootClassName="w-full"
                                        />
                                    </div>
                                    <div className="flex w-full space-x-3 items-end">
                                        <FormInput
                                            id="streetName"
                                            name="streetName"
                                            type="text"
                                            placeholder="number & street name"
                                            label="Number & street name"
                                            required
                                            rootClassName="w-full"
                                        />
                                        <FormInput
                                            id="townCity"
                                            name="townCity"
                                            type="text"
                                            placeholder="town/city"
                                            label="Town / City"
                                            required
                                            rootClassName="w-full"
                                        />
                                        <FormInput
                                            id="postcode"
                                            name="postcode"
                                            type="text"
                                            placeholder="postcode"
                                            label="Postcode"
                                            required
                                            rootClassName="w-full"
                                        />
                                    </div>
                                    <LocationSearch
                                        initial={selectedLocation}
                                        onSelectLocation={setSelectedLocation}
                                    />
                                </div>
                                }
                            </>
                            }
                            { workLocation === "community" &&
                            <LocationsList locations={places} setLocations={setPlaces} />
                            }
                        </div>
                    </div>

                    <div className="bg-yellow-100 px-1 py-2 rounded-lg">
                        <div className="text-xs leading-tight italic pb-2">
                            Here you can set an automated reply when volunteers come forward through the App - very useful if you're
                            not always available to respond - thank them for their interest and let them know roughly how long it might
                            be before you can get back to them... You can also temporarily hide an activity if you don't want it to be
                            visible to new volunteers for a period of time...
                        </div>
                        <div className="bg-yellow-200 p-2 rounded-lg space-y-1">
                            <div className="flex flex-col text-xs sm:text-base">
                                <label className="flex items-center">
                                    <Switch
                                        onChange={e => setAutoReply(prevState => !prevState)}
                                        color="primary"
                                        defaultChecked={props.initial.activity.autoReply}
                                    />
                                    <span className="ml-2">Auto reply when a volunteer first shows interest in this activity</span>
                                </label>

                                { autoReply &&
                                <div className="ml-6">
                                    <label className="flex items-center">
                                        <Switch
                                            onChange={e => setUseCharityAutoReply(prevState => !prevState)}
                                            color="primary"
                                            defaultChecked={props.initial.activity.autoReplyText === null}
                                        />
                                        <span className="ml-2">Use the default reply from your charity profile</span>
                                    </label>
                                    { !useCharityAutoReply &&
                                    <FormTextarea
                                        rows={2}
                                        id="autoReplyText"
                                        name="autoReplyText"
                                        type="text"
                                        placeholder="Auto reply text"
                                        label="Auto reply text"
                                        rootClassName="w-full"
                                        required
                                    />
                                    }
                                </div>
                                }
                            </div>
                            <label className="flex items-center text-xs sm:text-base">
                                <Switch
                                    onChange={e => setHiddenFlag(prevState => !prevState)}
                                    color={"primary"}
                                    defaultChecked={props.initial.activity.hidden}
                                />
                                <span className="ml-2">
                                    {props.editing ? "Hide this activity from new volunteers - it will still be visible to anyone already signed up for it" : "Don't show this activity in searches for now (you can publish it when you're ready by editing the activity)"}
                                </span>
                            </label>

                            { props.editing === true &&
                            <label className="flex items-center text-xs sm:text-base">
                                <Switch
                                    onChange={e => setFullFlag(prevState => !prevState)}
                                    color={"primary"}
                                    defaultChecked={fullFlag}
                                />
                                <span className="ml-2">Mark this activity as full. New volunteers will not be able to sign up for it.</span>
                            </label>
                            }
                        </div>
                    </div>

                    <FormStickyPrimaryButton alt={true}>
                        {props.editing ? "Save changes" : "Create"}
                    </FormStickyPrimaryButton>
                </FormWrapper>
            </div>

            <div className="flex flex-wrap gap-2 py-4 mx-auto w-full sm:w-fit lg:px-1 md:max-w-none">
                <GlassCard width="h-fit w-full sm:w-fit">
                    <PhotoFileSelector editing={props.initial.activity.imageURL || imageLocalURL} onSelectFile={onSelectImage} alt={true} />
                    <ActivityPreview
                        activity={{
                            imageURL: props.initial.activity.imageURL,
                            name: formValues.name,
                        }}
                        charity={props.initial.charity}
                        imageLocalURL={imageLocalURL}
                    />
                </GlassCard>
                { (charity.features.allowActivityVideo || props.initial.activity.videoURL !== null) &&
                <GlassCard width="h-fit w-full sm:w-fit">
                    <VideoFileSelector editing={props.initial.activity.videoURL || videoLocalURL} alt={true} onSelectFile={onSelectVideo} />
                    <Video
                        rmtPrefix={imageURLPrefix}
                        rmt={props.initial.activity.videoURL}
                        local={videoLocalURL}
                        what="imagePreview"
                        placeholderSeverity="info"
                        placeholderText="It's optional, but we strongly recommend adding a video - up to 30s duration - shown under 'Find out more' when browsing the activity."
                    />
                </GlassCard>
                }
            </div>

            { warning !== null &&
            <ProceedAnywayModal
                message={warning.message}
                onDismiss={ proceed => {
                    warning.onDismiss(proceed)
                    setWarning(null)
                }}
            />
            }
        </div>
    )
}
