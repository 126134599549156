import {useState} from "react";
import {useSelector} from "react-redux";

import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

import {useQuery} from "@apollo/react-hooks";

import {OnLoadViewer, Modal, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, RefreshCell, monetaryAmountToString, dateToString, cellStyles} from "gih_web_common";

import {GET_GIFTAID_CLAIM_SUBMISSIONS_FOR_CHARITY} from "../../utils/graphql/campaign";


const statuses = {
    pending: {
        icon: HourglassTopIcon,
        colour: "black"
    },
    processing: {
        icon: DirectionsRunIcon,
        colour: "amber"
    },
    success: {
        icon: CheckCircleOutlineIcon,
        colour: "green"
    },
    failure: {
        icon: ReportProblemIcon,
        colour: "red"
    },
}

function StatusField({status}) {
    console.log('status', status)
    const status_ = statuses[status];
    const Icon = status_.icon;
    return <Icon fontSize="large" className={`text-${status_.colour}-400`}/>;
}


export function GiftAidSubmissionsModal({onDismiss}) {

    const currentCharity = useSelector(state => state.charity);

    const [submissions, setSubmissions] = useState(null)

    const {refetch} = useQuery(GET_GIFTAID_CLAIM_SUBMISSIONS_FOR_CHARITY, {
        variables: {
            charityId: currentCharity.id,
        },
        fetchPolicy: "cache-and-network",
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setSubmissions(data.giftAidClaimSubmissionsForCharity),
    })

    return (
        <Modal
            title="GiftAid submissions made to HMRC"
            onDismiss={onDismiss}
        >
            <div className="text-sm">
                This is a list of the GiftAid claims we have submitted to HMRC on your behalf.
                The GiftAid due on the donation amount shown in the right most column should be credited
                directly to your bank account. Giving Is Human does not process this money at any time.
            </div>
            <div className="text-sm">
                If you have any queries, please contact our support team.
            </div>
            <OnLoadViewer loading={submissions === null}>
                { submissions &&
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <RefreshCell sx={cellStyles.dateTime} action={refetch} text="Submission prepared on" />
                                <TableCell sx={cellStyles.dateTime}>For donations up to</TableCell>
                                <TableCell sx={cellStyles.icon}>Status</TableCell>
                                <TableCell sx={cellStyles.money}>Amount donated for which GiftAid has been claimed</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { submissions.map(submission => (
                                <TableRow key={submission._id}>
                                    <TableCell align="left">{dateToString(submission.createdAt)}</TableCell>
                                    <TableCell align="left">{dateToString(submission.periodEnd)}</TableCell>
                                    <TableCell align="center"><StatusField status={submission.status} /></TableCell>
                                    <TableCell align="right">{monetaryAmountToString(submission.total, true)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                }
            </OnLoadViewer>
        </Modal>
    )
}
