import {useEffect, useState} from "react";

import StarOutlinedIcon from '@mui/icons-material/StarOutlined';

import {useLazyQuery} from "@apollo/react-hooks";

import {CustomButton, buttonStyles, OnLoadViewer, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, cellStyles, WebLink} from "gih_web_common";

import {GET_REGISTERED_CHARITIES} from "../../../utils/graphql/admin-charity-register";
import {makeWorkbook, makeSheet, downloadXLSX, stripProtocol, asHyperlink} from "../../../utils/xlsx";


function ExternalLink({to}) {
    if (to) {
        const noProtocol = stripProtocol(to);
        return <WebLink href={`https://${noProtocol}`}>{noProtocol}</WebLink>;
    } else {
        return <span>-</span>;
    }
}

export function CharityTable({search}) {

    const [result, setResult] = useState(null);

    const [request, {loading}] = useLazyQuery(GET_REGISTERED_CHARITIES, {
        onCompleted: data => {
            setResult(data.portalFindRegisteredCharities)
        },
    })

    useEffect(() => {
        if (search.postcode !== null || search.number !== null) {
            request({
                variables: search,
            })
        }
    }, [search])

    return (
        <>
            <OnLoadViewer loading={loading}>
                { result &&
                <>
                    <div className="flex flex-col w-fit py-4 gap-2">
                        <span>Showing {result.charities.length} out of {result.total} search results starting at offset {result.offset}</span>
                        <DownloadButton search={search} />
                    </div>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={cellStyles.fixedNarrow}>Number</TableCell>
                                    <TableCell sx={cellStyles.fixedNarrow}>Type</TableCell>
                                    <TableCell sx={cellStyles.fixedWide}>Name</TableCell>
                                    <TableCell sx={cellStyles.email}>E-mail</TableCell>
                                    <TableCell sx={cellStyles.email}>Phone</TableCell>
                                    <TableCell sx={cellStyles.fixedNarrow}>Website</TableCell>
                                    <TableCell sx={cellStyles.address}>Address</TableCell>
                                    <TableCell sx={cellStyles.freeWide}>Activities</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { result.charities.map(charity => {
                                    return (
                                        <TableRow key={charity._id}>
                                            <TableCell align="left">
                                                {charity.number}
                                                { charity.existing && <StarOutlinedIcon/> }
                                            </TableCell>
                                            <TableCell align="left">{charity.type}</TableCell>
                                            <TableCell align="left">{charity.name}</TableCell>
                                            <TableCell align="left">{charity.email ?? "-"}</TableCell>
                                            <TableCell align="left">{charity.phone ?? "-"}</TableCell>
                                            <TableCell align="left"><ExternalLink to={charity.website} /></TableCell>
                                            <TableCell align="left">{charity.address}</TableCell>
                                            <TableCell align="left">{charity.activities}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
                }
            </OnLoadViewer>
        </>
    )
}

function makeXLSXReport(charities) {

    const columns = [
        {
            width: 60,
            heading: {
                label: 'Name',
                horizontal: 'left',
            },
            data: {
                alignment: {
                    wrapText: true,
                },
            },
        }, {
            width: 20,
            heading: {
                label: 'Registration Number',
                horizontal: 'left',
            },
        }, {
            width: 50,
            heading: {
                label: 'Website',
                horizontal: 'left',
            },
        }, {
            width: 50,
            heading: {
                label: 'Address',
                horizontal: 'left',
            },
            data: {
                alignment: {
                    wrapText: true,
                },
            },
        }, {
            width: charities.reduce((w, charity) => Math.max(w, charity.email?.length ?? 0), 26),
            heading: {
                label: 'E-mail',
                horizontal: 'left',
            },
        }, {
            width: charities.reduce((w, charity) => Math.max(w, charity.phone?.length ?? 0), 26),
            heading: {
                label: 'Phone',
                horizontal: 'left',
            },
        }, 
    ];

    return makeSheet(makeWorkbook(), 'Registered Charities', columns, charities, charity => [
        charity.name,
        charity.number,
        asHyperlink(charity.website),
        charity.address,
        charity.email ?? '-',
        charity.phone ?? '-',
    ]);
}

export function DownloadButton({search}) {

    const [request] = useLazyQuery(GET_REGISTERED_CHARITIES, {
        variables: {
            ...search,
            offset: 0,
            limit: 10000,
        },
    });

    return (
        <CustomButton
            onClick={() => request().then(r => downloadXLSX(makeXLSXReport(r.data.portalFindRegisteredCharities.charities.filter(charity => !charity.existing)), 'charities.xlsx'))}
            className={buttonStyles.altLg}
        >
            Download all
        </CustomButton>
    );
}
