import Geohash from "latlon-geohash";


let geocoder = null;
let autoCompleteService = null;
let placeDetailsResolver = null;

export async function geohashToPlace(geohash, callback) {

    console.log('Resolving geohash to a place name')
    const coords = Geohash.decode(geohash)

    const google = window["google"];

    if (!geocoder) {
        const { Geocoder } = await google.maps.importLibrary("geocoding");
        geocoder = new Geocoder();
    }

    geocoder.geocode({ 'latLng': new google.maps.LatLng(coords.lat, coords.lon) }, (results, status) => {
        callback((status !== google.maps.GeocoderStatus.OK) ? `(place not found - ${status})` : results[0].formatted_address)
    });
}

export async function getPlacePredictions(query, callback) {

    const google = window["google"];

    if (!autoCompleteService) {
        const { AutocompleteService } = await google.maps.importLibrary("places");
        autoCompleteService = new AutocompleteService();
    }

    autoCompleteService.getPlacePredictions(query, callback);
}

export async function getPlaceDetails(placeId, callback) {

    const google = window["google"];

    if (!placeDetailsResolver) {
        const { PlacesService } = await google.maps.importLibrary("places");
        placeDetailsResolver = new PlacesService(document.createElement("div"));
    }

    placeDetailsResolver.getDetails({placeId: placeId}, callback);
}
