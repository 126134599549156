import {useEffect, useState, useRef} from "react";
import {useSelector} from "react-redux";

import {useLazyQuery} from "@apollo/react-hooks";
import {onSnapshot, query, where} from "firebase/firestore"

import {COUNT_PENDING_PARTICIPATIONS_FOR_CHARITY_ID} from "../../utils/graphql/charity";
import {chats} from "../../utils/firebase";
import {statusToBackground} from "../../utils/activity";

import {BadgeCount} from "./elements";


export function PendingParticipationBadge() {

    const charity = useSelector(state => state.charity);
    const [count, setCount] = useState(null)

    const [fetch] = useLazyQuery(COUNT_PENDING_PARTICIPATIONS_FOR_CHARITY_ID, {
        onCompleted: (data) => {
            console.log(data)
            setCount(data.countPendingParticipationsForCharityId)
        }
    })

    useEffect(() => {
        if (charity !== null) {
            setCount(0)
            fetch({
                variables: {
                    charityId: charity.id
                },
                pollInterval: 20000
            })
        }
    }, [charity])

    return (count > 0) ? (
        <BadgeCount count={count} background={`${statusToBackground('pending', true)} border-2`} />
    ) : (
        <></>
    )
}


export function TotalUnreadBadge() {

    const charity = useSelector(state => state.charity);
    const [count, setCount] = useState(null);
    const unreadByChat = useRef(new Map());

    useEffect(() => {
        if (charity !== null) {
            console.log(`Loading and starting total unread count watcher for charity.id=${charity.id}`);
            let totalUnread = 0;
            setCount(0);

            const unsubscribe = onSnapshot(query(chats, where("charityId", "==", charity.id), where("unread_charity", ">", 0)), (s) => {

                s.docChanges().forEach((change) => {

                    switch (change.type) {
                        case "added":
                        {
                            const unread = change.doc.data().unread_charity
                            console.log(`Adding ${unread} unread messages for ${change.doc.id}`)
                            unreadByChat.current.set(change.doc.id, unread)
                            totalUnread += unread
                            break;
                        }

                        case "removed":
                        {
                            const existing = unreadByChat.current.get(change.doc.id)
                            if (existing !== undefined) {
                                console.log(`Removing ${existing} unread messages for ${change.doc.id}`)
                                totalUnread -= existing
                                unreadByChat.current.delete(change.doc.id)
                            } else {
                                console.error(`Removal received for chat ${change.doc.id} not in unread map`)
                            }
                            break;
                        }

                        case "modified":
                        {
                            const unread = change.doc.data().unread_charity
                            const existing = unreadByChat.current.get(change.doc.id)
                            if (existing !== undefined) {
                                console.log(`Updating ${existing} -> ${unread} unread messages for ${change.doc.id}`)
                                unreadByChat.current.set(change.doc.id, unread)
                                totalUnread -= existing
                                totalUnread += unread
                            } else {
                                console.error(`Update received for chat ${change.doc.id} not in unread map`)
                            }
                            break;
                        }

                        default:
                            break;
                    }

                    setCount(totalUnread)
                })
            })

            return () => {
                if (unsubscribe) {
                    console.log('Unsubscribing from total unread count')
                    unsubscribe();
                }
            }
        }
    }, [charity])

    return (count > 0) ? (
        <BadgeCount count={count} background="bg-red-600 border-2" />
    ) : (
        <></>
    )
}
