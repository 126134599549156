import {Redirect, Route, Switch} from 'react-router-dom';
import {useSelector} from "react-redux";

import {Loading} from "gih_web_common";

import SignIn from "../pages/sign-in/sign-in";
import ResetPasswordPage from "../pages/sign-in/resetPasswordPage";
import SignUp from "../pages/sign-up/sign-up";
import SelfSignUp from "../pages/sign-up/self-sign-up";
import SendInvitePage from "../pages/user/invite";
import AccountSettingsPage from "../pages/user/settings";

import ChatsByActivity from "../pages/chats/by-activity/page";
import ChatsByVolunteer from "../pages/chats/by-volunteer/page";

import {TermsAndConditions, PrivacyPolicy, CookiePolicy, TermsAndConditionsSignup, PrivacyPolicySignup, CookiePolicySignup} from "../pages/legals";

import CreateActivity from '../pages/activities/create';
import ActivitiesPage from '../pages/activities/overview';
import ActivityDetailPage from '../pages/activities/detail';
import QRCodePrint from "../pages/activities/qrcode";
import EditActivityPage from "../pages/activities/edit";

import CreateCampaign from '../pages/campaigns/create';
import CampaignsPage from '../pages/campaigns/overview';
import CampaignDetailPage from '../pages/campaigns/detail';
import EditCampaignPage from "../pages/campaigns/edit";

import CharityProfilePage from "../pages/charity/profile";
import CreateNewCharity from "../pages/charity/create";
import EditCharityDetailsPage from "../pages/charity/edit";
import AdminCharityProfiles from "../pages/charity/select";
import CharityUsersList from "../pages/charity/users";
import CharityVolunteerStats from "../pages/charity/volunteerStats";
import CharityCampaignStats from "../pages/charity/campaignStats";

import OverviewPage from "../pages/overview/page";
import ResetPasswordLandingPage from "../pages/sign-in/resetPasswordLandingPage";
import {PageNotFoundBanner} from "../pages/common/errors";

function OverallSpinner() {
    return <div className="py-10 mx-auto w-fit"><Loading size={60} /></div>;
}

function NoAuthRoute({...props}) {

    const loginState = useSelector(state => state.loginState);
    const redirectPath = props.location?.search.replace('?redirect=', '');

    if (loginState === 'LoggedIn')
        return <Redirect to={redirectPath ?? "/"}/>;
    else if (loginState === 'LoggedOut' || loginState === 'SignupBegun' || loginState === 'SignupAuth')
        return <Route {...props} />;
    else
        return <OverallSpinner/>;
}

function isSpecialAdminPath(path) {
    return ["/charity/select", "/user/settings"].includes(path) || path.startsWith("/overview/");
}

function PrivateRoute({...props}) {

    const loginState = useSelector(state => state.loginState);
    const charity = useSelector(state => state.charity);
    const fullAccess = useSelector(state => state.user?.fullAccess);

    if (loginState === null || loginState === 'SigninAuth' || loginState === 'SigninUser')
        return <OverallSpinner/>;

    if (loginState !== 'LoggedIn')
        return <Redirect to={`/sign-in?redirect=${props.location?.pathname}`}/>;

    if (fullAccess && !charity && !isSpecialAdminPath(props.location.pathname))
        return <Redirect to="/charity/select" />;

    if ( charity
      && charity.provisional
      && !["/charity/edit", "/user/settings", "/privacyPolicy", "/cookiePolicy", "/termsAndConditions"].includes(props.location.pathname)
      && (!fullAccess || (!isSpecialAdminPath(props.location.pathname) && !["/charity/users"].includes(props.location.pathname)))
       ) {
        return <Redirect to="/charity/edit" />
    }

    if (props.location.pathname === '/' && charity)
        return (charity.features.enableGiving) ? <Redirect to="/campaigns/ongoing" /> : <Redirect to="/chats/byActivity/upcoming" />;

    return <Route {...props} />;
}

export default function AppRoutes() {

    return (
        <main>
            <Switch>
                <NoAuthRoute exact path='/sign-in' component={SignIn} />
                <NoAuthRoute exact path='/sign-up/:inviteId' component={SignUp} />
                <NoAuthRoute exact path='/signup/self' component={SelfSignUp}/>
                <NoAuthRoute exact path='/signup/cookie' component={CookiePolicySignup}/>
                <NoAuthRoute exact path='/signup/privacy' component={PrivacyPolicySignup}/>
                <NoAuthRoute exact path='/signup/terms' component={TermsAndConditionsSignup}/>
                <NoAuthRoute exact path='/resetPassword' component={ResetPasswordPage}/>
                <NoAuthRoute exact path='/accountMgmt' component={ResetPasswordLandingPage}/>

                <PrivateRoute exact path='/charity/create' component={CreateNewCharity}/>
                <PrivateRoute exact path='/charity/edit' component={EditCharityDetailsPage}/>
                <PrivateRoute exact path='/charity/select' component={AdminCharityProfiles}/>
                <PrivateRoute exact path='/charity/profile' component={CharityProfilePage}/>
                <PrivateRoute exact path='/charity/users' component={CharityUsersList}/>
                <PrivateRoute exact path='/charity/volunteerStats' component={CharityVolunteerStats}/>
                <PrivateRoute exact path='/charity/campaignStats' component={CharityCampaignStats}/>

                <PrivateRoute exact path='/activities/:activityGroup' component={ActivitiesPage}/>

                <PrivateRoute exact path='/activity/create/:id?' component={CreateActivity}/>
                <PrivateRoute exact path='/activity/:id' component={ActivityDetailPage}/>
                <PrivateRoute exact path='/activity/:id/qrcode' component={QRCodePrint}/>
                <PrivateRoute exact path='/activity/:id/edit' component={EditActivityPage}/>

                <PrivateRoute exact path='/campaigns/:campaignGroup' component={CampaignsPage}/>

                <PrivateRoute exact path='/campaign/create/:id?' component={CreateCampaign}/>
                <PrivateRoute exact path='/campaign/:id' component={CampaignDetailPage}/>
                <PrivateRoute exact path='/campaign/:id/edit' component={EditCampaignPage}/>

                <PrivateRoute exact path='/' />
                <PrivateRoute exact path='/chats/byVolunteer' component={ChatsByVolunteer}/>
                <PrivateRoute exact path='/chats/byActivity/:activityGroup' component={ChatsByActivity}/>

                <PrivateRoute exact path='/privacyPolicy' component={PrivacyPolicy}/>
                <PrivateRoute exact path='/termsAndConditions' component={TermsAndConditions}/>
                <PrivateRoute exact path='/cookiePolicy' component={CookiePolicy}/>

                <PrivateRoute exact path='/user/settings' component={AccountSettingsPage}/>
                <PrivateRoute exact path='/user/invite' component={SendInvitePage}/>

                <PrivateRoute exact path='/overview/:selectedTab/:id?' component={OverviewPage}/>

                <Route path="*" component={PageNotFoundBanner}/>
            </Switch>
        </main>
    );
}
