import {useState} from "react";
import {useMediaQuery} from 'react-responsive';

import {useQuery} from "@apollo/react-hooks";

import {addressToString, inputStyles} from "gih_web_common";

import {addressEq} from "../../utils/address";
import {GET_CHARITY_SITES} from "../../utils/graphql/charity";


function isSameSite(a, b) {
    return a.place.geohash === b.place.geohash && addressEq(a.address, b.address);
}

function asPlace(what) {
    return {
        geohash: what.geohash ?? null,
        name: what.locationName ?? null,
        qualifier: null,
    };
}

export function asSiteOption(id, what) {
    return {
        id: id,
        name: what.name,
        address: what.address,
        place: what.places?.[0] ?? asPlace(what),
    };
}

export function SiteSelector({charity, chosenSite, setChosenSite}) {

    const isNarrow = useMediaQuery({ query: '(max-width: 1280px)' });

    const [sites, setSites] = useState([]);

    useQuery(GET_CHARITY_SITES, {
        variables: {
            charityId: charity._id
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: data => {
            const sites = data.portalCharitySites.map(site => asSiteOption(site._id, site));
            if (chosenSite !== null) {
                let site;
                const defaultSite = asSiteOption("default", charity);
                if (chosenSite.place.geohash === null || isSameSite(defaultSite, chosenSite)) {
                    site = defaultSite;
                } else {
                    site = sites.find(site => isSameSite(site, chosenSite)) ?? null;
                }
                setChosenSite(site);
            }
            setSites(sites);
        }
    })

    function getSiteById(id) {
        if (id === "custom") {
            return null;
        } else if (id === "default") {
            return asSiteOption("default", charity);
        } else {
            return sites.find(site => site.id === id);
        }
    }

    return (
        <select value={chosenSite ? chosenSite.id : "custom"} onChange={event => setChosenSite(getSiteById(event.target.value))} className={inputStyles.select}>
            <option key="default" value="default">{isNarrow ? "main address" : "the main address/location of my organisation"}</option>
            { sites.map(site => ( <option key={site.id} value={site.id}>{`${site.name} (${addressToString(site.address)})`}</option> ))}
            <option key="custom" value="custom">an address/location I'll enter here</option>
        </select>
    );
}
