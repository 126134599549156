import { DateTime } from "luxon";

import { intervalToString } from "gih_web_common";


function readText(when, tRef) {
    if (when) {
        const minuteEnd = DateTime.fromJSDate(when).endOf('minute')
        if (minuteEnd >= tRef) {
            return 'Read just now'
        } else {
            return `Read ${intervalToString(minuteEnd, tRef)} ago`
        }
    } else {
        return 'Read'
    }
}

export default function ChatMessage({msg, next, previous, readTime, tRef}) {

    const nextContinue = next !== null && next.isMine === msg.isMine && DateTime.fromJSDate(next.timestamp).diff(DateTime.fromJSDate(msg.timestamp), 'hours').as('hours') < 1
    const prevContinue = previous !== null && previous.isMine === msg.isMine && DateTime.fromJSDate(msg.timestamp).diff(DateTime.fromJSDate(previous.timestamp, 'hours')).as('hours') < 1

    return (
        <>
            <div style={{
                maxWidth: "80%",
                marginTop: "2px",
                marginBottom: (!nextContinue && !msg.isLastRead) ? "14px" : "0px",
                marginLeft: "24px",
                marginRight: "24px",
                borderTopLeftRadius: (prevContinue && !msg.isMine) ? "4px" : "20px",
                borderTopRightRadius: (prevContinue && msg.isMine) ? "4px" : "20px",
                borderBottomLeftRadius: (nextContinue && !msg.isMine) ? "4px" : "20px",
                borderBottomRightRadius: (nextContinue && msg.isMine) ? "4px" : "20px",
                background: (msg.isMine ? "#088473" : "rgba(224,224,224,0.6)")
            }}>
                <p className="text-xs sm:text-sm overflow-hidden text-ellipsis px-2 py-2" style={{ color: msg.isMine ? "white" : "black" }}>{msg.text}</p>
            </div>
            { msg.isLastRead &&
            <div className="text-xs text-gray-400 py-1 pr-2">
                {readText(readTime, tRef)}
            </div>
            }
        </>
    )
}
