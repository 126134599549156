import {DateTime} from "luxon";
import {useState} from "react";

import {DateRangeCard} from "gih_web_common";

import {
    UsersCreatedByPeriod,
} from "./graphs-user";

import {
    WorkByPeriod,
    WorkByCharityPeriod,
    WorkByUserPeriod,
    CertificatesByPeriod,
    ActivitiesCreatedByPeriod,
    InterestByCharityPeriod,
    InterestByCharityByStatus,
    invalidateVolunteering,
} from "./graphs-volunteering";

import {
    CampaignsCreatedByPeriod,
    CampaignsReceivingDonationsByPeriod,
    PercentageOfDonationsWithGiftAidByPeriod,
    PercentageProfitPerTransactionByPeriod,
    DonationAmountByPeriod,
    DonationCountByPeriod,
    ProfitByPeriod,
    DonationsByCharity,
    DonationsByPostcode,
    DonationAmountDistribution,
    invalidateGiving,
} from "./graphs-giving";

export function DatabaseStats() {

    const now = DateTime.now()
    const startMonth = now.minus({months:3}).startOf('month').toJSDate()
    const endMonth = now.endOf('day').toJSDate()

    const startYear = now.startOf('year').toJSDate()
    const endYear = now.endOf('year').toJSDate()

    const [startDate, setStartDate] = useState(now.minus({days:35}).startOf('week').toJSDate())
    const [endDate, setEndDate] = useState(now.endOf('day').toJSDate())

    return (
        <div className="mx-auto max-w-[1650px] space-y-2">
            <DateRangeCard
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                onRefresh={() => { invalidateVolunteering(false); invalidateGiving(); }}
                unit="day"
            />

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <UsersCreatedByPeriod period="week" start={startDate} end={endDate} />
                <UsersCreatedByPeriod period="month" start={startMonth} end={endMonth} />

                <CampaignsCreatedByPeriod period="week" start={startDate} end={endDate} />
                <CampaignsCreatedByPeriod period="month" start={startMonth} end={endMonth} />

                <CampaignsReceivingDonationsByPeriod period="week" start={startDate} end={endDate} />
                <CampaignsReceivingDonationsByPeriod period="month" start={startMonth} end={endMonth} />

                <DonationAmountByPeriod period="day" start={startDate} end={endDate} />
                <ProfitByPeriod period="day" start={startDate} end={endDate} />

                <DonationAmountByPeriod period="week" start={startDate} end={endDate} />
                <DonationAmountByPeriod period="month" start={startMonth} end={endMonth} />

                <DonationCountByPeriod period="week" start={startDate} end={endDate} />
                <DonationCountByPeriod period="month" start={startMonth} end={endMonth} />

                <DonationAmountDistribution period="week" start={startDate} end={endDate} />
                <DonationAmountDistribution period="month" start={startMonth} end={endMonth} />

                <PercentageOfDonationsWithGiftAidByPeriod period="week" start={startDate} end={endDate} />
                <PercentageOfDonationsWithGiftAidByPeriod period="month" start={startMonth} end={endMonth} />

                <ProfitByPeriod period="week" start={startDate} end={endDate} />
                <ProfitByPeriod period="month" start={startMonth} end={endMonth} />

                <PercentageProfitPerTransactionByPeriod period="week" start={startDate} end={endDate} />
                <PercentageProfitPerTransactionByPeriod period="month" start={startMonth} end={endMonth} />

                <DonationsByCharity start={now.minus({days:6}).startOf('day').toJSDate()} end={endDate} />
                <DonationsByCharity start={now.minus({days:27}).startOf('day').toJSDate()} end={endDate} />

                <DonationsByPostcode start={now.minus({days:6}).startOf('day').toJSDate()} end={endDate} />
                <DonationsByPostcode start={now.minus({days:27}).startOf('day').toJSDate()} end={endDate} />

                { false &&
                <>
                    <ActivitiesCreatedByPeriod period="week" start={startDate} end={endDate} />
                    <ActivitiesCreatedByPeriod period="month" start={startMonth} end={endMonth} />

                    <InterestByCharityPeriod period="week" start={startDate} end={endDate} />
                    <InterestByCharityPeriod period="month" start={startMonth} end={endMonth} />

                    <InterestByCharityByStatus />
                    <div></div>

                    <WorkByPeriod period="week" start={startDate} end={endDate} />
                    <WorkByPeriod period="month" start={startMonth} end={endMonth} />

                    <WorkByCharityPeriod period="week" start={startDate} end={endDate} />
                    <WorkByCharityPeriod period="month" start={startMonth} end={endMonth} />

                    <WorkByUserPeriod period="month" start={startMonth} end={endMonth} />
                    <WorkByUserPeriod period="year" start={startYear} end={endYear} />

                    <CertificatesByPeriod period="month" start={startMonth} end={endMonth} />
                    <CertificatesByPeriod period="year" start={startYear} end={endYear} />
                </>
                }
            </div>
        </div>
    )
}
