import {useSelector} from "react-redux";

import {imgModifiers} from "gih_web_common";


export function UserAvatar(props) {

    const imageURLPrefix = useSelector(state => state.cfg?.imageURLPrefix);

    return (
        <>
        { props.chat.profilePicture &&
            <img className="h-[46px] w-[46px] min-w-[46px] rounded-full" src={imageURLPrefix + props.chat.profilePicture + imgModifiers("avatar")} alt="Avatar"/>
        }
        { !props.chat.profilePicture &&
            <div className="flex bg-alt-dark text-white rounded-full h-[46px] w-[46px] min-w-[46px] text-lg font-bold items-center justify-center">
                {props.chat.user_names[0][0]}
            </div>
        }
        </>
    )
}
