import {useState} from "react";

import Switch from '@mui/material/Switch';

import {TitledCard} from 'gih_web_common';

import {RecentInstalls, RecentLocationSearches, RecentSessions} from "./events"
import {AppUsersRecentlyCreated, AppUsersRequestedAccountDeletion, PortalUsersRecentlyCreated} from "./users"
import {ActivitiesRecentlyCreated} from "./activities"
import {CampaignsRecentlyCreated} from "./campaigns"
import {ParticipationsPending} from "./participations"
import {EmailsRecentlySent} from "./emails"


export function RecentEvents() {

    const days = 7;
    const [productionApps, setProductionApps] = useState(true);

    return (
        <div className="space-y-2 max-w-[2048px] mx-auto">
            <TitledCard title="App users created recently">
                <AppUsersRecentlyCreated days={days} />
            </TitledCard>

            <TitledCard title="Portal users created recently">
                <PortalUsersRecentlyCreated days={days}/>
            </TitledCard>

            <TitledCard title="Fundraisers created recently">
                <CampaignsRecentlyCreated days={days} fundraisers={true} />
            </TitledCard>

            <TitledCard title="Campaigns created recently">
                <CampaignsRecentlyCreated days={days} fundraisers={false} />
            </TitledCard>

            <TitledCard title="E-mails sent recently">
                <EmailsRecentlySent days={days} />
            </TitledCard>

            <TitledCard title="App users who requested account deletion">        
                <AppUsersRequestedAccountDeletion/>
            </TitledCard>

            <TitledCard title="Recent Installs">
                <label className="flex items-center text-xs sm:text-base">
                    <Switch
                        onChange={e => setProductionApps(prevState => !prevState)}
                        color="primary"
                        defaultChecked={true}
                    />
                    <span className="ml-1">
                        Show production App instances
                    </span>
                </label>
                <RecentInstalls days={days} productionApps={productionApps} />
            </TitledCard>

            <TitledCard title="Recent Sessions">
                <RecentSessions productionApps={productionApps} />
            </TitledCard>

            { false &&
            <>
                <TitledCard title="Activities created recently"> 
                    <ActivitiesRecentlyCreated days={days} />
                </TitledCard>

                <TitledCard title="Participations pending"> 
                    <ParticipationsPending/>
                </TitledCard>
                
                <TitledCard title="Recent location searches">
                    <RecentLocationSearches/>
                </TitledCard>
            </>
            }
        </div>
    );
}
