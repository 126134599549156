import {DateTime} from "luxon";
import {useState} from "react";

import {DateRangeCard} from "gih_web_common";

import {
    EmailsSentByPeriod,
    EmailsSentByPostcode,
    invalidateMarketing,
} from "./graphs-marketing";


export function MarketingStats() {

    const now = DateTime.now()
    const startMonth = now.minus({months:6}).startOf('month').toJSDate()
    const endMonth = now.endOf('day').toJSDate()

    const [startDate, setStartDate] = useState(now.minus({days:35}).startOf('week').toJSDate())
    const [endDate, setEndDate] = useState(now.endOf('day').toJSDate())
    
    const [prefix, setPrefix] = useState(null)

    function byPostcodeStart() {
        return (prefix !== null) ? startDate : DateTime.fromJSDate(endDate).startOf('week').toJSDate()
    }

    return (
        <div className="mx-auto max-w-[1650px] space-y-2">

            <DateRangeCard
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                prefix={prefix}
                setPrefix={setPrefix}
                onRefresh={invalidateMarketing}
                unit="day"
            />

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <EmailsSentByPeriod period="week" start={startDate} end={endDate} prefix={prefix} />
                <EmailsSentByPeriod period="month" start={startMonth} end={endMonth} prefix={prefix} />

                <EmailsSentByPostcode start={byPostcodeStart()} end={endDate} prefix={prefix} />
                <div></div>
            </div>
        </div>
    )
}
