import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Redirect, Link, useLocation, useParams} from "react-router-dom";

import queryString from "querystring";

import {createUserWithEmailAndPassword, signOut} from "firebase/auth";

import {useQuery, useMutation} from "@apollo/react-hooks";

import {buttonStyles, GlassCard, LocalLink, FormWrapper, FormInput, FormNewPassword, FormErrorText, FormPrimaryButton, nameStr, authErrorDecoder} from "gih_web_common";
import Logo from "gih_web_common/assets/gih-logo-1818x268.png";

import {CHECK_INVITE, ADD_USER} from "../../utils/graphql/user";
import {auth} from "../../utils/firebase";
import {logActionSuccess, logActionGraphQLFailure, logActionUserError, logFormValidationFailure} from "../../utils/analytics";
import {ACTIONS} from "../../utils/analyticsConstants";

import {SelfSignUpProgress} from "../common/steps";


function getFormValues(invite) {
    return {
        email: invite.sentInviteEmail ?? ""
    }
}

function ErrorWidget({error}) {
    if (error.graphQLErrors && error.graphQLErrors[0].extensions.code === 'GIH_USER_ALREADY_EXISTS') {
        return (
            <div className="flex flex-col gap-4">
                <div className="text-lg">It looks like you've already signed up!</div>
                <div className="text-sm">You can signin using the e-mail address and password you set previously.</div>
                <Link to={`/sign-in`} className={buttonStyles.altLg}>
                    Signin
                </Link>
            </div>
        )
    } else {
        return <div className="text-red-500">{error.toString()}</div>
    }
}

const SignUp = () => {

    const {inviteId} = useParams();
    const location = useLocation();

    const loginState = useSelector(state => state.loginState);
    const dispatch = useDispatch();

    const [invite, setInvite] = useState(null);
    const [addUser] = useMutation(ADD_USER);

    const {error: checkInviteError} = useQuery(CHECK_INVITE, {
        variables: { inviteId: inviteId },
        fetchPolicy: "no-cache",
        onCompleted: data => { setInvite(data.checkInvite) }
    });

    function signUp(values) {

        return new Promise((resolve, reject) => {

            const signupEmail = invite.sentInviteEmail ?? values.email.trim().toLowerCase();
            const passwd = values.newPassword;

            dispatch({ type: 'SIGNUP_BEGIN', payload: null });

            createUserWithEmailAndPassword(auth, signupEmail, passwd)
                .then(({user: fbUser}) => {

                    if (!fbUser) {
                        console.log('Firebase user was not created');
                        reject("Something went wrong on our side.");
                        return;
                    }

                    const firstName = values.firstName.trim();
                    const lastName = values.lastName.trim();

                    logActionSuccess(ACTIONS.userFirebaseCreate, `for ${signupEmail} (${firstName} ${lastName})`);

                    addUser({
                        variables: {
                            inviteId: inviteId,
                            details: {
                                firebaseId: fbUser.uid,
                                email: signupEmail,
                                firstName: firstName,
                                lastName: lastName,
                            }
                        }
                    }).then(r => {
                        const user = r.data.portalAddUser;
                        logActionSuccess(ACTIONS.userCreate, `for ${user.email} (${nameStr(user)})`);

                        dispatch({type: "SET_AUTH", payload: fbUser});
                        dispatch({type: "SET_USER", payload: user});
                        dispatch({type: "SET_CHARITY", payload: user.charityId});

                        console.log('User creation complete');
                        resolve('Success');

                    }, e => {
                        logActionGraphQLFailure(ACTIONS.userCreate, e);
                        signOut(auth).then(() => {
                            dispatch({ type: 'CLEAR_AUTH', payload: null });
                            reject("Something went wrong - please check that the e-mail address you entered matches the e-mail that originally received the invitation and try again.");
                        });
                    });

            }, e => {
                logActionUserError(ACTIONS.userFirebaseCreate, `with code ${e.code} for ${signupEmail}`);
                dispatch({ type: 'CLEAR_AUTH', payload: null });
                reject(authErrorDecoder(e));
            });
        });
    }

    return (
        <div className="min-h-screen flex flex-col justify-center items-center space-y-3">
            { invite !== null && invite.sentInviteEmail !== null &&
            <GlassCard>
                <SelfSignUpProgress activeStep={1} />
            </GlassCard>
            }
            <GlassCard width="max-w-lg">
                <div className="flex justify-center mb-6">
                    <img src={Logo} alt="" />
                </div>
                { loginState === 'LoggedIn' &&
                    <Redirect to={queryString.parse(location.search).redirect ? queryString.parse(location.search).redirect : "/"} />
                }
                { checkInviteError &&
                    <ErrorWidget error={checkInviteError} />
                }
                { invite !== null && (
                    <div>
                        <div className="flex justify-between align-start pb-4">
                            { invite.sentInviteEmail === null &&
                            <h2 className="text-3xl font-semibold">Sign up to manage<br/>{invite.charityId.name}</h2>
                            }
                            { invite.sentInviteEmail !== null &&
                            <h2 className="text-2xl font-semibold">Your e-mail address has been verified - please enter your name and choose a password</h2>
                            }
                        </div>
                        { invite.sentInviteEmail === null &&
                        <div className="mt-2 text-sm">
                            Already registered? {" "}
                            <LocalLink to="/sign-in">
                                Sign In
                            </LocalLink>
                        </div>
                        }
                        <FormWrapper
                            onSubmit={signUp}
                            onValidationFailure={logFormValidationFailure}
                            initialValues={getFormValues(invite)}
                            className="space-y-6"
                        >
                            <div className="space-y-2">
                                <div className="flex w-full space-x-3">
                                    <FormInput
                                        id="firstName"
                                        name="firstName"
                                        type="text"
                                        autoComplete="given-name"
                                        placeholder="first name"
                                        label="First name"
                                        required
                                        rootClassName="w-full"
                                    />
                                    <FormInput
                                        id="lastName"
                                        name="lastName"
                                        type="text"
                                        autoComplete="family-name"
                                        required
                                        label="Last name"
                                        placeholder="last name"
                                        rootClassName="w-full"
                                    />
                                </div>
                                <FormInput
                                    id="email-address"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    placeholder="e-mail address - must match the address of the invite"
                                    label="E-mail address"
                                    className="rounded-md"
                                    disabled={invite.sentInviteEmail !== null}
                                    required
                                />
                                <FormNewPassword
                                    account={null}
                                    id="newPassword"
                                    name="newPassword"
                                    type="password"
                                    autoComplete="new-password"
                                    required
                                    label="Choose a password"
                                    placeholder="new password"
                                />
                                <FormErrorText/>
                            </div>
                            <div className="mx-auto">
                                <FormPrimaryButton type="submit">
                                    {(invite.sentInviteEmail !== null) ? "Complete sign up" : "Sign up"}
                                </FormPrimaryButton>
                            </div>
                            <div className="flex items-center text-sm">
                                <p>
                                    By clicking "Sign up", you agree to our {" "}
                                    <LocalLink to="/signup/terms">
                                        Terms & Conditions
                                    </LocalLink>
                                    {" "}and{" "}
                                    <LocalLink to="/signup/privacy">
                                        Privacy Policy
                                    </LocalLink>
                                </p>
                            </div>
                        </FormWrapper>
                    </div>
                )}
            </GlassCard>
        </div>
    )
}

export default SignUp;