import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {use100vh} from 'react-div-100vh'

import {useQuery} from "@apollo/react-hooks";
import QRCODE from "qrcode";

import PrintIcon from "@mui/icons-material/Print";

import Logo from 'gih_web_common/assets/gih-logo-1818x268.png';
import {buttonStyles, OnLoadViewer, addressToString, dateToString} from "gih_web_common";

import {GET_ACTIVITY_BY_ID} from "../../utils/graphql/activity";
import {rruleToText} from "../../utils/repetition";

import {TitleAndBackButton} from "../common/elements";

import "./qrcode.css";


export default function QRCodePrint() {

    const height = use100vh();
    const {id} = useParams();

    const [qr, setQr] = useState("");

    const {loading: activityQueryInProgress, data: activityQueryResult} = useQuery(GET_ACTIVITY_BY_ID, {
        variables: { activityId: id },
    });

    useEffect(() =>  {
        QRCODE.toDataURL("gihtech://Checkin/" + id, {
            width: 800,
            height: 800
        }).then((qrData) => {
            setQr(qrData);
        });
    }, [id]);

    const activity = activityQueryResult ? activityQueryResult.portalFindActivityById : null;

    return (
        <div className="m-2 p-2 mx-auto max-w-4xl h-full bg-white overflow-y-auto" style={{ maxHeight: `${height - 64}px`}}>
            <TitleAndBackButton title="Print-ready checkin sheet" />
            <OnLoadViewer loading={activityQueryInProgress}>
                { activity &&
                <>
                    <div id="gih-logo" className="p-3 flex flex-wrap items-center justify-between gap-2">
                        <img width={400} alt="GIH logo" src={Logo}/>
                        <div className="text-4xl font-bold">
                            CHECKIN
                        </div>
                    </div>
                    <div className="bg-gray-100 px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xl font-medium text-gray-400">Activity</dt>
                        <dd className="mt-1 text-2xl text-gray-900 sm:mt-0 sm:col-span-2">{activity.name}</dd>
                    </div>
                    <div className="bg-white px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xl font-medium text-gray-400">{activity.repetition !== null ? "Start Date" : "Event Date"}</dt>
                        <dd className="mt-1 text-2xl text-gray-900 sm:mt-0 sm:col-span-2"> {dateToString(activity.eventDate)}</dd>
                    </div>
                        { activity.repetition !== null &&
                            <div className="bg-white px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-xl font-medium text-gray-400">Activity takes place every</dt>
                                <dd className="mt-1 text-2xl text-gray-900 sm:mt-0 sm:col-span-2">{rruleToText(activity.repetition, activity.scheduling)}</dd>
                            </div>
                        }
                    <div className="bg-gray-100 px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-xl font-medium text-gray-400">Address</dt>
                        <dd className="mt-1 text-2xl text-gray-900 sm:mt-0 sm:col-span-2">
                            {addressToString(activity.address)}
                        </dd>
                    </div>
                    <div className="w-fit mx-auto">
                        <img src={qr} alt="" width={400} height={400}/>
                    </div>
                    <div className="text-xl space-y-2">
                        <div>If you signed up to this activity through Giving Is Human, please scan to checkin.</div>
                        <div>Android users please open the Giving Is Human App first and touch “Checkin”.</div>
                        <button className={buttonStyles.altLg} id="print-button" onClick={window.print}>
                            <PrintIcon/><span className="ml-2">Print code</span>
                        </button>
                    </div>
                </>
                }
            </OnLoadViewer>
        </div>
    )
}
