import {RRule} from "rrule";

export const frequencyOptions = [
    {
        label: "On certain day(s) of the week",
        value: "weekly",
        qualifier: eventType => true,
    }, {
        label: "On a certain day of the month",
        value: "monthly",
        qualifier: eventType => eventType === "repeatingEvent",
    }, {
        label: "Every day",
        value: "daily",
        qualifier: eventType => eventType !== "anyTime",
    }, {
        label: "On any day of the week",
        value: "daily",
        qualifier: eventType => eventType === "anyTime",
    }
]

export const dayOptions = [
    {
        id: "mon",
        label: "Mon",
        value: RRule.MO
    }, {
        id: "tue",
        label: "Tue",
        value: RRule.TU
    }, {
        id: "wed",
        label: "Wed",
        value: RRule.WE
    }, {
        id: "thu",
        label: "Thu",
        value: RRule.TH
    }, {
        id: "fri",
        label: "Fri",
        value: RRule.FR
    }, {
        id: "sat",
        label: "Sat",
        value: RRule.SA
    }, {
        id: "sun",
        label: "Sun",
        value: RRule.SU
    }
]

export const monthlyWhichOptions = [
    {
        label: "first",
        value: 1
    }, {
        label: "second",
        value: 2
    }, {
        label: "third",
        value: 3
    }, {
        label: "fourth",
        value: 4
    }, {
        label: "last",
        value: -1
    }
]

export const endConditionOptions = [
    {
        label: "no end date",
        value: "repeatForever"
    }, {
        label: "ending on",
        value: "endOn"
    }
]

export const eventTypeOptions = [
    {
        label: "One time event",
        value: "oneTimeEvent",
        additional: false,
    }, {
        label: "Repeating event",
        value: "repeatingEvent",
        additional: false,
    }, {
        label: "During the school day",
        value: "schoolTime",
        additional: true,
    }, {
        label: "During school holidays",
        value: "schoolHolidays",
        additional: true,
    }, {
        label: "Outside school hours & holidays",
        value: "outsideSchoolHours",
        additional: true,
    }, {
        label: "Flexible - any time",
        value: "anyTime",
        additional: true,
    }, {
        label: "Particular events by agreement",
        value: "byAgreement",
        additional: true,
    }
]
