import {gql} from "@apollo/client";

export const GET_CONFIG = gql`
    query GetConfig {
        getConfig {
            imageURLPrefix
            videoURLPrefix
            embedURLPrefix
            selfSignupForGiving
            charity {
                nameMaxLength
                briefDescriptionMaxLength
                descriptionMaxLength
                keywordsMax
            }
            activity {
                nameMaxLength
                descriptionMaxLength
                placesMax
                keywordsMax
            }
            campaign {
                nameMaxLength
                descriptionMaxLength
                placesMax
                keywordsMax
                updatePostedByMaxLength
                updateHeadingMaxLength
                updateTextMaxLength
                minimumTargetGBP
                maximumTargetGBP
            }
        }
    }
`

export const charityBaseInfo = `
    _id
    name
    logoPicture
    defaultAcceptMessage
    defaultDeclineMessage
    provisional
    awaiting1stActivity
    awaiting1stCampaign
    features {
        allowManageAvailability
        allowActivityVideo
        allowSmsNotifications
        enableGiving
        additionalSchedulingOpts
    }
`

export const charityDetailInfo = `
    ${charityBaseInfo}
    fullDescription
    briefDescription
    geohash
    locationName
    profilePicture
    address {
        line1
        line2
        streetName
        townCity
        postcode
    }
    contacts {
        role
        firstName
        lastName
        phone        
        email
        preferences {
            marketing
            customerSuccess
        }
    }
    defaultAutoReply
    keywords
    website
    registration
    companyNumber
    regNosLocked
    hmrcId
    giftAidEligible
    giftAidAutoClaim
    rating
`

export const CHECK_KEYWORD = gql`
    query CheckKeyword($word: String!) {
        checkKeyword(word: $word)
    }
`
