import {gql} from "@apollo/client";

import {charityBaseInfo, charityDetailInfo} from './basic'


export const GET_CHARITY_BY_ID = gql`
    query FindCharityById($charityId: ID!) {
        portalFindCharityById(id: $charityId) {
            ${charityBaseInfo}
        }
    }
`

export const GET_CHARITY_DETAIL_BY_ID = gql`
    query FindCharityById($charityId: ID!) {
        portalFindCharityById(id: $charityId) {
            ${charityDetailInfo}
        }
    }
`

export const UPDATE_CHARITY_DETAILS = gql`
    mutation UpdateCharityDetails($charityId: ID!, $details: CharityInput!) {
        updateCharityDetails(id: $charityId, details: $details) {
            ${charityDetailInfo}
        }
    }
`

export const GET_CHARITY_SITES = gql`
    query CharitySites($charityId: ID!) {
        portalCharitySites(id: $charityId) {
            _id
            name
            address {
                line1
                line2
                streetName
                townCity
                postcode
            }
            geohash
            locationName
        }
    }
`

export const ADD_CHARITY_SITE = gql`
    mutation AddSite($charityId: ID!, $site: SiteInput!) {
        addCharitySite(charityId: $charityId, site: $site)
    }
`

export const UPDATE_CHARITY_SITE = gql`
    mutation UpdateSite($siteId: ID!, $site: SiteInput!) {
        updateCharitySite(siteId: $siteId, site: $site)
    }
`

export const DELETE_CHARITY_SITE = gql`
    mutation DeleteSite($siteId: ID!) {
        deleteCharitySite(siteId: $siteId)
    }
`

export const CONFIGURE_PAYOUTS = gql`
    mutation ConfigurePayouts($charityId: ID!) {
        configurePayouts(charityId: $charityId)
    }
`

export const GET_CHARITY_PAYOUT_STATUS_BY_ID = gql`
    query CharityPayoutAccountStatus($charityId: ID!) {
        portalCharityPayoutAccountStatus(charityId: $charityId)
    }
`

export const GET_CHARITY_EXPRESS_DASHBOARD_LINK_BY_ID = gql`
    query PortalCharityExpressDashboardLink($charityId: ID!) {
        portalCharityExpressDashboardLink(charityId: $charityId)
    }
`

export const ALL_CHARITY_USERS_LIST = gql`
    query FindUsersByCharityId($charityId: ID!) {
        findUsersByCharityId(charityId: $charityId) {
            _id
            firstName
            lastName
            email
            firebaseId
            fullAccess
            disabled
            createdAt
            updatedAt
            lastSignIn
            lastSeen
            enableConcierge
            smsNotificationPhone
            smsNotificationDays
            lastEmailNotification
            lastSmsNotification
        }
    }
`

export const SET_ACCOUNT_DISABLE = gql`
    mutation PortalSetAccountDisable($firebaseId: String!, $disable: Boolean!) {
        portalSetAccountDisable(firebaseId: $firebaseId, disable: $disable) {
            _id
            firstName
            lastName
            email
            firebaseId
            smsNotificationPhone
            smsNotificationDays
            fullAccess
            disabled
        }
    }
`

export const GET_WORK_FOR_CHARITY_BY_ACTIVITY = gql `
    query WorkForCharityByActivity($charityId: ID!) {
        workForCharityByActivity(charityId: $charityId) {
            minutes
            _id
            name
        }
    }
`

export const GET_WORK_FOR_CHARITY_BY_PERIOD = gql `
    query WorkForCharityByPeriod($charityId: ID!, $start: DateTime!, $end: DateTime!, $period: String!) {
        workForCharityByPeriod(charityId: $charityId, start: $start, end: $end, period: $period) {
            _id
            minutes
        }
    }
`

export const GET_WORK_FOR_CHARITY_BY_USER = gql`
    query WorkForCharityByUser($charityId: ID!, $start: DateTime!, $end: DateTime!) {
        workForCharityByUser(charityId: $charityId, start: $start, end: $end) {
            userId {
                _id
                firstName
                lastName
                firebaseId
            }
            activities {
                info {
                    _id
                    name
                }
                minutesWorked
            }
            checkins
            lastAttended
            minutesWorked
        }
    }
`

export const GET_WORK_STATS_FOR_CHARITY_BY_USER = gql`
    query WorkForCharityByUser($charityId: ID!, $start: DateTime!, $end: DateTime!) {
        workForCharityByUser(charityId: $charityId, start: $start, end: $end) {
            userId {
                _id
                firstName
                lastName
                firebaseId
            }
            checkins
            lastAttended
            minutesWorked
        }
    }
`

export const GET_ALL_PARTICIPATION_CHARITYID = gql`
    query GetAllParticipationByCharityId($charityId: ID!) {
        getAllParticipationByCharityId(charityId: $charityId) {
            activityId {
                _id
            }
            userId{
                _id
                profilePicture
            }
            status
            firebaseId
        }
    }
`

export const COUNT_PENDING_PARTICIPATIONS_FOR_CHARITY_ID = gql`
    query CountPendingParticipationsForCharityId($charityId: ID!) {
        countPendingParticipationsForCharityId(charityId: $charityId)
    }
`

export const GET_INVITES_BY_CHARITY = gql`
    query InvitesByCharityId($charityId: ID!) {
        invitesByCharityId(charityId: $charityId) {
            sentInviteEmail
            createdAt
            expiresAt
            trace {
                _id
                createdAt
                category
                to
                status
                lastStatusChange
                openCount
                lastOpen
                clickCount
                lastClick
            }
        }
    }
`

export const DELETE_INVITE = gql `
    mutation DeleteInvite($charityId: ID!, $toEmail: String!) {
        deleteInvite(charityId: $charityId, toEmail: $toEmail)
    }
`
