import {DateTime} from "luxon";
import {useState} from "react";

import {DateRangeCard} from "gih_web_common";

import {
    NotiifcationsByTypeByPeriod,
} from "./graphs-user";

import {
    OutcomesByPeriod,
    InterestByPeriod,
    invalidateVolunteering
} from "./graphs-volunteering";


export function AdvancedDatabaseStats() {

    const now = DateTime.now()
    const startMonth = now.minus({months:3}).startOf('month').toJSDate()
    const endMonth = now.endOf('day').toJSDate()

    const [startDate, setStartDate] = useState(now.minus({days:28}).startOf('week').toJSDate())
    const [endDate, setEndDate] = useState(now.endOf('day').toJSDate())

    return (
        <div className="space-y-2 mx-auto max-w-[1650px]">            
            <DateRangeCard
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                onRefresh={() => invalidateVolunteering(true)}
                unit="day"
            />

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <InterestByPeriod period="week" start={startDate} end={endDate} />
                <InterestByPeriod period="month" start={startMonth} end={endMonth} />

                <OutcomesByPeriod period="week" start={startDate} end={endDate} />
                <OutcomesByPeriod period="month" start={startMonth} end={endMonth} />

                <NotiifcationsByTypeByPeriod outcome="success" period="day" start={startDate} end={endDate} />
                <NotiifcationsByTypeByPeriod outcome="success" period="week" start={startMonth} end={endMonth} />

                <NotiifcationsByTypeByPeriod outcome="tokenNoLongerValid" period="day" start={startDate} end={endDate} />
                <NotiifcationsByTypeByPeriod outcome="tokenNoLongerValid" period="week" start={startMonth} end={endMonth} />
            </div>
        </div>
    )
}
