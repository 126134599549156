import {formError} from "gih_web_common";

import {upload} from "../../../utils/firebase";
import {getAddress, validateAddress} from  "../../../utils/address";
import {FILES} from  "../../../utils/analyticsConstants";


export async function validate(values, existing, charity, campaignCfg) {

    console.log("Validating:", values);

    if (!existing.imageURL && !values.imageFile) {
        throw formError("upload a picture for the campaign");
    }

    if (values.keywords.length < 1) {
        throw formError("enter one or more keywords that describe the campaign");
    }

    if (values.endDate === null) {
        throw formError("choose an end date for the campaign");
    }

    const multiplePlaces = (values.workLocation === "community");

    const campaign = {
        type: values.type,
        workLocation: values.workLocation,
        name: values.name,
        fullDescription: values.fullDescription,
        imageURL: existing.imageURL,
        videoURL: existing.videoURL,
        videoPosterTime: values.videoPosterTime,
        address: multiplePlaces ? null : getAddress(values.chosenSite ? values.chosenSite.address : values),
        places: (multiplePlaces || !values.chosenSite) ? values.places : [ values.chosenSite.place ],
        keywords: values.keywords,
        hidden: values.hidden,
        target: parseInt(values.target) * 100,
        endDate: values.endDate
    }

    if (campaign.target < campaignCfg.minimumTargetGBP*100 || campaign.target > campaignCfg.maximumTargetGBP*100) {
        throw { target: `Target amount must be between £${campaignCfg.minimumTargetGBP} up to £${campaignCfg.maximumTargetGBP}` };
    }

    if (campaign.address !== null) {
        validateAddress(campaign.address);
    }

    if (campaign.places.length < 1 || !campaign.places[0].geohash) {
        throw formError("enter a place name for location based search (this can be the postcode, street or area where the results of the campaign will be felt)");
    }

    await upload(values.imageFile, 'campaign', 'header', FILES.campaignImage, (fullPath) => { campaign.imageURL = fullPath });
    await upload(values.videoFile, 'campaign', 'video', FILES.campaignVideo, (fullPath) => { campaign.videoURL = fullPath });

    return campaign;
}
