import {RRule} from "rrule";

import {dateToString, dateToStringOnlyTime, dateToStringNoTime, reinterpretAsLocal} from "gih_web_common";


export function rruleToText(s, scheduling) {

    const rule = RRule.fromString(s)
    const dtStart = reinterpretAsLocal(rule.options.dtstart)
    const starting = (dtStart.getTime() > Date.now()) ? ` starting on ${dateToStringNoTime(dtStart)}` : "";

    switch (scheduling) {
    
        case "repeatingEvent":
            return `At ${dateToStringOnlyTime(dtStart)} ${rule.toText()}` + starting

        case "schoolTime":
            return `During school hours ${rule.toText()}` + starting

        case "schoolHolidays":
            return `During school holidays at ${dateToStringOnlyTime(dtStart)} ${rule.toText()}` + starting

        case "outsideSchoolHours":
            return `Outside school hours & during holidays ${rule.toText()}` + starting

        case "anyTime":
            return `Anytime ${rule.toText().replace('every day', 'any day')}` + starting

        case "byAgreement":
            return `Various events you can choose to attend ${rule.toText()}` + starting

        default:
            return "???"
    }
}

export function fmtNextOccurrence(when, scheduling) {

    switch (scheduling) {

        case "oneTimeEvent":
            return {
                title: "Date",
                text: dateToString(when)
            }

        case "repeatingEvent":
            return {
                title: "Next occurrence",
                text: dateToString(when)
            }

        case "byAgreement":
        case "anyTime":
        case "outsideSchoolHours":
        case "schoolTime":
            return {
                title: "Next valid day",
                text: dateToStringNoTime(when)
            }

        case "schoolHolidays":
            return {
                title: "Next occurrence",
                text: dateToString(when)
            }

        default:
            return {
                title: "Next occurrence",
                text: "???"
            }
    }
}