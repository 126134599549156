import {useState} from "react";

import {useQuery} from "@apollo/react-hooks";

import {OnLoadViewer, TableContainer2, Table, TableHead, TableBody, TableRow, TableCell, RefreshCell, monetaryAmountToString, dateToString, cellStyles, FormattedUserName, Lozenge, RefreshButton} from "gih_web_common";

import {GET_DONATIONS_BY_CREATION_DATE, GET_DONATIONS_SUMMARY} from "../../../utils/graphql/admin-giving";
import {PieChart, seriesToColour} from "../../../utils/stats";

import {CharityCell, BoldMetric} from "../../common/elements";


const statuses = {
    pending: {
        colour: 'bg-yellow-500',
        text: 'Pending',
    },
    complete: {
        colour: 'bg-green-500',
        text: 'Complete'
    },
    failed: {
        colour: 'bg-red-500',
        text: 'Failed'
    }
};

const sources = {
    app: {
        colour: 'bg-purple-500',
        text: 'App',
    },
    web: {
        colour: 'bg-blue-500',
        text: 'Web',
    }
};

export function DonationsSummary({dateRange}) {

    const [summary, setSummary] = useState(null);

    const {refetch, loading, error} = useQuery(GET_DONATIONS_SUMMARY, {
        variables: dateRange,
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setSummary(data.donationSummary),
    });

    return (
        <OnLoadViewer loading={loading} error={error}>
            { summary &&
            <>
                <div className="flex flex-wrap text-base sm:text-lg items-center gap-1">
                    <RefreshButton action={refetch} />
                    <BoldMetric prefix="There were" value={summary.count} suffix="donation" />
                    { summary.count > 0 &&
                    <>
                        <BoldMetric prefix="totalling" value={monetaryAmountToString(summary.total)} />
                        <BoldMetric prefix="+GiftAid" value={monetaryAmountToString(summary.giftAidTotal)} />
                        <BoldMetric prefix="given to" value={summary.campaignCount} suffix="campaign" />
                        <BoldMetric prefix="by" value={summary.registeredUserCount} suffix="registered user" />
                        <BoldMetric prefix="and" value={summary.count - summary.appCount} suffix="unregistered user" />
                        <BoldMetric prefix="with total tips of" value={monetaryAmountToString(summary.tipTotal)} />
                        <BoldMetric prefix="making an average tip of" value={`${monetaryAmountToString(summary.tipTotal / summary.count)} (${(100 * summary.tipTotal / summary.total).toFixed(1)}%)`} />
                    </>
                    }
                </div>
                { summary.count > 0 &&
                <div className="flex flex-wrap items-center gap-4 w-min sm:w-fit mx-auto">
                    <PieChart
                        title="Donation method"
                        labels={['Web', 'App']}
                        values={[summary.count - summary.appCount, summary.appCount]}
                        size={320}
                    />

                    <PieChart
                        title="GiftAid"
                        labels={['Without', 'With']}
                        values={[summary.count - summary.giftAidCount, summary.giftAidCount]}
                        size={320}
                    />

                    <PieChart
                        title="Distribution of tip amount"
                        labels={summary.tipAmounts.map(bucket => bucket.label)}
                        values={summary.tipAmounts.map(bucket => bucket.count)}
                        size={320}
                    />

                    <PieChart
                        title="Distribution of tip percentage"
                        labels={summary.tipPercentages.map(bucket => bucket.label)}
                        values={summary.tipPercentages.map(bucket => bucket.count)}
                        size={320}
                    />

                    <PieChart
                        title="Campaigns that received donation(s) by charity rating"
                        labels={summary.charityRatings.map(bucket => bucket.label)}
                        values={summary.charityRatings.map(bucket => bucket.count)}
                        size={320}
                        palette={summary.charityRatings.map(bucket => seriesToColour(bucket))}
                    />
                </div>
                }
            </>
            }
        </OnLoadViewer>
    );
}

export function DonationsRecentlyCreated({showIncomplete, dateRange}) {

    const {refetch, loading, error, data} = useQuery(GET_DONATIONS_BY_CREATION_DATE, {
        variables: {
            ...dateRange,
            showIncomplete: showIncomplete,
        },
    });
    const donations = data?.donationsByCreationDate;

    return (
        <TableContainer2
            loading={loading}
            error={error}
            rows={donations}
            emptyText="No donations"
            refresh={refetch}
            sx={{maxHeight: 740}}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <RefreshCell sx={cellStyles.dateTime} action={refetch} text="Created" />
                        <TableCell sx={cellStyles.fixedNarrow}>Status</TableCell>
                        <TableCell sx={cellStyles.charity}>Charity</TableCell>
                        <TableCell sx={cellStyles.freeMedium}>Campaign/Fundraiser</TableCell>
                        <TableCell sx={cellStyles.person}>Donor</TableCell>
                        <TableCell sx={cellStyles.fixedNarrow}>Donor postcode</TableCell>
                        <TableCell sx={cellStyles.money}>Amount</TableCell>
                        <TableCell sx={cellStyles.money}>Tip</TableCell>
                        <TableCell sx={cellStyles.icon}>GiftAid</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { donations?.map(donation => (
                        <TableRow key={donation._id}>
                            <TableCell align="left">{dateToString(donation.createdAt)}</TableCell>
                            <TableCell align="center" className="flex flex-wrap">
                                <Lozenge colour={sources[donation.source].colour} text={sources[donation.source].text} />
                                <Lozenge colour={statuses[donation.status].colour} text={statuses[donation.status].text}/>
                            </TableCell>
                            <CharityCell charity={donation.campaignId.charityId} />
                            <TableCell align="left">
                                { donation.fundraiserId ?
                                <div className="flex flex-wrap gap-1">
                                    <span className="font-bold">{donation.fundraiserId.name}</span>
                                    <span className="italic">by {donation.fundraiserId.createdBy}</span>
                                    <span>for <strong>{donation.campaignId.name}</strong></span>
                                </div>
                                :
                                <span>{donation.campaignId.name}</span>
                                }
                            </TableCell>
                            <TableCell align="left"><FormattedUserName user={donation} /></TableCell>
                            <TableCell align="left">{donation.postcode ?? '-'}</TableCell>
                            <TableCell align="right">{monetaryAmountToString(donation.donation)}</TableCell>
                            <TableCell align="right">{monetaryAmountToString(donation.tip)}</TableCell>
                            <TableCell align="center">{donation.giftAid ? 'Yes' : '-'}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer2>
    );
}
