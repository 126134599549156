import {DateTime} from "luxon";

import {RefreshButton} from "gih_web_common";

import {
    EngagementByCharity,
    EngagementByActivity,
    EngagementByCampaign,
    NewInstallsByPlatformByPeriod,
    NewInstallsByMarketingLeadByPeriod,
    NewSignupsByPlatformByPeriod,
    NewSignupsByNotificationPermissionByPeriod,
    ActiveUsersByPlatformByPeriod,
    UserRetentionByInstallCohort,
    invalidate
} from "./graphs"


export function EventStats() {

    const now = DateTime.now()
    const earlyStart = now.minus({days:35}).startOf('week').toJSDate()
    const start = now.minus({days:14}).startOf('day').toJSDate()
    const end = now.endOf('day').toJSDate()

    return (
        <div className="mx-auto max-w-[1650px] space-y-2">
            <RefreshButton action={() => invalidate(false)} label={true} />

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <NewInstallsByPlatformByPeriod period="day" start={start} end={end} />
                <NewInstallsByPlatformByPeriod period="week" start={earlyStart} end={end} />

                <NewInstallsByMarketingLeadByPeriod period="day" start={start} end={end} simplify={true} />
                <NewInstallsByMarketingLeadByPeriod period="week" start={earlyStart} end={end} simplify={true} />

                <NewSignupsByPlatformByPeriod period="day" start={start} end={end} />
                <NewSignupsByPlatformByPeriod period="week" start={earlyStart} end={end} />

                <ActiveUsersByPlatformByPeriod period="day" start={start} end={end} signedUp={false} />
                <ActiveUsersByPlatformByPeriod period="week" start={earlyStart} end={end} signedUp={false} />

                <ActiveUsersByPlatformByPeriod period="day" start={start} end={end} signedUp={true} />
                <ActiveUsersByPlatformByPeriod period="week" start={earlyStart} end={end} signedUp={true} />

                <UserRetentionByInstallCohort period="week" start={earlyStart} end={end} />
                <NewSignupsByNotificationPermissionByPeriod period="week" start={start} end={end} />

                <EngagementByCharity start={start} end={end} />
                <EngagementByActivity start={start} end={end} />

                <EngagementByCampaign start={start} end={end} />
            </div>
        </div>
    )
}
