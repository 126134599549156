import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import {LocationSearch, noPlace} from "./locationSearch"


export function LocationsList({locations, setLocations}) {

    return (
        <>
            { locations.length < 1 &&
            <div className="w-fit bg-green-500 px-2 py-2 my-1 text-sm rounded-lg">
                No places - start typing the name of a place below and select an option to add it.
            </div>
            }
            { locations.map(location => (
                <div key={location.geohash} className="grid grid-cols-[2.5em_auto_auto] items-center w-fit bg-green-500 px-2 py-1 my-1 rounded-lg">
                    <div>
                        <RemoveCircleIcon fontSize="medium" className="text-red-500 hover:text-red-700" onClick={() => {
                            setLocations(locations.filter(l => l.geohash !== location.geohash))
                        }} />
                    </div>
                    <div className="text-sm sm:text-base">{location.name}</div>
                    <div className="ml-2 py-1 px-1 rounded-lg bg-yellow-500 text-xs sm:text-sm">{location.qualifier}</div>
                </div>
            ))}
            <LocationSearch initial={noPlace()} clearAfterSelect={true} partition={true} onSelectLocation={location => {
                if (!locations.find(l => l.geohash === location.geohash)) {
                    setLocations(locations.concat(location))
                }
            }} />
        </>
    )
}
