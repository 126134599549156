import {useEffect} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {use100vh} from 'react-div-100vh'

import {useQuery, useMutation} from "@apollo/react-hooks";

import {OnLoadViewer} from "gih_web_common";

import {GET_ACTIVITY_BY_ID, UPDATE_ACTIVITY} from "../../utils/graphql/activity";
import {GET_CHARITY_DETAIL_BY_ID} from "../../utils/graphql/charity";
import {logScreenViewEvent, logActionSuccess, logActionGraphQLFailure} from "../../utils/analytics";
import {ACTIONS, SCREEN_NAME, SCREEN_CLASS} from "../../utils/analyticsConstants";
import {evictAllDependentOnActivity} from "../../utils/graphql/cache";

import {ActivityDetailsForm} from "./form/form";
import {getDerivedValues} from "./form/helpers";


export default function EditActivity() {

    const height = use100vh();
    const {id} = useParams();
    const history = useHistory();

    const currentCharity = useSelector(state => state.charity);

    const [updateActivity] = useMutation(UPDATE_ACTIVITY, { update: evictAllDependentOnActivity })

    const {loading: activityQueryInProgress, data: activityQueryResult} = useQuery(GET_ACTIVITY_BY_ID, {
        variables: { activityId: id },
        fetchPolicy: "network-only"
    });

    const {loading: charityQueryInProgress, data: charityQueryResult} = useQuery(GET_CHARITY_DETAIL_BY_ID, {
        variables: { charityId: currentCharity.id},
    });

    useEffect(() => {
        logScreenViewEvent(SCREEN_NAME.activityCreate, SCREEN_CLASS.activity)
    }, [])

    const onValidated = (activity) => {
        return updateActivity({
            variables: {
                activityId: id,
                activity: activity
            }
        }).then(r => {
            logActionSuccess(ACTIONS.activityUpdate, `Updated activity ${id}`);
            history.push(`/activity/${id}`)
        }, e => {
            logActionGraphQLFailure(ACTIONS.activityUpdate, e);
            throw e;
        });
    }

    return (
        <div className="p-1 lg:p-4 flex flex-col overflow-y-auto" style={{ maxHeight: `${height - 64}px`}}>
            <div className="mx-auto">
                <OnLoadViewer loading={charityQueryInProgress || activityQueryInProgress}>
                    { activityQueryResult && charityQueryResult &&
                    <ActivityDetailsForm
                        initial={{
                            activity: activityQueryResult.portalFindActivityById,
                            charity: charityQueryResult.portalFindCharityById,
                            derived: getDerivedValues(activityQueryResult.portalFindActivityById)
                        }}
                        editing={true}
                        onValidated={onValidated}
                    />
                    }
                </OnLoadViewer>
            </div>
        </div>
    )
}
