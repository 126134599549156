import {useState} from "react";
import {useHistory} from "react-router-dom";

import {useMutation} from "@apollo/react-hooks";

import {GlassCard, LocalLink, FormWrapper, FormInput, FormErrorText, FormPrimaryButton, AlertModal} from "gih_web_common";
import Logo from 'gih_web_common/assets/gih-logo-1818x268.png';

import {SEND_PASSWORD_RESET_EMAIL} from "../../utils/graphql/user";
import {logActionSuccess, logActionGraphQLFailure} from "../../utils/analytics";
import {ACTIONS} from "../../utils/analyticsConstants";


export default function ResetPasswordPage() {

    const history = useHistory();

    const [sendPasswordResetEmail] = useMutation(SEND_PASSWORD_RESET_EMAIL);

    const [outcome, setOutcome] = useState(null);

    const sendResetLink = (values) => {
        const email = values.email.trim();
        return sendPasswordResetEmail({
            variables: {
                email: email
            }
        }).then(r => {
            logActionSuccess(ACTIONS.userSendPasswordResetEmail, `for ${email}`);
            setOutcome({
                type: "success",
                message: "If we were able to find that e-mail address in our system then we have now sent you a link to reset your password."
            });
        }, e => {
            logActionGraphQLFailure(ACTIONS.userSendPasswordResetEmail, e);
            throw e;
        });
    }

    return (
        <div className="min-h-screen flex justify-center items-center">
            <GlassCard width="max-w-md">
                <div className="flex justify-center mb-6">
                    <img src={Logo} alt=""/>
                </div>
                <div className="flex flex-col align-start mb-4 space-y-3">
                    <h2 className="text-2xl font-bold">Request a password reset link by e-mail</h2>
                    <LocalLink to='/sign-in' className="text-xs">
                        I've remembered - take me back to sign-in.
                    </LocalLink>
                </div>
                <FormWrapper onSubmit={sendResetLink} className="space-y-6">
                    <FormInput
                        id="email-address"
                        name="email"
                        type="email"
                        autoComplete="email"
                        placeholder="e-mail address"
                        label="E-mail address"
                        hideLabel
                        required
                    />
                    <div className="mx-auto">
                        <FormErrorText/>
                        <FormPrimaryButton type="submit">
                            Send link
                        </FormPrimaryButton>
                    </div>
                </FormWrapper>
            </GlassCard>

            { outcome !== null &&
            <AlertModal
                type={outcome.type}
                message={outcome.message}
                onDismiss={ () => {
                    setOutcome(null)
                    history.push("/sign-in")
                }}
            />
            }
        </div>
    )
}
