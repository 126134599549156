import {useSelector} from "react-redux";

import NearMeIcon from '@mui/icons-material/NearMe';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import {Image, phonePreviewStyle, phonePreviewScreenStyle} from "gih_web_common";

import activityPreviewBackground from "../../../images/createActivityMock.png";

export function ActivityPreview({activity, charity, imageLocalURL}) {

    const imageURLPrefix = useSelector(state => state.cfg?.imageURLPrefix);

    return (
        <div className="relative mx-auto text-white" style={phonePreviewStyle()}>
            <img src={activityPreviewBackground} alt="" className="object-contain"/>
            <div className="absolute space-y-3" style={phonePreviewScreenStyle(true)}>
                <div className="mx-auto w-fit">
                    <Image rmtPrefix={imageURLPrefix} rmt={activity.imageURL} local={imageLocalURL} what="phonePreview" />
                </div>
                <div className="absolute px-2 py-1 space-y-3 bottom-0 bg-black bg-opacity-50 w-full">
                    <div className="font-bold text-lg">
                        {(activity.name.length > 0) ? activity.name : "Activity name"}
                    </div>
                    <div className="flex flex-row grid grid-cols-2 text-base items-center">
                        <div>with {charity.name}</div>
                        <div className="mx-auto">
                            <Image rmtPrefix={imageURLPrefix} rmt={charity.logoPicture} local={null} what="logoSmall" />
                        </div>
                    </div>
                    <div className="flex px-1 py-2 text-sm space-x-1 items-center">
                        <CalendarMonthIcon/>
                        <span>(when this takes place)</span>
                    </div>
                    <div className="flex px-1 py-2 text-sm space-x-1 items-center">
                        <NearMeIcon/>
                        <span>(distance from volunteer)</span>
                    </div>
                    <div className="rounded-lg bg-alt-dark w-fit px-3 py-1 font-bold text-sm">
                        Find out more
                    </div>
                </div>
            </div>
        </div>
    )
}
