import {useState} from "react";
import {DateTime} from "luxon";

import {useQuery} from "@apollo/react-hooks";

import {arrayByTimeToGraphData, makeDonationDatasets, BarGraph} from "../../utils/stats";
import {GET_DONATIONS_TO_CAMPAIGN_BY_PERIOD} from "../../utils/graphql/campaign";


export function CampaignDonationStats({campaignId}) {

    const now = DateTime.now()
    const byWeekStart = now.minus({weeks: 4}).startOf('week').toJSDate()
    const byMonthStart = now.minus({months: 11}).startOf('month').toJSDate()
    const end = now.endOf('month').toJSDate()

    return (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mx-auto w-full">
            <DonationGraph campaignId={campaignId} start={byWeekStart} end={end} period="week" />
            <DonationGraph campaignId={campaignId} start={byMonthStart} end={end} period="month" />
        </div>
    )
}

function DonationGraph({campaignId, start, end, period}) {

    const [graphData, setGraphData] = useState(null);

    const {loading} = useQuery(GET_DONATIONS_TO_CAMPAIGN_BY_PERIOD, {
        variables: {
            campaignId: campaignId,
            start: start,
            end: end,
            period: period
        },
        fetchPolicy: "cache-and-network",
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData(arrayByTimeToGraphData(data.donationsToCampaignByPeriod, period, makeDonationDatasets())),
    })

    return (
        <BarGraph title={`Total amount donated by ${period}`} data={graphData} stacked={true} />
    )
}
