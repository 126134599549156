import {useState} from "react";
import {useSelector} from "react-redux";

import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import {useMutation, useQuery} from "@apollo/react-hooks";

import {buttonStyles, cellStyles, GlassCard, OnLoadViewer, addressToString, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, ProceedAnywayModal} from "gih_web_common";

import {GET_CHARITY_SITES, ADD_CHARITY_SITE, UPDATE_CHARITY_SITE, DELETE_CHARITY_SITE} from "../../utils/graphql/charity";
import {siteTemplate} from "../../utils/address";

import SiteModal from "../common/siteModal";


export function SitesList() {

    const charity = useSelector(state => state.charity);

    const [editing, setEditing] = useState(null)
    const [warning, setWarning] = useState(null)

    const [deleteSite] = useMutation(DELETE_CHARITY_SITE)
    const [addSite] = useMutation(ADD_CHARITY_SITE)
    const [updateSite] = useMutation(UPDATE_CHARITY_SITE)

    function onDelete(site) {
        setWarning({
            message: `Are you sure you want to delete the address "${site.name}"? Existing activities will NOT be affected.`,
            onDismiss: (proceed) => {
                if (proceed) {
                    return deleteSite({
                        variables: {
                            siteId: site._id
                        }
                    }).then(r => {
                        refetch()
                    })
                } else {
                    return null
                }
            },
        })
    }

    function onDoneSiteModal(values) {

        if (values !== null) {
            let op

            if (values.siteId !== null) {
                op = updateSite({
                    variables: values
                })
            } else {
                op = addSite({
                    variables: {
                        charityId: charity.id,
                        site: values.site
                    }
                })
            }

            return op.then(() => {
                setEditing(null)
                refetch()
            })

        } else {
            setEditing(null)
        }
    }

    const {refetch, loading, data} = useQuery(GET_CHARITY_SITES, {
        variables: {
            charityId: charity.id
        },
    })

    const sites = data?.portalCharitySites;

    return (
        <div>
            <GlassCard width="max-w-screen-2xl">
                <div className="text-2xl font-bold mb-2">
                    Address Book
                </div>
                <OnLoadViewer loading={loading}>
                    { sites &&
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={cellStyles.limitedMedium}>Name</TableCell>
                                    <TableCell sx={cellStyles.address}>Full postal address</TableCell>
                                    <TableCell sx={cellStyles.freeWide}>Place name for search</TableCell>
                                    <TableCell sx={cellStyles.actions}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { sites.map(site => {
                                    return (
                                        <TableRow key={site._id}>
                                            <TableCell align="left">{site.name}</TableCell>
                                            <TableCell align="left">{addressToString(site.address)}</TableCell>
                                            <TableCell align="left">{site.locationName}</TableCell>
                                            <TableCell>
                                                <div className="flex flex-col lg:flex-row gap-2 justify-center">
                                                    <button onClick={() => { setEditing(site) }} className={buttonStyles.altLg}>
                                                        <ModeEditOutlineIcon/> Edit
                                                    </button>
                                                    <button onClick={() => { return onDelete(site) }} className={buttonStyles.redLg}>
                                                        <DeleteForeverIcon/> Delete
                                                    </button>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )})
                                }
                                <TableRow key="Trailer">
                                    <TableCell>
                                        <button onClick={() => { setEditing(siteTemplate()) }} className={buttonStyles.altLg}>
                                            <AddIcon/> Add
                                        </button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    }
                </OnLoadViewer>
            </GlassCard>

            { editing &&
            <SiteModal initial={editing} onComplete={site => onDoneSiteModal(site)} />
            }

            { warning &&
            <ProceedAnywayModal
                message={warning.message}
                onDismiss={ proceed => {
                    warning.onDismiss(proceed)
                    setWarning(null)
                }}
            />
            }
        </div>
    )
}
