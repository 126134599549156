import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";

import {useQuery} from "@apollo/react-hooks";

import {buttonStyles, cellStyles, GlassCard, OnLoadViewer, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, RefreshCell, dateToString} from "gih_web_common";

import {GET_CHARITY_FOLLOWUPS} from "../../../utils/graphql/admin-charity";
import {statuses} from "../../../utils/comments";

import {CharityCell} from "../../common/elements";


export function FollowUps() {

    const dispatch = useDispatch();
    const history = useHistory();

    const {data, refetch} = useQuery(GET_CHARITY_FOLLOWUPS);

    function setCurrentCharity(charity) {
        dispatch({type: "SET_CHARITY", payload: charity});
        history.push('/charity/profile');
    }

    const followUps = data?.portalCharityFollowUps;

    return (
        <GlassCard width="max-w-full">
            <div className="text-2xl font-bold pb-2">
                Pending follow-up actions
            </div>
            <OnLoadViewer loading={followUps === null || followUps === undefined}>
                { followUps &&
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <RefreshCell sx={cellStyles.actions} action={refetch} text="Action" />
                                <TableCell sx={cellStyles.charity}>Charity</TableCell>
                                <TableCell sx={cellStyles.icon}>Status</TableCell>
                                <TableCell sx={cellStyles.dateTime}>Due</TableCell>
                                <TableCell sx={cellStyles.freeWide}>Comment</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { followUps.map(followUp => {
                                const status = statuses[followUp.status]
                                const Icon = status.icon
                                return (
                                    <TableRow key={followUp.charityId._id}>
                                        <TableCell>
                                            <button onClick={() => { setCurrentCharity(followUp.charityId) }} className={buttonStyles.altLgNarrow}>
                                                Go to charity
                                            </button>
                                        </TableCell>
                                        <CharityCell charity={followUp.charityId} />
                                        <TableCell align="center"><Icon size={30} className={`text-${status.colour}-400`}/></TableCell>
                                        <TableCell align="left">{dateToString(followUp.due)}</TableCell>
                                        <TableCell align="left">{followUp.text}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                }
            </OnLoadViewer>
        </GlassCard>
    )
}
