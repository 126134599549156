import {use100vh} from 'react-div-100vh'
import {parseISO} from 'date-fns';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import {Bar, Pie} from "react-chartjs-2";

import {GlassCard, OnLoadViewer} from "gih_web_common";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);


function statsOptions(title, stacked) {
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: `${title}`,
                font: {
                    size: 18,
                }
            },
        },
    }
    if (stacked) {
        options.scales = {
            x: {
                stacked: true,
            },
            y: {
                stacked: true
            }
        }
    }
    return options
}

export const minutesToHours = (min) => {
    let time= min
    let hours = Math.floor(time/60)
    return hours
}

export const notificationTypes = [
    {
        label: "ParticipationUpdate",
        value: "ParticipationUpdate",
        getter: item => item.ParticipationUpdate,
        colour: "#00876c",
    }, {
        label: "ActivityReminder",
        value: "ActivityReminder",
        getter: item => item.ActivityReminder,
        colour: "#71b070",
    }, {
        label: "ActivityUpdate",
        value: "ActivityUpdate",
        getter: item => item.ActivityUpdate,
        colour: "#cad579",
    }, {
        label: "ActivityCancelled",
        value: "ActivityCancelled",
        getter: item => item.ActivityCancelled,
        colour: "#f9c66a",
    }, {
        label: "NewAchievement",
        value: "NewAchievement",
        getter: item => item.NewAchievement,
        colour: "#ee844f",
    }, {
        label: "CampaignUpdate",
        value: "CampaignUpdate",
        getter: item => item.CampaignUpdate,
        colour: "#FF375F",
    }, {
        label: "Other",
        value: "Other",
        getter: item => item.Other,
        colour: "#d43d51",
    },
];

const palettes = [
    [            
        "#00876c", // Green
        "#449c6e",
        "#71b070",
        "#9dc472",
        "#cad579",
        "#fae684",
        "#f9c66a",
        "#f5a658",
        "#ee844f",
        "#e4624e",
        "#d43d51", // Red
    ], [
        "#e85c81", // Pink
        "#f47caf",
        "#fb9ed9",
        "#ffc0fe",
        "#d08edd",
        "#9e60bf",
        "#6834a2",
        "#210c87", // Blue
    ], [
        "#0051ba", // Blue
        "#0072d0",
        "#0092e0",
        "#28b1eb",
        "#64cef5",
        "#98ebff",
        "#8dd3e8",
        "#83bbd0",
        "#78a4b8",
        "#6d8e9f",
        "#617887", // Grey
    ]
];

export const colourScheme = {
    users: "rgb(128, 0, 0)",
    activities: "rgb(0, 128, 0)",
    checkins: "rgb(128, 128, 0)",
    work: "rgb(0, 128, 128)",
    campaign: "#FF9292",
    donationAmount: "#9A4DE3",
    donationFundraiserAmount: "#3099E3",
    donationGiftAidAmount: "#BFC831",
    donationTipAmount: "rgb(128, 128, 0)",
    donationCount: "rgb(0, 128, 128)",
    profitAmount: "rgb(0, 0, 128)",
    percentOfdonations: "rgb(128, 0, 128)",
    percentProfit: "#A9CDFF",
    emailCount: "#005144",
};

export function hash(s) {

    let hash = 0;

    for (let i=0 ; i<s.length; i++) {
        const code = s.charCodeAt(i);
        hash = (((hash << 5) - hash) + code) & 0x7fffffff;
    }

    return hash;
}

export function osToPalette(osName) {
    return  palettes[(osName === 'iOS') ? 0 : 2]
}

export function seriesToColour(series) {

    const eventPalette = palettes[1]
    const osPalette = palettes[2]

    const byName = {
        "Macintosh": osPalette[0],
        "iOS":osPalette[2],
        "Linux": osPalette[4],
        "Chrome OS": osPalette[6],
        "Android": osPalette[8],
        "Windows": osPalette[10],

        "BrowseActivity": eventPalette[0],
        "OpenActivity": eventPalette[2],
        "ExpressedInterest": eventPalette[4],
        "CheckInSuccess": eventPalette[6],
        
        "unrated": "#404040",
        "blue": "#138AFF",
        "bronze": "#B37B0D",
        "silver": "#D3D6DB",
        "gold": "#F2C50A",
    }

    function fallbackColour(text) {
        const h = hash(text)
        const palette = palettes[((h >> 4) & 0xF) % palettes.length]
        return palette[(h & 0xF) % palette.length]
    }

    return byName[series.label] ?? fallbackColour(series.label)
}

export function seriesByTimeToGraphData(r, getSeriesBackground = seriesToColour) {
    return {
        labels: r.times.map(t => parseISO(t).toLocaleDateString("en-GB")),
        datasets: r.series.map((series,index) => {
            return {
                ...series,
                backgroundColor: getSeriesBackground(series)
            }
        })
    }
}

export function seriesByNameToGraphData(r) {
    return {
        labels: r.names,
        datasets: r.series.map((series,index) => {
            return {
                ...series,
                backgroundColor: seriesToColour(series)
            }
        })
    }
}

export function seriesByValueToGraphData(r) {
    return {
        labels: r.values,
        datasets: r.series.map((series,index) => {
            return {
                ...series,
                backgroundColor: seriesToColour(series)
            }
        })
    }
}

export function periodToDateFmt(period) {
    if (period === "year") {
        return { year: 'numeric' }
    } else if (period === "month") {
        return { year: 'numeric', month: 'short' }
    } else {
        return undefined
    }
}

export function arrayByTimeToGraphData(r, period, datasetDefns) {
    return {
        labels: r.map(item => parseISO(item.when).toLocaleDateString("en-GB", periodToDateFmt(period))),
        datasets: datasetDefns.filter(d => (d.getter !== null)).map(d => ({
            label: d.label,
            backgroundColor: d.colour,
            data: r.map(item => d.getter(item))
        }))            
    };
}

export function arrayByNameToGraphData(r, datasetDefns) {
    return {
        labels: r.map(item => item.name),
        datasets: datasetDefns.filter(d => (d.getter !== null)).map(d => ({
            label: d.label,
            backgroundColor: d.colour,
            data: r.map(item => d.getter(item))
        }))            
    };
}

export function expandSeries(seriesDefns, data, getter, matcher) {

    const series = [];
    seriesDefns.forEach(defn => {
        if (defn.value !== null) {
            series.push({
                label: defn.label,
                data: data.map(item => {
                    const values = item.counts.find(item => matcher(item, defn.value))
                    return values ? getter(values) : 0
                }),
                backgroundColor: defn.colour
            })
        }
    });

    return series;
}

export function makeDonationDatasets() {
    return [
        {
            label: "Direct donations",
            getter: item => (item.total - item.fundraiserTotal) / 100,
            colour: colourScheme.donationAmount
        }, {
            label: "Fundraiser donations",
            getter: item => item.fundraiserTotal / 100,
            colour: colourScheme.donationFundraiserAmount
        }, {
            label: "GiftAid",
            getter: item => item.giftAidTotal / 100,
            colour: colourScheme.donationGiftAidAmount
        }
    ];
}

export function BarGraph({title, data, stacked=false, tall=false}) {

    const availableHeight = use100vh() - 64 - 64;
    const height = tall ? 800 : 600;
    const maxHeight = (tall || availableHeight < 800) ? availableHeight : (availableHeight / 2);

    return (
        <GlassCard width="min-w-[350px] max-w-[800px] w-full" style={{
            height: `${height}px`,
            maxHeight: `${maxHeight}px`
        }}>
            <OnLoadViewer loading={!data}>
                { data && data.labels.length > 0 &&
                <div className="h-full w-full bg-bg-paper text-fg-default rounded-lg">
                    <Bar
                        options={statsOptions(title, stacked)}
                        data={data}
                    />
                </div>
                }
                { data && data.labels.length === 0 &&
                <div className="flex flex-col w-full h-full items-center justify-center border border-table-border bg-bg-paper text-fg-default rounded-lg p-2">
                    <div className="italic">{title}</div>
                    <div className="font-bold">No data</div>
                </div>
                }
            </OnLoadViewer>
        </GlassCard>
    );
}

function pieChartColourMapper(labels) {
    return [ 0, 0, 0, 0, 1, 1, 1, 1].map((v, i) => palettes[v][(i%4)*2]);
}

export function PieChart({title, labels, values, size, palette=pieChartColourMapper()}) {

    const data = {
        labels: labels,
        datasets: [{
            label: title,
            data: values,
            backgroundColor: palette,
        }],
    };

    return (
        <div className="flex items-center px-1 py-2 rounded-lg bg-bg-paper" style={{ height: size, maxHeight: size, width: size, maxWidth: size }}>
            <Pie data={data} options={statsOptions(title, false)} />
        </div>
    );
}
