import {useState} from "react";
import {useSelector} from "react-redux";

import Switch from '@mui/material/Switch';
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";

import {useLazyQuery} from "@apollo/react-hooks";

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";

import {GlassCard, GroupBox, VideoPosterPreview, FormWrapper, FormInput, FormTextarea, FormStickyPrimaryButton, ProceedAnywayModal, TagInput} from "gih_web_common";
import "gih_web_common/dist/general/date-picker/datepicker.css";

import {CHECK_KEYWORD} from "../../../utils/graphql/basic";
import {string2DateOrNull} from "../../../utils/date";
import {logFormValidationFailure, logFileSuccess, logFileFailure} from "../../../utils/analytics";
import {FORMS, FILES} from "../../../utils/analyticsConstants";

import {LocationSearch, noPlace, getPlace} from "../../common/locationSearch"
import {LocationsList} from "../../common/locationList"
import {TitleAndBackButton, VideoFileSelector, PhotoFileSelector, FormGroup} from "../../common/elements";
import {asSiteOption, SiteSelector} from "../../common/siteSelector";

import {validate} from "./helpers";
import {CampaignPreview} from "./preview";


function getFormValues(initial) {
    return {
        type:               initial.campaign.type,
        name:               initial.campaign.name,
        fullDescription:    initial.campaign.fullDescription,
        line1:              initial.campaign.address?.line1 ?? "",
        line2:              initial.campaign.address?.line2 ?? "",
        streetName:         initial.campaign.address?.streetName,
        townCity:           initial.campaign.address?.townCity,
        postcode:           initial.campaign.address?.postcode,
        target:             initial.campaign.target / 100,
    }
}

export const CampaignDetailsForm = (props) => {

    const charity = useSelector(state => state.charity);
    const imageURLPrefix = useSelector(state => state.cfg?.imageURLPrefix);
    const campaignCfg = useSelector(state => state.cfg?.campaign);

    const [warning, setWarning] = useState(null);
    const [chosenSite, setChosenSite] = useState(asSiteOption("-", props.initial.campaign));
    const [formValues, setFormValues] = useState(getFormValues(props.initial));
    const [selectedLocation, setSelectedLocation] = useState((props.initial.campaign.places.length > 0) ? getPlace(props.initial.campaign.places[0]) : noPlace());
    const [hiddenFlag, setHiddenFlag] = useState(props.initial.campaign.hidden);
    const [endDate, setEndDate] = useState(string2DateOrNull(props.initial.campaign.endDate));
    const [type, setType] = useState(props.initial.campaign.type);
    const [workLocation, setWorkLocation] = useState(props.initial.campaign.workLocation);
    const [places, setPlaces] = useState(props.initial.campaign.places.map(place => getPlace(place)));

    const [imageFile, setImageFile] = useState(null);
    const [imageLocalURL, setImageLocalURL] = useState(null);

    const [videoFile, setVideoFile] = useState(null);
    const [videoLocalURL, setVideoLocalURL] = useState(null);
    const [videoPosterTime, setVideoPosterTime] = useState(props.initial.campaign.videoPosterTime);

    const [keywords, setKeywords] = useState(props.editing === true ? props.initial.campaign.keywords : props.initial.charity.keywords);
    const [checkKeyword] = useLazyQuery(CHECK_KEYWORD);

    async function getValues() {

        return {
            ...formValues,
            chosenSite: chosenSite,
            imageFile: imageFile,
            videoFile: videoFile,
            videoPosterTime: videoPosterTime,
            keywords: keywords,
            hidden: hiddenFlag,
            endDate: endDate,
            workLocation: workLocation,
            places: (workLocation === "community") ? places : [ selectedLocation ],
        }
    }

    function onSubmit() {
        return new Promise(async (resolve, reject) => {
            await validate(await getValues(), props.initial.campaign, props.initial.charity, campaignCfg)
                .then((campaign) => {
                    const warningMessage = null
                    if (warningMessage !== null) {
                        setWarning({
                            message: `${warningMessage} - are you sure that this is correct?`,
                            onDismiss: (proceed) => {
                                if (proceed) {
                                    resolve(props.onValidated(campaign))
                                } else {
                                    reject(`${warningMessage} - please check`)
                                }
                            }
                        })
                    } else {
                        resolve(props.onValidated(campaign))
                    }
                })
                .catch(e => {
                    logFormValidationFailure(FORMS.campaign, e);
                    reject(e);
                })
        })
    }

    function onSelectVideo(file) {
        try {
            setVideoPosterTime(null);
            setVideoFile(file);
            setVideoLocalURL(URL.createObjectURL(file));
            logFileSuccess(FILES.campaignVideo, file);
        } catch (e) {
            logFileFailure(FILES.campaignVideo, e);
        }
    }

    function onSelectImage(file) {
        try {
            setImageFile(file);
            setImageLocalURL(URL.createObjectURL(file));
            logFileSuccess(FILES.campaignImage, file);
        } catch (e) {
            logFileFailure(FILES.campaignImage, e);
        }
    }

    function getTitle() {
        if (props.editing) {
            return "Edit campaign";
        } else if (charity.awaiting1stCampaign) {
            return "Tell us about your first fundraising campaign!";
        } else {
            return "Create a new campaign";
        }
    }

    return (
        <div className="flex flex-wrap w-fit grid grid-cols-1 space-y-2 xl:grid-cols-[minmax(560px,700px)_760px]">
            <div className="flex flex-col space-y-1">
                <TitleAndBackButton title={getTitle()} />

                <FormGroup colour="stone" title="Where does the work of your organisation take place?">
                    <FormControl>
                        <label id="workLocationRadioButtonGroupLabel" className="text-xs sm:text-sm">Type of place</label>
                        <RadioGroup
                            row
                            aria-labelledby="workLocationRadioButtonGroupLabel"
                            name="workLocationRadioButtonGroup"
                            value={workLocation}
                            onChange={(e) => setWorkLocation(e.target.value)}
                        >
                            <FormControlLabel value="onsite" control={<Radio />} label="at a specific address" />
                            <FormControlLabel value="community" control={<Radio />} label="in one or more communities" />
                            <FormControlLabel value="remote" control={<Radio />} label="anywhere" />
                        </RadioGroup>
                    </FormControl>
                </FormGroup>

                <FormGroup colour="stone" title="What is the purpose of this campaign?">
                    <FormControl>
                        <label id="typeRadioButtonGroupLabel" className="text-xs sm:text-sm">Type of campaign</label>
                        <RadioGroup
                            row
                            aria-labelledby="typeRadioButtonGroupLabel"
                            name="typeRadioButtonGroup"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                        >
                            <FormControlLabel value="project" control={<Radio />} label="for a specific project" />
                            <FormControlLabel value="general" control={<Radio />} label="for my organisation in general" />
                        </RadioGroup>
                    </FormControl>
                </FormGroup>

                <FormWrapper
                    onSubmit={onSubmit}
                    initialValues={getFormValues(props.initial)}
                    className="space-y-2"
                    onValueChange={(values) => { setFormValues(values) }}
                    onValidationFailure={logFormValidationFailure}
                >
                    <FormGroup colour="slate" title="Tell prospective donors...">
                        <FormInput
                            id="name"
                            name="name"
                            length={formValues.name.length}
                            maxLength={campaignCfg.nameMaxLength}
                            type="text"
                            placeholder="name"
                            label="Campaign name"
                            required
                            rootClassName="w-full"
                        />
                        <FormTextarea
                            rows={5}
                            id="fullDescription"
                            name="fullDescription"
                            length={formValues.fullDescription.length}
                            maxLength = {campaignCfg.descriptionMaxLength}
                            type="text"
                            placeholder="description"
                            label="Description"
                            required
                            rootClassName="w-full"
                        />
                        <TagInput
                            id="keywords"
                            label="Keywords"
                            placeholder="Enter a keyword and press return"
                            value={keywords}
                            setValue={setKeywords}
                            findMatches={async hint => {
                                const r = await checkKeyword({ variables: { word: hint }});
                                return r.data.checkKeyword;
                            }}
                            settings={{
                                maxTags: campaignCfg.keywordsMax,
                            }}
                        />
                    </FormGroup>

                    <FormGroup colour="blue" title="How much do you need to raise and by when?">
                        <div className="flex flex-wrap w-full gap-2 items-center">
                            <FormInput
                                id="target"
                                name="target"
                                type="number"
                                placeholder="amount"
                                label="Target"
                                rootClassName="w-full"
                                subText="Total amount you are looking to raise in pounds"
                            />
                            <div className="flex flex-row items-center mb-2">
                                <span className="whitespace-nowrap px-2">ending on</span>
                                <DatePicker
                                    id="endDate"
                                    dateFormat="d MMMM, yyyy"
                                    calendarStartDay={1}
                                    minDate={new Date()}
                                    startDate={endDate}
                                    selected={endDate}
                                    onChange={date => setEndDate(date)}
                                    placeholderText="Select a date"
                                    autoComplete="off"
                                    className="text-fg-default bg-bg-paper"
                                />
                            </div>
                        </div>
                    </FormGroup>

                    { workLocation !== "community" &&
                    <FormGroup colour="green" title={`
                        Where does the work for which you are raising money take place? You can choose the main address
                        from your organisation profile, use an address from your address book or enter an address here.
                        If the your work takes place across a wide area or in various locations, then consider changing
                        the "type of place" to "in one or more communities" so you can list the areas where your services
                        are available.
                    `}>
                        <label className="flex flex-wrap items-center gap-1">
                            <span className="whitespace-nowrap text-xs sm:text-base">for work done at</span>
                            <SiteSelector charity={props.initial.charity} chosenSite={chosenSite} setChosenSite={setChosenSite} />
                        </label>
                        { chosenSite === null &&
                        <div className="ml-3">
                            Address
                            <div className="flex w-full space-x-3 items-end">
                                <FormInput
                                    id="line1"
                                    name="line1"
                                    type="text"
                                    placeholder="Optional 1"
                                    label="Room/building/floor/flat name/number (optional)"
                                    rootClassName="w-full"
                                />
                                <FormInput
                                    id="line2"
                                    name="line2"
                                    type="text"
                                    placeholder="Optional 2"
                                    label="Room/building/floor/flat name/number (optional)"
                                    rootClassName="w-full"
                                />
                            </div>
                            <div className="flex w-full space-x-3 items-end">
                                <FormInput
                                    id="streetName"
                                    name="streetName"
                                    type="text"
                                    placeholder="number & street name"
                                    label="Number & street name"
                                    required
                                    rootClassName="w-full"
                                />
                                <FormInput
                                    id="townCity"
                                    name="townCity"
                                    type="text"
                                    placeholder="town/city"
                                    label="Town / City"
                                    required
                                    rootClassName="w-full"
                                />
                                <FormInput
                                    id="postcode"
                                    name="postcode"
                                    type="text"
                                    placeholder="postcode"
                                    label="Postcode"
                                    required
                                    rootClassName="w-full"
                                />
                            </div>
                            <LocationSearch
                                initial={selectedLocation}
                                onSelectLocation={setSelectedLocation}
                            />
                        </div>
                        }
                    </FormGroup>
                    }

                    { workLocation === "community" &&
                    <FormGroup colour="green" title={`
                        Where does the work for which you are raising money take place? For work in the community you
                        can create a list of places, boroughs, towns, cities or regions.
                    `}>
                        <LocationsList locations={places} setLocations={setPlaces} />
                    </FormGroup>
                    }

                    <FormGroup colour="yellow" title={`
                        Here you can set automated messages of thanks when smaller donations are received. You can also
                        choose to hide the campaign should you so wish (it will still be visible to those who have already
                        donated if any).
                    `}>
                        <label className="flex items-center text-xs sm:text-base">
                            <Switch
                                onChange={e => setHiddenFlag(prevState => !prevState)}
                                color={"primary"}
                                defaultChecked={props.initial.campaign.hidden}
                            />
                            <span className="ml-2">
                                {props.editing ? "Hide this campaign from new donors - it will still be visible to anyone who has already donated" : "Don't show this campaign in searches for now (you can publish it when you're ready by editing the campaign)"}
                            </span>
                        </label>
                    </FormGroup>

                    <FormStickyPrimaryButton>
                        {props.editing ? "Save changes" : "Create"}
                    </FormStickyPrimaryButton>
                </FormWrapper>
            </div>

            <div className="flex flex-wrap gap-1 mx-auto lg:px-1 md:max-w-none w-full sm:w-fit">
                <GlassCard width="w-full sm:max-w-min h-fit space-y-2">
                    <PhotoFileSelector editing={props.initial.campaign.imageURL || imageLocalURL} onSelectFile={onSelectImage} />
                    <GroupBox className="text-sm">
                        Here's a preview to give you an idea of how donors will see your campaign while browsing in
                        the app - click the "Find out more" button to see a preview of the detailed information!
                        Click again to return to the summary.
                    </GroupBox>
                    <CampaignPreview
                        campaign={{
                            ...props.initial.campaign,
                            name: formValues.name,
                            fullDescription: formValues.fullDescription,
                            target: formValues.target * 100,
                            endDate: endDate,
                        }}
                        charity={props.initial.charity}
                        imageLocalURL={imageLocalURL}
                    />
                </GlassCard>
                { (charity.features.allowActivityVideo || props.initial.campaign.videoURL !== null) &&
                <GlassCard width="w-full sm:max-w-min h-fit space-y-2">
                    <VideoFileSelector editing={props.initial.campaign.videoURL || videoLocalURL} onSelectFile={onSelectVideo} />
                    <VideoPosterPreview
                        rmtPrefix={imageURLPrefix}
                        rmt={props.initial.campaign.videoURL}
                        local={videoLocalURL}
                        posterTime={videoPosterTime}
                        setPosterTime={setVideoPosterTime}
                        what="imagePreview"
                        placeholderSeverity="info"
                        placeholderText={true ?
                            "To make a strong connection with donors, we ask you to upload a video for every campaign - up to 30s duration - shown under 'Find out more' when browsing the campaign."
                            : "It's optional, but we strongly recommend adding a video - up to 30s duration - shown under 'Find out more' when browsing the campaign."
                        }
                    />
                </GlassCard>
                }
            </div>

            { warning !== null &&
            <ProceedAnywayModal
                message={warning.message}
                onDismiss={ proceed => {
                    warning.onDismiss(proceed)
                    setWarning(null)
                }}
            />
            }
        </div>
    )
}
