import {useQuery} from "@apollo/react-hooks";

import {RefreshButton, GlassCard, OnLoadViewer, Lozenge} from "gih_web_common";

import { GET_LIVE_COUNTS } from "../../../utils/graphql/admin-charity";
import { statusToText, statusToBackground } from "../../../utils/activity";


export function LiveStatus() {

    const {data, refetch} = useQuery(GET_LIVE_COUNTS);

    const counts = data?.liveCounts;

    return (
        <GlassCard>
            <OnLoadViewer loading={!counts}>
                { counts &&
                <div className="flex flex-col gap-2 w-fit">
                    <div className="flex flex-row items-center">
                        <RefreshButton action={refetch} />
                        <div className="flex flex-col">
                            <InitiativeCount count={counts.campaigns} what="campaigns" />
                            { false && /* VOLUNTEERING */ <InitiativeCount count={counts.activities} what="activities" /> }
                        </div>
                    </div>
                    <div className="px-2 py-1 flex flex-wrap gap-1 items-center bg-bg-secondary rounded-lg text-sm">
                        <span>Charity engagement</span>
                        <span className="flex flex-wrap gap-1">
                            <Lozenge text={`${counts.charities.active} active`} colour="bg-green-600" />
                            <Lozenge text={`${counts.charities.chasing} chasing`} colour="bg-orange-600" />
                            <Lozenge text={`${counts.charities.waiting} waiting`} colour="bg-yellow-600" />
                            <Lozenge text={`${counts.charities.dormant} dormant`} colour="bg-blue-600" />
                            <Lozenge text={`${counts.charities.dead} dead`} colour="bg-gray-600" />
                            <Lozenge text={`${counts.charities.provisional} provisional`} colour="bg-pink-500" />
                        </span>
                    </div>
                    { false && /* VOLUNTEERING */
                    <div className="px-2 py-1 flex flex-wrap gap-1 items-center bg-teal-200 rounded-lg text-sm">
                        <span>Volunteer engagement (expressions of interest)</span>
                        <span className="flex flex-wrap gap-1">
                            { counts.participationsByStatus.map(participations => (
                                <Lozenge key={participations.status} text={`${participations.count} ${statusToText(participations.status).toLowerCase()}`} colour={statusToBackground(participations.status, true)} />
                            ))}
                        </span>
                    </div>
                    }
                </div>
                }
            </OnLoadViewer>
        </GlassCard>
    )
}

function InitiativeCount({count, what}) {
    return (
        <div className="flex flex-wrap text-base sm:text-lg items-center">
            <span><span className="font-bold text-xl sm:text-3xl px-2">{count.geographic}</span> geographic and</span>
            <span><span className="font-bold text-xl sm:text-3xl px-2">{count.remote}</span>non-geographic {what} live in</span>
            <span><span className="font-bold text-xl sm:text-3xl px-2">{count.charities}</span>charities</span>
            <span><span className="font-bold text-xl sm:text-3xl px-2">{count.withVideo}</span>with video</span>
        </div>
    );
}
