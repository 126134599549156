import {gql} from "@apollo/client";

const campaignSummary = `
    _id
    type
    workLocation
    name
    imageURL
    videoURL
    videoPosterTime
    hidden
    address {
        line1
        line2
        streetName
        townCity
        postcode
    }
    places {
        name
        qualifier
        geohash
    }
    dynamicLink
    createdAt
    target
    endDate
    uniqueDonors
    total
`;

const campaignDetail = `
    ${campaignSummary}
    fullDescription
    createdAt
    lastNotifiableUpdate
    createdBy
    keywords
    charityId {
        _id
    }
`;

const campaignUpdate = `
    _id
    createdAt
    type
    postedBy
    heading
    videoName
    imageName
    text
    threshold
`;

export const GET_CAMPAIGN_LIST_BY_CHARITYID = gql`
    query FindCampaignsByCharityId($charityId: ID!) {
        portalFindCampaignsByCharityId(charityId: $charityId) {
            ${campaignSummary}
        }
    }
`;

export const GET_CAMPAIGN_BY_ID = gql`
    query PortalFindCampaignById($campaignId: ID!) {
        portalFindCampaignById(campaignId: $campaignId) {
            ${campaignDetail}
        }
    }
`;

export const ADD_NEW_CAMPAIGN = gql`
    mutation AddCampaign($charityId: ID!, $campaign: CampaignInput!) {
        addCampaign(charityId: $charityId, campaign: $campaign) {
            ${campaignDetail}
        }
    }
`;

export const UPDATE_CAMPAIGN = gql`
    mutation UpdateCampaign($campaignId: ID!, $campaign: CampaignInput!) {
        updateCampaign(id: $campaignId, campaign: $campaign) {
            ${campaignDetail}
        }
    }
`;

export const CANCEL_CAMPAIGN = gql`
    mutation CancelCampaign($campaignId: ID!, $tryDelete: Boolean!, $reason: String) {
        cancelCampaign(id: $campaignId, tryDelete: $tryDelete, reason: $reason) {
            _id
            deleted
            charityId
        }
    }
`;

export const GET_FUNDRAISERS_BY_CAMPAIGN_ID = gql`
    query PortalFindFundraisersByCampaignId($campaignId: ID!) {
        portalFindFundraisersByCampaignId(campaignId: $campaignId) {
            ${campaignDetail}
        }
    }
`;

export const GET_DONATIONS_TO_CAMPAIGN_BY_CREATION_DATE = gql`
     query DonationsToCampaignByCreationDate($campaignId: ID!) {
        donationsToCampaignByCreationDate(campaignId: $campaignId) {
            _id
            createdAt
            firstName
            lastName
            comment
            donation
            giftAid
            fundraiserId {
                _id
                createdBy
                name
            }
        }
    }   
`;

const donationTotals = `
    total
    fundraiserTotal
    giftAidTotal
`;

export const GET_DONATIONS_TO_CAMPAIGN_BY_PERIOD = gql`
     query DonationsToCampaignByPeriod($campaignId: ID!, $start: DateTime!, $end: DateTime!, $period: String!) {
        donationsToCampaignByPeriod(campaignId: $campaignId, start: $start, end: $end, period: $period) {
            when
            ${donationTotals}
        }
    }   
`;

export const GET_DONATIONS_TO_CHARITY_BY_PERIOD = gql`
     query DonationsToCharityByPeriod($charityId: ID!, $start: DateTime!, $end: DateTime!, $period: String!) {
        donationsToCharityByPeriod(charityId: $charityId, start: $start, end: $end, period: $period) {
            when
            ${donationTotals}
        }
    }   
`;

export const GET_DONATIONS_TO_CHARITY_BY_CAMPAIGN = gql`
     query DonationsToCharityByCampaign($charityId: ID!, $start: DateTime!, $end: DateTime!) {
        donationsToCharityByCampaign(charityId: $charityId, start: $start, end: $end) {
            _id
            name
            ${donationTotals}
        }
    }   
`;

export const GET_DONATIONS_TO_CHARITY_GIFTAIDED = gql`
     query DonationsToCharityGiftAided($charityId: ID!, $start: DateTime!, $end: DateTime!) {
        donationsToCharityGiftAided(charityId: $charityId, start: $start, end: $end) {
            title
            firstName
            lastName
            house
            postcode
            lastDonation
            total
        }
    }   
`;

export const GET_GIFTAID_CLAIM_SUBMISSIONS_FOR_CHARITY = gql`
     query GiftAidClaimSubmissionsForCharity($charityId: ID!) {
        giftAidClaimSubmissionsForCharity(charityId: $charityId) {
            _id
            createdAt
            periodEnd
            total
            status
        }
    }   
`;

export const GET_CAMPAIGN_UPDATES = gql`
    query PortalFindCampaignUpdates($campaignId: ID!, $type: String!) {
        portalFindCampaignUpdates(campaignId: $campaignId, type: $type) {
            ${campaignUpdate}
        }
    }
`;

export const ADD_CAMPAIGN_UPDATE = gql`
    mutation AddCampaignUpdate($campaignId: ID!, $update: CampaignUpdateInput!) {
        addCampaignUpdate(campaignId: $campaignId, update: $update) {
            ${campaignUpdate}
        }
    }
`;

export const UPDATE_CAMPAIGN_UPDATE = gql`
    mutation UpdateCampaignUpdate($id: ID!, $update: CampaignUpdateInput!) {
        updateCampaignUpdate(id: $id, update: $update) {
            ${campaignUpdate}
        }
    }
`;

export const DELETE_CAMPAIGN_UPDATE = gql`
    mutation DeleteCampaignUpdate($id: ID!) {
        deleteCampaignUpdate(id: $id)
    }
`;
