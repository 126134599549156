import {DateTime} from "luxon";
import {useState} from "react";

import {useQuery} from "@apollo/react-hooks";

import {DateRangeCard} from "gih_web_common";

import { GET_WEB_REQUEST_SUMMARY_COUNTS } from "../../../utils/graphql/admin-web-stats";

import {invalidateWeb, RequestsByOrigin, RequestsToSiteByOrigin, RequestsToSiteByTargetCampaign} from "./graphs";

export function WebStats() {

    const now = DateTime.now();

    const [startDate, setStartDate] = useState(now.startOf('day').toJSDate());
    const [endDate, setEndDate] = useState(now.endOf('day').toJSDate());

    const [hosts, setHosts] = useState([]);
    const [byCampaignHosts, setByCampaignHosts] = useState([]);

    const perSiteOperation = "InteractionEvent";
    const byCampaignOperation = "FindCampaignById";

    useQuery(GET_WEB_REQUEST_SUMMARY_COUNTS, {
        variables: {
            start: startDate,
            end: endDate,
            operation: perSiteOperation,
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setHosts(data.webRequestSummaryCounts.map(site => site.host)),
    });

    useQuery(GET_WEB_REQUEST_SUMMARY_COUNTS, {
        variables: {
            start: startDate,
            end: endDate,
            operation: byCampaignOperation,
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setByCampaignHosts(data.webRequestSummaryCounts.map(site => site.host)),
    });

    return (
        <div className="mx-auto max-w-[1650px] space-y-2">
            <DateRangeCard
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                onRefresh={invalidateWeb}
                unit="day"
            />

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <RequestsByOrigin start={startDate} end={endDate} operation="GetConfig" />
                
                { hosts.map(host =>
                    <RequestsToSiteByOrigin key={host} start={startDate} end={endDate} operation={perSiteOperation} host={host} />
                )}

                { byCampaignHosts.map(host =>                
                    <RequestsToSiteByTargetCampaign key={host} start={startDate} end={endDate} operation={byCampaignOperation} host={host} />
                )}
            </div>
        </div>
    );
}
