import {useState} from "react";

import {buttonStyles, ModalForm, FormInput, FormPrimaryButton} from "gih_web_common";

import {logFormValidationFailure} from "../../utils/analytics";
import {getAddress} from  "../../utils/address";

import {LocationSearch} from "./locationSearch";


function getFormValues(initial) {
    return {
        name:       initial.name,
        line1:      initial.address.line1 ?? "",
        line2:      initial.address.line2 ?? "",
        streetName: initial.address.streetName,
        townCity:   initial.address.townCity,
        postcode:   initial.address.postcode,
    }
}

function getLocation(initial) {
    return {
        geohash: initial.geohash,
        name: initial.locationName
    }
}

export default function SiteModal({initial, onComplete}) {

    const [formValues, setFormValues] = useState(getFormValues(initial));
    const [selectedLocation, setSelectedLocation] = useState(getLocation(initial));

    function getValues() {

        return {
            siteId: initial._id,
            site: {
                name: formValues.name,
                address: getAddress(formValues),
                geohash: selectedLocation.geohash,
                locationName: selectedLocation.name
            }
        }
    }

    function onSubmit() {
        return new Promise(async (resolve, reject) => {
            if (!selectedLocation.geohash) {
                reject("Please specify a place for location based searches")
            } else {
                resolve(onComplete(getValues()))
            }
        })
    }

    return (
        <ModalForm
            title={initial._id ? "Edit address book entry" : "New address book entry"}
            onDismiss={() => onComplete(null)}
            onSubmit={onSubmit}
            initialValues={getFormValues(initial)}
            onValueChange={values => setFormValues(values)}
            onValidationFailure={logFormValidationFailure}
            footer={
                <>
                    <FormPrimaryButton type="submit">
                        {initial._id ? "Save changes": "Add"}
                    </FormPrimaryButton>
                    <button className={buttonStyles.redLg} type="button" onClick={() => onComplete(null)}>
                        Cancel
                    </button>
                </>
            }
        >
            <div className="flex w-full space-x-3">
                <FormInput
                    id="name"
                    name="name"
                    type="text"
                    placeholder="name"
                    label="Your name for this location"
                    required
                    rootClassName="w-full"
                />
            </div>
            <div className="flex w-full space-x-3 items-end">
                <FormInput
                    id="line1"
                    name="line1"
                    type="text"
                    placeholder="Optional 1"
                    label="Room/building/floor/flat name/number (optional)"
                    rootClassName="w-full"
                />
                <FormInput
                    id="line2"
                    name="line2"
                    type="text"
                    placeholder="Optional 2"
                    label="Room/building/floor/flat name/number (optional)"
                    rootClassName="w-full"
                />
            </div>
            <div className="flex w-full space-x-3 items-end">
                <FormInput
                    id="streetName"
                    name="streetName"
                    type="text"
                    placeholder="number & street name"
                    label="Number & street name"
                    required
                    rootClassName="w-full"
                />
                <FormInput
                    id="townCity"
                    name="townCity"
                    type="text"
                    placeholder="town/city"
                    label="Town / City"
                    required
                    rootClassName="w-full"
                />
                <FormInput
                    id="postcode"
                    name="postcode"
                    type="text"
                    placeholder="postcode"
                    label="Postcode"
                    required
                    rootClassName="w-full"
                />
            </div>
            <LocationSearch
                initial={getLocation(initial)}
                onSelectLocation={setSelectedLocation}
            />
        </ModalForm>
    )
}
