import {useEffect, useState} from 'react'
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {use100vh} from 'react-div-100vh'

import IosShareIcon from '@mui/icons-material/IosShare';

import {useQuery} from "@apollo/react-hooks";

import {buttonStyles, GlassPreviewCard, GroupBox, OnLoadViewer, addressToString, placesToString} from "gih_web_common";

import {logScreenViewEvent} from "../../utils/analytics";
import {SCREEN_NAME, SCREEN_CLASS} from "../../utils/analyticsConstants";
import {GET_ACTIVITY_LIST_BY_CHARITYID} from "../../utils/graphql/activity";
import {groupActivities, workLocation2Text} from "../../utils/activity";
import {rruleToText, fmtNextOccurrence} from "../../utils/repetition";

import {truncate} from "../common/style";

import {Badge} from "./badge";


export function ActivityCards({group}) {

    const height = use100vh();

    const charity = useSelector(state => state.charity);
    const imageURLPrefix = useSelector(state => state.cfg?.imageURLPrefix);

    const [activities, setActivities] = useState(groupActivities(null));
    const [linkCopied, setLinkCopied] = useState(null);

    const {loading: activityListLoading} = useQuery(GET_ACTIVITY_LIST_BY_CHARITYID, {
        variables: { charityId: charity.id},
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setActivities(groupActivities(data.portalFindActivitiesByCharityId)),
    });

    useEffect(() => {
        logScreenViewEvent(SCREEN_NAME.activityList, SCREEN_CLASS.activity)
    }, [])

    return (
        <OnLoadViewer loading={activityListLoading}>
            <div className="w-full py-4 overflow-y-auto" style={{ maxHeight: `${height - 64 - 64}px`}}>
                <div className="p-1 lg:p-4 mx-auto w-fit grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 max-w-screen-2xl">
                    { activities[group].map(activity => (
                        <GlassPreviewCard
                            key={activity._id}
                            rmtPrefix={imageURLPrefix}
                            rmt={activity.imageURL}
                            height={800}
                            overlay={<Badge activity={activity}/>}
                        >                                
                            <GroupBox className="h-22 text-lg font-bold">
                                {activity.name}
                            </GroupBox>
                            <GroupBox className="h-24 text-sm">
                                {(activity.address !== null) ? addressToString(activity.address) : truncate(placesToString(activity.places), 128)}
                            </GroupBox>
                            <GroupBox className="h-10 text-sm">
                                {fmtNextOccurrence(activity.eventDate, activity.scheduling).text}
                            </GroupBox>
                            <GroupBox className="h-24 text-sm">
                                {activity.repetition ? rruleToText(activity.repetition, activity.scheduling) : ""}
                            </GroupBox>
                            <GroupBox className="h-10">
                                {workLocation2Text[activity.workLocation]}
                            </GroupBox>

                            <div className="flex flex-row justify-center space-x-4 pt-2">
                                <Link to={`/activity/${activity._id}`} className={buttonStyles.altLg}>
                                    View details
                                </Link>
                                { activity.dynamicLink &&
                                <CopyToClipboard text={activity.dynamicLink} onCopy={() => setLinkCopied(activity.dynamicLink)}>
                                    <button className={buttonStyles.blueLg}>
                                        <IosShareIcon /> {(linkCopied === activity.dynamicLink) ? "Link copied!" : "Get link & share"}
                                    </button>
                                </CopyToClipboard>
                                }
                            </div>
                        </GlassPreviewCard>
                    ))}
                    { activities[group].length < 1 &&
                        <div className={"text-2xl font-semibold p-2"}>No {(group === "upcoming") ? "upcoming" : "past"} activities</div>
                    }
                </div>
            </div>
        </OnLoadViewer>
    )
}
