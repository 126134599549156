import {useState} from "react";
import {useQuery} from "@apollo/react-hooks";

import {dateToStringNoTime} from "gih_web_common";

import {graphQLCache} from "../../../index";

import {arrayByTimeToGraphData, arrayByNameToGraphData, colourScheme, BarGraph} from "../../../utils/stats";

import {
    GET_EMAILS_SENT_BY_PERIOD,
    GET_EMAILS_SENT_BY_POSTCODE,
} from "../../../utils/graphql/admin-charity-register";

export function invalidateMarketing() {
    console.log(`Evicting database marketing stats query results`);

    const queries = [
        "emailsSentByByPeriod",
        "emailsSentByByPostcode",
    ];

    queries.forEach(query => {
        graphQLCache.evict({
            id: "ROOT_QUERY", 
            fieldName: query,
        })
    });

    graphQLCache.gc();
}

export function EmailsSentByPeriod({start, end, period, prefix}) {

    const [graphData, setGraphData] = useState(null);

    const {error} = useQuery(GET_EMAILS_SENT_BY_PERIOD, {
        variables: {
            start: start,
            end: end,
            period: period,
            prefix: prefix,
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData(arrayByTimeToGraphData(data.emailsSentByByPeriod, period, [
            {
                label: "Number of e-mails",
                getter: item => item.count,
                colour: colourScheme.emailCount
            }
        ]))
    })

    return (
        <BarGraph
            title={`E-mails sent per ${period}${(prefix !== null) ? ` to postcode ${prefix}xx` : ""}`}
            data={graphData}
        />
    )
}

export function EmailsSentByPostcode({start, end, prefix}) {

    const [graphData, setGraphData] = useState(null);

    const {error} = useQuery(GET_EMAILS_SENT_BY_POSTCODE, {
        variables: {
            start: start,
            end: end,
            prefix: prefix,
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData(arrayByNameToGraphData(data.emailsSentByByPostcode, [
            {
                label: "Number of e-mails",
                getter: item => item.count,
                colour: colourScheme.emailCount
            }
        ]))
    })

    return (
        <BarGraph
            title={`Postcodes ranked by e-mails sent for period ${dateToStringNoTime(start)} - ${dateToStringNoTime(end)}${(prefix !== null) ? ` to postcode ${prefix}xx` : ""}`}
            data={graphData}
        />
    )
}
