export function Badge({activity}) {

    let text = null

    if (activity.cancelled) {
        text = "Cancelled";
    } else if (activity.full) {
        text = "Full"
    } else if (activity.hidden) {
        text = "Hidden in search"
    }

    return !text ? null :
        <div className="whitespace-nowrap inline px-2 py-1 bg-red-600 display:inline rounded-full items-center text-xs sm:text-sm text-white font-bold">
            {text}
        </div>;
}
