import {useState} from "react";

import {GlassCard, FormWrapper, FormInput} from "gih_web_common";

import {CharityTable} from "./charity-table"


function asNumberOrNull(s) {
    return (s && s.length > 0) ? parseInt(s) : null;
}

export function RegisteredCharities() {

    const [search, setSearch] = useState({ postcode: null, incomeLowerBound: null, incomeUpperBound: null, number: null, offset: 0 });

    return (
        <GlassCard width="w-full max-w-[2048px]">
            <FormWrapper className="space-y-6" onValueChange={(values) => {
                setSearch({
                    postcode: (values.postcode?.length >= 2) ? values.postcode : (values.postcode?.length >= 1) ? (values.postcode + '[0-9]') : null,
                    incomeLowerBound: asNumberOrNull(values.incomeLower),
                    incomeUpperBound: asNumberOrNull(values.incomeUpper),
                    number: asNumberOrNull(values.number),
                    offset: 0,
                    limit: asNumberOrNull(values.limit) ?? 200,
                })
            }}>
                <div className="space-y-2 w-[200px] py-2">
                    <FormInput
                        id="postcode"
                        name="postcode"
                        type="text"
                        placeholder="postcode"
                        label="Postcode prefix"
                        className="rounded-md"
                    />
                    <FormInput
                        id="incomeLower"
                        name="incomeLower"
                        type="number"
                        placeholder="income"
                        label="Income lower bound"
                        className="rounded-md"
                    />
                    <FormInput
                        id="incomeUpper"
                        name="incomeUpper"
                        type="number"
                        placeholder="income"
                        label="Income upper bound"
                        className="rounded-md"
                    />
                    <FormInput
                        id="number"
                        name="number"
                        type="number"
                        placeholder="number"
                        label="Registered charity number"
                        className="rounded-md"
                    />
                    <FormInput
                        id="limit"
                        name="limit"
                        type="number"
                        placeholder="limit"
                        label="Maximum number of results"
                        className="rounded-md"
                    />
                </div>
            </FormWrapper>
            <CharityTable search={search} />
        </GlassCard>
    )
}
