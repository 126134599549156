import { DateTime } from "luxon"


function getEndTime(activity) {
    return DateTime.fromISO(activity.eventDate).plus({minutes: activity.minutes})
}

export function groupActivities(activityList) {

    let groups = {
        "upcoming": [],
        "past": []
    }

    if (activityList !== null) {

        let watershed = DateTime.now()

        activityList.forEach((activity) => {
            let group = ((activity.repetitionActive || getEndTime(activity) > watershed) && !activity.cancelled) ? "upcoming" : "past"
            groups[group].push(activity)
        })

        Object.keys(groups).forEach((group) => {
            let ascending = (group === "upcoming") ? 1 : -1
            groups[group].sort((a, b) => {
                let da = new Date(a.eventDate)
                let db = new Date(b.eventDate)
                if (da < db) return -ascending
                if (da > db) return ascending
                return 0
            })
        })

        console.log('Sorted groups', groups)
    }

    return groups
}

export function isEditable(activity) {
    let watershed = DateTime.now()
    return (activity.repetitionActive || activity.cancelled || getEndTime(activity) > watershed)
}

export function isCancelable(activity) {
    let watershed = DateTime.now()
    return !activity.cancelled && (activity.repetitionActive || getEndTime(activity) > watershed)
}

export const workLocation2Text = {
    onsite: "Onsite",
    community: "In the community",
    hybrid: "Hybrid",
    remote: "Remote",
    residential: "Residential/overnight"
}

const statuses = {
    pending: {
        text: "Pending",
        colour: "lime",
        active: "700",
        inactive: "600"
    },
    waitApplication: {
        text: "Wait apply",
        colour: "cyan",
        active: "600",
        inactive: "400"
    },
    checking: {
        text: "Checking",
        colour: "amber",
        active: "600",
        inactive: "500"
    },
    decline: {
        text: "Rejected",
        colour: "red",
        active: "600",
        inactive: "500"
    },
    accept: {
        text: "Accepted",
        colour: "green",
        active: "600",
        inactive: "500"
    },
    withdrewAccepted: {
        text: "Withdrew",
        colour: "red",
        active: "600",
        inactive: "500"
    },
    withdrewPending: {
        text: "No longer interested",
        colour: "stone",
        active: "500",
        inactive: "300"
    },
    noResponse: {
        text: "No response",
        colour: "red",
        active: "500",
        inactive: "300"
    },
    other: {
        text: null,
        colour: "gray",
        active: "600",
        inactive: "400"
    }
}

export const statusStatsDefns = [
    {
        label: "Pending",
        value: "pending",
        getter: item => item.pending,
        colour: "rgb(128, 128, 128)"
    },
    {
        label: "Waiting application form",
        value: "waitApplication",
        getter: item => 0,
        colour: "rgb(8 145 178)"
    },
    {
        label: "Checks/training",
        value: "checking",
        getter: item => 0,
        colour: "rgb(192, 192, 0)"
    },
    {
        label: "Accepted",
        value: "accept",
        getter: null,
        colour: "rgb(0, 192, 0)"
    },
    {
        label: "Rejected",
        value: "reject",
        getter: item => item.declined,
        colour: "rgb(192, 0, 0)"
    },
    {
        label: "Withdrew while pending",
        value: "withdrewPending",
        getter: item => item.withdrewPending,
        colour: "rgb(64, 64, 64)"
    },
    {
        label: "Withdrew after acceptance",
        value: "withdrewAccepted",
        getter: item => item.withdrewAccepted,
        colour: "rgb(128, 128, 0)"
    },
    {
        label: "Checked in",
        value: null,
        getter: item => item.checkedIn,
        colour: "rgb(0, 192, 192)"
    },
    {
        label: "No show",
        value: null,
        getter: item => item.noShow,
        colour: "rgb(192, 128, 0)"
    },
    {
        label: "Volunteer did not reply",
        value: "noResponse",
        getter: item => 0,
        colour: "#EE6C00"
    }
]

function status2Scheme(status) {
    return statuses[status] ?? statuses['other']
}

export function statusToText(status) {
    return status2Scheme(status).text ?? `??? ${status}`
}

export function statusToBackground(status, active) {
    const scheme = status2Scheme(status)
    return active ? `bg-${scheme.colour}-${scheme.inactive} hover:bg-${scheme.colour}-${scheme.active}` : `bg-${scheme.colour}-${scheme.active}`
}

export function filterToColour(status, active, filter) {
    if (filter === null || filter === status) {
        return `text-white border border-white ${statusToBackground(status, active)}`
    } else {
        const scheme = status2Scheme(status)
        if (active) {
            return `text-${scheme.colour}-500 border border-${scheme.colour}-500 bg-white hover:text-white hover:bg-${scheme.colour}-${scheme.active}`
        } else {
            return `text-${scheme.colour}-500 border border-${scheme.colour}-500 bg-white`
        }
    }
}

export function nullCounts() {
    return {
        pending: 0,
        waitApplication: 0,
        checking: 0,
        accept: 0,
        decline: 0,
        withdrewAccepted: 0,
    }
}

export function noVolunteers(counts) {
    return counts.pending === 0
        && counts.waitApplication === 0
        && counts.checking === 0
        && counts.accept === 0
        && counts.decline === 0
        && counts.withdrewAccepted === 0
}

export function updateCounts(activity, status, delta) {
    if (status === "pending") {
        activity.participations.pending += delta
    } else if (status === "waitApplication") {
        activity.participations.waitApplication += delta
    } else if (status === "noResponse") {
        // Not counted
    } else if (status === "checking") {
        activity.participations.checking += delta
    } else if (status === "accept") {
        activity.participations.accept += delta
    } else if (status === "decline") {
        activity.participations.decline += delta
    } else if (status === "withdrewPending") {
        // Not counted
    } else if (status === "withdrewAccepted") {
        activity.participations.withdrewAccepted += delta
    }
}

export function canAccept(status) {
    return status === "pending"
        || status === "checking"
        || status === "waitApplication"
        || status === "decline"
}

export function canDecline(status) {
    return status === "pending"
        || status === "checking"
        || status === "waitApplication"
        || status === "accept"
}

export function canWaitApplication(status) {
    return status === "pending"
        || status === "noResponse"
        || status === "checking"
        || status === "decline"
}

export function canCheckOrTrain(status) {
    return status === "pending"
        || status === "noResponse"
        || status === "waitApplication"
        || status === "accept"
        || status === "decline"
}

export function canNoResponse(status) {
    return status === "pending"
        || status === "checking"
        || status === "waitApplication"
        || status === "accept"
}

export function getButtons(activity, participationStatus, charity) {
    let buttons = []
    if (isEditable(activity)) {
        if (canAccept(participationStatus)) {
            buttons.push({
                title: "Insert accept message",
                msg: charity.defaultAcceptMessage ? charity.defaultAcceptMessage : "Your request to participate in this activity has been accepted. Please feel free to join us!",
            })
        }
        if (canDecline(participationStatus)) {
            buttons.push({
                title: "Insert reject message",
                msg: charity.defaultDeclineMessage ? charity.defaultDeclineMessage : "Sorry, we're not able to accept your offer of help at present, but do keep an eye on upcoming activities with our charity!",
            })
        }
    }
    return buttons
}
