import ReCAPTCHA from "react-google-recaptcha-enterprise";

import {useState, useRef} from "react";
import {useSelector} from "react-redux";
import Portal from 'react-overlays/Portal';

import {useMutation} from "@apollo/react-hooks";

import {GlassCard, LocalLink, FormWrapper, FormErrorText, FormInput, FormPrimaryButton, AlertModal} from 'gih_web_common';
import Logo from 'gih_web_common/assets/gih-logo-1818x268.png';

import {SELF_SIGNUP} from "../../utils/graphql/user";
import {logActionSuccess, logActionGraphQLFailure, logActionUserError, logActionFailed, logFormValidationFailure} from "../../utils/analytics";
import {ACTIONS} from "../../utils/analyticsConstants";

import {SelfSignUpProgress} from "../common/steps";


export default function SelfSignUp() {

    const selfSignupForGiving = useSelector(state => state.cfg?.selfSignupForGiving);

    const recaptchaRef = useRef();
    const [outcome, setOutcome] = useState(null);
    const [lastEmail, setLastEmail] = useState(null);
    const [sendVerificationEmail] = useMutation(SELF_SIGNUP);

    async function onSubmit(values) {
        return new Promise((resolve, reject) => {
            const toEmail = values.email.trim().toLowerCase();
            recaptchaRef.current.executeAsync()
            .then(token => {
                return sendVerificationEmail({
                    variables: {
                        email: toEmail,
                        name: values.name.trim(),
                        token: token
                }})
            }).then(r => {
                const result = r.data.selfSignUp
                switch (result) {
                    case "invited":
                    case "reinvited":
                        logActionSuccess(ACTIONS.userSendSelfSignupEmail, `for ${toEmail} with result ${result}`);
                        setOutcome({
                            type: "success",
                            message: `We've sent a verification e-mail to ${toEmail}`,
                            description: `If it doesn't arrive within the next few minutes, please check your spam folder. Press the "Complete signup" button in the e-mail to complete signing up your organisation. Thanks!`
                        });
                        setLastEmail(toEmail);
                        resolve("Success");
                        break;

                    case "notinvited":
                        logActionUserError(ACTIONS.userSendSelfSignupEmail, `Invite not sent - e-mail may already be signed up for ${toEmail}`);
                        setOutcome({
                            type: "warning",
                            message: `Could not send invite to ${toEmail}`,
                            description: `That address may already be connected to another organisation.`
                        });
                        reject("Failure");
                        break;

                    default:
                        logActionFailed(ACTIONS.userSendSelfSignupEmail, `Unexpected result ${result} for ${toEmail}`);
                        reject("Failure");
                        break;
                }
            }, e => {
                logActionGraphQLFailure(ACTIONS.userSendSelfSignupEmail, e);
                if (e.message.includes("Bad Request (400)")) {
                    setOutcome({
                        type: "warning",
                        message: `"${toEmail}" does not appear to be a valid e-mail address`
                    });
                } else {
                    setOutcome({
                        type: "warning",
                        message: `Failed to send e-mail - please try again later`
                    });
                }
                reject(e);
            });
        });
    }

    return (
        <div className="min-h-screen flex flex-col justify-center items-center space-y-2 p-1 lg:p-4">
            <GlassCard>
                <SelfSignUpProgress activeStep={0} />
            </GlassCard>
            <GlassCard width="max-w-lg">
                <div className="flex justify-center mb-6">
                    <img src={Logo} alt="" />
                </div>
                <div>
                    <div className="py-2 space-y-3">
                        <h1 className="text-2xl sm:text-3xl font-semibold">
                            Create a profile for your charity or non-profit organisation
                        </h1>
                        <h2 className="text-base sm:text-xl font-semibold">
                            Enter the name of your organisation and the main e-mail address you'll be using to administer your {selfSignupForGiving ? "fundraising campaigns" : "volunteering opportunities"}.
                        </h2>
                        <h3 className="text-sm">
                            (you can invite as many other admins as you wish once you've created a profile for your organisation)
                        </h3>
                    </div>
                    <div className="mt-2 mb-1 text-sm">
                        Already registered? {" "}
                        <LocalLink to="/sign-in">
                            Click to sign in
                        </LocalLink>
                    </div>
                    <FormWrapper
                        onSubmit={onSubmit}
                        onValueChange={() => setLastEmail(null)}
                        onValidationFailure={logFormValidationFailure}
                        className="space-y-3"
                    >
                        <div className="space-y-2">
                            <FormInput
                                id="name"
                                name="name"
                                type="text"
                                placeholder="organisation name"
                                label="Organisation name"
                                required
                            />
                            <FormInput
                                id="email-address"
                                name="email"
                                type="email"
                                autoComplete="email"
                                placeholder="e-mail address"
                                label="E-mail address"
                                className="rounded-md"
                                required
                            />
                        </div>
                        <Portal>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                size="invisible"
                                sitekey="6LcS7ZUlAAAAADqpgqwCAJi1umJehqwZJK1wsZ0-"
                                action="self-signup"
                            />
                        </Portal>
                        <div className="mx-auto space-y-2">
                            <FormErrorText/>
                            <FormPrimaryButton type="submit">
                                {(lastEmail !== null) ? "Resend verification e-mail" : "Sign up"}
                            </FormPrimaryButton>
                        </div>
                        <div className="flex items-center text-sm">
                            <p>
                                By clicking "Sign up", you agree to our {" "}
                                <LocalLink to="/signup/terms">
                                    Terms & Conditions
                                </LocalLink>
                                {" "}and{" "}
                                <LocalLink to="/signup/privacy">
                                    Privacy Policy
                                </LocalLink>
                            </p>
                        </div>
                    </FormWrapper>
                </div>
            </GlassCard>
            { outcome !== null &&
            <AlertModal
                type={outcome.type}
                message={outcome.message}
                description={outcome.description}
                onDismiss={ () => {
                    setOutcome(null)
                }}
            />
            }
        </div>
    )
}
