import {useState} from "react";
import {useQuery} from "@apollo/react-hooks";
import {parseISO} from 'date-fns';

import { GET_OUTCOMES_BY_PERIOD,
         GET_WORK_BY_PERIOD,
         GET_WORK_BY_CHARITY_BY_PERIOD,
         GET_WORK_BY_USER_BY_PERIOD,
         GET_ACTIVITIES_CREATED_BY_PERIOD,
         GET_PARTICIPATIONS_CREATED_BY_PERIOD,
         GET_PARTICIPATIONS_CREATED_BY_CHARITY_BY_PERIOD,
         GET_PARTICIPATIONS_BY_CHARITY_BY_STATUS,
         GET_CERTIFICATES_CREATED_BY_PERIOD } from "../../../utils/graphql/admin-volunteering";

import {
    BarGraph,
    minutesToHours,
    arrayByTimeToGraphData,
    expandSeries,
    colourScheme,
    seriesToColour,
    periodToDateFmt,
} from "../../../utils/stats";

import {statusStatsDefns} from "../../../utils/activity";

import {graphQLCache} from "../../../index";

import {truncate} from "../../common/style";


export function invalidateVolunteering(advanced) {
    console.log(`Evicting database volunteering stats query results with advanced=${advanced}`);

    const queries = advanced ? [
        "outcomesByPeriod",
        "participationsCreatedByPeriod",        
    ] : [
        "usersCreatedByPeriod",
        "activitiesCreatedByPeriod",
        "workByPeriod",
        "workByCharityByPeriod",
        "workByUserByPeriod",
        "participationsCreatedByCharityByPeriod",
    ];

    queries.forEach(query => {
        graphQLCache.evict({
            id: "ROOT_QUERY", 
            fieldName: query,
        })
    });
    
    graphQLCache.gc();
}


function makeSeriesDefns(data) {

    const seriesById = new Map()

    data.forEach(item => {
        item.counts.forEach(count => {
            seriesById.set(count.entityId, count.name)
        })
    })

    const seriesDefns = []

    seriesById.forEach((name, entityId) => {
        seriesDefns.push({
            label: name,
            value: entityId,
            colour: seriesToColour({ label: name })
        })
    })

    seriesDefns.sort((a, b) => {
        if (a.label > b.label) return 1
        if (a.label < b.label) return -1
        return 0
    })

    return seriesDefns
}


export function OutcomesByPeriod({start, end, period}) {

    const [graphData, setGraphData] = useState(null);

    const {error} = useQuery(GET_OUTCOMES_BY_PERIOD, {
        variables: {
            start: start,
            end: end,
            period: period
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData(arrayByTimeToGraphData(data.outcomesByPeriod, period, statusStatsDefns))
    })

    return (
        <BarGraph title={`Activity outcomes by ${period}`} data={graphData} stacked={true} />
    )
}


export function WorkByPeriod({start, end, period}) {

    const [graphData, setGraphData] = useState(null);

    const {error} = useQuery(GET_WORK_BY_PERIOD, {
        variables: {
            start: start,
            end: end,
            period: period
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData(arrayByTimeToGraphData(data.workByPeriod, period, [
            {
                label: "Number of checkins",
                getter: item => item.checkins,
                colour: colourScheme.checkins
            },
            {
                label: "Total work (hours)",
                getter: item => minutesToHours(item.minutesWorked),
                colour: colourScheme.work
            },                    
        ]))
    })

    return (
        <BarGraph title={`Volunteering work done by ${period}`} data={graphData} />
    )
}


export function WorkByCharityPeriod({start, end, period}) {

    const [graphData, setGraphData] = useState(null);

    const {error} = useQuery(GET_WORK_BY_CHARITY_BY_PERIOD, {
        variables: {
            start: start,
            end: end,
            period: period
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            const result = data.workByCharityByPeriod
            setGraphData({
                labels: result.map(item => parseISO(item.when).toLocaleDateString("en-GB", periodToDateFmt(period))),
                datasets: expandSeries(makeSeriesDefns(result), result, values => minutesToHours(values.minutesWorked), (item, value) => item.entityId === value)
            })
        }
    })

    return (
        <BarGraph title={`Work by charity by ${period}`} data={graphData} stacked={true} />
    )
}


export function WorkByUserPeriod({start, end, period}) {

    const [graphData, setGraphData] = useState(null);

    const {error} = useQuery(GET_WORK_BY_USER_BY_PERIOD, {
        variables: {
            start: start,
            end: end,
            period: period
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            const result = data.workByUserByPeriod
            setGraphData({
                labels: result.map(item => parseISO(item.when).toLocaleDateString("en-GB", periodToDateFmt(period))),
                datasets: expandSeries(makeSeriesDefns(result), result, values => minutesToHours(values.minutesWorked), (item, value) => item.entityId === value)
            })
        }
    })

    return (
        <BarGraph title={`Work by volunteer by ${period}`} data={graphData} stacked={true} />
    )
}


export function ActivitiesCreatedByPeriod({start, end, period}) {

    const [graphData, setGraphData] = useState(null);

    const {error} = useQuery(GET_ACTIVITIES_CREATED_BY_PERIOD, {
        variables: {
            start: start,
            end: end,
            period: period
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData(arrayByTimeToGraphData(data.activitiesCreatedByPeriod, period, [
            {
                label: "Number of activities",
                getter: item => item.count,
                colour: colourScheme.activities
            }
        ]))
    })

    return (
        <BarGraph title={`Activities created per ${period}`} data={graphData} />
    )
}


export function InterestByPeriod({start, end, period}) {

    const [graphData, setGraphData] = useState(null);

    const {error} = useQuery(GET_PARTICIPATIONS_CREATED_BY_PERIOD, {
        variables: {
            start: start,
            end: end,
            period: period
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            const result = data.participationsCreatedByPeriod
            setGraphData({
                labels: result.map(item => parseISO(item.when).toLocaleDateString("en-GB", periodToDateFmt(period))),
                datasets: expandSeries(statusStatsDefns, result, values => values.count, (item, value) => item.status === value)
            })
        }
    })

    return (
        <BarGraph title={`Expressions of interest by status by ${period}`} data={graphData} stacked={true} />
    )
}


export function InterestByCharityPeriod({start, end, period}) {

    const [graphData, setGraphData] = useState(null);

    const {error} = useQuery(GET_PARTICIPATIONS_CREATED_BY_CHARITY_BY_PERIOD, {
        variables: {
            start: start,
            end: end,
            period: period
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            const result = data.participationsCreatedByCharityByPeriod
            setGraphData({
                labels: result.map(item => parseISO(item.when).toLocaleDateString("en-GB", periodToDateFmt(period))),
                datasets: expandSeries(makeSeriesDefns(result), result, values => values.count, (item, value) => item.entityId === value)
            })
        }
    })

    return (
        <BarGraph title={`Expressions of interest by charity by ${period}`} data={graphData} stacked={true} />
    )
}


export function InterestByCharityByStatus() {

    const [graphData, setGraphData] = useState(null);

    const {error} = useQuery(GET_PARTICIPATIONS_BY_CHARITY_BY_STATUS, {
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            const result = data.participationsByCharityByStatus
            setGraphData({
                labels: result.map(item => truncate(item.name, 24)),
                datasets: expandSeries(statusStatsDefns, result, values => values.count, (item, value) => item.status === value)
            })
        }
    })

    return (
        <BarGraph title={`Volunteers by charity by status`} data={graphData} stacked={true} />
    )
}


const certDefns = [
    {
        label: "Bronze",
        value: 20,
        colour: "#a05822"
    },
    {
        label: "Silver",
        value: 50,
        colour: "#c0c0c0"
    },
    {
        label: "Gold",
        value: 100,
        colour: "#ffd700"
    },
]

export function CertificatesByPeriod({start, end, period}) {

    const [graphData, setGraphData] = useState(null);

    const {error} = useQuery(GET_CERTIFICATES_CREATED_BY_PERIOD, {
        variables: {
            start: start,
            end: end,
            period: period
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            const result = data.certificatesByTypeByPeriod
            setGraphData({
                labels: result.map(item => parseISO(item.when).toLocaleDateString("en-GB", periodToDateFmt(period))),
                datasets: expandSeries(certDefns, result, values => values.count, (item, value) => item.hours === value)
            })
        }
    })

    return (
        <BarGraph title={`Certificates by type by ${period}`} data={graphData} stacked={true} />
    )
}
