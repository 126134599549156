import {useState} from "react";

import Switch from '@mui/material/Switch';

import {ActiveCampaigns, CampaignsSummary} from "./campaigns";
import {DonationsRecentlyCreated, DonationsSummary} from "./donations";

import {TitledCard, IntervalSelector, getDefaultInterval} from 'gih_web_common';


export function Dashboard() {

    const [showIncompleteDonations, setShowIncompleteDonations] = useState(false);
    const [dateRange, setDateRange] = useState(getDefaultInterval());

    return (
        <div className="space-y-3 max-w-[2048px] mx-auto">
            <TitledCard title="Recent donations">
                <div className="flex flex-wrap items-center gap-3">
                    <label className="flex items-center text-xs sm:text-base">
                        <Switch
                            onChange={e => setShowIncompleteDonations(prevState => !prevState)}
                            color="primary"
                            defaultChecked={false}
                        />
                        <span>Show incomplete donations</span>
                    </label>

                    <IntervalSelector setDateRange={setDateRange} />
                </div>
                <DonationsSummary dateRange={dateRange}/>
                <DonationsRecentlyCreated showIncomplete={showIncompleteDonations} dateRange={dateRange} />
            </TitledCard>

            <TitledCard title="Active campaigns">
                <CampaignsSummary/>
                <ActiveCampaigns/>
            </TitledCard>
        </div>
    );
}
