import BedtimeIcon from '@mui/icons-material/Bedtime';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import CelebrationIcon from '@mui/icons-material/Celebration';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';

export const statuses = {
    dead: {
        icon: BlockOutlinedIcon,
        colour: "red"
    },
    dormant: {
        icon: BedtimeIcon,
        colour: "gray"
    },
    chasing: {
        icon: DirectionsRunIcon,
        colour: "yellow"
    },
    waiting: {
        icon: HourglassBottomIcon,
        colour: "yellow"
    },
    active: {
        icon: CelebrationIcon,
        colour: "green"
    }
}

export const statusOptions = [
    { label: "---", value: "" },
    { label: "Dead", value: "dead" },
    { label: "Dormant", value: "dormant" },
    { label: "Chasing", value: "chasing" },
    { label: "Waiting", value: "waiting" },
    { label: "Active", value: "active" }
]

