import {DateTime} from "luxon";
import {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {use100vh} from "react-div-100vh";

import {DateRangeCard, monthStart, monthEnd} from "gih_web_common";

import {logScreenViewEvent} from "../../utils/analytics";
import {SCREEN_NAME, SCREEN_CLASS} from "../../utils/analyticsConstants";

import {HoursWorkedForCharityByPeriod,
        HoursWorkedForCharityByActivity,
        HoursWorkedForCharityByVolunteer} from "./volunteerGraphs"


export default function CharityVolunteerStats() {

    const height = use100vh();
    const charity = useSelector(state => state.charity);

    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    function haveValidDateRange() {
        return startDate !== null && endDate !== null && endDate >= startDate
    }

    const now = DateTime.now()
    const byMonthStart = now.minus({months: 11}).startOf('month').toJSDate()
    const byYearStart = new Date(2022, 0, 1)
    const end = now.endOf('month').toJSDate()

    useEffect(() => {
        logScreenViewEvent(SCREEN_NAME.charityVolunteerStats, SCREEN_CLASS.charity)
    }, [])

    return (
        <div className="p-1 sm:p-3 overflow-y-auto w-full" style={{ maxHeight: `${height - 64}px`}}>
            <div className="mx-auto max-w-[1650px] w-full space-y-2">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    <HoursWorkedForCharityByPeriod charityId={charity.id} start={byMonthStart} end={end} period="month" />
                    <HoursWorkedForCharityByPeriod charityId={charity.id} start={byYearStart} end={end} period="year" />

                    <HoursWorkedForCharityByActivity charityId={charity.id} start={byMonthStart} end={end} />
                    <HoursWorkedForCharityByVolunteer charityId={charity.id} start={byMonthStart} end={end} />
                </div>
    
                <DateRangeCard
                    label="Choose a range of months to generate custom graphs"
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    unit="month"
                />

                { haveValidDateRange() &&
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    <HoursWorkedForCharityByPeriod charityId={charity.id} start={monthStart(startDate)} end={monthEnd(endDate)} period="month" />

                    <HoursWorkedForCharityByActivity charityId={charity.id} start={monthStart(startDate)} end={monthEnd(endDate)} />
                    <HoursWorkedForCharityByVolunteer charityId={charity.id} start={monthStart(startDate)} end={monthEnd(endDate)} />
                </div>
                }
            </div>
        </div>
    )
}
