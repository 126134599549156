import {useQuery, useMutation} from "@apollo/react-hooks";
import {useSelector} from "react-redux";

import {CustomButton, buttonStyles} from "gih_web_common";

import {GET_CHARITY_PAYOUT_STATUS_BY_ID, CONFIGURE_PAYOUTS} from "../../utils/graphql/charity";

import {Banner} from "../common/hint";


export function StripeBanner() {

    const charity = useSelector(state => state.charity);

    const {data: payoutAccountStatus} = useQuery(GET_CHARITY_PAYOUT_STATUS_BY_ID, {
        variables: { charityId: charity.id },
    });
    
    const [configurePayouts] = useMutation(CONFIGURE_PAYOUTS, {
        variables: {
            charityId: charity.id
        },
        onCompleted: r => {
            window.location.assign(r.configurePayouts)
        }
    })

    return (payoutAccountStatus && payoutAccountStatus.portalCharityPayoutAccountStatus !== 'enabled') ?
        <Banner title="Warning! The Stripe dashboard requires your attention.">
            You need to finish configuring payouts and/or provide any missing information requested
            by Stripe before you can receive any (further) donations.
            <CustomButton className={buttonStyles.primaryLgNarrow} onClick={() => configurePayouts()}>Configure Stripe now</CustomButton>
        </Banner>
    : null;
}
