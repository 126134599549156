import {useState} from "react";
import { useSelector } from "react-redux";

import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import {useMutation, useQuery} from "@apollo/react-hooks";

import {buttonStyles, cellStyles, GlassCard, OnLoadViewer, ImageVCentre, ImagePlaceholder, BrokenMediaPlaceholder, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, dateToString} from "gih_web_common";

import {GET_CAMPAIGN_UPDATES, DELETE_CAMPAIGN_UPDATE} from "../../../utils/graphql/campaign";
import {logActionSuccess, logActionGraphQLFailure} from "../../../utils/analytics";
import {ACTIONS} from "../../../utils/analyticsConstants";

import {AddOrEditUpdate} from "./form";
import {typeOptions, modelUpdate, getAudience} from "./helpers";


export function CampaignUpdates({campaignId, type}) {

    const user = useSelector(state => state.user);
    const imageURLPrefix = useSelector(state => state.cfg?.imageURLPrefix);

    const [current, setCurrent] = useState(null);

    const [deleteUpdate] = useMutation(DELETE_CAMPAIGN_UPDATE);

    function del(updateId) {
        return deleteUpdate({
            variables: {
                id: updateId
            }
        }).then(r => {
            logActionSuccess(ACTIONS.campaignUpdateDelete, `for updateId ${updateId}`);
            refetch();
        }, e => {
            logActionGraphQLFailure(ACTIONS.campaignUpdateDelete, e);
            throw e;
        });
    }

    const {data, refetch} = useQuery(GET_CAMPAIGN_UPDATES, {
        variables: {
            campaignId: campaignId,
            type: type,
        },
        fetchPolicy: "cache-and-network",
    })

    const opts = typeOptions.find(opts => opts.value === type);
    const updates = data?.portalFindCampaignUpdates;

    return (
        <GlassCard width="max-w-full w-full">
            <div className="text-2xl font-bold mb-2">
                {opts.tableName}
            </div>
            <OnLoadViewer loading={updates === null}>
                { updates &&
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={cellStyles.dateTime}>Created</TableCell>
                                { false && <TableCell sx={cellStyles.fixedNarrow}>Type</TableCell> }
                                <TableCell sx={cellStyles.fixedMedium}>Audience</TableCell>
                                <TableCell sx={cellStyles.fixedWide}>Preview</TableCell>
                                <TableCell sx={cellStyles.limitedMedium}>Heading</TableCell>
                                <TableCell sx={cellStyles.freeWide}>Message</TableCell>
                                <TableCell sx={cellStyles.person}>Added by</TableCell>
                                <TableCell sx={cellStyles.actions}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { updates.map(update => {
                                const type = typeOptions.find(option => option.value === update.type)
                                const Icon = type.icon
                                return (
                                    <TableRow key={update._id}>
                                        <TableCell>{dateToString(update.createdAt)}</TableCell>
                                        { false && <TableCell align="left"><Icon fontSize="large" className={type.colour}/></TableCell> }
                                        <TableCell align="left">{getAudience(update)}</TableCell>
                                        <TableCell align="left">
                                            <ImageVCentre
                                                rmtPrefix={imageURLPrefix}
                                                rmt={update.imageName ?? update.videoName}
                                                what={update.imageName ? "thumbnail" : "videoThumbnail"}
                                                NotFoundPlaceholder={update.videoName ? ImagePlaceholder : BrokenMediaPlaceholder}
                                                notFoundSeverity={update.videoName ? "info" : "error"}
                                                notFoundText={update.videoName ? "Generating preview image..." : null}
                                            />
                                        </TableCell>
                                        <TableCell align="left"><span className="text-xs">{update.heading}</span></TableCell>
                                        <TableCell align="left"><span className="text-xs">{update.text}</span></TableCell>
                                        <TableCell align="left">{update.postedBy}</TableCell>
                                        <TableCell>
                                            <div className="flex flex-col lg:flex-row gap-2 justify-center">
                                                <button onClick={() => { setCurrent(update) }} className={buttonStyles.primaryLg}>
                                                    <ModeEditOutlineIcon/> Edit
                                                </button>
                                                <button onClick={() => { return del(update._id) }} className={buttonStyles.redLg}>
                                                    <DeleteForeverIcon/> Delete
                                                </button>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )})
                            }
                            <TableRow>
                                <TableCell>
                                    <button onClick={() => { setCurrent(modelUpdate(campaignId, opts, user)) }} className={buttonStyles.primaryLg}>
                                        <AddIcon/> Add
                                    </button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                }
            </OnLoadViewer>
            { current &&
            <AddOrEditUpdate initial={current} onComplete={
                changed => {
                    setCurrent(null)
                    if (changed) { refetch() }
                }}
            />
            }
        </GlassCard>
    )
}
