import {useState} from "react";
import {useSelector} from "react-redux";

import AddIcon from '@mui/icons-material/Add';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';

import {useMutation} from "@apollo/react-hooks";

import {ModalForm, GroupBox, Video, ImageVCentre, FormInput, FormTextarea, FormPrimaryButton, inputStyles} from "gih_web_common";

import {ADD_CAMPAIGN_UPDATE, UPDATE_CAMPAIGN_UPDATE} from "../../../utils/graphql/campaign";
import {logFileSuccess, logFileFailure, logActionSuccess, logActionGraphQLFailure, logFormValidationFailure} from "../../../utils/analytics";
import {ACTIONS, FILES} from "../../../utils/analyticsConstants";

import {VideoFileSelector, PhotoFileSelector, FormGroup} from "../../common/elements";

import {typeOptions, validate} from "./helpers";


function getFormValues(initial) {
    return {
        postedBy: initial.postedBy,
        heading: initial.heading,
        message: initial.text,
        threshold: initial.threshold?.toString() ?? "1"
    }
}

export function AddOrEditUpdate({initial, onComplete}) {

    const charity = useSelector(state => state.charity);
    const imageURLPrefix = useSelector(state => state.cfg?.imageURLPrefix);
    const campaignCfg = useSelector(state => state.cfg?.campaign);

    const [type, setType] = useState(initial.type);
    const [formValues, setFormValues] = useState(getFormValues(initial));
    const [imageFile, setImageFile] = useState(null);
    const [imageLocalURL, setImageLocalURL] = useState(null);
    const [videoFile, setVideoFile] = useState(null);
    const [videoLocalURL, setVideoLocalURL] = useState(null);

    const [addUpdate] = useMutation(ADD_CAMPAIGN_UPDATE)
    const [updateUpdate] = useMutation(UPDATE_CAMPAIGN_UPDATE)

    async function onSubmit() {

        const update = await validate(getValues(), initial);

        let mutation;
        let action;

        if (initial._id === null) {
            action = ACTIONS.campaignUpdateCreate;
            mutation = addUpdate({
                variables: {
                    campaignId: initial.campaignId,
                    update: update,
                }
            });
        } else {
            action = ACTIONS.campaignUpdateUpdate;
            mutation = updateUpdate({
                variables: {
                    id: initial._id,
                    update: update,
                }
            });
        }

        return mutation
            .then(r => {
                logActionSuccess(action, 'Campaign update');
                onComplete(true);
            }, e => {
                logActionGraphQLFailure(action, e);
                throw e;
            });
    }
    
    function getValues() {
        return {
            ...formValues,
            type: type,
            imageFile: imageFile,
            videoFile: videoFile,
        }
    }

    function onSelectVideo(file) {
        try {
            setVideoFile(file);
            setVideoLocalURL(URL.createObjectURL(file));
            logFileSuccess(FILES.campaignUpdateVideo, file);
        } catch (e) {
            logFileFailure(FILES.campaignUpdateVideo, e);
        }
    }

    function onSelectImage(file) {
        try {
            setImageFile(file);
            setImageLocalURL(URL.createObjectURL(file));
            logFileSuccess(FILES.campaignUpdateImage, file);
        } catch (e) {
            logFileFailure(FILES.campaignUpdateImage, e);
        }
    }

    const opts = typeOptions.find(opts => opts.value === type);

    return (
        <ModalForm
            title={opts.title}
            onDismiss={() => onComplete(false)}
            onSubmit={onSubmit}
            initialValues={getFormValues(initial)}
            onValueChange={values => setFormValues(values)}
            onValidationFailure={logFormValidationFailure}
            footer={
                <FormPrimaryButton type="submit">
                    { (initial._id !== null) ? <span><ModeEditOutlineIcon/> Save changes</span> : <span><AddIcon/> Add</span> }
                </FormPrimaryButton>
            }
        >
            { false &&
            <div className="flex flex-wrap items-center gap-3">
                <span className="whitespace-nowrap">What is this?</span>
                <select value={type} onChange={event => setType(event.target.value)} className={inputStyles.select}>
                    { typeOptions.map(option => <option key={option.value} value={option.value}>{option.label}</option> )}
                </select>
            </div>
            }

            <FormGroup colour="yellow" title="Who are you posting as?">
                <FormInput
                    id="postedBy"
                    name="postedBy"
                    length={formValues.postedBy.length}
                    maxLength={campaignCfg.updatePostedByMaxLength}
                    type="text"
                    placeholder="Posted by..."
                    label="Posted by"
                    required
                    rootClassName="w-full"
                />
            </FormGroup>

            { type === "thanks" &&
            <FormGroup colour="yellow" title="How much does the donor need to give in total in order to receive this thank you message?">
                <div className="flex flex-row items-center">
                    <div className="text-2xl pt-5 pr-2">£</div>
                    <FormInput
                        id="threshold"
                        name="threshold"
                        type="number"
                        placeholder="Amount"
                        label="Amount"
                        required
                        rootClassName="w-full"
                    />
                </div>
            </FormGroup>
            }

            <FormGroup colour="slate" title="What do you want to say?">
                <FormInput
                    id="heading"
                    name="heading"
                    length={formValues.heading.length}
                    maxLength={campaignCfg.updateHeadingMaxLength}
                    type="text"
                    placeholder="Summary heading..."
                    label="Heading"
                    required
                    rootClassName="w-full"
                />
                <FormTextarea
                    rows={5}
                    id="message"
                    name="message"
                    length={formValues.message.length}
                    maxLength={campaignCfg.updateTextMaxLength}
                    type="text"
                    placeholder="Type your update here..."
                    label="Message"
                    required
                    rootClassName="w-full"
                />
            </FormGroup>

            <div className="flex flex-wrap gap-2 mx-auto lg:px-1 md:max-w-none grid grid-cols-1 md:grid-cols-2">
                { (!charity.features.allowActivityVideo || type === "progress") &&
                <GroupBox>
                    <PhotoFileSelector editing={initial.imageName || imageLocalURL} onSelectFile={onSelectImage} />
                    <ImageVCentre
                        rmtPrefix={imageURLPrefix}
                        rmt={initial.imageName}
                        local={imageLocalURL}
                        what="imagePreview"
                        placeholderSeverity="info"
                        placeholderText="Optional photo."
                    />
                </GroupBox>
                }
                { (charity.features.allowActivityVideo || initial.videoName !== null) &&
                <GroupBox>
                    <VideoFileSelector editing={initial.videoName || videoLocalURL} maxSize={100} onSelectFile={onSelectVideo} />
                    <Video
                        rmtPrefix={imageURLPrefix}
                        rmt={initial.videoName}
                        local={videoLocalURL}
                        what="imagePreview"
                        placeholderSeverity={type === "thanks" ? "warning" : "info"}
                        placeholderText={`${type === "thanks" ? "For thank you messages a video is required" : "Optional video"} - up to 30s duration.`}
                    />
                </GroupBox>
                }
            </div>
        </ModalForm>
    )
}
