import {useSelector} from "react-redux";

import {addressToString, Image, phonePreviewStyle, phonePreviewScreenStyle} from "gih_web_common";

import charityPreviewBackground from "../../images/charityMock.png";


export const CharityDetailPreview = ({charity, imageLocalURL, logoLocalURL}) => {

    const imageURLPrefix = useSelector(state => state.cfg?.imageURLPrefix);

    return (
        <div className="relative mx-auto text-white" style={phonePreviewStyle()}>
            <img src={charityPreviewBackground} alt="" className="object-contain"/>
            <div className="absolute overflow-y-auto space-y-3" style={phonePreviewScreenStyle(false)}>
                <div className="mx-auto">
                    <Image rmtPrefix={imageURLPrefix} rmt={charity.profilePicture} local={imageLocalURL} what="phonePreview" />
                </div>
                <div className="flex flex-row grid grid-cols-2 px-2 py-2">
                    <div className="h-full text-lg font-bold overflow-hidden">
                        {(charity.name.length > 0) ? charity.name : "Your charity name"}
                    </div>
                    <div className="h-full space-y-3">
                        <div className="mx-auto w-fit">
                            <Image rmtPrefix={imageURLPrefix} rmt={charity.logoPicture} local={logoLocalURL} what="logoSmall" />
                        </div>
                        <div className="text-center text-xs">
                            {(charity.briefDescription.length > 0) ? charity.briefDescription : "Your tag line"}
                        </div>
                    </div>
                </div>
                <div className="w-full text-center py-2 bg-primary-dark font-bold">
                    About us
                </div>
                <div className="text-sm mx-1 whitespace-pre-wrap">
                    {charity.fullDescription ?? "About your organisation..."}
                </div>
                { charity.address &&
                <div className="rounded-lg bg-gray-700 p-2 mx-1 text-sm text-primary-default">
                    {addressToString(charity.address)}
                </div>
                }
                <div className="w-full text-center py-2 bg-primary-dark font-bold">
                    Giving opportunities
                </div>
                <div className="rounded-lg bg-gray-700 p-2 mx-1 min-h-[96px] text-sm">
                    Your campaign...
                </div>
               <div className="rounded-lg bg-gray-700 p-2 mx-1 min-h-[96px] text-sm">
                    Your campaign...
                </div>
                <div className="w-full text-center py-2 bg-alt-dark font-bold">
                    Opportunities to get involved
                </div>
                <div className="rounded-lg bg-gray-700 p-2 mx-1 min-h-[96px] text-sm">
                    Your activity...
                </div>
                <div className="rounded-lg bg-gray-700 p-2 mx-1 min-h-[96px] text-sm">
                    Your activity...
                </div>
            </div>
        </div>
    )
}
