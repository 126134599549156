import {useState} from "react";
import {useSelector} from "react-redux";

import SaveAltIcon from '@mui/icons-material/SaveAlt';

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";

import {useLazyQuery} from "@apollo/react-hooks";

import {buttonStyles, CustomButton, Modal, dateToStringNoTime, dateForFilename, graphQLErrorAsText} from "gih_web_common";

import {GET_DONATIONS_TO_CHARITY_GIFTAIDED} from "../../utils/graphql/campaign";
import {logActionSuccess, logActionGraphQLFailure} from "../../utils/analytics";
import {ACTIONS} from "../../utils/analyticsConstants";
import {makeWorkbook, makeSheet, downloadXLSX} from "../../utils/xlsx";


function makeXLSXReport(donationsByDonor) {

    // The format of the report and instructions on how to fill it in are found here:
    // https://www.gov.uk/guidance/schedule-spreadsheet-to-claim-back-tax-on-gift-aid-donations

    const columns = [
        {
            width: 10,
            heading: {
                label: 'Item',
                horizontal: 'right',
            },
        }, {
            width: 10,
            heading: {
                label: 'Title',
                horizontal: 'left',
            },
        }, {
            width: donationsByDonor.reduce((w, donor) => Math.max(w, donor.firstName.length), 16),
            heading: {
                label: 'First name',
                horizontal: 'left',
            },
        }, {
            width: donationsByDonor.reduce((w, donor) => Math.max(w, donor.lastName.length), 16),
            heading: {
                label: 'Last name',
                horizontal: 'left',
            },
        }, {
            width: donationsByDonor.reduce((w, donor) => Math.max(w, donor.house.length), 16),
            heading: {
                label: 'House',
                horizontal: 'left',
            },
        }, {
            width: 16,
            heading: {
                label: 'Postcode',
                horizontal: 'left',
            },
        }, {
            width: 20,
            heading: {
                label: 'Aggregated donations',
                horizontal: 'center',
            },
        }, {
            width: 20,
            heading: {
                label: 'Sponsored event (Yes/blank)',
                horizontal: 'center',
            },
        }, {
            width: 16,
            heading: {
                label: 'Donation date (DD/MM/YY)',
                horizontal: 'left',
            },
            data: {
                numFmt: 'dd/mm/yyyy',
            },
        }, {
            width: 18,
            heading: {
                label: 'Amount',
                horizontal: 'right',
            },
            data: {
                numFmt: '0.00'
            }
        },
    ];

    function donorAsRow(donor, index) {
        return [
            index+1,
            donor.title,
            donor.firstName,
            donor.lastName,
            donor.house,
            donor.postcode,
            ,
            ,
            dateToStringNoTime(donor.lastDonation, 'short'),
            donor.total / 100,
        ];
    }

    return makeSheet(makeWorkbook(), 'Donations', columns, donationsByDonor, donorAsRow);
}

export function GiftAidReportModal({onDismiss}) {

    const charity = useSelector(state => state.charity);

    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [error, setError] = useState(null);

    const [fetchDonations] = useLazyQuery(GET_DONATIONS_TO_CHARITY_GIFTAIDED);

    function execute() {
        return fetchDonations({
            variables: {
                charityId: charity.id,
                start: start,
                end: end,
            }
        }).then(r => {
            if (r.data) {
                return downloadXLSX(makeXLSXReport(r.data.donationsToCharityGiftAided), `GiftAidedDonations-${dateForFilename(start)}-${dateForFilename(end)}.xlsx`)
                    .then(() => {
                        logActionSuccess(ACTIONS.giftAidReportGet, 'Generated Excel spreadsheet');
                        onDismiss();
                    }, e => {
                        logActionGraphQLFailure(ACTIONS.giftAidReportGet, e);
                    });
            } else {
                setError(graphQLErrorAsText(r.error));
                logActionGraphQLFailure(ACTIONS.giftAidReportGet, r.error);
            }
        });
    }

    return (
        <Modal
            title="Download report of donations with GiftAid"
            onDismiss={onDismiss}
            error={error}
            footer={
                <CustomButton
                    className={buttonStyles.primaryLg}
                    type="button"
                    disabled={!start || !end}
                    onClick={execute}
                >
                    <SaveAltIcon/><span className="ml-1">Download spreadsheet</span>
                </CustomButton>
            }
        >
            <div className="flex flex-wrap sm:flex-nowrap gap-2 items-center">
                <span className="whitespace-nowrap">Beginning on</span>
                <DatePicker
                  id="startDate"
                  dateFormat="d MMMM, yyyy"
                  calendarStartDay={1}
                  selected={start}
                  onChange={date => setStart(date)}
                  placeholderText="start date"
                  autoComplete="off"
                  className="text-fg-default bg-bg-paper"
                />
                <span className="whitespace-nowrap">ending on</span>
                <DatePicker
                  id="endDate"
                  dateFormat="d MMMM, yyyy"
                  calendarStartDay={1}
                  selected={end}
                  onChange={date => setEnd(date)}
                  placeholderText="end date"
                  autoComplete="off"
                  className="text-fg-default bg-bg-paper"
                />
            </div>
        </Modal>
    )
}
