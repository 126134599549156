import {useEffect, useState} from "react";
import {Link, useParams, useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {CopyToClipboard} from 'react-copy-to-clipboard';

import IosShareIcon from '@mui/icons-material/IosShare';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import AddIcon from '@mui/icons-material/Add';

import {useQuery, useMutation} from "@apollo/react-hooks";

import {buttonStyles, inputStyles, GlassCard, OnLoadViewer, ImageVCentre, VideoVCentre, Modal, addressToString, placesToString, CampaignProgressBar, AlertModal, dateToString, dateToStringNoTime} from "gih_web_common";

import {GET_CAMPAIGN_BY_ID, CANCEL_CAMPAIGN} from "../../utils/graphql/campaign";
import {workLocation2Text} from "../../utils/activity";
import {logScreenViewEvent, logActionSuccess, logActionGraphQLFailure} from "../../utils/analytics";
import {ACTIONS, SCREEN_NAME, SCREEN_CLASS} from "../../utils/analyticsConstants";
import {evictAllDependentOnCampaign} from "../../utils/graphql/cache";
import {geohashToPlace} from "../../utils/google";

import {TitleAndBackButton} from "../common/elements";

import {Badge} from "./badge";
import {CampaignDonationStats} from "./graphs";
import {CampaignDonations, CampaignFundraisers} from "./tables";
import {CampaignUpdates} from "./updates/table";


function getItems(campaign) {
    let items = [
        { title: "Name", text: campaign.name },
        { title: "Target", text: `£${campaign.target/100}` },
        { title: "Description", text: campaign.fullDescription },
        { title: "Work location", text: workLocation2Text[campaign.workLocation] },
        { title: "Address", text: (campaign.address !== null) ? addressToString(campaign.address) : null },
        { title: "Place(s) for location based search", text: placesToString(campaign.places) },
        { title: "Ending on", text: dateToStringNoTime(campaign.endDate) },
        { title: "Created on", text: dateToString(campaign.createdAt) },
        { title: "Created by", text: campaign.createdBy },
        { title: "Updated on", text: (campaign.lastNotifiableUpdate !== null) ? dateToString(campaign.lastNotifiableUpdate) : null },
        { title: "Keywords", text: campaign.keywords.join(', ') },
        { title: "Hidden", text: campaign.hidden ? "This campaign is only visible to donors who have already donated" : null},
    ]
    let odd = false
    items.forEach(item => {
        item.odd = odd
        if (item.text !== null) {
            odd = !odd
        }
    })
    return items
}

export default function CampaignDetailPage() {

    const {id} = useParams();

    const [canDelete, setCanDelete] = useState(false);
    const [campaign, setCampaign] = useState(null);

    const {loading: campaignQueryInProgress, refetch: refetchCampaign} = useQuery(GET_CAMPAIGN_BY_ID, {
        variables: {
            campaignId: id
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            const a = data.portalFindCampaignById
            if (a.places.length < 1 || a.places[0].name !== null) {
                setCampaign(a)

            } else {
                geohashToPlace(a.places[0].geohash, placeName => {
                    setCampaign({
                        ...a,
                        places: [{
                            name: placeName,
                            qualifier: null,
                            geohash: a.places[0].geohash,
                        }],
                    })
                })
            }
        }
    })

    useEffect(() => {
        logScreenViewEvent(SCREEN_NAME.campaignDetail, SCREEN_CLASS.campaign)
    }, [])

    return (
        <div className="p-1 lg:p-4 flex flex-col overflow-y-auto" style={{ height: 'calc(100vh - 64px)' }}>
            <OnLoadViewer loading={campaignQueryInProgress}>
                { campaign &&
                <>
                    <div className="flex flex-wrap mx-auto xl:grid xl:grid-cols-[minmax(700px,1200px)_700px] gap-2">
                        <div className="w-full space-y-2">
                            <TitleAndBackButton title="Campaign" />
                            <GlassCard width="w-full max-w-[800px]">
                                <CampaignProgressBar total={campaign.total} target={campaign.target} supporters={campaign.uniqueDonors} />
                            </GlassCard>
                            <DetailsTable campaign={campaign} canDelete={canDelete} refetch={refetchCampaign} />
                        </div>
                        <PictureAndQR campaign={campaign} />
                    </div>

                    <div className="flex flex-col gap-4 py-3 xl:max-w-[1920px] max-w-full mx-auto">
                        <CampaignUpdates campaignId={id} type="thanks" />
                        <CampaignUpdates campaignId={id} type="progress" />
                        <CampaignDonationStats campaignId={id} />
                        <CampaignFundraisers campaignId={id} />
                        <CampaignDonations campaignId={id} />
                    </div>
                </>
                }
            </OnLoadViewer>
        </div>
    )
}

function PictureAndQR({campaign}) {

    const charity = useSelector(state => state.charity);
    const imageURLPrefix = useSelector(state => state.cfg?.imageURLPrefix);

    return (
        <GlassCard width="h-fit w-full sm:w-fit">
            <div className="mx-auto w-fit grid grid-col-1 md:grid-cols-2 gap-2">
                <ImageVCentre
                    rmtPrefix={imageURLPrefix}
                    rmt={campaign.imageURL}
                    what="imagePreview"
                >
                    <Badge campaign={campaign} />
                </ImageVCentre>
                { charity.features.allowActivityVideo &&
                <VideoVCentre
                    rmtPrefix={imageURLPrefix}
                    rmt={campaign.videoURL}
                    what="imagePreview"
                    posterTime={campaign.videoPosterTime}
                    placeholderSeverity="info"
                    placeholderText="Click 'Edit' to add a video - videos help make an emotional connection with prospective donors."
                />
                }
            </div>
        </GlassCard>
    )
}

function getEmbedCode(campaign, embedURLPrefix) {
    return `<iframe width="560" height="315" src="${embedURLPrefix}campaign/${campaign._id}" title="Giving Is Human campaign" frameborder="0"></iframe>`;
}

function DetailsTable({campaign, canDelete, refetch}) {

    const history = useHistory();
    const embedURLPrefix = useSelector(state => state.cfg?.embedURLPrefix);

    const [linkCopied, setLinkCopied] = useState(null);
    const [cancellationAction, setCancellationAction] = useState(null);
    const [cancellationOutcome, setCancellationOutcome] = useState(null);

    function dismissOutcome() {
        if (cancellationOutcome.deleted) {
            history.goBack()
        } else {
            refetch()
        }
        setCancellationOutcome(null)
    }

    return (
        <div>
            <GlassCard>
                <dl>
                    { getItems(campaign).map(item => (item.text ?
                        <div key={item.title} className={`${item.odd ? "bg-bg-secondary" : "bg-bg-tertiary"} px-4 py-4 lg:grid lg:grid-cols-2`}>
                            <dt className="text-sm font-medium text-fgCard-secondary mr-2">{item.title}</dt>
                            <dd className="text-sm text-fgCard-default whitespace-pre-wrap">{item.text}</dd>
                        </div>
                        :
                        null
                    ))}

                    <div className="flex bg-bg-secondary px-2 py-5 justify-center grid grid-cols-1 lg:grid lg:grid-cols-2 2xl:grid-cols-3 gap-3">
                        <Link to={`/campaign/${campaign._id}/edit`} className={buttonStyles.primaryLg}>
                            <ModeEditOutlineIcon/><span className="ml-1">Edit</span>
                        </Link>
                        <button onClick={() => { setCancellationAction(canDelete) }} className={buttonStyles.redLg}>
                            { canDelete ? <span><DeleteForeverIcon/> Delete</span> : <span><EventBusyIcon/> Cancel campaign</span>}
                        </button>
                        { campaign.cancelled &&
                        <Link to={`/campaign/${campaign._id}/edit`} className={buttonStyles.primaryLg}>
                            <ModeEditOutlineIcon/><span className="ml-1">Edit and reinstate the event</span>
                        </Link>
                        }
                        <Link to={`/campaign/create/${campaign._id}`} className={buttonStyles.primaryLg}>
                            <AddIcon/><span className="ml-1">Create a duplicate</span>
                        </Link>
                        { campaign.dynamicLink &&
                        <CopyToClipboard text={campaign.dynamicLink} onCopy={() => setLinkCopied('share')}>
                            <button className={buttonStyles.blueLg}>
                                <IosShareIcon /><span className="ml-1">{(linkCopied === 'share') ? "Link copied!" : "Get link & share"}</span>
                            </button>
                        </CopyToClipboard>
                        }
                        <CopyToClipboard text={getEmbedCode(campaign, embedURLPrefix)} onCopy={() => setLinkCopied('embed')}>
                            <button className={buttonStyles.blueLg}>
                                <CodeOutlinedIcon /><span className="ml-1">{(linkCopied === 'embed') ? "Code copied!" : "Get embed code"}</span>
                            </button>
                        </CopyToClipboard>
                    </div>
                </dl>
            </GlassCard>

            { cancellationAction !== null &&
            <CancelCampaignModal
                tryDelete={cancellationAction}
                campaign={campaign}
                setOutcome={ (outcome) => {
                    setCancellationAction(null)
                    setCancellationOutcome(outcome)
                }}
            />
            }

            { cancellationOutcome !== null &&
            <AlertModal
                type={cancellationOutcome.type}
                message={cancellationOutcome.message}
                onDismiss={dismissOutcome}
            />
            }
        </div>
    )
}

function CancelCampaignModal({campaign, setOutcome, tryDelete}) {

    const [cancelCampaign] = useMutation(CANCEL_CAMPAIGN, { update: evictAllDependentOnCampaign })

    const [cancellationReason, setCancellationReason] = useState(null);

    const requestCancel = (tryDelete) => {
        return cancelCampaign({
            variables: {
                campaignId: campaign._id,
                tryDelete: tryDelete,
                reason: cancellationReason
            }
        }).then(r => {
            logActionSuccess(ACTIONS.campaignCancel, `Campaign ${r.data.cancelCampaign ? "cancelled" : "deleted"}`);
            setOutcome({
                type: "success",
                message: `Campaign was successfully ${r.data.cancelCampaign ? "cancelled" : "deleted"}`,
            });
        }, e => {
            logActionGraphQLFailure(ACTIONS.campaignCancel, e);
            setOutcome({
                type: "warning",
                message: `Cancellation failed: ${e}`,
            });
            throw e;
        });
    }

    return (
        <Modal
            onDismiss={() => setOutcome(null)}
            title={`Are you sure you want to ${tryDelete ? "delete" : "cancel"} this campaign?`}
            footer={
                <button className={buttonStyles.redLg} type="button" onClick={() => requestCancel(tryDelete)}>
                    {tryDelete ? "Confirm Delete" : "Confirm Cancel"}
                </button>
            }
        >
            { !tryDelete &&
            <input
                className={`w-full ${inputStyles.text}`}
                required={true}
                type="text"
                id="message_text"
                name="message_text"
                placeholder="Enter a reason for cancelling that will be seen by donors"
                value={cancellationReason}
                onChange={(event) => setCancellationReason(event.target.value)}
                autoComplete="off"
            />
            }
        </Modal>
    )
}
