import {useState} from "react";
import {use100vh} from 'react-div-100vh'
import {DateTime} from "luxon";

import Switch from '@mui/material/Switch';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import {useQuery} from "@apollo/react-hooks";

import GoogleMapReact from 'google-map-react';

import {RefreshButton} from "gih_web_common";

import {graphQLCache} from "../../../index";
import { GET_APP_USER_LOCATIONS, GET_LOCATION_SEARCH_EVENTS } from "../../../utils/graphql/admin-user";


function Marker({notifiable}) {
    return (
        <LocationOnIcon className={notifiable ? "text-blue-500" : "text-red-500"} />
    )
}

function SearchMarker() {
    return (
        <LocationOnIcon className="text-pink-500" />
    )
}

const defaultMapPosition = {
    centre: { lat: 52.5, lng: -2.5 },
    zoom: 8
};

export function AppUserMap() {

    const height = use100vh();
    const [users, setUsers] = useState([]);
    const [searches, setSearches] = useState([]);
    const [showSearches, setShowSearches] = useState(true);
    const [showRegisteredUsers, setShowRegisteredUsers] = useState(true);

    const now = DateTime.now()
    const historicalDays = 28

    useQuery(GET_LOCATION_SEARCH_EVENTS, {
        variables: {
            start: now.minus({days:historicalDays}).startOf('day').toJSDate(),
            end: now.endOf('day').toJSDate(),
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setSearches(data.locationSearches)
    })

    useQuery(GET_APP_USER_LOCATIONS, {
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setUsers(data.portalGetAppUserLocations)
    })

    function refresh() {
        graphQLCache.evict({
            id: "ROOT_QUERY", 
            fieldName: "portalGetAppUserLocations",
        });

        graphQLCache.evict({
            id: "ROOT_QUERY", 
            fieldName: "locationSearches",
        });

        graphQLCache.gc();
    }

    return (
        <div className="w-full">
            <div className="flex h-[48px] items-center gap-4">
                <RefreshButton label={true} action={refresh} />
                <label className="flex items-center text-xs sm:text-base">
                    <Switch
                        onChange={e => setShowSearches(prevState => !prevState)}
                        color="primary"
                        defaultChecked={true}
                    />
                    <span className="ml-1">
                        Show locations searched in the last {historicalDays} days
                    </span>
                </label>
                <label className="flex items-center text-xs sm:text-base">
                    <Switch
                        onChange={e => setShowRegisteredUsers(prevState => !prevState)}
                        color="primary"
                        defaultChecked={true}
                    />
                    <span className="ml-1">
                        Show locations of registered users
                    </span>
                </label>
            </div>

            <div className="w-full" style={{ height: `${height - 64 - 64 - 48 - 8}px`}}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyCtCZu52G3DnhTzDf_sFnk8lTInwTRi7Ss" }}
                    defaultCenter={defaultMapPosition.centre}
                    defaultZoom={defaultMapPosition.zoom}
                >
                { showRegisteredUsers && users.map(user => (
                    <Marker
                        key={user._id}
                        lat={user.lat}
                        lng={user.lng}
                        notifiable={user.notifiable}
                    />
                ))}
                { showSearches && searches.map(search => (
                    <SearchMarker
                        key={search.when}
                        lat={search.where.latitude}
                        lng={search.where.longitude}
                    />
                ))}
                </GoogleMapReact>
            </div>
        </div>
    )
}
