import {OnLoadViewer} from "gih_web_common";

import {TabSelector} from "../common/tab-selector";
import {ChatList} from "./chat-list";


export default function ChatListPane(props) {

    return (
        <>
            <TabSelector tab={"top"}/>

            <OnLoadViewer loading={props.loading}>
                { props.rankedUsers &&
                <ChatList rankedUsers={props.rankedUsers}
                          activeUser={props.activeUser}
                          setActiveUser={props.setActiveUser}
                          activeChat={props.activeChat}
                          setActiveChat={props.setActiveChat}
                />
                }
            </OnLoadViewer>
        </>
    )
}
