import {useState, useEffect} from "react";
import {Combobox, Transition} from "@headlessui/react";
import {FiMapPin} from "react-icons/fi";

import Geohash from "latlon-geohash";
import {inputStyles} from "gih_web_common";

import {getPlacePredictions, getPlaceDetails} from "../../utils/google";

export function noPlace() {
    return {
        geohash: null,
        name: null,
        qualifier: null
    }
}

export function getPlace(place) {
    return {
        name: place.name,
        qualifier: place.qualifier,
        geohash: place.geohash,
    }
}


function getDetails(place, partition) {

    //console.log('components', place.address_components)

    const geometry = place.geometry.location;
    const geohash = Geohash.encode(geometry.lat(), geometry.lng(), 8);

    if (partition) {
        const name = []
        const qualifier = []
        let inName = true
        let skip = false

        place.address_components.forEach((c, index) => {
        
            if (skip) {
                skip = false

            } else {
                if (name.length > 0) {
                    if (c.types.find(type => type === "administrative_area_level_1")) {
                        inName = false
                    } else if (c.types.find(type => type === "postal_town")
                      && `Greater ${c.short_name}` === place.address_components[index+1].short_name) {
                        inName = false
                        skip = true
                    }
                }

                if (!c.types.find(type => type === "postal_code" || type === "country") || c.types.find(type => type === "postal_code_prefix")) {
                    if (name.length === 0 || c.short_name !== name[name.length - 1]) {
                        if (inName) {
                            name.push(c.short_name)
                        } else {
                            qualifier.push(c.short_name)
                        }
                    }
                }

                if (c.types.find(type => type === "administrative_area_level_3" || type === "locality" || type === "postal_town")) {
                    inName = false
                }
            }
        })

        return {
            geohash: geohash,
            name: name.join(', '),
            qualifier: qualifier.join(', ')
        }

    } else {

        return {
            geohash: geohash,
            name: place.formatted_address,
            qualifier: null
        }
    }
}

export function LocationSearch({initial, onSelectLocation, clearAfterSelect=false, partition=false}) {

    const [locationSearchTimer, setLocationSearchTimer] = useState(null);
    const [locationQuery, setLocationQuery] = useState("");
    const [locationResults, setLocationResults] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedLocationDetails, setSelectedLocationDetails] = useState(initial);

    useEffect(() => {
        clearTimeout(locationSearchTimer);
        const trimmedQuery = locationQuery.trim()
        //console.log(`Location query now: "${trimmedQuery}"`)
        if (trimmedQuery.length > 2) {
            setLocationSearchTimer(
                setTimeout(() => {
                    getPlacePredictions({ componentRestrictions: { country: "uk" }, input: trimmedQuery }, (p) => {
                        setLocationResults(p ?? []);
                        //console.log("Location search results:", p)
                    })
                }, 500)
            );
        } else {
            setLocationResults([]);
        }
    }, [locationQuery])

    async function setSelectedLocationAndFetchDetails(where) {
        if (where !== null) {
            await getPlaceDetails(where.place_id, async (place) => {
                const details = getDetails(place, partition);
                console.log(`Geohash for selected location is: ${details.geohash} with name: ${details.name} (${details.qualifier})`)
                if (clearAfterSelect) {
                    setSelectedLocation(null);
                    setSelectedLocationDetails(noPlace());
                } else {
                    setSelectedLocationDetails(details);
                }
                onSelectLocation(details);
            })
        } else {
            setSelectedLocationDetails(noPlace());
            onSelectLocation(noPlace());
        }
    }

    return (
        <div className="w-full">
            <Combobox value={selectedLocation} onChange={setSelectedLocationAndFetchDetails}>
                <div className="flex flex-col mt-4 w-full relative">
                    <Combobox.Label className="text-xs opacity-80 mb-1">
                        Place name for location based search
                    </Combobox.Label>
                    <Combobox.Input
                        autoComplete="off"
                        placeholder={selectedLocationDetails.name ? "Type a street, place or postcode to choose a different location" : "Type a street, place or postcode to set a location"}
                        className={`w-full my-1 ${inputStyles.text}`}
                        onChange={(event) => setLocationQuery(event.target.value)}
                        displayValue={choice => choice ? choice.structured_formatting.main_text : ""}
                    />
                    <Transition
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setLocationQuery('')}
                    >
                        <Combobox.Options
                            className="absolute w-full py-1 mt-1 overflow-auto text-base bg-bg-paper rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 z-50 focus:outline-none text-xs sm:text-base">
                            { locationResults.map((r) => (
                                <Combobox.Option
                                    key={r.place_id}
                                    value={r}
                                    className={({active}) => `cursor-default flex items-center select-none relative py-2 px-4 ${active ? 'text-white bg-primary-dark' : 'text-fg-default'}`}
                                >
                                    <FiMapPin className="mr-3" size="16"/>
                                    <div className="text-xs sm:text-base">
                                        <p>
                                            {r.structured_formatting.main_text}
                                        </p>
                                        <p style={{opacity: 0.8}}>
                                            {r.structured_formatting.secondary_text}
                                        </p>
                                    </div>
                                </Combobox.Option>
                            ))
                            }
                        </Combobox.Options>
                    </Transition>
                </div>
            </Combobox>
            { selectedLocationDetails.name &&
            <div className="w-full text-sm mb-1">
                <span>Current location:</span><span className="font-bold ml-1">{selectedLocationDetails.name}</span>
            </div>
            }
        </div>
    )
}
