import {useState} from "react";

import {useQuery} from "@apollo/react-hooks";

import {nameStr} from "gih_web_common";

import {minutesToHours, arrayByTimeToGraphData, BarGraph} from "../../utils/stats";

import { GET_WORK_FOR_CHARITY_BY_PERIOD,
         GET_WORK_FOR_CHARITY_BY_ACTIVITY,
         GET_WORK_STATS_FOR_CHARITY_BY_USER } from "../../utils/graphql/charity";


export function HoursWorkedForCharityByPeriod({charityId, start, end, period}) {

    const [graphData, setGraphData] = useState(null);

    const {error} = useQuery(GET_WORK_FOR_CHARITY_BY_PERIOD, {
        variables: {
            charityId: charityId,
            start: start,
            end: end,
            period: period
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData(arrayByTimeToGraphData(data.workForCharityByPeriod, period, [
            {
                label: "Hours volunteered",
                getter: item => minutesToHours(item.minutes),
                colour: "rgb(0, 128, 0)"
            }
        ]))
    })

    return (
        <BarGraph title={`Total hours volunteered each ${period}`} data={graphData} />
    )
}


export function HoursWorkedForCharityByActivity({charityId, start, end}) {

    const [graphData, setGraphData] = useState(null);

    const {error} = useQuery(GET_WORK_FOR_CHARITY_BY_ACTIVITY, {
        variables: {
            charityId: charityId
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            let result = data.workForCharityByActivity
            setGraphData({
                labels: result.map((item) => item.name),
                datasets: [
                    {
                        label: "Hours volunteered",
                        data: result.map((item) => minutesToHours(item.minutes)),
                        backgroundColor: "rgb(128, 128, 0)"
                    }
                ]
            })
        }
    })

    return (
        <BarGraph title={`Total hours volunteered by activity between ${start.toDateString()} and ${end.toDateString()}`} data={graphData} />
    )
}


export function HoursWorkedForCharityByVolunteer({charityId, start, end}) {

    const [graphData, setGraphData] = useState(null);
    
    const {error} = useQuery(GET_WORK_STATS_FOR_CHARITY_BY_USER, {
        variables: {
            charityId: charityId,
            start: start,
            end: end,
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            let result = data.workForCharityByUser
            setGraphData({
                labels: result.map((item) => nameStr(item.userId)),
                datasets: [
                    {
                        label: "Hours volunteered",
                        data: result.map((item) => minutesToHours(item.minutesWorked)),
                        backgroundColor: "rgb(128, 0, 128)"
                    }
                ]
            })
        }
    })

    return (
        <BarGraph title={`Total hours worked by volunteer between ${start.toDateString()} and ${end.toDateString()}`} data={graphData} />
    )
}
