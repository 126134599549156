import {RRule} from "rrule";
import {DateTime} from "luxon";

import {formError, dateToString} from "gih_web_common";

import {upload} from "../../../utils/firebase";
import {getAddress, validateAddress} from  "../../../utils/address";
import {FILES} from  "../../../utils/analyticsConstants";

import {dayOptions} from "./repetition";


export function getDerivedValues(activity) {

    const derived = {
        hours:        Math.floor(activity.minutes / 60),
        minutes:      activity.minutes - 60 * Math.floor(activity.minutes / 60),
        frequency:    "weekly",
        interval:     "1",
        monthlyWhich: null,
        monthlyWhat:  null,
        endCondition: "repeatForever",
        startDate:    null,
        until:        null,
        days:         []
    }

    if (activity.repetition !== null) {

        const rule = RRule.fromString(activity.repetition)

        derived.startDate = rule.options.dtstart

        if (rule.options.freq === RRule.WEEKLY) {
            derived.frequency = "weekly"
            derived.interval = rule.options.interval
            derived.days = rule.options.byweekday.map((day) => dayOptions[day].value.toString())

        } else if (rule.options.freq === RRule.MONTHLY) {
            derived.frequency = "monthly"
            derived.interval = rule.options.interval
            derived.monthlyWhat =  rule.options.bynweekday[0][0]
            derived.monthlyWhich = rule.options.bynweekday[0][1]

        } else if (rule.options.freq === RRule.DAILY) {
            derived.frequency = "daily"
        }

        if (rule.options.until !== null) {
            derived.endCondition = "endOn"
            derived.until = rule.options.until
        }
    }

    return derived
}

function asByWeekDay(days) {
    return days.map((value) => {
        for (const day of [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU]) {
            if (day.toString() === value) {
                return day
            }
        }
        return null
    })
}

export async function validate(values, existing, charity) {

    console.log("Validating:", values)

    switch (values.scheduling) {
    
        case "repeatingEvent":
            if (values.interval.length < 1 || parseInt(values.interval) === 0) {
                throw formError("enter a repetition interval greater than zero");
            }

            if (values.frequency === "weekly" && values.days.length === 0) {
                throw formError("select at least one day in the week");
            }

            if (values.startDate === null) {
                throw formError("choose a date/time for the first occurrence of this activity");
            }

            if (values.endCondition === "endOn" && values.until === null) {
                throw formError("choose a date for the last occurrence of this activity");
            }

            if (values.frequency === "monthly") {
                if (values.monthlyWhich === null) {
                    throw formError("select the week of the month");
                }

                if (values.monthlyWhat === null) {
                    throw formError("select a day in your chosen week of the month");
                }        
            }

            break;

        case "oneTimeEvent":
            if (values.eventDate === null) {
                throw formError("choose a date/time for the activity");
            }

            break;

        case "schoolTime":
        case "schoolHolidays":
        case "outsideSchoolHours":
        case "anyTime":
        case "byAgreement":
            if (values.startDate === null) {
                throw formError("choose a date/time for the first occurrence of this activity");
            }

            if (values.frequency === "weekly" && values.days.length === 0) {
                throw formError("select at least one day in the week");
            }

            if (values.endCondition === "endOn" && values.until === null) {
                throw formError("choose a date for the last occurrence of this activity");
            }

            break;

        default:
            break;
    }

    if (values.hours > 48) {
        throw { hours: "Maximum duration is 48 hours" }
    }

    if (values.minutes > 59) {
        throw { minutes: "Maximum minutes is 59" }
    }

    if (values.hours < 1 && values.minutes < 5) {
        throw { minutes: "Minimum duration is 5 minutes" }
    }

    if (!existing.imageURL && !values.imageFile) {
        throw formError("upload a photo for the activity");
    }

    if (values.keywords.length < 1) {
        throw formError("enter one or more keywords that describe the activity");
    }

    const multiplePlaces = (values.workLocation === "community");

    const activity = {
        name: values.name,
        fullDescription: values.fullDescription,
        briefDescription: null,
        scheduling: values.scheduling,
        eventDate: values.eventDate,
        minutes: parseInt(values.hours) * 60 + parseInt(values.minutes),
        repetition: null,
        imageURL: existing.imageURL,
        videoURL: existing.videoURL,
        address: multiplePlaces ? null : getAddress(values.chosenSite ? values.chosenSite.address : values),
        places: (multiplePlaces || !values.chosenSite) ? values.places : [ values.chosenSite.place ],
        autoReply: values.autoReply,
        autoReplyText: values.autoReplyText,
        keywords: values.keywords,
        hidden: values.hidden,
        full: values.full,
        workLocation: values.workLocation,
        ageRestricted: values.ageRestricted,
        manageAvailability: values.manageAvailability,
        requiredVolunteers: values.requiredVolunteers
    }

    if (activity.workLocation !== "community") {
        validateAddress(activity.address)
    }

    if (activity.places.length < 1 || !activity.places[0].geohash) {
        throw formError("enter a place name for location based search (this can be the postcode, street or area where the activity is taking place)");
    }

    switch (values.scheduling) {
    
        case "repeatingEvent":
        {
            let frequency = null
            let byweekday = null
            let until = null

            if (values.endCondition === "endOn") {
                until = DateTime.fromJSDate(values.until).endOf('day').toJSDate()
            }

            switch (values.frequency) {
                case "weekly":
                    frequency = RRule.WEEKLY
                    byweekday = asByWeekDay(values.days)
                    break;
                case "monthly":
                    frequency = RRule.MONTHLY
                    byweekday = dayOptions[values.monthlyWhat].value.nth(values.monthlyWhich)
                    break;
                case "daily":
                    frequency = RRule.DAILY
                    break;
                default:
                    throw `Unexpected frequency ${values.frequency}`
            }

            try {
                const rule = new RRule({
                    freq: frequency,
                    interval: parseInt(values.interval),
                    byweekday: byweekday,
                    dtstart: values.startDate,
                    until: until
                })
                activity.repetition = rule.toString()
                activity.eventDate = null
            } catch (error) {
                throw `Failed to construct repetition pattern: ${error}`
            }

            break;
        }

        case "oneTimeEvent":
            break;

        case "schoolTime":
        case "schoolHolidays":
        case "outsideSchoolHours":
        case "anyTime":
        case "byAgreement":
        {
            let frequency = null
            let byweekday = null
            let until = null
            let start = values.startDate

            if (values.endCondition === "endOn") {
                until = DateTime.fromJSDate(values.until).endOf('day').toJSDate()
            }
            
            if (values.scheduling !== "schoolHolidays") {
                start = DateTime.fromJSDate(values.startDate).set({hour: 9, minute: 0}).toJSDate()
            }

            switch (values.frequency) {
                case "weekly":
                    frequency = RRule.WEEKLY
                    byweekday = asByWeekDay(values.days)
                    break;
                case "daily":
                    frequency = RRule.DAILY
                    break;
                default:
                    throw `Unexpected frequency ${values.frequency}`
            }

            try {
                const rule = new RRule({
                    freq: frequency,
                    interval: 1,
                    byweekday: byweekday,
                    dtstart: start,
                    until: until
                })
                activity.repetition = rule.toString()
                activity.eventDate = null
            } catch (error) {
                throw `Failed to construct repetition pattern: ${error}`
            }

            break;
        }

        default:
            break;

    }

    await upload(values.imageFile, 'activity', 'header', FILES.activityImage, (fullPath) => { activity.imageURL = fullPath })
    await upload(values.videoFile, 'activity', 'video', FILES.activityVideo, (fullPath) => { activity.videoURL = fullPath })

    console.log("Activity:", activity)

    return activity
}

export function getWarning(activity) {

    // One off event scheduled between 00:00 - 06:00 so ask the user to check that this is correct.
    if (activity.eventDate !== null && activity.eventDate.getHours() < 6) {
        return "Start time is in the middle of the night"
    }

    // Similar check for repeating event.
    if (activity.repetition !== null) {
        const rule = RRule.fromString(activity.repetition)
        if (rule.options.dtstart.getHours() < 6) {
            return "Start time for repeating activity is in the middle of the night"
        }

        const firstOccurrence = rule.after(new Date(), false)

        console.log('firstOccurrence', firstOccurrence, 'rule.options.dtstart', rule.options.dtstart)

        if (firstOccurrence.getTime() !== rule.options.dtstart.getTime()) {
            return `The first date that fits the recurrence pattern is ${dateToString(firstOccurrence)} which doesn't match the start date ${dateToString(rule.options.dtstart)} you chose`
        }
    }

    return null
}
