import {useDispatch, useSelector} from "react-redux";
import {FiXCircle} from "react-icons/fi";

import {useMutation} from "@apollo/react-hooks";

import {HINT_ACK} from "../../utils/graphql/user";
import {logActionSuccess, logActionGraphQLFailure} from "../../utils/analytics";
import {ACTIONS} from "../../utils/analyticsConstants";


export function Hint({children, name, forceVisible=null}) {

    const dispatch = useDispatch();
    const user = useSelector(state => state.user);

    const [acknowledge] = useMutation(HINT_ACK)

    function onDismiss() {
        acknowledge({
            variables: {
                name: name
            }
        }).then(() => {
            logActionSuccess(ACTIONS.userHintAck, `for hint ${name}`);
            dispatch({type: "SET_HINT", payload: name})
        }, e => {
            logActionGraphQLFailure(ACTIONS.userHintAck, e);
        });
    }

    if (user.hintsAcked[name] && (forceVisible === null || !forceVisible(user))) {
        return null
    }

    return (
        <div className="flex flex-col p-4 text-sm gap-3 bg-blue-100 m-2 rounded-lg border-2 border-blue-400 text-blue-700">
            <div className="flex px-2 justify-between">
                <h1 className="font-bold text-lg">Hint</h1>
                { (forceVisible === null || !forceVisible(user)) &&
                <FiXCircle onClick={onDismiss} size={24} className="transition duration-200 hover:text-red-500"/>
                }
            </div>
            {children}
        </div>
    );
}

export function Banner({title, children}) {

    return (
        <div className="flex flex-col p-4 text-sm gap-3 bg-amber-100 m-2 rounded-lg border-2 border-amber-400 text-amber-700 max-w-2xl">
            <div className="flex px-2 justify-between">
                <h1 className="font-bold text-lg">{title}</h1>
            </div>
            {children}
        </div>
    );
}
