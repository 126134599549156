import {addressTemplate} from "./address";


export function charityTemplate() {
    return {
        name: "",
        registration: null,
        companyNumber: null,
        hmrcId: null,
        regNosLocked: false,
        giftAidEligible: false,
        giftAidAutoClaim: false,
        fullDescription: "",
        briefDescription: "",
        keywords: [],
        geohash: null,
        locationName: null,
        profilePicture: null,
        logoPicture: null,
        contacts: [],
        defaultAutoReply: "Thanks for contacting us, we'll be in touch shortly!",
        defaultAcceptMessage: null,
        defaultDeclineMessage: null,
        address: addressTemplate(),
        rating: 'unrated',
    };
}

export const ratingOpts = [
    {
        label: 'Unrated',
        value: 'unrated'
    }, {
        label: 'Blue',
        value: 'blue'
    }, {
        label: 'Bronze',
        value: 'bronze'    
    }, {
        label: 'Silver',
        value: 'silver'    
    }, {
        label: 'Gold',
        value: 'gold'    
    }
];
