function asE164_(phoneNumber) {

    // Remove any non-digit characters from the phone number
    const digitsOnly = phoneNumber.replace(/\D/g, '');

    // If phone number starts with +, return it as is
    if (digitsOnly.startsWith('+')) {
        return digitsOnly;
    }

    // If phone number starts with 0, remove 0 and add +44
    if (digitsOnly.startsWith('0')) {
        return `+44${digitsOnly.slice(1)}`;
    }

    // If phone number starts with 44, add +
    if (digitsOnly.startsWith('44')) {
        return `+${digitsOnly}`;
    }

    // If phone number starts with 7, add +44
    if (digitsOnly.startsWith('7')) {
        return `+44${digitsOnly}`;
    }

    // If phone number doesn't match any of the above patterns, return as is
    return digitsOnly;
}


export function asE164(rawNumber) {
    const e164 = asE164_(rawNumber)
    return (e164.length >= 13 && e164.length <= 15) ? e164 : null
}
