export function modeFromPath(pathname) {
    if (       pathname.includes("/campaigns/")
            || pathname === "/charity/campaignStats") {
        return true
    } else if (pathname.includes("/activities/")
            || pathname.includes("/chats/")
            || pathname === "/charity/volunteerStats"
            || pathname.includes("/activity/")) {
        return false
    } else {
        return null
    }
}
