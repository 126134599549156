import {useState} from "react";

import {useQuery} from "@apollo/react-hooks";

import {arrayByTimeToGraphData, arrayByNameToGraphData, makeDonationDatasets, BarGraph} from "../../utils/stats";

import { GET_DONATIONS_TO_CHARITY_BY_PERIOD,
         GET_DONATIONS_TO_CHARITY_BY_CAMPAIGN } from "../../utils/graphql/campaign";


export function DonationsToCharityByPeriod({charityId, start, end, period}) {

    const [graphData, setGraphData] = useState(null);

    const {error} = useQuery(GET_DONATIONS_TO_CHARITY_BY_PERIOD, {
        variables: {
            charityId: charityId,
            start: start,
            end: end,
            period: period
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData(arrayByTimeToGraphData(data.donationsToCharityByPeriod, period, makeDonationDatasets()))
    })

    return (
        <BarGraph title={`Total donations by ${period}`} data={graphData} stacked={true} />
    )
}


export function DonationsToCharityByCampaign({charityId, start, end}) {

    const [graphData, setGraphData] = useState(null);

    const {error} = useQuery(GET_DONATIONS_TO_CHARITY_BY_CAMPAIGN, {
        variables: {
            charityId: charityId,
            start: start,
            end: end
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData(arrayByNameToGraphData(data.donationsToCharityByCampaign, makeDonationDatasets()))
    })

    return (
        <BarGraph title={`Total donations by campaign between ${start.toDateString()} and ${end.toDateString()}`} data={graphData} stacked={true} />
    )
}
