import {useState} from "react";
import {Link} from "react-router-dom";

import {useQuery} from "@apollo/react-hooks";

import {buttonStyles, cellStyles, OnLoadViewer, graphQLErrorAsText, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, dateToString, Lozenge} from "gih_web_common";

import { GET_PORTAL_USER_BY_ID, GET_EVENTS_FOR_PORTAL_USER, GET_INTERESTING_EVENTS_FOR_PORTAL_USERS } from "../../../utils/graphql/admin-user";

import {logActionGraphQLFailure} from "../../../utils/analytics";
import {ACTIONS} from "../../../utils/analyticsConstants";

import {colours, Screens, getText, getColour} from "../common/events";
import {PortalUserTable} from "../common/user-table";


export const portalEventAttrs = new Map([
    ['user_engagement', { category: 'general', colour: colours.general }],
]);

export function PortalUser({userId}) {

    const [user, setUser] = useState(null)

    const {loading, refetch} = useQuery(GET_PORTAL_USER_BY_ID, {
        variables: {
            userId: userId
        },
        onCompleted: data => {
            setUser(data.portalUserById)
        },
    });

    return (
        <OnLoadViewer loading={loading}>
            { user &&
            <>
                <div className="ml-2 text-xl sm:text-2xl font-bold">Details</div>
                <PortalUserTable users={[user]} refetch={() => refetch()} withActions={false} />
            </>
            }
        </OnLoadViewer>
    )
}

export function PortalUserEventsTable({start, end, userId}) {

    const [events, setEvents] = useState(null);

    const {loading, error} = useQuery(userId ? GET_EVENTS_FOR_PORTAL_USER : GET_INTERESTING_EVENTS_FOR_PORTAL_USERS, {
        variables: userId ? {
            start: start,
            end: end,
            userId: userId,
        } : {
            start: start,
            end: end,
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted: data => {
            setEvents(data.eventsForPortalUser ?? data.interestingEventsForPortalUsers);
        },
        onError: e => {
           logActionGraphQLFailure(ACTIONS.superAdminStatsQuery, e);
           setEvents(null);
        }
    });

    return (
        <>
            <OnLoadViewer loading={loading}>
                { events &&
                <TableContainer sx={{maxHeight: 800}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={cellStyles.dateTime}>When</TableCell>
                                <TableCell sx={cellStyles.actions}>Actions</TableCell>
                                <TableCell sx={cellStyles.limitedMedium}>Environment</TableCell>
                                <TableCell sx={cellStyles.freeWide}>What</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { events.map((event, i) => (
                                <TableRow key={`${event.when}_${i}`}>
                                    <TableCell align="left">{dateToString(event.when, true)}</TableCell>
                                    <TableCell align="center">
                                        { event.userId &&
                                        <Link to={`/overview/portal-users/${event.userId}`} className={buttonStyles.altLgNarrow}>Details</Link>
                                        }
                                    </TableCell>
                                    <TableCell align="left">{(event.env !== null) ? event.env : '-'}</TableCell>
                                    { event.screens ?
                                    <Screens screens={event.screens} filter={null} portal={true} />
                                    :
                                    <TableCell align="left"><Lozenge colour={getColour(portalEventAttrs, event)} text={getText(event)} /></TableCell>
                                    }
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                }
            </OnLoadViewer>
            { error &&
            <div className="text-red-600">
                {graphQLErrorAsText(error)}
            </div>
            }
        </>
    )
}
