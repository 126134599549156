import {useState, useRef, useEffect} from "react";

import {useQuery} from "@apollo/react-hooks";
import { onSnapshot, query, where, orderBy } from "firebase/firestore"

import {OnLoadViewer, graphQLErrorAsText, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, dateToString, cellStyles, Lozenge} from "gih_web_common";

import { GET_APP_USER_BY_ID, GET_EVENTS_FOR_USER } from "../../../utils/graphql/admin-user";

import {chats} from "../../../utils/firebase";
import {logActionGraphQLFailure} from "../../../utils/analytics";
import {ACTIONS} from "../../../utils/analyticsConstants";

import {AppUserTable} from "../common/user-table";
import {appNoteworthyEventAttrs, Screens, getText, getColour} from "../common/events";


export function AppUser({userId}) {

    const [user, setUser] = useState(null)
    const [participationsByActivityId, setActivitiesById] = useState(null)

    const {loading, refetch} = useQuery(GET_APP_USER_BY_ID, {
        variables: {
            userId: userId
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            const byId = new Map()
            data.portalGetAppUserById.participations.forEach(p => {
                byId.set(p.activityId._id.toString(), p)
            })
            setActivitiesById(byId)
            setUser(data.portalGetAppUserById)
        }
    })

    return (
        <OnLoadViewer loading={loading}>
            { user &&
            <>
                <div className="ml-2 text-xl sm:text-2xl font-bold">Details</div>
                <AppUserTable users={[user]} refetch={() => refetch()} withActions={false} />
                { participationsByActivityId.size > 0 &&
                <>
                    <div className="ml-2 mt-3 text-xl sm:text-2xl font-bold">Chats</div>
                    <AppUserChats firebaseId={user.user.firebaseId} participationsByActivityId={participationsByActivityId} />
                </>
                }
            </>
            }
        </OnLoadViewer>
    )
}

function AppUserChats({firebaseId, participationsByActivityId}) {

    const [chats_, setChats] = useState([]);

    const chatsRef = useRef([]);
    chatsRef.current = chats_;

    useEffect(() => {

        if (firebaseId !== null) {

            console.log(`Subscribing to chats list for user ${firebaseId}`)
            setChats([])

            const unsubscribe = onSnapshot(query(chats, where("users", "array-contains", firebaseId), orderBy("timestamp", "asc")), (s) => {

                const chatsList = [...chatsRef.current];

                s.docChanges().forEach((change) => {

                    const chat = {
                        id: change.doc.id,
                        ...change.doc.data(),
                        participation: participationsByActivityId.get(change.doc.data().activity_id)
                    }

                    switch (change.type) {
                        case "added":
                        {
                            console.log('addingChat', chat)
                            chatsList.unshift(chat)
                            break;
                        }

                        case "removed":
                        {
                        
                            break;
                        }

                        case "modified":
                        {
                            const index = chatsList.findIndex(item => item.id === chat.id);
                            if (index !== -1) {
                                console.log('updatingChat', chat)
                                chatsList[index] = chat;
                            } else {
                                console.log('updatingForUnknownChat', chat)
                            }
                            break;
                        }

                        default:
                            break;
                    }
                })
            
                setChats(chatsList);
            })

            return () => {
                if (unsubscribe) {
                    console.log('Unsubscribing from chats list')
                    unsubscribe()
                }
            }
        }

    }, [firebaseId])

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={cellStyles.dateTime}>Created</TableCell>
                        <TableCell sx={cellStyles.dateTime}>Timestamp</TableCell>
                        <TableCell sx={cellStyles.freeMedium}>Activity</TableCell>
                        <TableCell sx={cellStyles.fixedMedium}>Unread</TableCell>
                        <TableCell sx={cellStyles.freeWide}>Last message</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { chats_.map(chat => (
                        <TableRow key={chat.id}>
                            <TableCell align="left">{chat.participation ? dateToString(chat.participation.createdAt) : '<Missing>'}</TableCell>
                            <TableCell align="left">{dateToString(chat.timestamp.toDate())}</TableCell>
                            <TableCell align="left">{chat.participation ? chat.participation.activityId.name : '<Missing>'}</TableCell>
                            <TableCell align="left">
                                { chat.unread_charity > 0 &&
                                <Lozenge colour="bg-green-500" text={`Charity: ${chat.unread_charity}`} />
                                }
                                { chat.unread_user > 0 &&
                                <Lozenge colour="bg-blue-500" text={`Volunteer: ${chat.unread_user}`} />
                                }
                            </TableCell>
                            <TableCell align="left">{chat.lastMessage.message}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export function AppUserEventsTable({start, end, userId}) {

    const [events, setEvents] = useState(null);

    const {loading, error} = useQuery(GET_EVENTS_FOR_USER, {
        variables: {
            start: start,
            end: end,
            userId: userId,
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted: data => {
            setEvents(data.eventsForUser);
        },
        onError: e => {
           logActionGraphQLFailure(ACTIONS.superAdminStatsQuery, e);
           setEvents(null);
        }
    });

    return (
        <>
            <OnLoadViewer loading={loading}>
                { events &&
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={cellStyles.daetTime}>When</TableCell>
                                <TableCell sx={cellStyles.freeWide}>Environment</TableCell>
                                <TableCell sx={cellStyles.freeWide}>What</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { events.map(event => (
                                <TableRow key={event.when}>
                                    <TableCell align="left">{dateToString(event.when)}</TableCell>
                                    <TableCell align="left">{(event.env !== null) ? event.env : '-'}</TableCell>
                                    { event.screens ?
                                    <Screens screens={event.screens} filter={null} portal={false} />
                                    :
                                    <TableCell align="left"><Lozenge colour={getColour(appNoteworthyEventAttrs, event)} text={getText(event)} /></TableCell>
                                    }
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                }
            </OnLoadViewer>
            { error &&
            <div className="text-red-600">
                {graphQLErrorAsText(error)}
            </div>
            }
        </>
    )
}
