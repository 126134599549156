import {useDispatch, useSelector} from "react-redux";
import {useMutation} from "@apollo/react-hooks";

import {ModalForm, FormInput, FormPrimaryButton, nameStr} from "gih_web_common";

import {UPDATE_USER_DETAILS} from "../../../utils/graphql/user";
import {logActionSuccess, logActionGraphQLFailure, logFormValidationFailure} from "../../../utils/analytics";
import {ACTIONS} from "../../../utils/analyticsConstants";


export function SetAliasModal({onDismiss}) {

    const dispatch = useDispatch();
    const user = useSelector(state => state.user);

    const [updateUserDetails] = useMutation(UPDATE_USER_DETAILS);

    function setUserAlias(values) {
        const alias = values.alias.trim();
        return updateUserDetails({
            variables: {
                firebaseId: user.firebaseId,
                firstName: user.firstName,
                lastName: user.lastName,
                alias: alias ? alias : null,
                enableConcierge: user.enableConcierge,
            }
        }).then((response) => {
            const updates = response.data.portalUpdateUserDetails;
            logActionSuccess(ACTIONS.userUpdate, `for user ${nameStr(updates)} (alias "${updates.alias}")`);
            dispatch({
                type: "SET_USER",
                payload: {
                    ...user,
                    ...updates,
                }
            });
            onDismiss();
        }, e => {
            logActionGraphQLFailure(ACTIONS.userUpdate, e);
            throw e;
        });
    }

    return (
        <ModalForm
            title="Set an alias for your messages to volunteers"
            initialValues={ { alias: user.alias ?? ""} }
            onDismiss={onDismiss}
            onSubmit={setUserAlias}
            onValidationFailure={logFormValidationFailure}
            footer={
                <FormPrimaryButton type="submit" className="mx-auto" alt={true}>
                    {user.alias ? "Update" : "Set"} alias
                </FormPrimaryButton>
            }
        >
            <div>Enter an alias that will appear as the message sender or leave blank to use your name.</div>
            <FormInput
                id="alias"
                name="alias"
                type="text"
                placeholder="alias"
                label="Alias"
                rootClassName="w-full"
            />
        </ModalForm>
    )
}
