import {useState} from "react";
import {DateTime} from "luxon";

import {useQuery} from "@apollo/react-hooks";

import {OnLoadViewer, TableContainer2, Table, TableHead, TableBody, TableRow, TableCell, RefreshCell, dateToString, cellStyles} from "gih_web_common";

import {GET_EMAILS_BY_SENT_DATE} from "../../../utils/graphql/admin-user";

import {CharityCell} from "../../common/elements";
import {StatusLozenge, InteractionLozenge} from "../../common/email";


export function EmailsRecentlySent({days}) {

    const now = DateTime.now();

    const {refetch, loading, data, error} = useQuery(GET_EMAILS_BY_SENT_DATE, {
        variables: {
            start: now.minus({days:days}).startOf('day').toJSDate(),
            end: now.endOf('day').toJSDate(),
        },
    });
    const emails = data?.portalGetEmailsBySentDate;

    return (
        <TableContainer2
            loading={loading}
            error={error}
            rows={emails}
            emptyText={`None sent within the last ${days} days`}
            refresh={refetch}
            sx={{maxHeight: 740}}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <RefreshCell sx={cellStyles.dateTime} action={refetch} text="Created" />
                        <TableCell sx={cellStyles.charity}>Charity</TableCell>
                        <TableCell sx={cellStyles.email}>E-mail</TableCell>
                        <TableCell sx={cellStyles.fixedMedium}>Category</TableCell>
                        <TableCell sx={cellStyles.fixedMedium}>Status</TableCell>
                        <TableCell sx={cellStyles.freeWide}>Interactions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { emails?.map(email => (
                        <TableRow key={email.trace._id}>
                            <TableCell align="left">{dateToString(email.trace.createdAt)}</TableCell>
                            <CharityCell charity={email.charityId} />
                            <TableCell align="left">{email.trace.to}</TableCell>
                            <TableCell align="left">{email.trace.category}</TableCell>
                            <TableCell align="left"><StatusLozenge status={email.trace.status} lastChange={email.trace.lastStatusChange} /></TableCell>
                            <TableCell align="left">
                                { email.trace.openCount > 0 &&
                                <InteractionLozenge colour="bg-amber-600" what="Read" count={email.trace.openCount} last={email.trace.lastOpen} />
                                }
                                { email.trace.clickCount > 0 &&
                                <InteractionLozenge colour="bg-green-600" what="Clicked" count={email.trace.clickCount} last={email.trace.lastClick} />
                                }
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer2>
    );
}
