import {DateTime} from "luxon";

import {useState, useEffect} from "react";
import {useMediaQuery} from 'react-responsive'
import {useSelector} from "react-redux";

import {onSnapshot, orderBy, query, where} from "firebase/firestore";
import {useQuery} from "@apollo/react-hooks";

import {nameStr} from "gih_web_common";

import {logScreenViewEvent} from "../../../utils/analytics";
import {SCREEN_NAME, SCREEN_CLASS} from "../../../utils/analyticsConstants";
import {GET_WORK_FOR_CHARITY_BY_USER} from "../../../utils/graphql/charity";
import {chats} from "../../../utils/firebase";

import MessagePane from "../common/message-pane";

import ChatListPane from "./chat-list-pane";


export default function ChatsByVolunteer() {

    const isNarrow = useMediaQuery({ query: '(max-width: 1280px)' })
    const charity = useSelector(state => state.charity);

    const [activeChat, setActiveChat_] = useState(null);

    function setActiveChat(what) {
        if (!activeChat || !what || what.id !== activeChat.id) {
            setActiveChat_(what)
        }
    }

    useEffect(() => {
        logScreenViewEvent(SCREEN_NAME.manageVolunteers, SCREEN_CLASS.volunteers)
    }, [])

    const now = DateTime.now()

    const [rankedUsers, setRankedUsers] = useState(null);
    const [activeUser, setActiveUser] = useState(null);

    const {loading: workByUserLoading} = useQuery(GET_WORK_FOR_CHARITY_BY_USER, {
        variables: {
            charityId: charity.id,
            start: now.minus({months: 11}).startOf('month').toJSDate(),
            end: now.endOf('month').toJSDate(),
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            if (data) {
                let users = []
                data.workForCharityByUser.forEach((user) => {
                    users.push({
                        firebaseId: user.userId.firebaseId,
                        name: nameStr(user.userId),
                        minutesWorked: user.minutesWorked,
                        activities: user.activities
                    })
                })
                setRankedUsers(users)
            }
        }
    })

    useEffect(() => {
        if (rankedUsers) {
            
            let userAndActivityByChatId = new Map()

            rankedUsers.forEach((user) => {
                user.chats = []
                user.totalUnread = 0
                user.activities.forEach((activity) => {
                    userAndActivityByChatId.set((user.firebaseId + "_" + activity.info._id), {
                        user: user,
                        activity: activity
                    })
                })
            })

            console.log("Loading and starting chats watcher")
            let syncSeq = 0

            const unsubscribe = onSnapshot(query(chats, where("charityId", "==", charity.id), orderBy("timestamp", "desc")), (s) => {

                s.docChanges().forEach((change) => {

                    let r = userAndActivityByChatId.get(change.doc.id)

                    if (r) {

                        let chat = {
                            id: change.doc.id,
                            activity: r.activity.info,
                            minutesWorked: r.activity.minutesWorked,
                            ...change.doc.data()
                        }

                        switch (change.type) {
                            case "added":
                            {
                                if (syncSeq === 0) {
                                    console.log(`Pushing chat ${chat.id} to user ${r.user.name}`)
                                    r.user.chats.push(chat)
                                } else {
                                    let index = r.user.chats.findIndex((item) => item.timestamp < chat.timestamp)
                                    console.log(`Inserting chat ${chat.id} at index ${index} for user ${r.user.name}`)
                                    r.user.chats.splice(index, 0, chat)
                                }
                                r.user.totalUnread += chat.unread_charity
                                break;
                            }

                            case "removed":
                            {
                                let existingIndex = r.user.chats.findIndex((item) => item.id === chat.id)
                                if (existingIndex >= 0) {
                                    let removed = r.user.chats.splice(existingIndex, 1)
                                    console.log(`Updating chat ${chat.id} for user ${r.user._id} old unread ${removed[0].unread_charity}`)
                                    r.user.totalUnread -= removed[0].unread_charity
                                } else {
                                    console.log(`Removal received for chat ${chat.id} not in the chats list for user ${r.user.name}`)
                                }
                                break;
                            }

                            case "modified":
                            {
                                let existingIndex = r.user.chats.findIndex((item) => item.id === chat.id)
                                if (existingIndex >= 0) {
                                    let removed = r.user.chats.splice(existingIndex, 1)
                                    chat.participationStatus = removed[0].participationStatus
                                    console.log(`Updating chat ${chat.id} in user ${r.user.name} old unread ${removed[0].unread_charity} new unread ${chat.unread_charity}`)
                                    r.user.chats.unshift(chat)
                                    r.user.totalUnread -= removed[0].unread_charity
                                    r.user.totalUnread += chat.unread_charity
                                } else {
                                    console.log(`Update received for chat ${chat.id} not in the chats list for user ${r.user.name}`)
                                }
                                break;
                            }

                            default:
                                break;
                        }
                    } else {
                        //console.log(`Ignoring chat ${change.doc.id} not in set derived from users who have worked recently`)
                    }
                })
            })

            return () => {
                if (unsubscribe) {
                    console.log('Unsubscribing from chat list')
                    unsubscribe();
                }
            }

        }
    }, [rankedUsers, charity?.id]);

    return (
        <div className="flex flex-wrap xl:grid xl:grid-cols-[minmax(400px,600px)_minmax(600px,1fr)] w-full h-full overflow-hidden">
            { (!isNarrow || activeChat === null) &&
            <div className="w-full">
                <div className="flex items-center w-full px-2 h-[56px]">
                    <p className="text-3xl font-bold">Manage Volunteers</p>
                </div>
                <ChatListPane rankedUsers={rankedUsers}
                              activeUser={activeUser}
                              activeChat={activeChat}
                              setActiveUser={setActiveUser}
                              setActiveChat={setActiveChat}
                />
            </div>
            }
            <div className="xl:border-l xl:border-gray-400 w-full">
                { activeChat !== null &&
                <MessagePane activeChat={activeChat}
                             submitRequestedStatus={null}
                             requestedStatus={null}
                             showStatus={false}
                             backButton={ isNarrow ? () => setActiveChat(null) : null }
                             loading={workByUserLoading}
                />
                }
            </div>
        </div>
    )
}
