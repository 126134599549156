import {Fragment, useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import Textarea from 'react-expanding-textarea'
import {osName, OsTypes, BrowserView, isDesktop} from 'react-device-detect';
import {useMediaQuery} from 'react-responsive'
import {use100vh} from 'react-div-100vh'

import {Menu, Transition} from "@headlessui/react";

import {FiSend, FiCheckCircle, FiXCircle} from "react-icons/fi";
import {GrDocumentVerified} from "react-icons/gr";
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import BedtimeOutlinedIcon from '@mui/icons-material/BedtimeOutlined';

import {
    doc,
    onSnapshot,
    query,
    collection,
    orderBy,
    increment,
    runTransaction,
    serverTimestamp
} from "firebase/firestore"

import { DateTime } from "luxon";

import {buttonStyles, inputStyles, OnLoadViewer, AlertModal, nameStr} from "gih_web_common";

import {firestore, chats} from "../../../utils/firebase";
import {statusToText, statusToBackground, canAccept, canDecline, canWaitApplication, canCheckOrTrain, canNoResponse} from "../../../utils/activity";

import ChatMessage from "./message";
import {UserAvatar} from "./avatar";

import {ManualCheckinModal} from "./manual-checkin";
import {SetAliasModal} from "./set-alias";


const menuItems = [
    {
        icon: PostAddOutlinedIcon,
        colour: "yellow",
        text: "Waiting application",
        newStatus: "waitApplication",
        allowed: status => canWaitApplication(status)
    },
    {
        icon: GrDocumentVerified,
        colour: "amber",
        text: "Checks/training",
        newStatus: "checking",
        allowed: status => canCheckOrTrain(status)
    },
    {
        icon: BedtimeOutlinedIcon,
        colour: "gray",
        text: "Gone away/no response",
        newStatus: "noResponse",
        allowed: status => canNoResponse(status)
    },
    {
        icon: FiCheckCircle,
        colour: "green",
        text: "Accept",
        newStatus: "accept",
        allowed: status => canAccept(status),
    },
    {
        icon: FiXCircle,
        colour: "red",
        text: "Decline",
        newStatus: "decline",
        allowed: status => canDecline(status),
    }
]

function isMacOs() {
    return osName === OsTypes.MAC_OS
}

function clearUnread(activeChat, user) {
    const chatRef = doc(chats, activeChat.id)
    runTransaction(firestore, async transaction => {
        const r = await transaction.get(chatRef)
        if (!r.exists) {
            throw new Error('Chat no longer exists')
        }
        const chat = r.data()
        if (chat.unread_charity > 0) {
            console.log(`Clearing unread count of ${chat.unread_charity} for chat ${r.id}`)
            transaction.update(chatRef, {
                unread_charity: 0,
                charity_read_timestamp: serverTimestamp()
            })
        } else {
            console.log(`Unread count for chat ${r.id} is already zero`)
        }
    }).catch(e => {
        console.log(`Failed to clear unread count: ${e}`)
    })
}

export default function MessagePane(props) {

    const height = use100vh();
    const isNarrow = useMediaQuery({ query: '(max-width: 640px)' })
    
    const user = useSelector(state => state.user);

    const [messages, setMessages] = useState([]);
    const [textMessage, setTextMessage] = useState("");

    const [showAliasModal, setShowAliasModal] = useState(false);
    const [showCheckinModal, setShowCheckinModal] = useState(false);
    const [showCheckinSuccessModal, setShowCheckinSuccessModal] = useState(false);

    const tRef = DateTime.now().endOf('minute')

    const messagesRef = useRef([]);
    const chatContainerRef = useRef(null);
    messagesRef.current = messages;

    useEffect(() => {
        setMessages([])
        setTextMessage("")

        if (props.activeChat) {
            console.log(`Active chat now ${props.activeChat.id}`)
            let lastReadIndex = null

            const unsubscribe = onSnapshot(query(collection(chats, props.activeChat.id, "messages"), orderBy("timestamp", "desc")), (s) => {
                if (s.docChanges().length === 0) {
                    console.log('Zero change set')
                    setMessages([]);
                } else {
                    const volunteerUID = props.activeChat.users[0];
                    let messagesList = [...messagesRef.current];
                    let added = false;

                    s.docChanges().forEach((d) => {
                        const doc = d.doc.data()
                        const msg = {
                            id: doc.id,
                            ...doc,
                            timestamp: doc.timestamp.toDate(),
                            isMine: doc.uid !== volunteerUID,
                            isLastRead: false,
                        }
                        switch (d.type) {
                            case "added":
                                if (messagesList.length > 0 && messagesList[messagesList.length - 1].timestamp.getTime() < msg.timestamp.getTime()) {
                                    messagesList.push(msg);
                                } else {
                                    messagesList.splice(0, 0, msg);
                                }
                                added = true
                                break;
    
                            case "removed":
                            case "modified":
                                let index = messagesList.findIndex((value) => value.id === d.doc.id);
                                if (index !== -1) {
                                    messagesList[index] = msg;
                                }
                                break;

                            default:
                                break;
                        }
                    })

                    if (added) {
                        if (user.fullAccess) {
                            console.log("Not clearing unread count - super-admin user")
                        } else {
                            clearUnread(props.activeChat, user)
                        }
                    }

                    let unread = props.activeChat.unread_user
                    console.log(`Unread count is ${unread}`)

                    for (let i = messagesList.length - 1; i >= 0; i--) {
                        const msg = messagesList[i];
                        if (msg.isMine) {
                            if (unread > 0) {
                                unread--;
                            } else {
                                if (lastReadIndex !== i) {
                                    console.log(`Last read index change from ${lastReadIndex} -> ${i}`)
                                    if (lastReadIndex !== null) {
                                        messagesList[lastReadIndex].isLastRead = false
                                    }
                                    lastReadIndex = i
                                    messagesList[lastReadIndex].isLastRead = true
                                }
                                break;
                            }
                        }
                    }

                    setMessages(messagesList);
                }
            })

            return () => {
                if (unsubscribe) {
                    console.log('Unsubscribing from chat')
                    unsubscribe()
                }

            }
        }

    }, [props.activeChat])

    useEffect(() => {
        chatContainerRef.current.scrollTo({
            top: chatContainerRef.current.scrollHeight,
            behavior: "smooth"
        });
    }, [messages]);


    function sendMessage(value) {

        value.preventDefault();

        let message = textMessage
        console.log(`Sending message: "${message}"`)
        setTextMessage("")

        const when = new Date();
        const chatRef = doc(chats, props.activeChat.id)

        return runTransaction(firestore, async transaction => {

            const chat = await transaction.get(chatRef)

            if (!chat.exists) {
                throw new Error('Chat no longer exists')
            }

            let changes = {
                timestamp: when,
                lastMessage: {message: message, uid: user.firebaseId},
                unread_user: increment(1),
            }

            let users = chat.data().users
            let userNames = chat.data().user_names

            let senderIndex = users.indexOf(user.firebaseId)
            let senderName = user.alias ?? nameStr(user)

            if (senderIndex === -1) {
                console.log("Adding new charity user to chat")
                users.push(user.firebaseId)
                userNames.push(senderName)
            } else if (userNames[senderIndex] !== senderName) {
                console.log("Updating charity user's name in chat")
                userNames[senderIndex] = senderName
            } else {
                users = null
                userNames = null
            }

            if (users !== null) {
                changes.users = users
                changes.user_names = userNames
            }

            transaction.update(chatRef, changes)

            const newMessageRef = doc(collection(chatRef, "messages"))

            transaction.set(newMessageRef, {
                uid: user.firebaseId,
                timestamp: when,
                text: message
            })
            
            props.activeChat.unread_user++;
        })
    }

    return (
        <div className="relative w-full" style={{ height: `${height - 64}px`}}>
            <div className="flex justify-between w-full px-2 h-[76px] sm:h-[56px] border-b border-gray-400">
                <div className="flex space-x-3 items-center">
                    { props.backButton !== null &&
                    <button onClick={() => { props.backButton() }}>
                        <ArrowCircleLeftOutlinedIcon fontSize="large" />
                    </button>
                    }
                    <UserAvatar chat={props.activeChat} />
                    <div className="flex flex-wrap items-center">
                        <div className="overflow-hidden max-h-full text-sm md:text-base font-bold">{props.activeChat.user_names[0]}</div>
                        <div className="overflow-hidden text-ellipsis max-h-[36px] ml-1 text-xs md:text-sm">({props.activeChat.activity.name})</div>
                    </div>
                </div>
                <div className="flex space-x-1 sm:space-x-3 items-center ml-1">
                    { user.fullAccess &&
                    <>
                        <button onClick={() => { clearUnread(props.activeChat, user) }} className={`${buttonStyles.altLgNarrow} hidden 2xl:inline`}>
                            ReadAll
                        </button>
                        <Link to={`/overview/app-users/${props.activeChat.userId}`} className={`${buttonStyles.altLgNarrow} hidden 2xl:inline`}>
                            Detail
                        </Link>
                    </>
                    }
                    <button onClick={() => { setShowCheckinModal(true) }} className={buttonStyles.altLgNarrow}>
                        Checkin
                    </button>
                    <OnLoadViewer loading={props.requestedStatus !== null}>
                        <Menu as="div" className="relative">
                            { props.showStatus &&
                            <Menu.Button className={`${buttonStyles.menu} text-white ${statusToBackground(props.activeChat.participationStatus, true)}`}>
                                <span className="sr-only">
                                    Open volunteer status menu
                                </span>
                                <span>{`${isDesktop ? "Status: " : ""}${statusToText(props.activeChat.participationStatus)}`}</span>
                                <svg className="-mr-1 ml-1 h-4 w-4"
                                     xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 20 20"
                                     fill="currentColor"
                                     aria-hidden="true">
                                    <path fillRule="evenodd"
                                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                          clipRule="evenodd"
                                    />
                                </svg>
                            </Menu.Button>
                            }
                            { props.activeChat.canSetStatus &&
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-300"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-300"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute right-0 w-60 rounded-md shadow-lg bg-white z-[50]">
                                { menuItems.map(item => {
                                    if (item.allowed(props.activeChat.participationStatus)) {
                                        const Icon = item.icon
                                        return <Menu.Item key={item.newStatus}>
                                            {({active}) => (
                                                <div className={`flex w-full py-2 px-2 space-x-3 items-center ${active ? "bg-alt-dark text-white": "text-black"}`}
                                                     onClick={ () => { props.submitRequestedStatus(item.newStatus) }}
                                                >
                                                    <Icon fontSize="large" size={30} color={item.colour}/>
                                                    <div className="text-s font-medium">{item.text}</div>
                                                </div>
                                            )}
                                        </Menu.Item>
                                    } else {
                                        return <></>
                                    }
                                })}
                                </Menu.Items>
                            </Transition>
                            }
                        </Menu>
                    </OnLoadViewer>
                </div>
            </div>

            <div ref={chatContainerRef} className="flex flex-col overflow-y-auto py-3 overscroll-contain" style={{
                height: `${height - 64 - (isNarrow ? 76 : 56) - (isDesktop ? 84 : 0) - 58}px`
            }}>
                { messages.map((msg, index) => {
                    return <div key={msg.id} style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: msg.uid !== props.activeChat.users[0] ? "flex-end" : "flex-start",
                        "whiteSpace": "pre-wrap"
                    }}>
                        { (index === 0 || (index !== 0 && (msg.timestamp.getTime() - messages[index - 1].timestamp.getTime()) > 1200000)) &&
                            <div style={{
                                margin: "8px 0",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center"
                            }}>
                                <p className="text-xs text-gray-400">{DateTime.fromJSDate(msg.timestamp).toFormat("LLL dd, HH:mm")}</p>
                            </div>
                        }
                        <ChatMessage
                            msg={msg}
                            next={(index >= messages.length - 1) ? null : messages[index + 1]}
                            previous={(index > 0) ? messages[index - 1] : null}
                            readTime={props.activeChat.user_read_timestamp?.toDate()}
                            tRef={tRef}
                        />
                    </div>
                })}
            </div>

            <div className="absolute z-1 w-[98%]" style={{ bottom: "0px" }}>
                <div className="flex p-2 bg-white">
                    <Textarea
                        className={`w-full ${inputStyles.text}`}
                        id="message_text"
                        name="message_text"
                        style={{resize:"none"}}
                        placeholder="Message..."
                        value={textMessage}
                        onChange={(event) => {
                            setTextMessage(event.target.value)
                        }}
                        onKeyDown={(event) => {
                            if (isMacOs()) {
                                if (event.altKey && event.key === 'Enter') {
                                    sendMessage(event)
                                }
                            } else {
                                if (event.shiftKey && event.key === 'Enter') {
                                    sendMessage(event)
                                }
                            }
                        }}
                        autoComplete="off"
                    />
                    <button className="px-2 py-1" onClick={sendMessage}>
                        <FiSend size={30} className="text-alt-default hover:text-alt-dark"/>
                    </button>
                </div>

                <BrowserView>
                    <div className="flex px-2 justify-between font-bold text-gray-500 text-xs sm:text-base">
                        <span>{isMacOs() ? "Return to add a new line" : "Enter to to add a new line"}</span>
                        <span>{isMacOs() ? "⌥(alt) + Return to send" : "Shift + Enter to send"}</span>
                    </div>

                    <div className="flex p-2 space-x-2 items-center justify-center text-xs sm:text-sm">
                        {
                            props.activeChat.buttons.map((b, index) => {
                                return <button onClick={() => setTextMessage(b.msg)}
                                        key={index}
                                        className="bg-alt-dark hover:bg-alt-darker text-white font-semibold py-2 px-3 border rounded-md">
                                    {b.title}
                                </button>
                            })
                        }
                        <div className="flex px-2">
                            <span className="text-black border-2 border-alt-default font-bold py-2 px-3 rounded-l-lg">Message sender will appear as: {user.alias ?? nameStr(user) }</span>
                            <button onClick={() => {
                                setShowAliasModal(true)
                            }}
                                    className="bg-alt-dark hover:bg-alt-darker text-white font-bold py-2 px-3 rounded-r-lg">
                                Change Alias
                            </button>
                        </div>
                    </div>
                </BrowserView>
            </div>

            { showAliasModal &&
                <SetAliasModal
                    onDismiss={() => {
                        setShowAliasModal(false)
                    }}
                />
            }

            { showCheckinModal &&
                <ManualCheckinModal
                    activity={props.activeChat.activity}
                    user={{
                        firebaseId: props.activeChat.users[0],
                        name: props.activeChat.user_names[0]
                    }}
                    onDismiss={() => {
                        setShowCheckinModal(false)
                    }}
                    onSuccess={() => {
                        setShowCheckinModal(false)
                        setShowCheckinSuccessModal(true)
                    }}
                />
            }

            { showCheckinSuccessModal &&
                <AlertModal
                    type="success"
                    message="Volunteer was successfully checked in"
                    onDismiss={() => setShowCheckinSuccessModal(false)}
                />
            }
        </div>
    )
}
