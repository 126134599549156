import {useEffect, useState, useRef} from "react";
import {useMediaQuery} from 'react-responsive'
import {useSelector} from "react-redux";

import {useQuery, useMutation} from "@apollo/react-hooks";

import {onSnapshot, query, orderBy, where} from "firebase/firestore"

import {logScreenViewEvent, logActionSuccess, logActionGraphQLFailure} from "../../../utils/analytics";
import {ACTIONS, SCREEN_NAME, SCREEN_CLASS} from "../../../utils/analyticsConstants";
import {GET_ACTIVITY_LIST_BY_CHARITYID, UPDATE_USER_PARTICIPATION_STATUS} from "../../../utils/graphql/activity";
import {GET_ALL_PARTICIPATION_CHARITYID} from "../../../utils/graphql/charity";
import {chats} from "../../../utils/firebase";
import {groupActivities, getButtons, nullCounts, updateCounts} from "../../../utils/activity";
import {evictAllDependentOnParticipationStatus} from "../../../utils/graphql/cache";

import {graphQLCache} from "../../../index";
import MessagePane from "../common/message-pane";
import ChatListPane from "./chat-list-pane";


function applyParticipations(activitiesById, participations) {
    console.log(`Updating participation counts on ${activitiesById.size} activities from ${participations.length} participations`)
    activitiesById.forEach((activity) => {
        activity.participations = nullCounts()
    })
    participations.forEach((participation) => {
        let activity = activitiesById.get(participation.activityId._id)
        if (activity) {
            updateCounts(activity, participation.status, 1)
            let chat = activity.chats.find((chat) => chat.users[0] === participation.firebaseId)
            if (chat) {
                chat.participationStatus = participation.status
                chat.profilePicture = participation.userId?.profilePicture
                chat.userId = participation.userId?._id
            }
        }
    })
}

function setParticipationStatus(activitiesById, activeChat, status, charity) {
    let activity = activitiesById.get(activeChat.activity_id)

    if (!activity) {
        console.log(`No activity with id ${activeChat.activity_id}`)
        return
    }

    let chat = activity.chats.find((chat) => chat.id === activeChat.id)
    
    if (!chat) {
        console.log(`No chat with id ${activeChat.id} under activity ${activeChat.activity_id}`)
        return
    }

    updateCounts(activity, chat.participationStatus, -1)
    chat.participationStatus = status
    updateCounts(activity, chat.participationStatus, 1)

    activeChat.participationStatus = status
    activeChat.buttons = getButtons(activity, status, charity)
}


export default function ChatsByActivity() {

    const charity = useSelector(state => state.charity);
    const isNarrow = useMediaQuery({ query: '(max-width: 1280px)' })

    const [activeChat, setActiveChat_] = useState(null);
    const [requestedStatus, setRequestedStatus] = useState(null);

    function submitRequestedStatus(status) {
        return new Promise((resolve) => {
            setRequestedStatus(status)
            resolve("Success")
        })
    }

    function setActiveChat(what) {
        if (!activeChat || !what || what.id !== activeChat.id) {
            setActiveChat_(what)
        }
    }

    useEffect(() => {
        logScreenViewEvent(SCREEN_NAME.manageVolunteersByActivity, SCREEN_CLASS.volunteers)
    }, [])

    const {loading: activityListLoading, data: activityListResponse} = useQuery(GET_ACTIVITY_LIST_BY_CHARITYID, {
        variables: {
            charityId: charity.id
        },
    });

    const {loading: userParticipationLoading, data: userParticipationResponse, refetch: userParticipationRefetch} = useQuery(GET_ALL_PARTICIPATION_CHARITYID, {
        variables: {
            charityId: charity.id
        },
        fetchPolicy: "cache-and-network",
        notifyOnNetworkStatusChange: true,
        onCompleted: () => {
            console.log('Participations request complete')
        }
    });

    const [updateUserParticipation] = useMutation(UPDATE_USER_PARTICIPATION_STATUS);

    const [activitiesByGroup, setActivitiesByGroup] = useState(null);
    const [activitiesById, setActivitiesById] = useState(null);
    const [activeActivity, setActiveActivity] = useState(null);
    const [chatSyncSeq, setChatSyncSeq] = useState(0);
    const initialParticipationsSync = useRef(false);

    useEffect(() => {        
        if (chatSyncSeq > 0) {
            //console.log('chatSyncSeq', chatSyncSeq, initialParticipationsSync)
            const participations = userParticipationResponse?.getAllParticipationByCharityId

            if (participations) {
                applyParticipations(activitiesById, participations)
                if (!initialParticipationsSync.current) {
                    setActivitiesByGroup(groupActivities(Array.from(activitiesById.values())))
                    initialParticipationsSync.current = true
                }
            }
        }
    }, [chatSyncSeq, userParticipationResponse, activitiesById])

    useEffect(() => {
        if (activityListResponse?.portalFindActivitiesByCharityId) {

            const activitiesById_ = new Map()

            activityListResponse.portalFindActivitiesByCharityId.forEach((activity) => {
                activitiesById_.set(activity._id, {
                    ...activity,
                    chats: [],
                    totalUnread: 0,
                    participations: nullCounts()
                })
            })

            setActivitiesById(activitiesById_)
            setActivitiesByGroup(null)
            setActiveActivity(null)
            setChatSyncSeq(0)

            console.log("Loading and starting chats watcher")
            let syncSeq = 0

            const unsubscribe = onSnapshot(query(chats, where("charityId", "==", charity.id), orderBy("timestamp", "desc")), (s) => {

                let addedSome = false

                s.docChanges().forEach((change) => {
                    const chat = {
                        id: change.doc.id,
                        participationStatus: null,
                        ...change.doc.data()
                    }

                    const activity = activitiesById_.get(chat.activity_id)

                    if (activity) {
                        switch (change.type) {
                            case "added":
                            {
                                const index = activity.chats.findLastIndex((item) => item.timestamp < chat.timestamp)
                                if (index < 0) {
                                    console.log(`Pushing chat ${chat.id} to activity ${activity._id}`)
                                    activity.chats.push(chat)
                                } else {
                                    console.log(`Inserting chat ${chat.id} at index ${index} in activity ${activity._id}`)
                                    activity.chats.splice(index, 0, chat)
                                }
                                activity.totalUnread += chat.unread_charity
                                addedSome = true
                                break;
                            }

                            case "removed":
                            {
                                let existingIndex = activity.chats.findIndex((item) => item.id === chat.id)
                                if (existingIndex >= 0) {
                                    const removed = activity.chats.splice(existingIndex, 1)
                                    console.log(`Updating chat ${chat.id} in activity ${activity._id} old unread ${removed[0].unread_charity}`)
                                    activity.totalUnread -= removed[0].unread_charity
                                } else {
                                    console.log(`Removal received for chat ${chat.id} not in the chats list for activity ${activity._id}`)
                                }
                                break;
                            }

                            case "modified":
                            {
                                let existingIndex = activity.chats.findIndex((item) => item.id === chat.id)
                                if (existingIndex >= 0) {
                                    const existing = activity.chats[existingIndex]
                                    console.log(`Updating chat ${chat.id} in activity ${activity._id} old unread ${existing.unread_charity} new unread ${chat.unread_charity}`)
                                    activity.totalUnread -= existing.unread_charity
                                    activity.totalUnread += chat.unread_charity
                                    if (existing.timestamp.toDate() !== chat.timestamp.toDate()) {
                                        console.log(`Moving chat ${chat.id} to top of list`)
                                        chat.participationStatus = existing.participationStatus
                                        activity.chats.splice(existingIndex, 1)
                                        activity.chats.unshift(chat)
                                    }
                                } else {
                                    console.log(`Update received for chat ${chat.id} not in the chats list for activity ${activity._id}`)
                                }
                                break;
                            }

                            default:
                                break;
                        }
                    } else {
                        console.log(`Ignoring chat ${chat.id} referencing unknown activity ${chat.activity_id}`)
                    }
                })

                if (syncSeq > 0 && addedSome) {
                    console.log('Requesting latest participations list')
                    userParticipationRefetch()
                }

                syncSeq++
                setChatSyncSeq(syncSeq)
            })

            return () => {
                if (unsubscribe) {
                    console.log('Unsubscribing from chat list')
                    unsubscribe();
                }
            }
        }
    }, [activityListResponse]);

    useEffect(() => {
        if (requestedStatus !== null) {
            console.log(`Requesting participation status change to "${requestedStatus}"`)
            setParticipationStatus(activitiesById, activeChat, null, charity)
            updateUserParticipation({
                variables: {
                    status: requestedStatus,
                    activityId: activeChat.activity_id,
                    firebaseId: activeChat.users[0],
                    charityId: charity.id
                }
            }).then(r => {
                const newStatus = r.data.updateParticipation.status;
                logActionSuccess(ACTIONS.participationStatusSet, `Updated participation status to "${newStatus}"`);
                setParticipationStatus(activitiesById, activeChat, newStatus, charity);
                evictAllDependentOnParticipationStatus(graphQLCache, charity.id);
                setRequestedStatus(null);
            }, e => {
                logActionGraphQLFailure(ACTIONS.participationStatusSet, e);
                throw e;
            });
        }
    }, [requestedStatus])

    return (
        <div className="flex flex-wrap xl:grid xl:grid-cols-[minmax(600px,700px)_minmax(600px,1fr)] w-full h-full overflow-hidden bg-white">
            { (!isNarrow || activeChat === null) &&
            <div className="w-full">
                <div className="flex items-center w-full px-2 h-[56px]">
                    <p className="text-3xl font-bold">Manage Volunteers</p>
                </div>
                <ChatListPane activitiesByGroup={activitiesByGroup}
                              activeActivity={activeActivity}
                              activeChat={activeChat}
                              setActiveActivity={setActiveActivity}
                              setActiveChat={setActiveChat}
                              loading={userParticipationLoading || activityListLoading}
                />
            </div>
            }
            <div className="xl:border-l xl:border-gray-400 w-full">
                { activeChat !== null &&
                <MessagePane activeChat={activeChat}
                             submitRequestedStatus={submitRequestedStatus}
                             requestedStatus={requestedStatus}
                             showStatus={true}
                             backButton={ isNarrow ? () => setActiveChat(null) : null }
                />
                }
            </div>
        </div>
    )
}
