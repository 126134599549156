import {useEffect, useState} from "react";
import {Link, useParams, useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {use100vh} from 'react-div-100vh'

import IosShareIcon from '@mui/icons-material/IosShare';
import PrintIcon from '@mui/icons-material/Print';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import AddIcon from '@mui/icons-material/Add';

import {useQuery, useMutation} from "@apollo/react-hooks";
import QRCODE from "qrcode";

import {
    buttonStyles,
    cellStyles,
    inputStyles,
    GlassCard,
    OnLoadViewer,
    Modal,
    ImageVCentre,
    VideoVCentre,
    addressToString,
    placesToString,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    AlertModal,
    dateToString,
    dateToStringNoTime,
    FormattedUserName,
    countToString,
    verbToString,
} from "gih_web_common";

import {
    GET_ACTIVITY_BY_ID,
    GET_ALL_PARTICIPATION_STATUS_BY_ACTIVITY,
    CANCEL_ACTIVITY,
    GET_WORK_FOR_ACTIVITY_BY_USER,
    GET_WORK_FOR_ACTIVITY_BY_OCCURRENCE
} from "../../utils/graphql/activity";

import minutesToString from "../../utils/duration";
import {rruleToText, fmtNextOccurrence} from "../../utils/repetition";
import {isCancelable, isEditable, workLocation2Text} from "../../utils/activity";
import {logScreenViewEvent, logActionSuccess, logActionGraphQLFailure} from "../../utils/analytics";
import {ACTIONS, SCREEN_NAME, SCREEN_CLASS} from "../../utils/analyticsConstants";
import {minutesToHours, BarGraph} from "../../utils/stats";
import {evictAllDependentOnActivity} from "../../utils/graphql/cache";
import {geohashToPlace} from "../../utils/google";

import {TitleAndBackButton} from "../common/elements";

import {Badge} from "./badge";
import {NotificationsHint} from "./notifications";


function getVolunteerManagementText(activity) {
    if (!activity.manageAvailability) {
        return null
    } else if (activity.requiredVolunteers) {
        return `Volunteers will be asked to indicate which sessions they can attend - sessions will show as full once ${countToString('person', activity.requiredVolunteers)} ${verbToString('say', activity.requiredVolunteers)} they are available`
    } else {
        return "Volunteers will be asked to indicate which sessions they can attend"
    }
}

function getAutoReplyText(activity) {
    if (!activity.autoReply) {
        return null;
    } else if (activity.autoReplyText) {
        return `Specific to this activity - "${activity.autoReplyText}"`;
    } else {
        return `Using the generic message from your charity profile.`
    }
}

function getItems(activity) {
    let items = [
        { title: "Name", text: activity.name },
        { title: "Description", text: activity.fullDescription },
        { title: "Duration", text: minutesToString(activity.minutes) },
        { title: "Age requirement", text: activity.ageRestricted ? "18+" : "16+"},
        fmtNextOccurrence(activity.eventDate, activity.scheduling),
        { title: "Event takes place", text: (activity.repetition !== null) ? rruleToText(activity.repetition, activity.scheduling) : null },
        { title: "Volunteer management", text: getVolunteerManagementText(activity) },
        { title: "Work location", text: workLocation2Text[activity.workLocation] },
        { title: "Address", text: (activity.workLocation !== "community") ? addressToString(activity.address) : null },
        { title: "Place(s) for location based search", text: placesToString(activity.places) },
        { title: "Created on", text: dateToString(activity.createdAt) },
        { title: "Created by", text: activity.createdBy },
        { title: "Updated on", text: (activity.updatedAt !== null) ? dateToString(activity.updatedAt) : null },
        { title: "Cancellation reason", text: activity.cancelled ? activity.cancelledReason : null },
        { title: "Keywords", text: activity.keywords.join(', ') },
        { title: "Hidden", text: activity.hidden ? "This activity is only visible to volunteers who have already signed up for it" : null},
        { title: "Full", text: activity.full ? "This activity is marked as full and new volunteers won't be able to sign up for it" : null},
        { title: "Automatic reply to new volunteers", text: getAutoReplyText(activity) },
    ]
    let odd = false
    items.forEach(item => {
        item.odd = odd
        if (item.text !== null) {
            odd = !odd
        }
    })
    return items
}

export default function ActivityDetailPage() {

    const height = use100vh();
    const {id} = useParams();

    const [canDelete, setCanDelete] = useState(false);
    const [activity, setActivity] = useState(null);

    const {loading: activityQueryInProgress, refetch: refetchActivity} = useQuery(GET_ACTIVITY_BY_ID, {
        variables: {
            activityId: id
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: async (data) => {
            const a = data.portalFindActivityById

            if (a.places.length < 1 || a.places[0].name !== null) {
                setActivity(a)

            } else {
                geohashToPlace(a.places[0].geohash, placeName => {
                    setActivity({
                        ...a,
                        places: [{
                            name: placeName,
                            qualifier: null,
                            geohash: a.places[0].geohash,
                        }],
                    })
                })
            }
        }
    })

    const {loading: userParticipationQueryInProgress} = useQuery(GET_ALL_PARTICIPATION_STATUS_BY_ACTIVITY, {
        variables: {
            activityId: id
        },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            setCanDelete(data.getAllParticipationStatusByActvityId.length === 0)
        }
    })

    useEffect(() => {
        logScreenViewEvent(SCREEN_NAME.activityDetail, SCREEN_CLASS.activity)
    }, [])

    return (
        <div className="p-1 lg:p-4 flex flex-col overflow-y-auto space-y-2" style={{ maxHeight: `${height - 64}px`}}>
            <OnLoadViewer loading={activityQueryInProgress || userParticipationQueryInProgress}>
                { activity &&
                <>
                    <div className="flex flex-wrap mx-auto xl:grid xl:grid-cols-[minmax(700px,1200px)_700px] gap-2">
                        <div className="w-full space-y-2">
                            <TitleAndBackButton title="Activity" />
                            <NotificationsHint />
                            <DetailsTable activity={activity} canDelete={canDelete} refetch={refetchActivity} />
                        </div>
                        <PictureAndQR activity={activity} />
                    </div>

                    <div className="mx-auto max-w-6xl w-full space-y-2">
                        <WorkTable activityId={id} />
                        <WorkStats activityId={id} />
                    </div>
                </>
                }
            </OnLoadViewer>
        </div>
    )
}

function PictureAndQR({activity}) {

    const charity = useSelector(state => state.charity);
    const imageURLPrefix = useSelector(state => state.cfg?.imageURLPrefix);

    const [qrLocalURL, setQRLocalURL] = useState(null);

    useEffect(() =>  {
        if (activity !== null) {
            QRCODE.toDataURL("gihtech://Checkin/" + activity._id).then((qrURL) => {
                setQRLocalURL(qrURL);
            });
        }
    }, [activity])

    return (
        <div className="mx-auto space-y-2 w-full">
            <GlassCard width="w-full">
                <div className="flex mx-auto w-fit grid grid-col-1 md:grid-cols-2 gap-2">
                    <ImageVCentre
                        rmtPrefix={imageURLPrefix}
                        rmt={activity.imageURL}
                        what="imagePreview"
                    >
                        <Badge activity={activity} />
                    </ImageVCentre>
                    { charity.features.allowActivityVideo &&
                    <VideoVCentre
                        rmtPrefix={imageURLPrefix}
                        rmt={activity.videoURL}
                        what="imagePreview"
                        placeholderSeverity="info"
                        placeholderText="Click 'Edit' to add a video - videos give prospective volunteers a much clearer understanding of what they would be doing."
                    />
                    }
                </div>
            </GlassCard>

            { isEditable(activity) && activity.workLocation === "onsite"  &&
            <GlassCard width="max-w-[500px] space-y-2">
                <div className="mx-auto w-fit">
                    <img src={qrLocalURL} alt="" width={120} height={120}/>
                </div>
                <div className="mx-auto px-2 pb-3 text-center">
                    Please use the button below to print this QR code on a full A4 sheet and display it prominently on the day(s) of the activity. Volunteers should scan it to check-in on arrival.
                </div>
                <Link to={`/activity/${activity._id}/qrcode`} className={buttonStyles.altLg}>
                    <PrintIcon/><span className="ml-2">Print code</span>
                </Link>
            </GlassCard>
            }
        </div>
    )
}

function DetailsTable({activity, canDelete, refetch}) {

    const history = useHistory();

    const [linkCopied, setLinkCopied] = useState(false);
    const [cancellationAction, setCancellationAction] = useState(null);
    const [cancellationOutcome, setCancellationOutcome] = useState(null);

    function dismissOutcome() {
        if (cancellationOutcome.deleted) {
            history.goBack()
        } else {
            refetch()
        }
        setCancellationOutcome(null)
    }

    return (
        <div>
            <GlassCard width="w-full">
                <dl>
                    { getItems(activity).map(item => ((item.text !== null) ?
                        <div key={item.title} className={`${item.odd ? "bg-white" : "bg-gray-100"} px-4 py-4 lg:grid lg:grid-cols-2`}>
                            <dt className="text-sm font-medium text-gray-500 mr-2">{item.title}</dt>
                            <dd className="text-sm text-gray-900 whitespace-pre-wrap">{item.text}</dd>
                        </div>
                        :
                        null
                    ))}

                    <div className="flex bg-gray-100 px-2 py-5 justify-center grid grid-cols-1 lg:grid lg:grid-cols-2 xl:grid-cols-4 gap-3">
                        { isEditable(activity) && isCancelable(activity) &&
                        <>
                            <Link to={`/activity/${activity._id}/edit`} className={buttonStyles.altLg}>
                                <ModeEditOutlineIcon/><span className="ml-1">Edit</span>
                            </Link>
                            <button onClick={() => { setCancellationAction(canDelete) }} className={buttonStyles.redLg}>
                                { canDelete ? <span><DeleteForeverIcon/> Delete</span> : <span><EventBusyIcon/> Cancel this activity</span>}
                            </button>
                        </>
                        }
                        { isEditable(activity) && activity.cancelled &&
                        <Link to={`/activity/${activity._id}/edit`} className={buttonStyles.altLg}>
                            <ModeEditOutlineIcon/><span className="ml-1">Edit and reinstate the event</span>
                        </Link>
                        }
                        <Link to={`/activity/create/${activity._id}`} className={buttonStyles.altLg}>
                            <AddIcon/><span className="ml-1">Create a duplicate</span>
                        </Link>
                        { isEditable(activity) && activity.dynamicLink &&
                        <CopyToClipboard text={activity.dynamicLink} onCopy={() => setLinkCopied(true)}>
                            <button className={buttonStyles.blueLg}>
                                <IosShareIcon /><span className="ml-1">{linkCopied ? "Link copied!" : "Get link & share"}</span>
                            </button>
                        </CopyToClipboard>
                        }
                    </div>
                </dl>
            </GlassCard>

            { cancellationAction !== null &&
            <CancelActivityModal
                delete={cancellationAction}
                activity={activity}
                setOutcome={ (outcome) => {
                    setCancellationAction(null)
                    setCancellationOutcome(outcome)
                }}
            />
            }

            { cancellationOutcome !== null &&
            <AlertModal
                type="success"
                message={`Activity was successfully ${cancellationOutcome.deleted ? "deleted" : "cancelled"}`}
                onDismiss={dismissOutcome}
            />
            }
        </div>
    )
}

function WorkStats({activityId}) {

    const [activityStats, setActivityStats] = useState();

    const {loading} = useQuery(GET_WORK_FOR_ACTIVITY_BY_OCCURRENCE, {
        variables: {
            activityId: activityId
        },
        fetchPolicy: "cache-and-network",
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            if (data.workForActivityByOccurrence.length > 0) {
                setActivityStats({
                    labels: data.workForActivityByOccurrence.map((item) => dateToStringNoTime(item._id)),
                    datasets: [
                        {
                            label: "Hours volunteered",
                            data: data.workForActivityByOccurrence.map((item) => minutesToHours(item.minutes)),
                            backgroundColor: "rgb(0, 128, 128)"
                        }
                    ]
                })
            } else {
                setActivityStats(null)
            }
        }
    })

    return (
        <OnLoadViewer loading={loading}>
            { activityStats &&
            <div>
                <div className="text-2xl font-bold mt-6 mb-2">
                    Total volunteering
                </div>
                <BarGraph title="Total hours volunteered by day" data={activityStats} />
            </div>
            }
        </OnLoadViewer>
    )
}

function WorkTable({activityId}) {

    const [work, setWork] = useState(null);

    const {loading} = useQuery(GET_WORK_FOR_ACTIVITY_BY_USER, {
        variables: {
            activityId: activityId
        },
        fetchPolicy: "cache-and-network",
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setWork(data.workForActivityByUser),
    })

    return (work && work.length > 0) ?
        <GlassCard width="w-full">
            <div className="text-2xl font-bold mb-2">
                Volunteers who have attended
            </div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={cellStyles.person}>Name</TableCell>
                            <TableCell sx={cellStyles.fixedNarrow}>Times attended</TableCell>
                            <TableCell sx={cellStyles.dateTime}>Last attended</TableCell>
                            <TableCell sx={cellStyles.relativeTime}>Total time (this activity)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { work.map((record) => (
                            <TableRow key={record.userId._id}>
                                <TableCell><FormattedUserName user={record.userId} /></TableCell>
                                <TableCell align="center">{record.checkins}</TableCell>
                                <TableCell align="left">{dateToString(record.lastAttended)}</TableCell>
                                <TableCell align="left">{minutesToString(record.minutesWorked)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </GlassCard>
    : null;
}

function CancelActivityModal(props) {

    const [cancelActivity] = useMutation(CANCEL_ACTIVITY, { update: evictAllDependentOnActivity })

    const [cancellationReason, setCancellationReason] = useState(null);

    const requestCancel = (tryDelete) => {
        return cancelActivity({
            variables: {
                activityId: props.activity._id,
                tryDelete: tryDelete,
                reason: cancellationReason
            }
        }).then(r => {
            props.setOutcome(r.data.cancelActivity);
            logActionSuccess(ACTIONS.activityCancel, 'Cancelled activity');
        }, e => {
            props.setOutcome(null);
            logActionGraphQLFailure(ACTIONS.activityCancel, e);
            throw e;
        });
    }

    return (
        <Modal
            onDismiss={() => props.setOutcome(null)}
            title={`Are you sure you want to ${props.delete ? "delete" : "cancel"} this activity?`}
            footer={
                <button className={buttonStyles.redLg} type="button" onClick={() => requestCancel(props.delete)}>
                    {props.delete ? "Confirm Delete" : "Confirm Cancel"}
                </button>
            }
        >
            { !props.delete &&
            <div className="flex">
                <input
                    className={`w-full ${inputStyles.text}`}
                    type="text"
                    id="message_text"
                    name="message_text"
                    placeholder="Enter a reason for cancelling that will be seen by volunteers"
                    value={cancellationReason}
                    onChange={(event) => setCancellationReason(event.target.value)}
                    autoComplete="off"
                />
            </div>
            }
        </Modal>
    )
}
