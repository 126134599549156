import {gql} from "@apollo/client";


export const GET_REGISTERED_CHARITIES = gql`
    query PortalFindRegisteredCharities($postcode: String, $incomeLowerBound: Int, $incomeUpperBound: Int, $number: Int, $offset: Int!, $limit: Int!) {
        portalFindRegisteredCharities(postcode: $postcode, incomeLowerBound: $incomeLowerBound, incomeUpperBound: $incomeUpperBound, number: $number, offset: $offset, limit: $limit) {
            total
            offset
            charities {
                _id
                name
                type
                number
                address
                postcode
                email
                phone
                website
                activities
                existing
            }
        }
    }
`

export const GET_EMAILS_SENT_BY_PERIOD = gql`
    query EmailsSentByByPeriod($start: DateTime!, $end: DateTime!, $period: String!, $prefix: String) {
        emailsSentByByPeriod(start: $start, end: $end, period: $period, prefix: $prefix) {
            when
            count
        }
    }
`

export const GET_EMAILS_SENT_BY_POSTCODE = gql`
    query EmailsSentByByPostcode($start: DateTime!, $end: DateTime!, $prefix: String) {
        emailsSentByByPostcode(start: $start, end: $end, prefix: $prefix) {
            name
            count
        }
    }
`
