export function nullIfEmpty(s) {
    const trimmed = s.trim()
    return (trimmed.length > 0) ? trimmed : null
}

export function addressTemplate() {
    return {
        line1: null,
        line2: null,
        streetName: "",
        townCity: "",
        postcode: "",
    }
}

export function siteTemplate() {
    return {
        _id: null,
        name: "",
        address: addressTemplate(),
        geohash: null,
        locationName: null
    }
}

export function validateAddress(address) {

    if (address.streetName.length < 1) {
        throw { streetName: "Street name cannot be empty" }
    }

    if (address.townCity.length < 1) {
        throw { townCity: "Town/city cannot be empty" }
    }

    if (address.postcode.length < 1) {
        throw { postcode: "Postcode cannot be empty" }
    }
}

export function addressEq(a, b) {
    return (a.line1 === b.line1
         && a.line2 === b.line2
         && a.streetName === b.streetName
         && a.townCity === b.townCity
         && a.postcode === b.postcode)   
}

export function getAddress(values) {
    return {
        line1: (values.line1 !== null && values.line1.length > 0) ? values.line1 : null,
        line2: (values.line2 !== null && values.line2.length > 0) ? values.line2 : null,
        streetName: values.streetName,
        townCity: values.townCity,
        postcode: values.postcode
    }
}

export function genderToString(gender) {
    
    let s = ''
    
    let append = (what, text) => {
        if (what) {
            if (s !== '') {
                s += ', '
            }
            s += text
        }
    }

    append(gender.female, 'female')
    append(gender.male, 'male')
    append(gender.nonBinary, 'nonBinary')
    append(gender.transgender, 'transgender')
    append(gender.intersex, 'intersex')
    append(gender.preferNotToSay, 'preferNotToSay')

    return s
}