import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {use100vh} from 'react-div-100vh'

import {useLazyQuery, useQuery, useMutation} from "@apollo/react-hooks";

import {GlassCard, OnLoadViewer} from "gih_web_common";

import {GET_ACTIVITY_BY_ID, ADD_NEW_ACTIVITY} from "../../utils/graphql/activity";
import {GET_CHARITY_DETAIL_BY_ID} from "../../utils/graphql/charity";
import {logScreenViewEvent, logActionSuccess, logActionGraphQLFailure} from "../../utils/analytics";
import {ACTIONS, SCREEN_NAME, SCREEN_CLASS} from "../../utils/analyticsConstants";
import {addressTemplate} from "../../utils/address";
import {evictAllDependentOnActivity} from "../../utils/graphql/cache";

import {SelfSignUpProgress} from "../common/steps";

import {ActivityDetailsForm} from "./form/form";
import {getDerivedValues} from "./form/helpers";


export default function CreateActivity() {

    const height = use100vh();
    const {id} = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const currentCharity = useSelector(state => state.charity);

    const [activityTemplate, setActivityTemplate] = useState(null);

    const [createNewActivity] = useMutation(ADD_NEW_ACTIVITY, { update: evictAllDependentOnActivity })

    const [requestActivity] = useLazyQuery(GET_ACTIVITY_BY_ID, {
        variables: { activityId: id },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            const r = data.portalFindActivityById
            setActivityTemplate({
                name: r.name,
                briefDescription: r.briefDescription,
                fullDescription: r.fullDescription,
                minutes: r.minutes,
                address: r.address,
                places: r.places,
                imageURL: r.imageURL,
                videoURL: r.videoURL,
                scheduling: r.scheduling,
                eventDate: null,
                repetition: r.repetition,
                cancelled: false,
                cancelledReason: null,
                autoReplyText: r.autoReplyText,
                autoReply: r.autoReply,
                hidden: r.hidden,
                full: false,
                workLocation: r.workLocation,
                ageRestricted: r.ageRestricted,
                manageAvailability: r.manageAvailability,
                requiredVolunteers: r.requiredVolunteers,
                keywords: r.keywords,
            })
        }
    });

    const {loading: charityQueryInProgress, data: charityQueryResult} = useQuery(GET_CHARITY_DETAIL_BY_ID, {
        variables: {
            charityId: currentCharity.id
        },
    });

    const onValidated = (activity) => {
        return createNewActivity({
            variables: {
                activity: activity,
                charityId: currentCharity.id
            }
        }).then(r => {
            logActionSuccess(ACTIONS.activityCreate, `Created activity ${r.data.addActivity._id}`);
            dispatch({type: "SET_AWAITING_1ST_ACTIVITY", payload: false})
            history.push(`/activity/${r.data.addActivity._id}`)
        }, e => {
            logActionGraphQLFailure(ACTIONS.activityCreate, e);
            throw e;
        });
    }

    useEffect(() => {
        if (id === undefined) {
            setActivityTemplate({
                name: "",
                briefDescription: null,
                fullDescription: "",
                minutes: 60,
                address: addressTemplate(),
                places: [],
                imageURL: null,
                videoURL: null,
                scheduling: "oneTimeEvent",
                eventDate: null,
                repetition: null,
                cancelled: false,
                cancelledReason: null,
                autoReplyText: null,
                autoReply: false,
                hidden: false,
                full: false,
                workLocation: "onsite",
                ageRestricted: false,
                manageAvailability: false,
                requiredVolunteers: null,
                keywords: [],
            })
        } else {
            requestActivity()
        }
    }, [id])

    useEffect(() => {
        logScreenViewEvent(SCREEN_NAME.activityCreate, SCREEN_CLASS.activity)
    }, [])

    return (
        <div className="p-1 lg:p-4 flex flex-col overflow-y-auto" style={{ maxHeight: `${height - 64}px`}}>
            <div className="mx-auto max-w-full space-y-2">
                { currentCharity.awaiting1stActivity && !currentCharity.features.enableGiving &&
                <GlassCard>
                    <SelfSignUpProgress activeStep={3} />
                </GlassCard>
                }
                <OnLoadViewer loading={charityQueryInProgress || activityTemplate === null}>
                    { charityQueryResult && activityTemplate !== null &&
                    <ActivityDetailsForm
                        initial={{
                            activity: activityTemplate,
                            charity: charityQueryResult.portalFindCharityById,
                            derived: getDerivedValues(activityTemplate)
                        }}
                        editing={false}
                        onValidated={onValidated}
                    />
                    }
                </OnLoadViewer>
            </div>
        </div>
    );
}
