import {useState} from "react";
import {useQuery} from "@apollo/react-hooks";

import {monetaryAmountToString, dateToStringNoTime} from "gih_web_common";

import {graphQLCache} from "../../../index";

import {arrayByTimeToGraphData, arrayByNameToGraphData, makeDonationDatasets, colourScheme, BarGraph} from "../../../utils/stats";

import {
    GET_DONATIONS_BY_CHARITY,
    GET_DONATIONS_BY_POSTCODE,
    GET_DONATIONS_BY_PERIOD,
    GET_CAMPAIGNS_CREATED_BY_PERIOD,
    GET_CAMPAIGNS_RECEIVING_DONATIONS_BY_PERIOD,
    GET_DONATIONS_AMOUNT_DISTRIBUTION
} from "../../../utils/graphql/admin-giving";


export function invalidateGiving() {
    console.log(`Evicting database giving stats query results`);

    const queries = [
        "donationsByPeriod",
    ];

    queries.forEach(query => {
        graphQLCache.evict({
            id: "ROOT_QUERY", 
            fieldName: query,
        })
    });
    
    graphQLCache.gc();
}

export function CampaignsCreatedByPeriod({start, end, period}) {

    const [graphData, setGraphData] = useState(null);

    const {error} = useQuery(GET_CAMPAIGNS_CREATED_BY_PERIOD, {
        variables: {
            start: start,
            end: end,
            period: period
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData(arrayByTimeToGraphData(data.campaignsCreatedByPeriod, period, [
            {
                label: "Number of campaigns",
                getter: item => item.count,
                colour: colourScheme.campaign
            }
        ])),
    })

    return (
        <BarGraph title={`Campaigns created per ${period}`} data={graphData} />
    )
}

export function CampaignsReceivingDonationsByPeriod({start, end, period}) {

    const [graphData, setGraphData] = useState(null);

    const {error} = useQuery(GET_CAMPAIGNS_RECEIVING_DONATIONS_BY_PERIOD, {
        variables: {
            start: start,
            end: end,
            period: period
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData(arrayByTimeToGraphData(data.campaignsReceivingDonationsByPeriod, period, [
            {
                label: "Number of campaigns",
                getter: item => item.count,
                colour: colourScheme.campaign
            }
        ])),
    })

    return (
        <BarGraph title={`Campaigns receiving donations per ${period}`} data={graphData} />
    )
}

export function DonationAmountDistribution({start, end}) {

    const [graphData, setGraphData] = useState(null);

    const {error} = useQuery(GET_DONATIONS_AMOUNT_DISTRIBUTION, {
        variables: {
            start: start,
            end: end,
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted: data => {
            const buckets = data.donationsAmountDistribution.map(bucket => { return {
                name: `${monetaryAmountToString(bucket.min)} - ${monetaryAmountToString(bucket.max)}`,
                count: bucket.count,
            }});
            setGraphData(arrayByNameToGraphData(buckets, [{
                label: "Number of donations",
                getter: item => item.count,
                colour: colourScheme.donationCount
            }]));
        },
    })

    return (
        <BarGraph title={`Distribution of amount per donation for period ${dateToStringNoTime(start)} - ${dateToStringNoTime(end)}`} data={graphData} />
    )
}

export function DonationsByCharity({start, end}) {

    const [graphData, setGraphData] = useState(null);

    const {error} = useQuery(GET_DONATIONS_BY_CHARITY, {
        variables: {
            start: start,
            end: end,
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData(arrayByNameToGraphData(data.donationsByCharity, makeDonationDatasets())),
    })

    return (
        <BarGraph title={`Charities ranked by total donations for period ${dateToStringNoTime(start)} - ${dateToStringNoTime(end)}`} data={graphData} stacked={true} />
    )
}

export function DonationsByPostcode({start, end}) {

    const [graphData, setGraphData] = useState(null);

    const {error} = useQuery(GET_DONATIONS_BY_POSTCODE, {
        variables: {
            start: start,
            end: end,
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData(arrayByNameToGraphData(data.donationsByPostcode, makeDonationDatasets())),
    })

    return (
        <BarGraph title={`Postcodes ranked by total donations for period ${dateToStringNoTime(start)} - ${dateToStringNoTime(end)}`} data={graphData} stacked={true} />
    )
}

export function DonationAmountByPeriod({start, end, period}) {

    const [graphData, setGraphData] = useState(null);

    const {loading} = useQuery(GET_DONATIONS_BY_PERIOD, {
        variables: {
            start: start,
            end: end,
            period: period
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData(arrayByTimeToGraphData(data.donationsByPeriod, period, makeDonationDatasets())),
    })

    return (
        <BarGraph title={`Total amount donated by ${period}`} data={graphData} stacked={true} />
    )
}

export function PercentageOfDonationsWithGiftAidByPeriod({start, end, period}) {

    const [graphData, setGraphData] = useState(null);

    const {loading} = useQuery(GET_DONATIONS_BY_PERIOD, {
        variables: {
            start: start,
            end: end,
            period: period
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => setGraphData(arrayByTimeToGraphData(data.donationsByPeriod, period, [{
            label: "Percentage of donations",
            getter: item => item.percentWithGiftAid,
            colour: colourScheme.percentOfdonations
        }])),
    })

    return (
        <BarGraph title={`Percentage of donations with GiftAid by ${period}`} data={graphData} stacked={true} />
    )
}

export function PercentageProfitPerTransactionByPeriod({start, end, period}) {

    const [graphData, setGraphData] = useState(null);

    const {loading} = useQuery(GET_DONATIONS_BY_PERIOD, {
        variables: {
            start: start,
            end: end,
            period: period
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData(arrayByTimeToGraphData(data.donationsByPeriod, period, [{
            label: "Percentage profit",
            getter: item => item.percentProfit,
            colour: colourScheme.percentProfit
        }])),
    })

    return (
        <BarGraph title={`Percentage profit averaged by ${period}`} data={graphData} stacked={true} />
    )
}

export function DonationCountByPeriod({start, end, period}) {

    const [graphData, setGraphData] = useState(null);

    const {loading} = useQuery(GET_DONATIONS_BY_PERIOD, {
        variables: {
            start: start,
            end: end,
            period: period
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData(arrayByTimeToGraphData(data.donationsByPeriod, period, [{
            label: "Number of donations",
            getter: item => item.count,
            colour: colourScheme.donationCount
        }])),
    })

    return (
        <BarGraph title={`Number of donations by ${period}`} data={graphData} stacked={true} />
    )
}

export function ProfitByPeriod({start, end, period}) {

    const [graphData, setGraphData] = useState(null);

    const {loading} = useQuery(GET_DONATIONS_BY_PERIOD, {
        variables: {
            start: start,
            end: end,
            period: period
        },
        fetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setGraphData(arrayByTimeToGraphData(data.donationsByPeriod, period, [
            {
                label: "Total tipping",
                getter: item => item.tipTotal / 100,
                colour: colourScheme.profitAmount
            },
        ])),
    })

    return (
        <BarGraph title={`Total profit by ${period}`} data={graphData} stacked={true} />
    )
}
