import {useParams, useHistory} from "react-router-dom";
import {useMediaQuery} from 'react-responsive';

import {tabButtonStyle} from "../common/style";

import {CRMTab} from "./crm/tab";
import {Dashboard} from "./dashboard/tab";
import {RecentEvents} from "./recent/tab";
import {RegisteredCharities} from "./register/tab";
import {EventStats} from "./event-stats/short-term-tab";
import {WebStats} from "./web-stats/tab";
import {LongTermEventStats} from "./event-stats/long-term-tab";
import {AdvancedEventStats} from "./event-stats/advanced-tab";
import {DatabaseStats} from "./db-stats/tab";
import {AdvancedDatabaseStats} from "./db-stats/advanced-tab";
import {MarketingStats} from "./marketing-stats/tab";
import {AppUserSearch} from "./app-users/tab";
import {PortalUserSearch} from "./portal-users/tab";
import {AppUserMap} from "./map/app-users.js";
import {GiftAidSubmissions} from "./hmrc/giftAid.js";


const tabs = [
    {
        longLabel: "CRM",
        shortLabel: "CRM",
        id: "crm",
        tab: CRMTab,
    }, {
        longLabel: "Dashboard",
        shortLabel: "Dashboard",
        id: "dashboard",
        tab: Dashboard,
    }, {
        longLabel: "Recent events",
        shortLabel: "Recent",
        id: "recent-events",
        tab: RecentEvents,
    }, {
        longLabel: "Event stats",
        shortLabel: "Events",
        id: "event-stats",
        tab: EventStats,
    }, {
        longLabel: "Web stats",
        shortLabel: "Web",
        id: "web-stats",
        tab: WebStats,
    }, {
        longLabel: "Monthly event stats",
        shortLabel: "Monthly",
        id: "event-long-term-stats",
        tab: LongTermEventStats,
    }, {
        longLabel: "Database stats",
        shortLabel: "Database",
        id: "db-stats",
        tab: DatabaseStats,
    }, {
        longLabel: "Advanced events",
        shortLabel: "Events++",
        id: "advanced-events",
        tab: AdvancedEventStats,
    }, {
        longLabel: "Advanced database",
        shortLabel: "DB++",
        id: "advanced-db",
        tab: AdvancedDatabaseStats,
    }, {
        longLabel: "App users",
        shortLabel: "AppUsers",
        id: "app-users",
        tab: AppUserSearch,
    }, {
        longLabel: "App user map",
        shortLabel: "Map",
        id: "app-user-map",
        tab: AppUserMap,
    }, {
        longLabel: "Portal users",
        shortLabel: "PortalUsers",
        id: "portal-users",
        tab: PortalUserSearch,
    }, {
        longLabel: "Charity register",
        shortLabel: "Register",
        id: "charity-register",
        tab: RegisteredCharities,
    }, {
        longLabel: "Marketing stats",
        shortLabel: "Marketing",
        id: "marketing",
        tab: MarketingStats,
    }, {
        longLabel: "GiftAid submissions",
        shortLabel: "GiftAid",
        id: "giftaid",
        tab: GiftAidSubmissions,
    }
];

export default function OverviewPage() {

    const isNarrow = useMediaQuery({ query: '(max-width: 2400px)' })

    const {selectedTab} = useParams();
    const history = useHistory();

    function selectTab(tab) {
        history.push(`/overview/${tab}`);
    }

    return (
        <div className="px-1 lg:px-4 overflow-hidden w-full" style={{ height: 'calc(100vh - 64px)' }}>
            <div className="overflow-x-auto">
                <div className="flex flex-row space-x-3 py-3 h-[64px]">
                { tabs.map((tab) => {
                    return (
                        <button key={tab.id} onClick={() => { selectTab(tab.id)}} className={tabButtonStyle(selectedTab === tab.id, false)}>
                            {isNarrow ? tab.shortLabel : tab.longLabel}
                        </button>
                    )
                })}
                </div>
            </div>

            <div className="overflow-y-auto w-full py-1 lg:py-3" style={{ maxHeight: 'calc(100vh - 64px - 64px' }}>
            { (() => {
                    const Tab = tabs.find(tab => tab.id === selectedTab).tab;
                    return <Tab/>
                })()
            }
            </div>
        </div>
    )
}
