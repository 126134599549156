import colors from 'tailwindcss/colors';

import React from "react";

import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

import {buttonStyles, ImageVCentre, TableCell, FileSelector, Lozenge, pluralise} from "gih_web_common";

export function FormGroup({children, title, colour}) {

    const dark = false;

    return (
        <div className="px-1 py-2 rounded-lg" style={{
            backgroundColor: colors[colour][dark ? '700' : '100'],
            color: dark ? colors.white : colors.black,
        }}>
            <div className="text-xs leading-tight italic pb-2">
                {title}
            </div>
            <div className="p-2 rounded-lg space-y-1" style={{
                backgroundColor: colors[colour][dark ? '600' : '200']
            }}>
                {children}
            </div>
        </div>
    );
}

export function BoldMetric({prefix, value, suffix}) {
    return (
        <span className="flex items-center">
            <span>
                {prefix}
            </span>
            <span className="font-bold text-xl sm:text-3xl px-2">
                {value}
            </span>
            { suffix &&
            <span>
                {(value !== 1) ? pluralise(suffix) : suffix}
            </span>
            }
        </span>
    );
}

export function TitleAndBackButton({title, alt=false, back=true}) {

    const history = useHistory();

    return (
        <div className="flex items-center space-x-4 py-1 w-fit">
            { back &&
            <button onClick={() => { history.goBack() }} className={alt ? buttonStyles.altLgNarrow : buttonStyles.primaryLgNarrow}>
                Go back
            </button>
            }
            <span className="text-2xl sm:text-3xl font-bold text-fgCard-default">{title}</span>
        </div>
    )
}

export function CharityCell({charity}) {

    const imageURLPrefix = useSelector(state => state.cfg?.imageURLPrefix);

    return (
        <TableCell>
            <div className="flex flex-row w-fit items-center h-[64px] space-x-2">
                { (charity === null) ?
                <div>-</div>
                : charity.provisional ?
                <>
                    <Lozenge colour="bg-pink-500" text="provisional" />
                    <span className="text-sm">{charity.name}</span>
                </>
                :
                <>
                    <ImageVCentre rmtPrefix={imageURLPrefix} rmt={charity.logoPicture} what="logoSmall" />
                    <span className="text-sm">{charity.name}</span>
                </>
                }
            </div>
        </TableCell>
    )
}

export function ColourKey({items, suffix}) {
    return (
        <div>
            <div className="text-black text-sm font-bold inline-flex mr-2">Colour key</div>
            { items.map(item => (
                <div id={item.colour} className={`whitespace-nowrap text-black text-sm font-bold rounded-lg px-2 py-1 ml-1 my-1 inline-flex`} style={{
                    backgroundColor: item.colour
                }}>{item.text}</div>
            ))}
            <div className="whitespace-nowrap text-black text-sm py-1 ml-1 my-1 inline-flex">({suffix})</div>
        </div>
    )
}

export function BadgeCount({count, background='bg-red-500'}) {
    return (
        <div className={`whitespace-nowrap ${background} rounded-full text-white font-bold text-xs sm:text-sm h-[24px] min-w-[2.2em] justify-center items-center flex`}>
            {count}
        </div>
    )
}

export function VideoFileSelector({editing, what="video", maxSize=140, alt=false, onSelectFile}) {
    return <FileSelector
        editing={editing}
        text={`Please choose ${editing ? "new" : ""} a ${what} (supported formats: MP4 and MOV)`}
        what={what}
        buttonClass={alt ? buttonStyles.altLg : buttonStyles.primaryLg}
        accept=".mov, .mp4"
        maxSize={maxSize}
        onSelectFile={onSelectFile}
    />
}

export function PhotoFileSelector({editing, what="photo", alt=false, onSelectFile}) {
    return <FileSelector
        editing={editing}
        text={`Please choose ${editing ? "new" : ""} a ${what} (supported formats: JPG, JPEG and PNG)`}
        what={what}
        buttonClass={alt ? buttonStyles.altLg : buttonStyles.primaryLg}
        accept=".jpg, .jpeg, .png"
        maxSize={10}
        onSelectFile={onSelectFile}
    />
}
