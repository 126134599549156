import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";

import {useLazyQuery, useQuery, useMutation} from "@apollo/react-hooks";

import {GlassCard, OnLoadViewer} from "gih_web_common";

import {GET_CHARITY_DETAIL_BY_ID} from "../../utils/graphql/charity";
import {GET_CAMPAIGN_BY_ID, ADD_NEW_CAMPAIGN} from "../../utils/graphql/campaign";
import {logScreenViewEvent, logActionSuccess, logActionGraphQLFailure} from "../../utils/analytics";
import {ACTIONS, SCREEN_NAME, SCREEN_CLASS} from "../../utils/analyticsConstants";
import {addressTemplate} from "../../utils/address";
import {evictAllDependentOnCampaign} from "../../utils/graphql/cache";

import {SelfSignUpProgress} from "../common/steps";

import {CampaignDetailsForm} from "./form/form";


export default function CreateCampaign() {

    const {id} = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const currentCharity = useSelector(state => state.charity);

    const [campaignTemplate, setCampaignTemplate] = useState(null);

    const [createCampaign] = useMutation(ADD_NEW_CAMPAIGN, { update: evictAllDependentOnCampaign })

    const [requestCampaign] = useLazyQuery(GET_CAMPAIGN_BY_ID, {
        variables: { campaignId: id },
        fetchPolicy: "network-only",
        onCompleted: (data) => {
            const r = data.portalFindCampaignById
            setCampaignTemplate({
                type: r.type,
                workLocation: r.workLocation,
                name: r.name,
                fullDescription: r.fullDescription,
                imageURL: r.imageURL,
                videoURL: r.videoURL,
                videoPosterTime: r.videoPosterTime,
                hidden: r.hidden,
                address: r.address,
                places: r.places,
                keywords: r.keywords,
                target: null,
                endDate: null,
            })
        }
    });

    const {loading: charityQueryInProgress, data: charityQueryResult} = useQuery(GET_CHARITY_DETAIL_BY_ID, {
        variables: {
            charityId: currentCharity.id
        },
    });

    const onValidated = (campaign) => {
        return createCampaign({
            variables: {
                campaign: campaign,
                charityId: currentCharity.id
            }
        }).then(r => {
            logActionSuccess(ACTIONS.campaignCreate, `Created campaign ${r.data.addCampaign._id}`);
            dispatch({type: "SET_AWAITING_1ST_CAMPAIGN", payload: false});
            history.push(`/campaign/${r.data.addCampaign._id}`);
        }, e => {
            logActionGraphQLFailure(ACTIONS.campaignCreate, e);
            throw e;
        });
    }

    useEffect(() => {
        if (id === undefined) {
            setCampaignTemplate({
                type: "project",
                workLocation: "onsite",
                name: "",
                fullDescription: "",
                imageURL: null,
                videoURL: null,
                videoPosterTime: null,
                hidden: false,
                address: addressTemplate(),
                places: [],
                keywords: [],
                target: null,
                endDate: null,
            })
        } else {
            requestCampaign()
        }
    }, [id])

    useEffect(() => {
        logScreenViewEvent(SCREEN_NAME.campaignCreate, SCREEN_CLASS.campaign)
    }, [])

    return (
        <div className="p-1 lg:p-4 flex flex-col overflow-y-auto" style={{ height: 'calc(100vh - 64px)' }}>
            <div className="mx-auto max-w-full space-y-2">
                { currentCharity.awaiting1stCampaign &&
                <GlassCard>
                    <SelfSignUpProgress activeStep={3} />
                </GlassCard>
                }
                <OnLoadViewer loading={charityQueryInProgress || campaignTemplate === null}>
                    { charityQueryResult && campaignTemplate !== null &&
                    <CampaignDetailsForm
                        initial={{
                            campaign: campaignTemplate,
                            charity: charityQueryResult.portalFindCharityById,
                        }}
                        editing={false}
                        onValidated={onValidated}
                    />
                    }
                </OnLoadViewer>
            </div>
        </div>
    );
}
