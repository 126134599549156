import {useParams} from "react-router-dom";

import {OnLoadViewer} from "gih_web_common";

import {TabSelector} from "../common/tab-selector";
import {ChatList} from "./chat-list";


export default function ChatListPane(props) {

    const {activityGroup="upcoming"} = useParams();

    return (
        <>
            <TabSelector tab={activityGroup}/>

            <OnLoadViewer loading={props.loading}>
                { props.activitiesByGroup !== null &&
                <ChatList activities={props.activitiesByGroup[activityGroup]}
                          activeActivity={props.activeActivity}
                          setActiveActivity={props.setActiveActivity}
                          activeChat={props.activeChat}
                          setActiveChat={props.setActiveChat}
                          activityGroup={activityGroup}
                />
                }
            </OnLoadViewer>
        </>
    )
}
