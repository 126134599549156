import {TextRender, LocalLink} from 'gih_web_common';
import Logo from 'gih_web_common/assets/gih-logo-1818x268.png';

import {npoTsAndCs} from '../utils/legals/npoTsAndCs';
import {privacyPolicy} from '../utils/legals/privacyPolicy';
import {cookiePolicy} from '../utils/legals/cookiePolicy';


function getLink(legals, signup) {
    if (legals.what === 'privacyPolicy') {
        return {
            text: 'Cookie Policy',
            to: signup ? '/signup/cookie' : '/cookiePolicy'
        }
    } else if (legals.what === 'cookiePolicy') {
        return {
            text: 'Privacy Policy',
            to: signup ? '/signup/privacy' : '/privacyPolicy'
        }
    } else {
        return null;
    }
}

function Legals({legals, signup}) {

    const link = getLink(legals, signup);

    return (
        <>
            <h1 className="text-xl sm:text-2xl font-bold text-fgCard-default p-2 bg-bg-primary h-[96px] border-b-2 border-table-border">{legals.title}</h1>
            <div className="w-full overflow-y-auto bg-bg-secondary text-fgCard-default" style={{ height: 'calc(100vh - 64px - 96px' }}>
                <div className="max-w-screen-xl mx-auto space-y-2 px-1 sm:px-2 py-2">
                    <div className="space-x-4">
                        { link &&
                        <LocalLink to={link.to}>{link.text}</LocalLink>
                        }
                        { signup &&
                        <LocalLink to="/signup/self">Continue signup</LocalLink>
                        }
                    </div>
                    <TextRender what={legals.body} />
                </div>
            </div>
        </>
    );
}

function EmptyTopBanner() {
    return (
        <div className="bg-gray-800 flex items-center w-full z-10 h-16">
            <img className="h-12 w-auto object-contain" src={Logo} alt="Giving is Human"/>
        </div>
    );
}

export function TermsAndConditions() {
    return <Legals legals={npoTsAndCs} signup={false} />
}

export function PrivacyPolicy() {
    return <Legals legals={privacyPolicy} signup={false} />
}

export function CookiePolicy() {
    return <Legals legals={cookiePolicy} signup={false} />
}

export function TermsAndConditionsSignup() {
    return (
        <>
            <EmptyTopBanner/>
            <Legals legals={npoTsAndCs} signup={true} />
        </>
    );
}

export function PrivacyPolicySignup() {
    return (
        <>
            <EmptyTopBanner/>
            <Legals legals={privacyPolicy} signup={true} />
        </>
    );
}

export function CookiePolicySignup() {
    return (
        <>
            <EmptyTopBanner/>
            <Legals legals={cookiePolicy} signup={true} />
        </>
    );
}
