import {dateToStringRelative, Lozenge, numberOfTimes} from "gih_web_common";


const statuses = {
    queued: {
        colour: "bg-gray-500",
        name: "Waiting to send",
    },
    processed: {
        colour: "bg-yellow-600",
        name: "Sent"
    },
    deferred: {
        colour: "bg-amber-600",
        name: "Waiting"
    },
    delivered: {
        colour: "bg-green-600",
        name: "Delivered"
    },
    bounce: {
        colour: "bg-red-600",
        name: "Bounced"
    },
    dropped: {
        colour: "bg-red-600",
        name: "Dropped"
    }
}

export function StatusLozenge({status, lastChange}) {
    return (
        <Lozenge colour={statuses[status].colour} text={`${statuses[status].name}${(lastChange !== null) ? ` (${dateToStringRelative(lastChange)})` : ""}`} />
    )
}

export function InteractionLozenge({what, colour, count, last}) {
    return (
        <Lozenge colour={colour} text={`${what} ${numberOfTimes(count)}, most recently ${dateToStringRelative(last)}`} />
    )
}

export function EmailTrace({trace}) {
    if (trace.clickCount > 0)
        return <InteractionLozenge colour="bg-green-600" what="Clicked" count={trace.clickCount} last={trace.lastClick} />
    else if (trace.openCount > 0)
        return <InteractionLozenge colour="bg-amber-600" what="Read" count={trace.openCount} last={trace.lastOpen} />
    else
        return <StatusLozenge status={trace.status} lastChange={trace.lastStatusChange} />
}
