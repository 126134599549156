import {useParams, useHistory} from "react-router-dom";
import {useMediaQuery} from 'react-responsive'

import {tabButtonStyle} from "../common/style";

import {CampaignCards} from "./cards"


export default function CampaignsPage() {

    const {campaignGroup} = useParams();
    const history = useHistory();
    const isNarrow = useMediaQuery({ query: '(max-width: 640px)' })

    function completedCampaignsButtonClicked() {
        history.push("/campaigns/completed");
    }

    function ongoingCampaignsButtonClicked() {
        history.push("/campaigns/ongoing");
    }

    return (
        <div className="px-1 lg:px-4 overflow-hidden" style={{ height: 'calc(100vh - 64px)' }}>
            <div className="flex flex-row space-x-3 px-3 py-3 h-[64px]">
                <button onClick={ongoingCampaignsButtonClicked} className={tabButtonStyle(campaignGroup === "ongoing", true)}>
                    {isNarrow ? "Ongoing" : "Ongoing campaigns"}
                </button>
                <button onClick={completedCampaignsButtonClicked} className={tabButtonStyle(campaignGroup === "completed", true)}>
                    {isNarrow ? "Completed" : "Completed campaigns"}
                </button>
            </div>

            <CampaignCards group={campaignGroup}/>
        </div>
    )
}
