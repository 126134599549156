import {useState, useEffect} from 'react';
import {useHistory} from "react-router-dom";

import {confirmPasswordReset, verifyPasswordResetCode} from 'firebase/auth';

import Logo from 'gih_web_common/assets/gih-logo-1818x268.png';
import {OnLoadViewer, FormWrapper, FormErrorText, FormNewPassword, FormPrimaryButton, AlertModal} from "gih_web_common";

import {logFormValidationFailure} from "../../utils/analytics";
import {auth} from "../../utils/firebase";


export default function ResetPasswordLandingPage() {

    const history = useHistory();

    const [linkInfo, setLinkInfo] = useState(false);
    const [outcome, setOutcome] = useState(null);

    useEffect(() => {
        // Confirm the password reset action and fetch the user's email
        const url = new URL(window.location.href);
        const mode = url.searchParams.get('mode');
        const oobCode = url.searchParams.get('oobCode');

        if (mode === 'resetPassword') {
            verifyPasswordResetCode(auth, oobCode)
            .then(email => {
                setLinkInfo({
                    email: email,
                    oobCode: oobCode,
                })
            }).catch(error => {
                setOutcome({
                    type: "warning",
                    message: `Failed to verify the password reset link: ${error}`
                });
            })
        } else {
            setOutcome({
                type: "warning",
                message: "Invalid password reset link."
            });
        }
    }, []);

    function onSubmit(values) {
        return confirmPasswordReset(auth, linkInfo.oobCode, values["newPassword"])
        .then(r => {
            setOutcome({
                type: "success",
                message: "Your password has been successfully updated."
            });
        }).catch(error => {
            setOutcome({
                type: "warning",
                message: `Failed to update your password: ${error.message}`
            });
        })
    }

    return (
        <div className="min-h-screen flex justify-center bg-stone-200 items-center">
            <div className="max-w-md p-6 bg-white rounded-lg">
                <div className="flex justify-center mb-6">
                    <img src={Logo} alt="" />
                </div>
                <OnLoadViewer loading={linkInfo === null && outcome === null}>
                { linkInfo !== null &&
                    <>
                        <div className="flex justify-between align-start mb-4">
                            <h2 className="text-3xl font-bold">Please enter a new password</h2>
                        </div>
                        <FormWrapper onSubmit={onSubmit} onValidationFailure={logFormValidationFailure} className="space-y-6">
                            <FormNewPassword
                                account={linkInfo.email}
                                id="newPassword"
                                name="newPassword"
                                type="password"
                                autoComplete="new-password"
                                required
                                label="Choose a new password"
                                hideShadow
                                placeholder="new password"
                            />
                            <FormErrorText/>
                            <div className="mx-auto">
                                <FormPrimaryButton type="submit">
                                    Update password
                                </FormPrimaryButton>
                            </div>
                        </FormWrapper>
                    </>
                }
                </OnLoadViewer>
            </div>

            { outcome !== null &&
            <AlertModal
                type={outcome.type}
                message={outcome.message}
                onDismiss={ () => {
                    setOutcome(null)
                    history.push("/sign-in")
                }}
            />
            }
        </div>
    );
}
