import {useState} from "react";

import {useQuery} from "@apollo/react-hooks";

import {GlassCard, OnLoadViewer, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, RefreshCell, monetaryAmountToString, dateToString, cellStyles, FormattedUserName, CampaignProgressBar} from "gih_web_common";

import {GET_DONATIONS_TO_CAMPAIGN_BY_CREATION_DATE, GET_FUNDRAISERS_BY_CAMPAIGN_ID} from "../../utils/graphql/campaign";


function FormattedFundraiserSummary({fundraiser}) {
    if (fundraiser)
        return <div className="flex flex-col gap-1">
            <span>{fundraiser.name}</span>
            <span className="font-bold">{fundraiser.createdBy}</span>
        </div>;
    else
        return <span>-</span>;
}

export function CampaignDonations({campaignId}) {

    const [donations, setDonations] = useState(null)

    const {refetch} = useQuery(GET_DONATIONS_TO_CAMPAIGN_BY_CREATION_DATE, {
        variables: {
            campaignId: campaignId,
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setDonations(data.donationsToCampaignByCreationDate),
    })

    return (
        <GlassCard width="w-full">
            <div className="text-2xl font-bold mb-2">
                Donations
            </div>
            <OnLoadViewer loading={donations === null}>
                { donations && (donations.length > 0) ?
                <TableContainer sx={{maxHeight: 460}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <RefreshCell sx={cellStyles.dateTime} action={refetch} text="Date" />
                                <TableCell sx={cellStyles.fixedWide}>Fundraiser</TableCell>
                                <TableCell sx={cellStyles.person}>Donor</TableCell>
                                <TableCell sx={cellStyles.money}>Amount</TableCell>
                                <TableCell sx={cellStyles.icon}>GiftAid</TableCell>
                                <TableCell sx={cellStyles.freeWide}>Message</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { donations.map(donation => (
                                <TableRow key={donation._id}>
                                    <TableCell align="left">{dateToString(donation.createdAt)}</TableCell>
                                    <TableCell align="left"><FormattedFundraiserSummary fundraiser={donation.fundraiserId} /></TableCell>
                                    <TableCell align="left"><FormattedUserName user={donation} /></TableCell>
                                    <TableCell align="right">{monetaryAmountToString(donation.donation)}</TableCell>
                                    <TableCell align="center">{donation.giftAid ? "yes" : "no"}</TableCell>
                                    <TableCell align="left">{donation.comment ?? "-"}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                :
                <div>No donations yet!</div>
                }
            </OnLoadViewer>
        </GlassCard>
    )
}

export function CampaignFundraisers({campaignId}) {

    const [fundraisers, setFundraisers] = useState(null)

    const {refetch} = useQuery(GET_FUNDRAISERS_BY_CAMPAIGN_ID, {
        variables: {
            campaignId: campaignId,
        },
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setFundraisers(data.portalFindFundraisersByCampaignId),
    })

    return (
        <GlassCard width="w-full">
            <div className="text-2xl font-bold mb-2">
                Individual Fundraisers
            </div>
            <OnLoadViewer loading={fundraisers === null}>
                { fundraisers && (fundraisers.length > 0) ?
                <TableContainer sx={{maxHeight: 460}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <RefreshCell sx={cellStyles.dateTime} action={refetch} text="Started" />
                                <TableCell sx={cellStyles.fixedWide}>Progress</TableCell>
                                <TableCell sx={cellStyles.person}>Fundraiser</TableCell>
                                <TableCell sx={cellStyles.freeWide}>Title</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { fundraisers.map(fundraiser => (
                                <TableRow key={fundraiser._id}>
                                    <TableCell align="left">{dateToString(fundraiser.createdAt)}</TableCell>
                                    <TableCell align="center">
                                        <CampaignProgressBar
                                            total={fundraiser.total}
                                            target={fundraiser.target}
                                            supporters={fundraiser.uniqueDonors}
                                        />
                                    </TableCell>
                                    <TableCell align="left">{fundraiser.createdBy}</TableCell>
                                    <TableCell align="left">{fundraiser.name}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                :
                <div>No fundraisers yet!</div>
                }
            </OnLoadViewer>
        </GlassCard>
    )
}
