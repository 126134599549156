import {browserSessionPersistence, setPersistence, indexedDBLocalPersistence, signInWithEmailAndPassword} from "firebase/auth";

import {GlassCard, LocalLink, FormWrapper, FormInput, FormCheckBox, FormErrorText, FormPrimaryButton, authErrorDecoder} from 'gih_web_common';
import Logo from 'gih_web_common/assets/gih-logo-1818x268.png';

import {auth} from "../../utils/firebase";
import {logActionSuccess, logActionUserError, logFormValidationFailure} from "../../utils/analytics";
import {ACTIONS} from "../../utils/analyticsConstants";


export default function SignIn() {

    function signIn(values) {
        return new Promise((resolve, reject) => {
            const persistence = values.remember_me ? indexedDBLocalPersistence : browserSessionPersistence;
            const email = values.email.trim().toLowerCase();
            setPersistence(auth, persistence)
                .then(() => {
                    signInWithEmailAndPassword(auth, email, values.password.toString())
                        .then(() => {
                            logActionSuccess(ACTIONS.userLogin, `for ${email}`);
                            // Automatic Redirect to HomeIndex
                            resolve();
                        }, e => {
                            logActionUserError(ACTIONS.userLogin, `for ${email} with code ${e.code}`);
                            reject(authErrorDecoder(e));
                        });
                });
        });
    }

    return (
        <div className="min-h-screen flex justify-center items-center p-1">
            <GlassCard width="max-w-md">
                <div className="flex justify-center mb-6">
                    <img src={Logo} alt=""/>
                </div>
                <div>
                    <div className="flex justify-between align-start mb-4">
                        <h2 className="text-3xl font-bold">Sign in to your account</h2>
                    </div>
                </div>
                <FormWrapper
                    onSubmit={signIn}
                    onValidationFailure={logFormValidationFailure}
                    className="space-y-6"
                >
                    <div className="rounded-md shadow-sm space-y-1">
                        <FormInput
                            id="email-address"
                            name="email"
                            type="email"
                            autoComplete="email"
                            placeholder="e-mail address"
                            label="E-mail address"
                            hideLabel
                            hideShadow
                            className="rounded-t-md"
                            disableRounded
                            required
                        />
                        <FormInput
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            required
                            label="Password"
                            hideLabel
                            hideShadow
                            placeholder="Password"
                            className="rounded-b-md"
                            disableRounded
                        />
                    </div>
                    <div className="flex items-center justify-between">
                        <FormCheckBox
                            id="remember_me"
                            name="remember_me"
                            label="Remember me"
                        />
                        <div className="text-sm">
                            <LocalLink to="/resetPassword">
                                Forgot your password?
                            </LocalLink>
                        </div>
                    </div>
                    <div className="mx-auto">
                        <FormErrorText/>
                        <FormPrimaryButton type="submit">
                            Sign in
                        </FormPrimaryButton>
                    </div>
                </FormWrapper>
            </GlassCard>
        </div>
    )
}
