import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {useMutation} from "@apollo/react-hooks";

import {ModalForm, FormInput, FormPrimaryButton} from "gih_web_common";

import {VERIFY_CODE, VERIFY_NUMBER} from "../../utils/graphql/user";
import {asE164} from "../../utils/phone";
import {logActionSuccess, logActionGraphQLFailure, logActionUserError} from "../../utils/analytics";
import {ACTIONS} from "../../utils/analyticsConstants";


export function PhoneVerificationModal({onComplete}) {

    const user = useSelector(state => state.user);
    const dispatch = useDispatch();

    const [phoneNumber, setPhoneNumber] = useState(null);

    const [sendVerificationCodeMutation] = useMutation(VERIFY_NUMBER);
    const [checkVerificationCodeMutation] = useMutation(VERIFY_CODE);

    function sendVerificationCode(values) {

        return new Promise (async (resolve, reject) => {
            const phoneE164 = asE164(values.phone);
            if (phoneE164 !== null) {
                await sendVerificationCodeMutation({
                    variables: {
                        phone: phoneE164
                    }
                }).then(r => {
                    logActionSuccess(ACTIONS.userPhoneVerificationCodeSend, `for ${phoneE164}`);
                    setPhoneNumber(phoneE164);
                    resolve();
                }).catch(e => {
                    logActionGraphQLFailure(ACTIONS.userPhoneVerificationCodeSend, e);
                    reject(e);
                })
            } else {
                reject("Invalid phone number");
            }
        })
    }

    function checkVerificationCode(values) {

        return new Promise(async (resolve, reject) => {
            await checkVerificationCodeMutation({
                variables: {
                    phone: phoneNumber,
                    code: values.code.trim()
                }
            }).then(r => {
                const data = r.data.checkAdminPhoneVerificationCode

                if (data !== null) {
                    logActionSuccess(ACTIONS.userPhoneVerificationCodeCheck, `for ${phoneNumber}`);
                    resolve();

                    dispatch({
                        type: "SET_USER",
                        payload: {
                            ...user,
                            smsNotificationPhone: data.smsNotificationPhone,
                            smsNotificationDays: data.smsNotificationDays,
                        }
                    });

                    onComplete({
                        type: "success",
                        message: "Your phone number has been verified",
                        smsNotificationDays: data.smsNotificationDays,
                    });

                } else {
                    const msg = "Verification failed - please check the code and try again.";
                    logActionUserError(ACTIONS.userPhoneVerificationCodeCheck, msg);
                    reject(msg);
                }
            }, e => {
                logActionGraphQLFailure(ACTIONS.userPhoneVerificationCodeCheck, e);
                reject(e);
            })
        })
    }

    function onSubmit(values) {
        if (phoneNumber !== null) {
            return checkVerificationCode(values)
        } else {
            return sendVerificationCode(values)
        }
    }

    return (
        <ModalForm
            title="Enter and verify your mobile number"
            onDismiss={() => onComplete(null)}
            onSubmit={onSubmit}
            id="adminPhoneForm"
            width={700}
            footer={
                <FormPrimaryButton type="submit" form="adminPhoneForm">
                    { (phoneNumber !== null) ? "Verify code" : "Send verification code" }
                </FormPrimaryButton>
            }
        >
            { (phoneNumber === null) ? (
            <FormInput
                id="phone"
                name="phone"
                type="text"
                required
                label="Enter the mobile number"
                hideShadow
                placeholder="Phone number"
            />
            ) : (
            <FormInput
                id="code"
                name="code"
                type="text"
                required
                label="Enter the verification code"
                hideShadow
                placeholder="Verification code"
            />
            )}
        </ModalForm>
    )
}
