import {updatePassword, reauthenticateWithCredential, EmailAuthProvider} from "firebase/auth";

import {ModalForm, FormInput, FormNewPassword, FormPrimaryButton, authErrorDecoder} from "gih_web_common";

import {auth} from "../../utils/firebase";


export function ChangePasswordModal({onComplete}) {

    function changePassword(values) {
        return new Promise(async (resolve, reject) => {
            const credential = EmailAuthProvider.credential(
                auth.currentUser.email,
                values["currentPassword"]
            )
            reauthenticateWithCredential(
                auth.currentUser,
                credential
            ).then(() => {
                return updatePassword(auth.currentUser, values["newPassword"]).then(() => {
                    onComplete({
                        type: "success",
                        message: "Your password was successfully updated"
                    })
                })
            }).catch((e) => {                
                reject({
                    newPassword: "empty",
                    text: authErrorDecoder(e)
                })
            })
        })
    }

    return (
        <ModalForm
            title="Change password"
            onDismiss={() => onComplete(null)}
            onSubmit={changePassword}
            id="passwordChangeForm"
            width={700}
            footer={
                <FormPrimaryButton type="submit" form="passwordChangeForm">
                    Change password
                </FormPrimaryButton>
            }
        >
            <FormInput
                id="currentPassword"
                name="currentPassword"
                type="password"
                autoComplete="current-password"
                placeholder="current password"
                label="Current password"
                required
                rootClassName="w-full"
            />
            <FormNewPassword
                account={null}
                id="newPassword"
                name="newPassword"
                type="password"
                autoComplete="new-password"
                required
                label="Choose a new password"
                hideShadow
                placeholder="new password"
            />
        </ModalForm>
    )
}
