import {useState} from "react";
import {DateTime} from "luxon";

import {useQuery} from "@apollo/react-hooks";

import {OnLoadViewer, TableContainer2, Table, TableHead, TableBody, TableRow, TableCell, RefreshCell, monetaryAmountToString, dateToString, dateToStringNoTime, cellStyles} from "gih_web_common";

import {GET_CAMPAIGNS_BY_CREATION_DATE} from "../../../utils/graphql/admin-giving";

import {CharityCell} from "../../common/elements";



export function CampaignsRecentlyCreated({days, fundraisers}) {

    const now = DateTime.now();

    const {refetch, loading, data, error} = useQuery(GET_CAMPAIGNS_BY_CREATION_DATE, {
        variables: {
            start: now.minus({days:days}).startOf('day').toJSDate(),
            end: now.endOf('day').toJSDate(),
            fundraisers: fundraisers,
        },
    });
    const campaigns = data?.campaignsByCreationDate;

    return (
        <TableContainer2
            loading={loading}
            error={error}
            rows={campaigns}
            emptyText={`None created within the last ${days} days`}
            refresh={refetch}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <RefreshCell sx={cellStyles.dateTime} action={refetch} text="Created" />
                        <TableCell sx={cellStyles.charity}>Charity</TableCell>
                        <TableCell sx={cellStyles.person}>Created by</TableCell>
                        <TableCell sx={cellStyles.freeWide}>Name</TableCell>
                        <TableCell sx={cellStyles.date}>Ending</TableCell>
                        <TableCell sx={cellStyles.money}>Target</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { campaigns?.map(campaign => (
                        <TableRow key={campaign._id}>
                            <TableCell align="left">{dateToString(campaign.createdAt)}</TableCell>
                            <CharityCell charity={campaign.charityId} />
                            <TableCell align="left">{campaign.createdBy}</TableCell>
                            <TableCell align="left">{campaign.name}</TableCell>
                            <TableCell align="left">{dateToStringNoTime(campaign.endDate)}</TableCell>
                            <TableCell align="left">{monetaryAmountToString(campaign.target)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer2>
    );
}
