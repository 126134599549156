import {useParams, useHistory} from "react-router-dom";
import {useMediaQuery} from 'react-responsive'

import {tabButtonStyle} from "../common/style";

import {ActivityCards} from "./cards"
import {ActivityCalendar} from "./calendar"


export default function ActivitiesPage() {

    const {activityGroup} = useParams();
    const history = useHistory();
    const isNarrow = useMediaQuery({ query: '(max-width: 640px)' })

    function pastEventButtonClicked() {
        history.push("/activities/past");
    }

    function upcomingEventsButtonClicked() {
        history.push("/activities/upcoming");
    }

    function calendarViewButtonClicked() {
        history.push("/activities/calendar");
    }

    return (
        <div className="px-1 lg:px-4 overflow-hidden">
            <div className="flex flex-row space-x-3 px-3 py-3 h-[64px]">
                <button onClick={upcomingEventsButtonClicked} className={tabButtonStyle(activityGroup === "upcoming", false)}>
                    {isNarrow ? "Upcoming" : "Upcoming activities"}
                </button>
                <button onClick={pastEventButtonClicked} className={tabButtonStyle(activityGroup === "past", false)}>
                    {isNarrow ? "Past" : "Past activities"}
                </button>
                <button onClick={calendarViewButtonClicked} className={tabButtonStyle(activityGroup === "calendar", false)}>
                    Calendar
                </button>
            </div>

            { activityGroup === "calendar" ? <ActivityCalendar/> : <ActivityCards group={activityGroup}/> }
        </div>
    )
}
