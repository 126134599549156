import {FollowUps} from "./followups"
import {LiveStatus} from "./live-status-widget"


export function CRMTab() {

    return (
        <div className="space-y-3 max-w-[2048px] mx-auto">
            <LiveStatus/>
            <FollowUps/>
        </div>
    )
}
