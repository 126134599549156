import { initializeApp } from "firebase/app";
import { getFirestore, collection } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { ref, uploadBytes, getStorage } from "firebase/storage";
import { getAnalytics, logEvent } from "firebase/analytics";

import uuid from "react-uuid";


const firebaseApp = initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG));

// Firebase Instances
export const auth = getAuth(firebaseApp);
export const firestore = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
export const analytics = getAnalytics(firebaseApp);

// Collections
export const chats = collection(firestore, process.env.REACT_APP_CHATS_COLLECTION);


export function logAction(what, outcome, text) {
    logEvent(analytics, 'user_action', {
        what: what,
        outcome: outcome,
        text: text.substring(0, 100),
        text2: text.substring(100),
    });
}

export async function upload(file, entity, element, what, onSuccess) {

    if (file) {

        if (!file.name) {
            throw `The ${what} file has no name`
        }

        const extension = file.name.substring(file.name.lastIndexOf('.'));
        const imageUniqueId = uuid()
        const target = entity + "_" + imageUniqueId + "_" + element + extension;

        console.log(`Uploading ${file.name} with extension ${extension} as ${target}`);

        const storageRef = ref(storage, target);
        const action = `${what}Upload`;

        return uploadBytes(storageRef, file)
            .then(snapshot => {
                const text = `${what} uploaded as ${snapshot.ref.fullPath}`;
                console.log(text);
                logAction(action, 'success', text);
                onSuccess(snapshot.ref.fullPath)
            }).catch(e => {
                logAction(action, 'failed', e.toString());
                throw `Sorry - something went wrong - please press "Save" again to retry.`
            });
    }
}
