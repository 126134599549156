import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState, useRef} from "react";
import {browserName, CustomView} from 'react-device-detect';

import {onAuthStateChanged, signOut} from 'firebase/auth';
import {useLazyQuery, useQuery} from "@apollo/react-hooks";

import {InactivityTimer, graphQLErrorAsText} from "gih_web_common";

import AppRoutes from "./nav/routes";
import Navbar from "./nav/navbar";

import {BrowserNotSupportedBanner} from "./pages/common/errors";

import {auth} from "./utils/firebase";
import {GET_CONFIG} from "./utils/graphql/basic";
import {GET_USER_BY_FIREBASEID} from "./utils/graphql/user";
import {GET_CHARITY_BY_ID} from "./utils/graphql/charity";
import {logActionSuccess, logActionGraphQLFailure} from "./utils/analytics";
import {ACTIONS} from "./utils/analyticsConstants";


function App() {

    const dispatch = useDispatch();

    const cfg = useSelector(state => state.cfg);
    const currentUser = useSelector(state => state.user);
    const loginState = useSelector(state => state.loginState);

    const [lastLoginState, setLastLoginState] = useState(null);

    useQuery(GET_CONFIG, {
        onCompleted: data => {
            logActionSuccess(ACTIONS.configGet, 'Initial');
            dispatch({type: "SET_CONFIG", payload: data.getConfig});
        },
        onError: e => {
            logActionGraphQLFailure(ACTIONS.configGet, e);
        },
    })

    const [inactivityTimeout, setInactivityTimeout] = useState(false);
    const inactivityTimer = useRef(null);

    const [requestCharity] = useLazyQuery(GET_CHARITY_BY_ID, {
        onCompleted: data => {
            if (loginState === 'SigninUser') {
                if (data.portalFindCharityById) {
                    console.log('Received CHARITY response')
                    dispatch({type: "SET_CHARITY", payload: data.portalFindCharityById})
                } else {
                    console.log('Cached charity no longer exists - reverting to default')
                    dispatch({type: "SET_CHARITY", payload: currentUser.charityId})
                }
            }
        }
    });

    const [requestUser] = useLazyQuery(GET_USER_BY_FIREBASEID, {
        fetchPolicy: "no-cache",
        onCompleted: data => {
            if (loginState === 'SigninAuth') {

                inactivityTimer.current?.stop();
                const user = data.portalFindUserByFirebaseId;

                if (user) {
                    console.log('Received USER response');
                    dispatch({type: "SET_USER", payload: user});
                    let effectiveCharityId = user.charityId._id;

                    if (user.fullAccess) {
                        const storedCharityId = window.localStorage.getItem("charityId");
                        if (storedCharityId) {
                            effectiveCharityId = storedCharityId;
                            console.log(`User with full access has effective charity id ${effectiveCharityId}`)
                        }
                    }

                    if (effectiveCharityId === user.charityId._id.toString()) {
                        dispatch({type: "SET_CHARITY", payload: user.charityId});
                    } else {
                        requestCharity({
                            variables: {
                                charityId: effectiveCharityId,
                            }});
                    }

                    inactivityTimer.current = new InactivityTimer({
                        gracePeriod: 1800, // Expire after 30 minutes
                        onExpiry: () => setInactivityTimeout(true),
                    });

                } else {
                    console.log('No USER matching firebase user - logging out');
                    signOut(auth);
                }
            }
        },
        onError: e => {
            console.log(`Failed to get user: ${graphQLErrorAsText(e)}`);
            signOut(auth);
        },
    });

    useEffect(() => {
        onAuthStateChanged(auth, async (authUser) => {

            if (loginState === 'SignupBegun') {
                console.log('Ignoring auth state change - signup in progress');

            } else if (authUser) {
                logActionSuccess(ACTIONS.userAuthenticate, `for Firebase user ${authUser.uid}`);
                dispatch({type: "SET_AUTH", payload: authUser});
                requestUser({
                    variables: {
                        firebaseId: authUser.uid
                    },
                });
            } else {
                console.log('Not logged in');
                dispatch({ type: "CLEAR_AUTH" });
            }
        });

    }, [dispatch]);

    useEffect(() => {
        if (inactivityTimeout) {
            logActionSuccess(ACTIONS.userInactivityLogout, "Inactivity timer expired");
            signOut(auth).then(() => {
                setInactivityTimeout(false);
                inactivityTimer.current?.stop();
                inactivityTimer.current = null;
            });
        }
    }, [inactivityTimeout]);

    useEffect(() => {
        if (loginState !== lastLoginState) {
            console.log('loginState', lastLoginState, '->', loginState);
            // TODO.
            setLastLoginState(loginState);
        }
    }, [loginState]);

    return (
        <>
            <CustomView condition={false}>
                <BrowserNotSupportedBanner/>
            </CustomView>
            <CustomView condition={true}>
                { loginState === 'LoggedIn' &&
                <Navbar/>
                }
                { loginState && cfg &&
                <AppRoutes/>
                }
            </CustomView>
        </>
    )
}

export default App;
