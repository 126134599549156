import {gql} from "@apollo/client";

import {charityBaseInfo} from './basic'


export const SEND_PASSWORD_RESET_EMAIL = gql`
    mutation SendPasswordResetEmail($email: String!) {
        sendPasswordResetEmail(email: $email)
    }
`

export const GET_USER_BY_FIREBASEID = gql`
    query PortalFindUserByFirebaseId($firebaseId: String!) {
        portalFindUserByFirebaseId(firebaseId: $firebaseId) {
            _id
            firstName
            lastName
            email
            alias
            enableConcierge
            smsNotificationPhone
            smsNotificationDays
            fullAccess
            charityId {
                ${charityBaseInfo}
            }
            hintsAcked {
                notification
                volunteerStatus
                autoReply
            }
        }
    }
`

export const VERIFY_NUMBER = gql`
    mutation SendAdminPhoneVerificationCode($phone: String!) {
        sendAdminPhoneVerificationCode(phone: $phone)
    }
`

export const VERIFY_CODE = gql`
    mutation CheckAdminPhoneVerificationCode($phone: String!, $code: String!) {
        checkAdminPhoneVerificationCode(phone: $phone, code: $code) {
            smsNotificationPhone
            smsNotificationDays
        }
    }
`

export const ADD_USER = gql`
    mutation PortalAddUser($inviteId: ID!, $details: PortalUserDetailsInput!) {
        portalAddUser(inviteId: $inviteId, details: $details) {
            _id
            firstName
            lastName
            email
            alias
            enableConcierge
            smsNotificationPhone
            smsNotificationDays
            fullAccess
            charityId {
                ${charityBaseInfo}
            }
            hintsAcked {
                notification
                volunteerStatus
                autoReply
            }
        }
    }
`

export const UPDATE_USER_DETAILS = gql`
    mutation PortalUpdateUserDetails($firebaseId: String!, $firstName: String!, $lastName: String!, $enableConcierge: Boolean!, $alias: String, $smsNotificationDays: Int) {
        portalUpdateUserDetails(firebaseId: $firebaseId, firstName: $firstName, lastName: $lastName, enableConcierge: $enableConcierge, alias: $alias, smsNotificationDays: $smsNotificationDays) {
            firstName
            lastName
            alias
            enableConcierge
            smsNotificationPhone
            smsNotificationDays
        }
    }
`

export const HINT_ACK = gql`
    mutation PortalHintAck($name: String!) {
        portalHintAck(name: $name)
    }
`

export const SELF_SIGNUP = gql`
    mutation SelfSignUp($email: String!, $name: String!, $token: String!) {
        selfSignUp(email: $email, name: $name, token: $token)
    }
`

export const SEND_INVITE = gql`
    mutation SendInvite($invite: InviteInput!) {
        sendInvite(invite: $invite) {
            charityId {
                _id
            }
            fromInviteEmail
            _id
        }
    }
`

export const CHECK_INVITE = gql`
    query CheckInvite($inviteId: ID!) {
        checkInvite(id: $inviteId) {
            _id
            fromInviteEmail
            sentInviteEmail
            charityId {
                _id
                name
                provisional
            }
            createdAt
        }
    }
`
