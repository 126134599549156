import { combineReducers } from 'redux';

import {nameStr} from "gih_web_common";

import {modeFromPath} from "./navigation";
import {setAnalyticsUserId} from "./analytics";


function configReducer(cfg=null, action) {

    switch (action.type) {
        case 'SET_CONFIG':
            cfg = {...action.payload};
            break;

        default:
            break;
    }

    return cfg;
}

function userReducer(user=null, action) {

    switch (action.type) {
        case 'SET_USER':
            user = { ...action.payload };
            delete user.__typename;
            setAnalyticsUserId(user._id);
            window.tidioChatApi.setVisitorData({
                distinct_id: user._id,
                email: user.email,
                name: nameStr(user),
            });
            window.tidioChatApi.setContactProperties({
                charityid: user.charityId._id,
                charityName: user.charityId.name,
            });
            break;

        case 'CLEAR_AUTH':
            user = null;
            break;

        case 'SET_HINT':
            user = { ...user };
            user.hintsAcked[action.payload] = true;
            break;

        default:
            break;
    }

    return user;
}

function authUserReducer(authUser=null, action) {

    switch (action.type) {
        case 'SET_AUTH':
            authUser = { ...action.payload };
            break;

        case 'CLEAR_AUTH':
            authUser = null;
            break;

        default:
            break;
    }

    return authUser;
}

function loginStateReducer(state=null, action) {

    switch (action.type) {
        case 'SET_AUTH':
            state = (state === 'SignupBegun' || state === 'SignupAuth') ? 'SignupAuth' : 'SigninAuth';
            break;

        case 'SET_USER':
            state = 'SigninUser';
            break;

        case 'SET_CHARITY':
            state = 'LoggedIn';
            break;

        case 'CLEAR_AUTH':
            state = 'LoggedOut';
            break;

        case 'SIGNUP_BEGIN':
            state = 'SignupBegun';
            break;

        case 'SIGNUP_GATHER_USER_DETAILS':
            state = 'SignupGatherUserDetails';
            break;

        default:
            break;
    }

    return state;
}

function givingModeReducer(givingMode=null, action) {

    switch (action.type) {
        case 'SET_CHARITY':
            givingMode = ( action.payload.features.enableGiving
                        && givingMode !== false
                        && modeFromPath(window.location.pathname) !== false
                        && modeFromPath(window.location.search) !== false);
            break;

        case 'SET_GIVING':
            givingMode =  action.payload;
            break;

        default:
            break;
    }

    return givingMode;
}

function charityReducer(charity=null, action) {

    switch (action.type) {

        case 'SET_CHARITY':
            charity = {
                id: action.payload._id.toString(),
                logoPicture: action.payload.logoPicture,
                defaultAcceptMessage: action.payload.defaultAcceptMessage,
                defaultDeclineMessage: action.payload.defaultDeclineMessage,
                name: action.payload.name,
                features: action.payload.features,
                provisional: action.payload.provisional,
                awaiting1stActivity: action.payload.awaiting1stActivity,
                awaiting1stCampaign: action.payload.awaiting1stCampaign,
            };
            window.localStorage.setItem("charityId", charity.id);
            break;

        case 'SET_AWAITING_1ST_ACTIVITY':
            charity = {
                ...charity,
                awaiting1stActivity: action.payload,
            };
            break;

        case 'SET_AWAITING_1ST_CAMPAIGN':
            charity = {
                ...charity,
                awaiting1stCampaign: action.payload,
            };
            break;

        case 'CLEAR_AUTH':
            charity = null;
            break;

        default:
            break;
    }

    return charity;
}

function charityFilterReducer(filter='live', action) {

    switch (action.type) {
        case 'SET_CHARITY_FILTER':
            filter = action.payload;
            break;

        default:
            break;
    }

    return filter;
}


export const reduxReducers = combineReducers({
    cfg: configReducer,
    user: userReducer,
    authUser: authUserReducer,
    charity: charityReducer,
    loginState: loginStateReducer,
    charityFilter: charityFilterReducer,
    givingMode: givingModeReducer,
});
