import {useState} from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {use100vh} from 'react-div-100vh'
import {FiPlusCircle, FiMinusCircle} from "react-icons/fi";

import {buttonStyles, dateToString, fmtChatTimestamp} from "gih_web_common";

import {statusToText, statusToBackground, filterToColour, isEditable, getButtons, noVolunteers} from "../../../utils/activity";

import {Badge} from "../../activities/badge";
import {NotificationsHint} from "../../activities/notifications";
import {truncate} from "../../common/style";
import {BadgeCount} from "../../common/elements";
import {Hint} from "../../common/hint";

import {UserAvatar} from "../common/avatar";
import {GroupMessageModal, GroupMessageResultModal} from "./group-message";


function asActiveChat(chat, activity, charity) {
    return {
        ...chat,
        activity: activity,
        canSetStatus: isEditable(activity),
        buttons: getButtons(activity, chat.participationStatus, charity),
    }
}

function ParticipationCount(props) {
    if (props.count > 0) {
        return (
            <button
              className={`whitespace-nowrap px-2 py-1 w-[7em] sm:w-24 text-xs sm:text-sm text-center font-semibold rounded-md ${filterToColour(props.status, props.active, props.filter)}`}
              onClick={() => { props.setFilter(props.status) }}
              disabled={!props.active}
            >
                {props.count} {statusToText(props.status).toLowerCase()}
            </button>
        )
    } else {
        return (
            <div className="w-0 sm:w-24"></div>
        )
    }
}

function ParticipationCounts(props) {

    // <ParticipationCount count={props.counts.decline} status="decline" colour="bg-red-400"/>
    return (
        <>
            { noVolunteers(props.counts) &&
            <div className="ml-auto bg-gray-400 whitespace-nowrap py-1 px-2 text-xs sm:text-sm text-white text-center font-semibold rounded-md">
                No volunteers
            </div>
            }
            { !noVolunteers(props.counts) &&
            <div className={`flex items-end items-center py-1 px-2 space-x-2 ${props.active ? "" : "opacity-60"}`}>
                <ParticipationCount active={props.active} filter={props.filter} count={props.counts.pending} status="pending" setFilter={props.setFilter} />
                <ParticipationCount active={props.active} filter={props.filter} count={props.counts.waitApplication} status="waitApplication" setFilter={props.setFilter} />
                <ParticipationCount active={props.active} filter={props.filter} count={props.counts.checking} status="checking" setFilter={props.setFilter} />
                <ParticipationCount active={props.active} filter={props.filter} count={props.counts.accept} status="accept" setFilter={props.setFilter} />
                <ParticipationCount active={props.active} filter={props.filter} count={props.counts.withdrewAccepted} status="withdrewAccepted" setFilter={props.setFilter} />
            </div>
            }
        </>
    )
}

function Chats(props) {

    const charity = useSelector(state => state.charity);

    return (
        <div className="block">
            { props.activity.chats.map((chat, index) => {
                if (props.filter === null || props.filter === chat.participationStatus) {
                    let isActiveChat = (props.activeChat && props.activeChat.id === chat.id);
                    return (
                        <div className={`flex justify-between items-center py-3 px-3 ${isActiveChat ? "bg-alt-lighter" : "hover:bg-alt-dark"} border-t border-gray-200`}
                                key={chat.id}
                                onClick={() => { props.setActiveChat(asActiveChat(chat, props.activity, charity)) }}>
                            <div className="flex items-center">
                                <UserAvatar chat={chat}/>
                                <div className="ml-4">
                                    <div className="flex py-1 items-center">
                                        <div className={`text-sm ${isActiveChat ? "text-alt-darker font-bold" : "text-black"}`}>{chat.user_names[0]}</div>
                                        <div className={`whitespace-nowrap ml-1 px-2 py-1 ${statusToBackground(chat.participationStatus, false)} rounded-full text-xs text-white`}>{statusToText(chat.participationStatus)}</div>
                                    </div>
                                    <div className={`text-xs ${isActiveChat ? "text-black" : "text-gray-500"}`}>{truncate(chat.lastMessage.message, 192)}</div>
                                </div>
                            </div>
                            <div className="flex items-center space-x-1 min-w-fit">
                                { chat.unread_charity > 0 &&
                                <BadgeCount count={chat.unread_charity} />
                                }
                                <p className="text-gray-500 text-xs text-right min-w-[4em]">{fmtChatTimestamp(chat.timestamp.toDate())}</p>
                            </div>
                        </div>
                    )
                } else {
                    return null
                }})
            }
            { props.activity.chats.find(chat => props.filter === null || chat.participationStatus === props.filter) === undefined &&
                <div className="px-2 py-2">No chats</div>
            }
        </div>
    )
}

export function ChatList(props) {

    const height = use100vh();
    const [sendToGroupOptions, setSendToGroupOptions] = useState(null);
    const [sendToGroupResult, setSendToGroupResult] = useState(null);

    const [filter, setFilter_] = useState(null);

    function setFilter(status) {
        const newFilter = (filter === status) ? null : status
        setFilter_(newFilter)
        console.log(`Set filter ${filter} -> ${newFilter}`)
    }

    return (
        <div className="overflow-y-auto overscroll-contain border-t border-gray-400" style={{ height: `${height - 64 - 56 - 64}px`}}>
            { props.activities.length < 1 &&
                <div className="text-xl font-semibold p-4">
                    No {(props.activityGroup === "upcoming") ? "upcoming" : "past"} activities
                </div>
            }
            { props.activities.map((activity) => {

                let active = (activity._id === props.activeActivity)

                return (
                    <div className="border-b border-gray-400" key={activity._id}>
                        <div className="sm:flex sm:justify-between gap-2 text-base items-center space-y-3 px-3 py-3">
                            <div className="flex space-x-2 items-center">
                                { !active &&
                                <FiPlusCircle onClick={() => { props.setActiveActivity(activity._id) }} className="w-[2em] h-[2em] min-w-[2em] hover:text-alt-darker"/>
                                }
                                { active &&
                                <FiMinusCircle onClick={() => { props.setActiveActivity(null) }} className="w-[2em] h-[2em] min-w-[2em] text-alt-dark hover:text-black"/>
                                }
                                <div className="flex gap-2 items-center">
                                    <div className="font-bold text-sm sm:text-base">{activity.name}</div>
                                    <div className="flex flex-row items-center gap-1">
                                        <Badge activity={activity} />
                                        { activity.totalUnread > 0 &&
                                        <BadgeCount count={activity.totalUnread} />
                                        }
                                    </div>
                                </div>
                            </div>
                            { active &&
                            <div className="flex space-x-2 items-center">
                                { activity.chats.length > 0 &&
                                <button onClick={() => { setSendToGroupOptions({ activityGroup: props.activityGroup, activity: activity }) }} className={buttonStyles.alt}>
                                    Group message
                                </button>
                                }
                                <Link to={`/activity/${activity._id}`} className={buttonStyles.alt}>
                                    Details
                                </Link>
                            </div>
                            }
                        </div>

                        <div className="flex items-center py-2 px-2">
                            <div className="min-w-[6em] max-w-[14em] font-medium text-xs">{dateToString(activity.eventDate)}</div>
                            <ParticipationCounts active={active} counts={activity.participations} filter={filter} setFilter={setFilter}/>
                        </div>

                        { active &&
                        <Chats activity={activity} activeChat={props.activeChat} setActiveChat={props.setActiveChat} filter={filter} />
                        }
                    </div>
                )
            })}

            <Hint name="volunteerStatus">
                <p>As soon as you have responded to new volunteers listed as <strong>"pending"</strong>, we recommend changing their status to <strong>"Waiting application"</strong>
                or <strong>"Checks & Training"</strong>, whichever sounds most appropriate as the first stage of your onboarding process.</p>
                <p>This helps us know that you're processing the new volunteer and clears them from the numbers you see in the daily catchup e-mails.</p>
            </Hint>
            
            <NotificationsHint />

            { sendToGroupOptions !== null &&
                <GroupMessageModal
                    activityGroup={sendToGroupOptions.activityGroup}
                    activity={sendToGroupOptions.activity}
                    onDismiss={() => {
                        setSendToGroupOptions(null)
                    }}
                    onCompletion={(result) => {
                        setSendToGroupOptions(null)
                        setSendToGroupResult(result)
                    }}
                />
            }

            { sendToGroupResult !== null &&
                <GroupMessageResultModal
                    result={sendToGroupResult}
                    onDismiss={() => setSendToGroupResult(null)}
                />
            }
        </div>
    )
}
