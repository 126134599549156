import {useState, useReducer} from "react";
import {useLazyQuery} from "@apollo/react-hooks";
import {useSelector} from "react-redux";

import {GlassCard, FormWrapper, FormInput, FormTextarea, FormStickyPrimaryButton, formError, DropDown, TagInput, inputStyles} from 'gih_web_common';

import {getAddress, nullIfEmpty} from  "../../utils/address";
import {upload} from  "../../utils/firebase";
import {CHECK_KEYWORD} from "../../utils/graphql/basic";
import {logFileSuccess, logFileFailure, logFormValidationFailure} from "../../utils/analytics";
import {FILES, FORMS} from "../../utils/analyticsConstants";
import {ratingOpts} from "../../utils/charity";

import {LocationSearch} from "../common/locationSearch"
import {PhotoFileSelector, FormGroup} from "../common/elements";
import {Hint} from  "../common/hint";

import {CharityDetailPreview} from "./preview";
import {ContactsTable, contactsUpdater, canonicaliseContacts} from "./contacts";
import {ContactModal} from "./contactModal";
import {ConfirmLegalStatus} from "./legalStatus";


function getFormValues(initial) {
    return {
        name:                   initial.name,
        registration:           initial.registration ?? "",
        companyNumber:          initial.companyNumber ?? "",
        hmrcId:                 initial.hmrcId ?? "",
        fullDescription:        initial.fullDescription,
        briefDescription:       initial.briefDescription,
        line1:                  initial.address.line1 ?? "",
        line2:                  initial.address.line2 ?? "",
        streetName:             initial.address.streetName,
        townCity:               initial.address.townCity,
        postcode:               initial.address.postcode,
        defaultAutoReply:       initial.defaultAutoReply,
        defaultAcceptMessage:   initial.defaultAcceptMessage ?? "",
        defaultDeclineMessage:  initial.defaultDeclineMessage ?? "",
        website:                initial.website ?? "",
    }
}

function getLocation(initial) {
    return {
        geohash: initial.geohash,
        name: initial.locationName
    }
}

function charityNumberOrNull(value) {

    const trimmed = value.trim()

    if (trimmed.length < 1) {
        return null
    }

    if (! /^(SC\d{6}|\d{6,7})$/.test(trimmed)) {
        throw { registration: "Registered charity number is invalid" }
    }

    return trimmed
}

async function validate(values, existing) {

    if (!existing.profilePicture && !values.imageFile)
        throw formError("upload a profile picture for your organisation");

    if (!existing.logoPicture && !values.logoFile)
        throw formError("upload a logo for your organisation");

    if (!values.geohash)
        throw formError("enter a place name for location based search (this can be the postcode, street or area)");

    if (values.contacts.length < 1)
        throw formError("add at least one contact person in the organisation");

    const charity = {
        name:                 values.name.trim(),
        registration:         charityNumberOrNull(values.registration),
        companyNumber:        nullIfEmpty(values.companyNumber),
        hmrcId:               nullIfEmpty(values.hmrcId),
        giftAidEligible:      values.giftAidEligible,
        giftAidAutoClaim:     values.giftAidAutoClaim,
        fullDescription:      values.fullDescription.trim(),
        briefDescription:     values.briefDescription.trim(),
        geohash:              values.geohash,
        locationName:         values.locationName,
        defaultAutoReply:     values.defaultAutoReply.trim(),
        defaultAcceptMessage: nullIfEmpty(values.defaultAcceptMessage),
        defaultDeclineMessage: nullIfEmpty(values.defaultDeclineMessage),
        contacts:             values.contacts,
        address:              getAddress(values),
        profilePicture:       existing.profilePicture,
        logoPicture:          existing.logoPicture,
        keywords:             values.keywords,
        website:              nullIfEmpty(values.website),
        rating:               values.rating,
    }

    await upload(values.imageFile, 'charity', 'banner', FILES.charityProfileImage, (fullPath) => { charity.profilePicture = fullPath })
    await upload(values.logoFile, 'charity', 'logo', FILES.charityLogoImage, (fullPath) => { charity.logoPicture = fullPath })

    return charity
}

export const CharityDetailsForm = (props) => {

    const fullAccess = useSelector(state => state.user?.fullAccess);
    const charityCfg = useSelector(state => state.cfg?.charity);

    const [formValues, setFormValues] = useState(getFormValues(props.initial));
    const [selectedLocation, setSelectedLocation] = useState(getLocation(props.initial));

    const [imageFile, setImageFile] = useState(null);
    const [imageLocalURL, setImageLocalURL] = useState(null);

    const [logoFile, setLogoFile] = useState(null);
    const [logoLocalURL, setLogoLocalURL] = useState(null);

    const [keywords, setKeywords] = useState(props.initial.keywords);
    const [checkKeyword] = useLazyQuery(CHECK_KEYWORD);

    const [giftAidEligible, setGiftAidEligible] = useState(props.initial.giftAidEligible);
    const [giftAidAutoClaim, setGiftAidAutoClaim] = useState(props.initial.giftAidAutoClaim);

    const [confirmLegalStatus, setConfirmLegalStatus] = useState(null);
    const [rating, setRating] = useState(props.initial.rating);

    const [contacts, modifyContacts] = useReducer(contactsUpdater, canonicaliseContacts(props.initial.contacts));
    const [editingContact, setEditingContact] = useState(null);

    function getValues() {
        return {
            ...formValues,
            imageFile: imageFile,
            logoFile: logoFile,
            geohash: selectedLocation.geohash,
            locationName: selectedLocation.name,
            keywords: keywords,
            giftAidEligible: giftAidEligible,
            giftAidAutoClaim: giftAidAutoClaim,
            contacts: contacts,
            rating: rating,
        };
    }

    function onSubmit() {
        return new Promise((resolve, reject) => {
            validate(getValues(), props.initial)
                .then(charity => {
                    if (!props.initial.regNosLocked && (charity.registration || charity.companyNumber)) {
                        return new Promise((resolve, reject) => {
                           setConfirmLegalStatus({
                                details: charity,
                                agreed: () => {
                                    setConfirmLegalStatus(null);
                                    resolve(charity);
                                },
                                cancel: () => {
                                    setConfirmLegalStatus(null);
                                    reject({ registration: "Check registration status" });
                                }
                            });
                        });
                    } else {
                        return charity;
                    }
                })
                .then(charity => {
                    resolve(props.onValidated(charity));
                }, e => {
                    logFormValidationFailure(FORMS.charity, e);
                    reject(e);
                });
        });
    }

    function onSelectImage(file) {
        try {
            setImageFile(file);
            setImageLocalURL(URL.createObjectURL(file));
            logFileSuccess(FILES.charityProfileImage, file);
        } catch (e) {
            logFileFailure(FILES.charityProfileImage, e);
        }
    }

    function onSelectLogo(file) {
        try {
            setLogoFile(file);
            setLogoLocalURL(URL.createObjectURL(file));
            logFileSuccess(FILES.charityLogoImage, file);
        } catch (e) {
            logFileFailure(FILES.charityLogoImage, e);
        }
    }

    return (
        <div className="flex flex-wrap lg:grid lg:grid-cols-[minmax(800px,1000px)_400px] gap-2">
            <FormWrapper
                onSubmit={onSubmit}
                initialValues={getFormValues(props.initial)}
                onValueChange={values => setFormValues(values)}
                onValidationFailure={logFormValidationFailure}
                className="space-y-2"
                id="detailsForm"
            >
                <FormGroup colour="slate" title="Here is where you can say who you are and what you do as an organisation.">
                    <FormInput
                        id="name"
                        name="name"
                        type="text"
                        placeholder="organisation name"
                        length={formValues.name.length}
                        maxLength={charityCfg.nameMaxLength}
                        label="Name of charity / non-profit organisation"
                        required
                        rootClassName="w-full"
                    />
                    <FormTextarea
                        rows={8}
                        id="fullDescription"
                        name="fullDescription"
                        type="text"
                        placeholder="about"
                        length={formValues.fullDescription.length}
                        maxLength={charityCfg.descriptionMaxLength}
                        label="About your organisation"
                        required
                        rootClassName="w-full"
                    />
                    <FormInput
                        id="briefDescription"
                        name="briefDescription"
                        type="text"
                        placeholder="tag line"
                        length={formValues.briefDescription.length}
                        maxLength={charityCfg.briefDescriptionMaxLength}
                        label="Tag line"
                        subText="a brief description of your mission or purpose displayed beneath the logo"
                        required
                        rootClassName="w-full"
                    />
                    <FormInput
                        id="website"
                        name="website"
                        type="text"
                        placeholder="Website URL"
                        label="Website"
                        required
                        rootClassName="w-full"
                    />
                    <FormInput
                        id="registration"
                        name="registration"
                        type="text"
                        placeholder="Registration number (optional)"
                        label={props.initial.regNosLocked ? "Registered charity number (locked - contact Giving Is Human support to update)" : "Registered charity number (if applicable)"}
                        rootClassName="w-full"
                        disabled={props.initial.regNosLocked}
                    />
                    <FormInput
                        id="companyNumber"
                        name="companyNumber"
                        type="text"
                        placeholder="Company number (optional)"
                        label={props.initial.regNosLocked ? "Company number (locked - contact Giving Is Human support to update)" : "Company number (if your organisation is registered with Companies House)"}
                        rootClassName="w-full"
                        disabled={props.initial.regNosLocked}
                    />
                    <TagInput
                        id="keywords"
                        label="Keywords for search"
                        placeholder="Enter a keyword and press return"
                        value={keywords}
                        setValue={setKeywords}
                        findMatches={async hint => {
                            const r = await checkKeyword({ variables: { word: hint }});
                            return r.data.checkKeyword;
                        }}
                        settings={{
                            maxTags: charityCfg.keywordsMax,
                        }}
                    />
                </FormGroup>

                <FormGroup colour="yellow" title="Contact information for key people and their mailing preferences - this for Giving Is Human use only - not visible to users of our app or website.">
                    <ContactsTable
                        contacts={contacts}
                        setEditing={setEditingContact}
                        deleteContact={index => modifyContacts({what: 'delete', index: index})}
                    />
                </FormGroup>

                <FormGroup colour="green" title="The main address of your organisation - you can choose this or enter a different address/place when you create a campaign.">
                    <div className="flex w-full space-x-3 items-end">
                        <FormInput
                            id="line1"
                            name="line1"
                            type="text"
                            placeholder="Optional 1"
                            label="Room/building/floor/flat name/number (optional)"
                            rootClassName="w-full"
                        />
                        <FormInput
                            id="line2"
                            name="line2"
                            type="text"
                            placeholder="Optional 2"
                            label="Room/building/floor/flat name/number (optional)"
                            rootClassName="w-full"
                        />
                    </div>
                    <div className="flex w-full space-x-3 items-end">
                        <FormInput
                            id="streetName"
                            name="streetName"
                            type="text"
                            placeholder="number & street name"
                            label="Number & street name"
                            required
                            rootClassName="w-full"
                        />
                        <FormInput
                            id="townCity"
                            name="townCity"
                            type="text"
                            placeholder="town/city"
                            label="Town / City"
                            required
                            rootClassName="w-full"
                        />
                        <FormInput
                            id="postcode"
                            name="postcode"
                            type="text"
                            placeholder="postcode"
                            label="Postcode"
                            required
                            rootClassName="w-full"
                        />
                    </div>
                    <LocationSearch
                        initial={getLocation(props.initial)}
                        onSelectLocation={setSelectedLocation}
                    />
                </FormGroup>

                { false && /* VOLUNTEERING */
                <FormGroup colour="stone" title="Shortcut replies that you can use when volunteers contact you. It's worth setting an auto reply that reflects how long prospective volunteers should expect to wait before hearing back from you, especially if you only work certain days of the week.">
                    <div className="flex w-full space-x-3">
                        <FormTextarea
                            rows={2}
                            id="defaultAcceptMessage"
                            name="defaultAcceptMessage"
                            type="text"
                            placeholder="Default acceptance message"
                            label="Default acceptance message"
                            rootClassName="w-full"
                        />
                        <FormTextarea
                            rows={2}
                            id="defaultDeclineMessage"
                            name="defaultDeclineMessage"
                            type="text"
                            placeholder="Default decline message"
                            label="Default decline message"
                            rootClassName="w-full"
                        />
                    </div>
                    <FormTextarea
                        rows={2}
                        id="defaultAutoReply"
                        name="defaultAutoReply"
                        type="text"
                        placeholder="Default auto reply"
                        label="Default auto reply"
                        required
                        rootClassName="w-full"
                    />
                </FormGroup>
                }

                { props.initial.features?.enableGiving &&
                <FormGroup colour="orange" title="Donation options.">
                    <label className="flex items-center text-xs sm:text-base">
                        <input
                            type="checkbox"
                            className={inputStyles.checkbox}
                            onChange={e => setGiftAidEligible(!giftAidEligible)}
                            checked={giftAidEligible}
                            disabled={!fullAccess}
                        />
                        <span className="ml-2">Organisation is eligible to claim GiftAid on donations</span>
                    </label>
                    <label className="flex items-center text-xs sm:text-base">
                        <input
                            type="checkbox"
                            className={inputStyles.checkbox}
                            onChange={e => setGiftAidAutoClaim(!giftAidAutoClaim)}
                            checked={giftAidAutoClaim}
                            disabled={!fullAccess}
                        />
                        <span className="ml-2">Giving Is Human will make automated GiftAid claim submissions to HMRC</span>
                    </label>
                    <FormInput
                        id="hmrcId"
                        name="hmrcId"
                        type="text"
                        placeholder="HMRC identifier"
                        label="HMRC identifier"
                        required={giftAidAutoClaim}
                        rootClassName="w-full"
                    />
                    { fullAccess &&
                    <label className="flex flex-col w-fit">
                        <span className="text-xs sm:text-sm text-fgCard-default mb-1">Internal rating</span>
                        <DropDown
                            value={rating}
                            setValue={setRating}
                            opts={ratingOpts}
                        />
                    </label>
                    }
                </FormGroup>
                }

                { false && /* VOLUNTEERING */
                <Hint name="autoReply">
                    <p>Many of the volunteers coming to you through the App will be volunteering for the first time.
                    They may well want to have a chat about the opportunity before filling out lengthy application forms.</p>
                    <p>It's worth considering setting an auto-reply on all your activities offering to give volunteers a call or for them to call you...</p>
                    <p>Our experience so far suggests that you'll get a positive outcome with something like:</p>
                    <p className="italic">"Hi and thanks for getting in touch!
                    We're really grateful that you are interested in helping [[DESCRIBE THE CAUSE]]
                    It might take [[NUMBER OF DAYS]] for us to get back to you, but feel free to pop any questions you have in this chat.
                    If you're happy to leave your phone number and let me know me a convenient time to call, I'll give you a ring as soon as I can!”</p>
                </Hint>
                }

                <FormStickyPrimaryButton form="detailsForm" disabled={!!editingContact}>
                    {props.editing ? "Save changes" : "Save"}
                </FormStickyPrimaryButton>
            </FormWrapper>

            <GlassCard width="w-full lg:w-fit h-fit">
                <div className="mx-auto">
                    <PhotoFileSelector editing={props.editing} what="logo" onSelectFile={onSelectLogo} />
                    <PhotoFileSelector editing={props.editing} what="profile picture" onSelectFile={onSelectImage} />
                </div>
                <CharityDetailPreview
                    charity={{
                        profilePicture: props.initial.profilePicture,
                        logoPicture: props.initial.logoPicture,
                        name: formValues.name,
                        briefDescription: formValues.briefDescription,
                        fullDescription: formValues.fullDescription,
                    }}
                    imageLocalURL={imageLocalURL}
                    logoLocalURL={logoLocalURL}
                />
            </GlassCard>
            
            { confirmLegalStatus &&
            <ConfirmLegalStatus confirm={confirmLegalStatus} />
            }

            { editingContact &&
            <ContactModal
                initial={editingContact.contact}
                isEditing={editingContact.index !== null}
                onComplete={contact => {
                    if (contact) {
                        modifyContacts({
                            what: (editingContact.index !== null) ? 'update' : 'add',
                            index: editingContact.index,
                            contact: contact,
                        });
                    }
                    setEditingContact(null);
                }}
            />
            }
        </div>
    )
}
