import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import {buttonStyles, cellStyles, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, FormattedUserName, Lozenge} from "gih_web_common";


function contactTemplate() {
    return {
        firstName: "",
        lastName: "",
        role: null,
        phone: null,
        email: null,
        preferences: {
            marketing: true,
            customerSuccess: true,
        }
    };
}

export function canonicaliseContacts(contacts) {
    return contacts.map(contact => ({
        firstName: contact.firstName,
        lastName: contact.lastName,
        role: contact.role,
        phone: contact.phone,
        email: contact.email,
        preferences: {
            marketing: contact.preferences.marketing,
            customerSuccess: contact.preferences.customerSuccess,
        }
    }));
}

export function contactsUpdater(contacts, action) {

    switch (action.what) {

        case 'add': {
            const c = Array.from(contacts);
            c.push(action.contact);
            return c;
        }

        case 'update':
            return contacts.map((contact, index) => (index === action.index) ? action.contact : contact);

        case 'delete':
            return contacts.filter((contact, index) => index !== action.index);

        default:
            break;
    }
}

export function ContactSummary({contact}) {
    return (
        <>
            <div className="flex flex-wrap gap-1 items-center">
                <FormattedUserName user={contact} />
                { contact.phone && <Lozenge colour="bg-teal-500" text={`Phone ${contact.phone}`} href={'tel:' + contact.phone} /> }
                { contact.email && <Lozenge colour="bg-teal-500" text={`E-mail ${contact.email}`} href={'mailto:' + contact.email} /> }
            </div>
            <ContactRole contact={contact} />
        </>
    );
}

function ContactRole({contact}) {
    return (
        <>
            <Lozenge colour="bg-green-500" text={contact.role} />
            { contact.preferences.marketing && <Lozenge colour="bg-blue-500" text="Opt-in: Marketing"/> }
            { contact.preferences.customerSuccess && <Lozenge colour="bg-blue-500" text="Opt-in: CustomerSuccess"/> }
        </>
    );
}

export function ContactsTable({contacts, setEditing, deleteContact}) {

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={cellStyles.person}>Name</TableCell>
                        <TableCell sx={cellStyles.email}>E-mail</TableCell>
                        <TableCell sx={cellStyles.email}>Phone</TableCell>
                        <TableCell sx={cellStyles.fixedNarrow}>Role</TableCell>
                        <TableCell sx={cellStyles.actions}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { contacts.map((contact, index) => (
                        <TableRow key={index}>
                            <TableCell align="left"><FormattedUserName user={contact} /></TableCell>
                            <TableCell align="left">{contact.email ?? '-'}</TableCell>
                            <TableCell align="left">{contact.phone ?? '-'}</TableCell>
                            <TableCell align="left"><ContactRole contact={contact} /></TableCell>
                            <TableCell>
                                <div className="flex flex-col lg:flex-row gap-2 justify-center">
                                    <button type="button" onClick={() => setEditing({ index: index, contact: contact})} className={buttonStyles.altLgNarrow}>
                                        <ModeEditOutlineIcon/> Edit
                                    </button>
                                    <button type="button" onClick={() => deleteContact(index)} className={buttonStyles.redLgNarrow}>
                                        <DeleteForeverIcon/> Delete
                                    </button>
                                </div>
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow key="Trailer">
                        <TableCell>
                            <button type="button" onClick={() => setEditing({index: null, contact: contactTemplate()})} className={buttonStyles.altLg}>
                                <AddIcon/> Add
                            </button>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}
