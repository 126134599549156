import {useState} from "react";
import {useSelector} from "react-redux";

import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import Textarea from 'react-expanding-textarea'

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";

import {useMutation, useQuery} from "@apollo/react-hooks";

import {buttonStyles, cellStyles, inputStyles, GlassCard, OnLoadViewer, Modal, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, dateToString} from "gih_web_common";

import {GET_CHARITY_COMMENTS, ADD_CHARITY_COMMENT, UPDATE_CHARITY_COMMENT, DELETE_CHARITY_COMMENT} from "../../utils/graphql/admin-charity";
import {string2DateOrNull} from "../../utils/date";
import {statuses, statusOptions} from "../../utils/comments";
import {evictAllDependentOnComments} from "../../utils/graphql/cache";


export function Comments() {

    const charity = useSelector(state => state.charity);
    const [current, setCurrent] = useState(null)

    const [deleteComment] = useMutation(DELETE_CHARITY_COMMENT, { update: evictAllDependentOnComments })

    function del(commentId) {
        return deleteComment({
            variables: {
                commentId: commentId
            }
        }).then(r => {
            refetch()
        })
    }

    const {data, refetch} = useQuery(GET_CHARITY_COMMENTS, {
        variables: {
            charityId: charity.id
        },
    })

    const comments = data?.portalCharityComments

    return (
        <div>
            <GlassCard width="max-w-screen-2xl">
                <div className="text-2xl font-bold mb-2">
                    Comments
                </div>
                <OnLoadViewer loading={comments === null || comments === undefined}>
                    { comments &&
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={cellStyles.dateTime}>Created</TableCell>
                                    <TableCell sx={cellStyles.dateTime}>Edited</TableCell>
                                    <TableCell sx={cellStyles.icon}>Status</TableCell>
                                    <TableCell sx={cellStyles.dateTime}>Follow up</TableCell>
                                    <TableCell sx={cellStyles.person}>Added by</TableCell>
                                    <TableCell sx={cellStyles.freeWide}>Message</TableCell>
                                    <TableCell sx={cellStyles.actions}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { comments.map(comment => {
                                    const status = statuses[comment.status]
                                    const Icon = status.icon
                                    return (
                                        <TableRow key={comment._id}>
                                            <TableCell>{dateToString(comment.createdAt)}</TableCell>
                                            <TableCell align="left">{dateToString(comment.updatedAt)}</TableCell>
                                            <TableCell align="center"><Icon fontSize="large" className={`text-${status.colour}-400`}/></TableCell>
                                            <TableCell align="left">{dateToString(comment.followUp)}</TableCell>
                                            <TableCell align="left">{comment.createdBy}</TableCell>
                                            <TableCell align="left"><span className="text-xs">{comment.text}</span></TableCell>
                                            <TableCell>
                                                <div className="flex flex-col lg:flex-row gap-2 justify-center">
                                                    <button onClick={() => { setCurrent(comment) }} className={buttonStyles.altLg}>
                                                        <ModeEditOutlineIcon/> Edit
                                                    </button>
                                                    <button onClick={() => { return del(comment._id) }} className={buttonStyles.redLg}>
                                                        <DeleteForeverIcon/> Delete
                                                    </button>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )})
                                }
                                <TableRow key="Trailer">
                                    <TableCell>
                                        <button onClick={() => { setCurrent({ text: "", status: "", _id: null, followUp: null }) }} className={buttonStyles.altLg}>
                                            <AddIcon/> Add
                                        </button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    }
                </OnLoadViewer>
            </GlassCard>

            { current &&
                <CommentModal comment={current} onComplete={
                    (changed) => {
                        setCurrent(null)
                        if (changed) { refetch() }
                    }
                } />
            }
        </div>
    )
}

function CommentModal({comment, onComplete}) {

    const charity = useSelector(state => state.charity);

    const [text, setText] = useState(comment.text);
    const [status, setStatus] = useState(comment.status);
    const [followUp, setFollowUp] = useState(string2DateOrNull(comment.followUp));

    const [addComment] = useMutation(ADD_CHARITY_COMMENT, { update: evictAllDependentOnComments })
    const [updateComment] = useMutation(UPDATE_CHARITY_COMMENT, { update: evictAllDependentOnComments })

    function execute() {

        const newComment = {
            text: text,
            status: status,
            followUp: followUp
        }

        let action;

        if (comment._id === null) {
            action = addComment({
                variables: {
                    charityId: charity.id,
                    comment: newComment
                }
            })
        } else {
            action = updateComment({
                variables: {
                    commentId: comment._id,
                    comment: newComment
                }
            })
        }

        return action.then(r => { onComplete(true) })
    }

    return (
        <Modal
            title={comment._id !== null ? "Edit comment" : "New comment"}
            onDismiss={() => onComplete(false)}
            footer={
                <button
                    className={buttonStyles.altLg}
                    type="button"
                    disabled={text.length < 1 || status === ""}
                    onClick={execute}
                >
                    { comment._id !== null ? <span><ModeEditOutlineIcon/> Update</span> : <span><AddIcon/> Add</span> }
                </button>
            }
        >
            <div className="flex flex-row w-full gap-3 items-center">
                <span className="whitespace-nowrap">Follow up</span>
                <DatePicker
                  id="followUpDate"
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  timeCaption="Time"
                  dateFormat="d MMMM, yyyy HH:mm"
                  calendarStartDay={1}
                  minDate={new Date()}
                  startDate={followUp}
                  selected={followUp}
                  onChange={date => setFollowUp(date)}
                  placeholderText="Select a date and time for a follow up if required"
                  autoComplete="off"
                  className="text-fg-default bg-bg-paper"
                />
            </div>
            <div className="flex flex-row gap-3 items-center">
                <span className="whitespace-nowrap">Status</span>
                <select value={status} onChange={event => setStatus(event.target.value)} className={inputStyles.select}>
                    { statusOptions.map(option => <option key={option.value} value={option.value}>{option.label}</option> )}
                </select>
            </div>
            <div className="flex w-full">
                <Textarea
                    className={`w-full ${inputStyles.text}`}
                    id="comment_text"
                    name="comment_text"
                    style={{resize:"none"}}
                    rows={6}
                    placeholder="Type your comment here..."
                    value={text}
                    onChange={(event) => setText(event.target.value)}
                    autoComplete="off"
                />
            </div>
        </Modal>
    )
}
