import {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import {useMutation} from "@apollo/react-hooks";

import {buttonStyles, inputStyles, FormWrapper, FormInput, FormErrorText, FormPrimaryButton, AlertModal, nameStr, GlassCard} from "gih_web_common";

import {UPDATE_USER_DETAILS} from "../../utils/graphql/user";
import {logScreenViewEvent, logActionSuccess, logActionGraphQLFailure, logFormValidationFailure} from "../../utils/analytics";
import {ACTIONS, SCREEN_NAME, SCREEN_CLASS} from "../../utils/analyticsConstants";
import {nullIfEmpty} from "../../utils/address";

import {TitleAndBackButton} from "../common/elements";
import {dayOptions} from "../activities/form/repetition";

import {PhoneVerificationModal} from "./phone-notifications-modal";
import {ChangePasswordModal} from "./change-password-modal";


export default function AccountSettingsPage() {

    const user = useSelector(state => state.user);
    const charity = useSelector(state => state.charity);

    const dispatch = useDispatch();

    const [updateUserDetailsMutation] = useMutation(UPDATE_USER_DETAILS)

    const [enableConcierge, setEnableConcierge] = useState(user.enableConcierge);
    const [smsNotificationDays, setSmsNotificationDays] = useState(user.smsNotificationDays);
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [showVerifyPhoneModal, setShowVerifyPhoneModal] = useState(false);
    const [outcome, setOutcome] = useState(null);

    const updateUserDetails = (values) => {
        return updateUserDetailsMutation({
            variables: {
                firebaseId: user.firebaseId,
                firstName: nullIfEmpty(values.firstName),
                lastName: nullIfEmpty(values.lastName),
                alias: nullIfEmpty(values.alias),
                enableConcierge: enableConcierge,
                smsNotificationDays: smsNotificationDays
            }
        }).then((response) => {
            const updates = response.data.portalUpdateUserDetails;
            logActionSuccess(ACTIONS.userUpdate, `for ${nameStr(updates)} (${updates.smsNotificationPhone})`);
            dispatch({
                type: "SET_USER",
                payload: {
                    ...user,
                    ...updates,
                }
            });
        }, e => {
            logActionGraphQLFailure(ACTIONS.userUpdate, e);
            throw e;
        });
    }

    useEffect(() => {
        logScreenViewEvent(SCREEN_NAME.accountSettings, SCREEN_CLASS.user)
    }, [])

    return (
        <div className="flex justify-center items-center overflow-y-auto" style={{ height: 'calc(100vh - 64px)' }}>
            <div className="mx-auto">
                <GlassCard width="max-w-2xl">
                    <TitleAndBackButton title="Account settings" />
                    <div className="px-2"><span className="font-bold">Charity</span><span className="ml-2 text-sm">{user.charityId.name}</span></div>
                    <div className="px-2"><span className="font-bold">User</span><span className="ml-2 text-sm">{user.email}</span></div>
                    <FormWrapper
                        onSubmit={updateUserDetails}
                        onValidationFailure={logFormValidationFailure}
                        id="userSettingsForm"
                        initialValues={{
                            firstName: user.firstName,
                            lastName: user.lastName,
                            alias: user.alias ?? ""
                        }}
                        className="space-y-6 py-2"
                    >
                        <div className="flex w-full space-x-3">
                            <FormInput
                                id="firstName"
                                name="firstName"
                                type="text"
                                autoComplete="name"
                                placeholder="first name"
                                label="Your first name"
                                required
                                rootClassName="w-full"
                            />
                            <FormInput
                                id="lastName"
                                name="lastName"
                                type="text"
                                autoComplete="name"
                                placeholder="last name"
                                label="Your last name"
                                required
                                rootClassName="w-full"
                            />
                            <FormInput
                                id="alias"
                                name="alias"
                                type="text"
                                placeholder="alias"
                                label="Alias for messaging"
                                rootClassName="w-full"
                            />
                        </div>
                        <label className="flex items-center">
                            <input
                                id="enableConcierge"
                                type="checkbox"
                                className={inputStyles.checkbox}
                                onChange={e => setEnableConcierge(!enableConcierge)}
                                color="primary"
                                checked={enableConcierge}
                            />
                            <span className="ml-2 text-sm">Send me a daily reminder e-mail if there are unread messages or new volunteers requiring attention.</span>
                        </label>
                
                        <FormErrorText/>

                        { charity.features.allowSmsNotifications &&
                        <div className="flex flex-wrap p-2 gap-2 items-center w-full sm:grid sm:grid-cols-2 border border-gray">
                            { (user.smsNotificationPhone === null || smsNotificationDays === null) ? (
                            <>
                                <div className="mx-auto">
                                    <button onClick={()=> {setShowVerifyPhoneModal(true)}} className={buttonStyles.alt}>Setup phone notification</button>
                                </div>
                                <label className="flex">
                                    <span className="ml-2 text-sm">Enter and verify your mobile number to receive timely notification of new volunteer activity by text.</span>
                                </label>
                            </>
                            ) : (
                            <>
                                <div className="ml-2 text-sm py-3 min-w-[20em]">{(smsNotificationDays !== 0)
                                    ? `We will notify new activity to ${user.smsNotificationPhone} between 09:00 - 16:00 on the selected days of the week.`
                                    : `Notification of new activity to ${user.smsNotificationPhone} is paused - select one or more days of the week to re-enable.`}
                                </div>
                                <div className="flex px-3 grid grid-cols-7">
                                    { dayOptions.map((item, index) => {
                                        return (
                                            <div key={item.id} className="flex flex-col justify-center w-[2em]">
                                                <input
                                                    id={item.id}
                                                    name="days"
                                                    type="checkbox"
                                                    className={inputStyles.checkbox}
                                                    checked={smsNotificationDays & (1 << index)}
                                                    value={1 << index}
                                                    onChange={() => setSmsNotificationDays(smsNotificationDays ^ (1 << index))}
                                                />
                                                <label className="pt-2 text-sm">{item.label}</label>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="mx-auto">
                                    <button onClick={()=> {setSmsNotificationDays(null)}} className={buttonStyles.alt}>Remove mobile number</button>
                                </div>
                            </>
                            )}
                        </div>
                        }

                        <div className="flex w-full justify-center grid grid-cols-2 gap-4">
                            <FormPrimaryButton type="submit" form="userSettingsForm">
                                Save changes
                            </FormPrimaryButton>
                            <button onClick={()=> {setShowChangePasswordModal(true)}} className={buttonStyles.altLg}>Change password</button>
                        </div>
                    </FormWrapper>

                    { showVerifyPhoneModal &&
                    <PhoneVerificationModal
                        onComplete={(outcome) => {
                            setShowVerifyPhoneModal(false)
                            if (outcome !== null) {
                                setSmsNotificationDays(outcome.smsNotificationDays)
                                setOutcome(outcome)
                            }
                        }}
                    />
                    }

                    { showChangePasswordModal &&
                    <ChangePasswordModal
                        onComplete={(outcome) => {
                            setShowChangePasswordModal(false)
                            if (outcome !== null) {
                                setOutcome(outcome)
                            }
                        }}
                    />
                    }

                    { outcome !== null &&
                    <AlertModal
                        type={outcome.type}
                        message={outcome.message}
                        onDismiss={ () => {
                            setOutcome(null)
                        }}
                    />
                    }
                </GlassCard>
            </div>
        </div>
    )
}
