import {useState} from "react";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {useEffect} from "react";
import {useMediaQuery} from 'react-responsive'

import {useQuery} from "@apollo/react-hooks";

import {buttonStyles, inputStyles, RefreshButton, GlassPreviewCard, GroupBox, Image, Modal, OnLoadViewer, addressToString, dateToStringRelative, Lozenge, SearchBox} from "gih_web_common";

import {GET_ALL_CHARITY_PROFILES} from "../../utils/graphql/admin-charity";
import {evictAllDependentQueries} from "../../utils/graphql/cache";
import {modeFromPath} from "../../utils/navigation";
import {logScreenViewEvent} from "../../utils/analytics";
import {SCREEN_NAME, SCREEN_CLASS} from "../../utils/analyticsConstants";

import {graphQLCache} from "../../index";


export const filterOptions = [
    { label: "All", value: "all", dependencies: { charities: true }, canSearch: true },
    { label: "All live", value: "live", dependencies: { comments: true }, canSearch: true },
    { label: "Published campaign", value: "visible", dependencies: { campaigns: true }, canSearch: false },
    { label: "Active - NO published campaign", value: "activeNotVisible", dependencies: { campaigns: true }, canSearch: false },
    { label: "Chasing", value: "chasing", dependencies: { comments: true }, canSearch: true },
    { label: "Waiting", value: "waiting", dependencies: { comments: true }, canSearch: true },
    { label: "Dormant", value: "dormant", dependencies: { comments: true }, canSearch: true },
    { label: "Dead", value: "dead", dependencies: { comments: true }, canSearch: true },
    { label: "No status", value: "noStatus", dependencies: { comments: true, charities: true }, canSearch: false },
    { label: "No admins", value: "noAdmins", dependencies: { charities: true }, canSearch: false },
    { label: "Provisional", value: "provisional", dependencies: { charities: true }, canSearch: false },
]


export default function AdminCharityProfiles(){

    const isNarrow = useMediaQuery({ query: '(max-width: 640px)' })
    const history = useHistory();
    const dispatch = useDispatch();

    const currentCharity = useSelector(state => state.charity);
    const imageURLPrefix = useSelector(state => state.cfg?.imageURLPrefix);
    const filter = useSelector(state => state.charityFilter);
    const givingMode = useSelector(state => state.givingMode);

    const [showModal, setShowModal] = useState(false);
    const [filterText, setFilterText] = useState("");

    const {loading, data} = useQuery(GET_ALL_CHARITY_PROFILES, {
        variables: {
            filter: filter,
            filterText: filterText ?? "",
        }
    })

    useEffect(() => {
        logScreenViewEvent(SCREEN_NAME.charitySelect, SCREEN_CLASS.charity)
    }, []);

    function setCurrentCharity(charity) {
        dispatch({type: "SET_CHARITY", payload: charity})
        setShowModal(true)
    }

    return (
        <div className="w-full" style={{ height: 'calc(100vh - 64px)' }}>
            <div className="h-[200px] border-b border-table-border mb-2 p-1 sm:p-4 space-y-2">
                { isNarrow ?
                <>
                    { !currentCharity.provisional &&
                    <div className="mx-auto w-fit">
                        <Image rmtPrefix={imageURLPrefix} rmt={currentCharity.logoPicture} what="logoSmall" />
                    </div>
                    }
                    <div className="text-base font-bold text-center text-fgCard-default">
                        {currentCharity.name}
                    </div>
                    <Link to="/charity/create" className={buttonStyles.altLg}>
                        Create new charity
                    </Link>
                </>
                :
                <div className="flex flex-row w-fit mx-auto gap-4 items-center">
                    { !currentCharity.provisional &&
                    <div className="mx-auto w-fit">
                        <Image rmtPrefix={imageURLPrefix} rmt={currentCharity.logoPicture} what="logoMedium" />
                    </div>
                    }
                    <div className="flex flex-col max-w-[360px] gap-2">
                        <div className="text-xl font-bold text-center text-fgCard-default">
                            {currentCharity.name}
                        </div>
                        <Link to="/charity/create" className={buttonStyles.altLg}>
                            Create new charity
                        </Link>
                    </div>
                </div>
                }

                <div className="flex flex-row items-center mx-auto w-fit space-x-1 sm:space-x-4">
                    <RefreshButton action={() => evictAllDependentQueries(graphQLCache, item => true)} />
                    <select value={filter} onChange={event => dispatch({type: "SET_CHARITY_FILTER", payload: event.target.value})} className={inputStyles.select}>
                        { filterOptions.map(option => <option key={option.value} value={option.value}>{option.label}</option> )}
                    </select>
                    { filterOptions.find(option => option.value === filter).canSearch &&
                    <SearchBox className="w-full" setText={setFilterText} />
                    }
                </div>
            </div>

            <OnLoadViewer loading={loading}>
                <div className="w-full overflow-y-auto" style={{ maxHeight: 'calc(100vh - 64px - 208px' }}>
                    <div className="p-1 lg:p-4 mx-auto w-fit grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 max-w-screen-2xl">
                        { data?.portalCharityTable.map(charity => (
                            <CharityCard key={charity._id} charity={charity} onSelect={()=>setCurrentCharity(charity)} />
                        ))}
                    </div>
                </div>
            </OnLoadViewer>

            { showModal &&
                <ConfirmationModal onDismiss={(path) => {
                    setShowModal(false)
                    if (path !== null) {
                        const newMode =  modeFromPath(path);
                        if (newMode !== null && newMode !== givingMode) {
                            dispatch({type: "SET_GIVING", payload: newMode});
                        }
                        history.push(path)
                    }}}
                />
            }
        </div>
    )
}

function CharityCard({charity, onSelect}) {

    const imageURLPrefix = useSelector(state => state.cfg?.imageURLPrefix);

    return (
        <GlassPreviewCard rmtPrefix={imageURLPrefix} rmt={charity.provisional ? null : charity.profilePicture} height={640}>
            <GroupBox translucent={true}>
                <Image rmtPrefix={imageURLPrefix} rmt={charity.provisional ? null : charity.logoPicture} what="logoSmall" />
                <Lozenge colour="bg-red-500 w-fit" text={`Created ${dateToStringRelative(charity.createdAt)}`} />
            </GroupBox>
            <GroupBox className="h-24 text-lg font-bold">
                {charity.name}
            </GroupBox>
            { !charity.provisional &&
            <GroupBox className="h-24 text-sm">
                {addressToString(charity.address)}
            </GroupBox>
            }
            <div className="flex flex-row justify-center space-x-4 pt-2">
                <button onClick={onSelect} className={buttonStyles.altLg}>
                    Select
                </button>
            </div>
        </GlassPreviewCard>
   );
}

function ConfirmationModal({onDismiss}) {

    const charity = useSelector(state => state.charity);
    const imageURLPrefix = useSelector(state => state.cfg?.imageURLPrefix);
    const fullAccess = useSelector(state => state.user?.fullAccess);

    return (
        <Modal
            title={null}
            onDismiss={() => onDismiss(null)}
            footer={
                <>
                    <button className={`${buttonStyles.altLg} lg:max-w-[20em]`} type="button" onClick={() => onDismiss("/charity/profile")}>Profile</button>
                    { (!charity.provisional || fullAccess) &&
                    <button className={`${buttonStyles.altLg} lg:max-w-[20em]`} type="button" onClick={() => onDismiss("/charity/users")}>Users</button>
                    }
                    { !charity.provisional &&
                    <button className={`${buttonStyles.altLg} lg:max-w-[20em]`} type="button" onClick={() => onDismiss("/activities/upcoming")}>Activities</button>
                    }
                    { !charity.provisional && charity.features.enableGiving &&
                    <button className={`${buttonStyles.primaryLg} lg:max-w-[20em]`} type="button" onClick={() => onDismiss("/campaigns/ongoing")}>Campaigns</button>
                    }
                </>
            }
        >
            { !charity.provisional &&
            <div className="p-3">
                <Image rmtPrefix={imageURLPrefix} rmt={charity.logoPicture} what="logoMedium" />
            </div>
            }
            <p className="my-2 text-fgCard-default text-lg leading-relaxed">
                "{charity.name}" is now the active charity.
            </p>
        </Modal>
    )
}
