import {useEffect} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useSelector} from "react-redux";

import {useQuery, useMutation} from "@apollo/react-hooks";

import {OnLoadViewer} from "gih_web_common";

import {GET_CHARITY_DETAIL_BY_ID} from "../../utils/graphql/charity";
import {GET_CAMPAIGN_BY_ID, UPDATE_CAMPAIGN} from "../../utils/graphql/campaign";
import {logScreenViewEvent, logActionSuccess, logActionGraphQLFailure} from "../../utils/analytics";
import {ACTIONS, SCREEN_NAME, SCREEN_CLASS} from "../../utils/analyticsConstants";
import {evictAllDependentOnCampaign} from "../../utils/graphql/cache";

import {CampaignDetailsForm} from "./form/form";


export default function EditCampaign() {

    const {id} = useParams();
    const history = useHistory();

    const currentCharity = useSelector(state => state.charity);

    const [updateCampaign] = useMutation(UPDATE_CAMPAIGN, { update: evictAllDependentOnCampaign })

    const {loading: campaignQueryInProgress, data: campaignQueryResult, error: campaignQueryError} = useQuery(GET_CAMPAIGN_BY_ID, {
        variables: { campaignId: id },
        fetchPolicy: "network-only"
    });

    const {loading: charityQueryInProgress, data: charityQueryResult} = useQuery(GET_CHARITY_DETAIL_BY_ID, {
        variables: { charityId: currentCharity.id},
    });

    useEffect(() => {
        logScreenViewEvent(SCREEN_NAME.campaignCreate, SCREEN_CLASS.campaign)
    }, [])

    const onValidated = (campaign) => {
        return updateCampaign({
            variables: {
                campaignId: id,
                campaign: campaign
            }
        }).then(r => {
            logActionSuccess(ACTIONS.campaignUpdate, `Updated campaign ${id}`);
            history.push(`/campaign/${id}`)
        }, e => {
            logActionGraphQLFailure(ACTIONS.campaignUpdate, e);
            throw e;
        });
    }

    return (
        <div className="p-1 lg:p-4 flex flex-col overflow-y-auto" style={{ height: 'calc(100vh - 64px)' }}>
            <div className="mx-auto">
                <OnLoadViewer loading={charityQueryInProgress || campaignQueryInProgress} error={campaignQueryError}>
                    { campaignQueryResult && charityQueryResult &&
                    <CampaignDetailsForm
                        initial={
                            {
                                campaign: campaignQueryResult.portalFindCampaignById,
                                charity: charityQueryResult.portalFindCharityById,
                            }
                        }
                        editing={true}
                        onValidated={onValidated}
                    />
                    }
                </OnLoadViewer>
            </div>
        </div>
    )
}
