import {useState} from "react";

import {useQuery, useMutation} from "@apollo/react-hooks";

import {OnLoadViewer, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, RefreshCell, CheckCell, dateToString, dateToStringNoTime, cellStyles, CampaignProgressBar} from "gih_web_common";

import {GET_CAMPAIGN_DASHBOARD, SET_CAMPAIGN_FLAG} from "../../../utils/graphql/admin-giving";
import {seriesToColour, PieChart} from "../../../utils/stats";
import {evistAllDependentOnCampaignFlags} from "../../../utils/graphql/cache";

import {CharityCell, BoldMetric} from "../../common/elements";


function makeSummary(campaigns) {

    const summary = {
        count: 0,
        fundraisers: 0,
        withFundraiser: 0,
        charityRatings: new Map(),
    };

    campaigns.forEach(campaign => {
        const count = summary.charityRatings.get(campaign.charityId.rating) ?? 0;
        summary.charityRatings.set(campaign.charityId.rating, count + 1);
        summary.fundraisers += campaign.fundraisers;
        summary.withFundraiser += (campaign.fundraisers > 0);
        summary.count += 1;
    });

    summary.charityRatings = Array.from(summary.charityRatings.entries()).map(([label, count]) => ({ label: label, count: count }));

    return summary;
}

export function CampaignsSummary({days}) {

    const [summary, setSummary] = useState(null);

    const {refetch, loading, error} = useQuery(GET_CAMPAIGN_DASHBOARD, {
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setSummary(makeSummary(data.campaignDashboard)),
    });

    return (
        <OnLoadViewer loading={loading} error={error}>
            { summary &&
            <>
                <div className="flex flex-wrap text-base sm:text-lg items-center gap-1">
                    <BoldMetric prefix="There are" value={summary.count} suffix="active campaign" />
                    <BoldMetric prefix="with" value={summary.fundraisers} suffix="active fundraiser" />
                    <BoldMetric prefix="in" value={summary.withFundraiser} suffix="campaign" />                    
                </div>
                <div className="flex flex-wrap items-center gap-4 w-min sm:w-fit mx-auto">
                    <PieChart
                        title="Active campaigns by charity rating"
                        labels={summary.charityRatings.map(bucket => bucket.label)}
                        values={summary.charityRatings.map(bucket => bucket.count)}
                        size={320}
                        palette={summary.charityRatings.map(bucket => seriesToColour(bucket))}
                    />
                </div>
            </>
            }
        </OnLoadViewer>
    );
}

export function ActiveCampaigns() {

    const [campaigns, setCampaigns] = useState(null);

    const {refetch} = useQuery(GET_CAMPAIGN_DASHBOARD, {
        notifyOnNetworkStatusChange: true,
        onCompleted: data => setCampaigns(data.campaignDashboard),
    });

    const [setCampaignFlag] = useMutation(SET_CAMPAIGN_FLAG, { update: evistAllDependentOnCampaignFlags });

    function setShared(id, checked) {
        setCampaignFlag({
            variables: {
                campaignId: id,
                flag: 'sharedToSocial',
                value: !checked,
            }
        });
    }

    return (
        <>
            <OnLoadViewer loading={campaigns === null}>
                { campaigns &&
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <RefreshCell sx={cellStyles.dateTime} action={refetch} text="Created" />
                                <TableCell sx={cellStyles.charity}>Charity</TableCell>
                                <TableCell sx={cellStyles.icon}>Rating</TableCell>
                                <TableCell sx={cellStyles.freeMedium}>Name</TableCell>
                                <TableCell sx={cellStyles.date}>Ending</TableCell>
                                <TableCell sx={cellStyles.fixedWide}>Progress</TableCell>
                                <TableCell sx={cellStyles.icon}>Payouts</TableCell>
                                <TableCell sx={cellStyles.icon}>Video</TableCell>
                                <TableCell sx={cellStyles.icon}>ThankYou</TableCell>
                                <TableCell sx={cellStyles.icon}>GiftAid</TableCell>
                                <TableCell sx={cellStyles.icon}>Fundraisers</TableCell>
                                <TableCell sx={cellStyles.icon}>Shared to social</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { campaigns.map(campaign => (
                            <TableRow key={campaign._id}>
                                <TableCell align="left">{dateToString(campaign.createdAt)}</TableCell>
                                <CharityCell charity={campaign.charityId} />
                                <TableCell align="center">
                                    <div className="rounded-lg p-1 text-white w-fit mx-auto" style={{ backgroundColor: seriesToColour({ label: campaign.charityId.rating }) }}>
                                        {campaign.charityId.rating}
                                    </div>
                                </TableCell>
                                <TableCell align="left">{campaign.name}</TableCell>
                                <TableCell align="left">{dateToStringNoTime(campaign.endDate)}</TableCell>
                                <TableCell align="center">
                                    <CampaignProgressBar
                                        total={campaign.total}
                                        target={campaign.target}
                                        supporters={campaign.uniqueDonors}
                                    />
                                </TableCell>
                                <CheckCell checked={campaign.charityId.stripeAccountStatus === 'enabled'} />
                                <CheckCell checked={campaign.videoURL !== null} />
                                <CheckCell checked={campaign.thankYou} />
                                <CheckCell checked={campaign.charityId.giftAidEligible} />
                                { (campaign.fundraisers > 0) ?
                                <TableCell align="center">
                                    <div className="font-bold text-3xl text-green-300">{campaign.fundraisers}</div>
                                </TableCell>
                                :
                                <CheckCell checked={false} />
                                }
                                <CheckCell checked={campaign.sharedToSocial} onClick={() => setShared(campaign._id, campaign.sharedToSocial)} />
                            </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                }
            </OnLoadViewer>
        </>
    );
}
