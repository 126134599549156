import {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {useSelector} from "react-redux";

import {useQuery, useLazyQuery, useMutation} from "@apollo/react-hooks";

import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import PublishIcon from '@mui/icons-material/Publish';

import {buttonStyles, CustomButton, GlassCard, OnLoadViewer, addressToString} from "gih_web_common";

import {
    GET_CHARITY_DETAIL_BY_ID,
    GET_CHARITY_PAYOUT_STATUS_BY_ID,
    GET_CHARITY_EXPRESS_DASHBOARD_LINK_BY_ID,
    CONFIGURE_PAYOUTS,
} from "../../utils/graphql/charity";

import {logScreenViewEvent} from "../../utils/analytics";
import {SCREEN_NAME, SCREEN_CLASS} from "../../utils/analyticsConstants";

import {TitleAndBackButton} from "../common/elements";

import {CharityDetailPreview} from "./preview";
import {SitesList} from "./sites";
import {Comments} from "./comments";
import {GiftAidReportModal} from "./giftAidReport";
import {GiftAidSubmissionsModal} from "./giftAidSubmissions";
import {ContactSummary} from "./contacts";
import {ConfirmLegalStatus} from "./legalStatus";


function getItems(charity) {
    const items = [
        { title: "Name of charity / non-profit organisation", text: charity.name },
        { title: "Registered charity number", text: charity.registration },
        { title: "Company registration number", text: charity.companyNumber },
        { title: "HMRC identifier", text: charity.hmrcId },
        { title: "GiftAid", text: (charity.giftAidEligible && charity.features.enableGiving) ? "Yes" : null },
        { title: "Automatically submit GiftAid claims to HMRC", text: (charity.giftAidAutoClaim && charity.features.enableGiving) ? "Yes" : null },
        { title: "Website", text: charity.website },
        { title: "Address", text: addressToString(charity.address) },
        { title: "Place name for distance based searches", text: charity.locationName },
        { title: "Description", text: charity.fullDescription },
        { title: "Tag line", text: charity.briefDescription },
        { title: "Keywords", text: charity.keywords.join(', ') },
        /* VOLUNTEERING { title: "Auto-reply", text: charity.defaultAutoReply }, */
    ];
    charity.contacts.forEach((contact, index) => {
        items.push({
            title: `Contact ${index+1}`,
            text: <ContactSummary contact={contact} />,
        });
    });
    let odd = false
    items.forEach(item => {
        item.odd = odd
        if (item.text !== null) {
            odd = !odd
        }
    });
    return items;
}

export default function CharityProfilePage() {

    const currentCharity = useSelector(state => state.charity);
    const fullAccess = useSelector(state => state.user?.fullAccess);

    const {loading: charityQueryInProgress, data: charityData} = useQuery(GET_CHARITY_DETAIL_BY_ID, {
        variables: { charityId: currentCharity.id },
    });

    const charity = charityData?.portalFindCharityById

    useEffect(() => {
        logScreenViewEvent(SCREEN_NAME.charityProfile, SCREEN_CLASS.charity)
    }, [])

    return (
        <div className="p-1 lg:p-4 flex flex-col overflow-y-auto" style={{ height: 'calc(100vh - 64px)' }}>
            <OnLoadViewer loading={charityQueryInProgress}>
                { charity &&
                <div className="mx-auto max-w-full space-y-2">
                    <TitleAndBackButton title="Charity profile" />
                    <div className="flex flex-wrap mx-auto gap-2 lg:grid lg:grid-cols-[minmax(600px,1200px)_380px]">
                        <DetailsTable charity={charity} />
                        <div className="mx-auto w-fit">
                            <CharityDetailPreview
                                charity={charity}
                                imageLocalURL={null}
                                logoLocalURL={null}
                            />
                        </div>
                    </div>

                    <SitesList />

                    { fullAccess &&
                    <Comments />
                    }
                </div>
                }
            </OnLoadViewer>
        </div>
    )
}

function DetailsTable({charity}) {

    const history = useHistory();

    const [showModal, setShowModal] = useState(null);
    const [confirmLegalStatus, setConfirmLegalStatus] = useState(null);

    const {data: payoutAccountStatus} = useQuery(GET_CHARITY_PAYOUT_STATUS_BY_ID, {
        variables: { charityId: charity._id },
    });

    const [configurePayouts] = useMutation(CONFIGURE_PAYOUTS, {
        variables: {
            charityId: charity._id
        },
        onCompleted: r => {
            window.location.assign(r.configurePayouts)
        }
    })

    const [requestExpressDashboardLink] = useLazyQuery(GET_CHARITY_EXPRESS_DASHBOARD_LINK_BY_ID, {
        variables: {
            charityId: charity._id
        },
        onCompleted: r => {
            console.log("Opening charity express dashboard");
            window.open(r.portalCharityExpressDashboardLink, '_blank', 'noreferrer');
        }
    })

    function onConfigurePayouts() {
        if (!charity.regNosLocked) {
            return new Promise((resolve, reject) => {
               setConfirmLegalStatus({
                    details: charity,
                    agreed: () => {
                        setConfirmLegalStatus(null);
                        resolve(configurePayouts());
                    },
                    cancel: jumpToEditProfile => {
                        setConfirmLegalStatus(null);
                        if (jumpToEditProfile)
                            history.push("/charity/edit");
                        resolve(true);
                    }
                });
            });
        } else {
            return configurePayouts();
        }
    }

    return (
        <>
            <GlassCard width="w-full">
                <dl>
                    { getItems(charity).map(item => (item.text ?
                        <div key={item.title} className={`${item.odd ? "bg-bg-secondary" : "bg-bg-tertiary"} px-4 py-4 lg:grid lg:grid-cols-2`}>
                            <dt className="text-sm font-medium text-fgCard-secondary mr-2">{item.title}</dt>
                            <dd className="text-sm text-fgCard-default whitespace-pre-wrap">{item.text}</dd>
                        </div>
                        :
                        null
                    ))}

                    <div className="flex bg-bg-secondary px-4 py-5 justify-items-center grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-3">
                        <Link to={`/charity/edit`} className={buttonStyles.altLg}>
                            <ModeEditOutlineIcon/><span className="ml-1">Edit profile</span>
                        </Link>
                        { charity.features.enableGiving && payoutAccountStatus &&
                        <>
                            <CustomButton onClick={onConfigurePayouts} className={buttonStyles.primaryLg}>
                                <AccountBalanceIcon className="mr-1"/>
                                <span>Configure payouts</span>
                                { (payoutAccountStatus.portalCharityPayoutAccountStatus === 'enabled') ?
                                    <CheckCircleIcon fontSize="large" className="ml-3 text-green-400 bg-white rounded-full p-1/2" />
                                  : <WarningIcon fontSize="large" className="ml-3 text-red-500 bg-white p-1/2" />
                                }
                            </CustomButton>
                            { (payoutAccountStatus.portalCharityPayoutAccountStatus === 'enabled' || payoutAccountStatus.portalCharityPayoutAccountStatus === 'setup_complete') &&
                            <CustomButton onClick={() => requestExpressDashboardLink()} className={buttonStyles.primaryLg}>
                                <DashboardIcon/><span className="ml-1">Payments dashboard</span>
                            </CustomButton>
                            }
                            { charity.giftAidEligible &&
                            <button onClick={() => setShowModal('report')} className={buttonStyles.primaryLg}>
                                <SaveAltIcon/>
                                <span className="ml-1">GiftAid report</span>
                            </button>
                            }
                            { charity.giftAidEligible && charity.hmrcId && charity.giftAidAutoClaim &&
                            <button onClick={() => setShowModal('submissions')} className={buttonStyles.primaryLg}>
                                <PublishIcon/>
                                <span className="ml-1">HMRC GiftAid history</span>
                            </button>
                            }
                        </>
                        }
                    </div>
                </dl>
            </GlassCard>

            { showModal === 'report' &&
            <GiftAidReportModal onDismiss={ () => setShowModal(null) } />
            }

            { showModal === 'submissions' &&
            <GiftAidSubmissionsModal onDismiss={ () => setShowModal(null) } />
            }

            { confirmLegalStatus &&
            <ConfirmLegalStatus confirm={confirmLegalStatus} />
            }
        </>
    );
}
