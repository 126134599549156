import {useState} from "react";

import {inputStyles, buttonStyles, ModalForm, FormInput, FormPrimaryButton, FormCheckBox} from "gih_web_common";

import {logFormValidationFailure} from "../../utils/analytics";
import {nullIfEmpty} from  "../../utils/address";


function getFormValues(initial) {
    return {
        firstName: initial.firstName,
        lastName: initial.lastName,
        phone: initial.phone ?? "",
        email: initial.email ?? "",
        preferences_marketing: initial.preferences.marketing,
        preferences_customer_success: initial.preferences.customerSuccess,
    };
}

const roles = [
    'fundraiser', 'marketing', 'founder', 'trustee', 'finance', 'manager', 'volunteering', 'other'
];

export function ContactModal({initial, isEditing, onComplete}) {

    const [formValues, setFormValues] = useState(getFormValues(initial));
    const [role, setRole] = useState(initial.role);

    function getValues() {
        return {
            firstName: formValues.firstName.trim(),
            lastName: formValues.lastName.trim(),
            phone: nullIfEmpty(formValues.phone),
            email: nullIfEmpty(formValues.email),
            role: role,
            preferences: {
                marketing: formValues.preferences_marketing,
                customerSuccess: formValues.preferences_customer_success,
            },
        };
    }

    function onSubmit() {
        return new Promise(async (resolve, reject) => {
            if (!role) {
                reject("Please select a role for this contact");
            } else {
                resolve(onComplete(getValues()));
            }
        });
    }

    return (
        <ModalForm
            title={isEditing ? "Edit contact" : "New contact"}
            id="contactForm"
            onDismiss={() => onComplete(null)}
            onSubmit={onSubmit}
            initialValues={getFormValues(initial)}
            onValueChange={values => setFormValues(values)}
            onValidationFailure={logFormValidationFailure}
            footer={
                <div className="flex flex-row gap-4">
                    <button className={buttonStyles.redLg} type="button" onClick={() => onComplete(null)}>
                        Cancel
                    </button>
                    <FormPrimaryButton type="submit" form="contactForm">
                        {isEditing ? "Save changes": "Add"}
                    </FormPrimaryButton>
                </div>
            }
        >
            <div className="flex w-full space-x-3 items-end">
                <FormInput
                    id="firstName"
                    name="firstName"
                    type="text"
                    placeholder="First name"
                    label="First name"
                    rootClassName="w-full"
                    required
                />
                <FormInput
                    id="lastName"
                    name="lastName"
                    type="text"
                    placeholder="Last name"
                    label="Last name"
                    rootClassName="w-full"
                    required
                />
            </div>
            <div className="flex w-full space-x-3 items-end">
                <FormInput
                    id="phone"
                    name="phone"
                    type="text"
                    placeholder="Phone number"
                    label="Phone/mobile number"
                    rootClassName="w-full"
                />
                <FormInput
                    id="email"
                    name="email"
                    type="text"
                    placeholder="E-mail"
                    label="E-mail address"
                    rootClassName="w-full"
                />
            </div>
            <div className="flex flex-wrap w-full gap-3">
                <label className="flex items-center text-xs sm:text-base space-x-2">
                    <span>Role</span>
                    <select value={role ?? ""} onChange={event => setRole(event.target.value)} className={inputStyles.select}>
                        <option value="">--</option>
                        { roles.map(role => <option key={role} value={role}>{role}</option> )}
                    </select>
                </label>
                <div className="flex flex-col gap-2">
                    <FormCheckBox
                        id="preferences_marketing"
                        name="preferences_marketing"
                        label="Send marketing e-mails"
                    />
                    <FormCheckBox
                        id="preferences_customer_success"
                        name="preferences_customer_success"
                        label="Send customer success e-mails"
                    />
                </div>
            </div>
        </ModalForm>
    );
}
