import {gql} from "@apollo/client";


export const GET_WEB_REQUEST_SUMMARY_COUNTS = gql`
    query webRequestSummaryCounts($start: DateTime!, $end: DateTime!, $operation: String!) {
        webRequestSummaryCounts(start: $start, end: $end, operation: $operation) {
            host
            origins {
                referrer
                attribution
                what
                targetCampaign {
                    _id
                    name
                }
                count
            }
        }
    }
`;
